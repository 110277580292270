import { Route, Routes } from "react-router-dom";
import { HouseholdsPage } from "features/households";
import { Login, SetupTFAPage, VerifyCodePage } from "features/auth";
import { AdminManagePage } from "features/admin";
import CreateRiskTolerance from "features/riskTolerance/components/createRiskTolerance";
import { NotFound } from "features/misc";
import {
  ClientCreateRiskTolerance,
  CreateRiskToleranceLink,
} from "features/riskTolerance/components";
import { CreateInvestmentPolicyStatement } from "features/createInvestmentPolicyStatement";
import { HouseholdParticipants } from "features/householdParticipants";
import { ParticipantPage } from "features/participant";
import { KnowYourClientPage } from "features/knowYourClient";
import { UploadAMLDocumentPage } from "features/documentUpload";
import { CreateBankAccountPage } from "features/createBankAccount";
import { CreateTradingAccountPage, ViewTradingAccountPage } from "features/createTradingAccount";
import { AdvisorsPage } from "features/advisors";
import { AuthUser } from "features/auth/types";
import { RootState } from "stores/store";
import { useSelector } from "react-redux";
import { UserRoles } from "constants/enums";

export const AppRoutes = () => {
  const participantProps = {
    participantId: "",
  };
  // User Data required to check which home page will be shown
  const userData: AuthUser = useSelector((state: RootState) => state.userAuthStore);

  return (
    <Routes>
      {/* Our Home Page, Need to check the user roles */}
      <Route
        path="/"
        element={
          userData.roles.includes(UserRoles.ADMIN) || userData.roles.includes(UserRoles.MANAGER) ? (
            <AdvisorsPage />
          ) : (
            <HouseholdsPage />
          )
        }
      />
      <Route path="/admin" element={<AdminManagePage />} />
      <Route path="/advisors" element={<AdvisorsPage />} />
      <Route path="/households" element={<HouseholdsPage />} />
      <Route path="/setup-tfa" element={<SetupTFAPage />} />
      <Route path="/verifyCode" element={<VerifyCodePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/households" element={<HouseholdsPage />} />
      <Route path="/households/:advisorId" element={<HouseholdsPage />} />
      <Route path="/participant/:participantId" element={<ParticipantPage />} />
      <Route path="/kyc/:participantId" element={<KnowYourClientPage />} />
      <Route path="/riskToleranceForm" element={<CreateRiskTolerance />} />
      <Route path="/riskToleranceForm/:clientId/" element={<CreateRiskTolerance />} />
      <Route path="clientRiskToleranceForm/:code" element={<ClientCreateRiskTolerance />} />
      <Route path="createRiskToleranceLink/:clientId/" element={<CreateRiskToleranceLink />} />
      <Route path="createRiskToleranceLink" element={<CreateRiskToleranceLink />} />
      <Route path="createIPS/:participantId" element={<CreateInvestmentPolicyStatement />} />
      <Route path="/householdParticipants/:householdId" element={<HouseholdParticipants />} />
      <Route path="/amlDocumentUpload/:participantId" element={<UploadAMLDocumentPage />} />
      <Route path="/createBankAccount/:participantId" element={<CreateBankAccountPage />} />
      <Route
        path="/createInvestmentAccount/:participantId"
        element={<CreateTradingAccountPage />}
      />
      <Route
        path="/investmentAccount/:participantId/:accountId"
        element={<ViewTradingAccountPage />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
