import { usePostQRCodeForTFASetupMutation } from "apis/authApi";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiResponseModel } from "types";
import { notify } from "utils";
import $ from "jquery";
import { onInputBlur, onInputFocus, onLoginLabelClick } from "../utils";
function VerifyCodePage() {
  // Hook for holding the verification code state
  const [verificationCode, setVerificationCode] = useState("");
  // Mutation Hook for verifying the code
  const [verifyCode] = usePostQRCodeForTFASetupMutation();
  // Navigate Hook
  const navigate = useNavigate();

  /**
   * Verify Code Handler
   * @param e
   */
  const handleVerifySubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const inputs = {
      code: verificationCode,
    };

    const { data, error }: ApiResponseModel = await verifyCode(inputs);
    if (data?.isSuccess) {
      navigate("/");
    } else if (error) {
      // Notify User of Errors Messages
      if (error?.data?.errorMessages && error?.data?.errorMessages.length > 0) {
        notify(error?.data?.errorMessages[0], "error");
      }
    }
  };

  // Main Page Component
  return (
    <div className="login-container-new">
      <div className="border-wrap-container">
        <div className="login-main-content-container">
          <div className="login-header-container">
            <button
              onClick={() => navigate(-1)}
              className="btn btn-primary"
              id="return-to-login-btn"
            >
              Go Back
            </button>
            <p className="login-header">Welcome Back</p>
            <p className="login-subheader">Advisor Portal</p>
          </div>
          <div className="login-form-container">
            <form className="login-form" onSubmit={handleVerifySubmit}>
              <p className="login-form-header" id="enter-verification-code-header">
                Please Enter Verification Code
              </p>
              <div className="login-form-input-cont">
                <label className="login-form-label" onClick={onLoginLabelClick}>
                  Code
                </label>
                <input
                  className="login-form-input"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  value={verificationCode}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                  id="verification-code"
                />
              </div>
              {verificationCode && (
                <div className="login-form-btn-submit-cont">
                  <button>Verify</button>
                </div>
              )}
            </form>
          </div>
          <div className="login-footer">
            <p>Issues with SIA Account?</p>
            <p>Contact SIA Private Wealth Development Team</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyCodePage;
