import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { notify } from "utils";

const apiRoute = "bankAccount";

const bankAccountApi = createApi({
    reducerPath: "bankAccountApi",
    baseQuery: fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_API_URL}/api/${apiRoute}`,
      credentials: "include",
    }),
    tagTypes: ["NewClients"],
    endpoints: (builder) => ({
      // Gets all clients from the database
      downloadVoidCheque: builder.query({
        query: (accountId: string) => ({
          url: `/voidCheque/${accountId}`,
          method: "GET",
          responseHandler: async (response) => {
            if (!response.ok){
              notify("An Error Occurred with retrieving the void cheque", "error")
              return;
            }
            var hiddenElement = document.createElement("a");
            var url = window.URL || window.webkitURL;
            var blob = await response.blob();
            var blobPDF = url.createObjectURL(blob);
            hiddenElement.href = blobPDF;
            hiddenElement.target = "_blank";
            hiddenElement.download = `${accountId.slice(0, 6)}_AMLDocument.jpeg`;
            hiddenElement.click();
          },
          cache: "no-cache"
        }),
      }),
      getIncompleteAccounts: builder.query({
        query: (participantId: string) => ({
          url: `/incomplete?participantId=${participantId}`,
          method: "GET",
        }),
      }),
    }),
  });

export const { useDownloadVoidChequeQuery, useLazyDownloadVoidChequeQuery, useLazyGetIncompleteAccountsQuery } = bankAccountApi;
export default bankAccountApi;