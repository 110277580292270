import React from "react";
import { KYCSectionProps } from "../types";
import KYCBoolean from "./KYCBoolean";

function ReviewDetails({ formData, setFormData }: KYCSectionProps) {
  return (
    <div className="kyc-section-container">
      <div className="kyc-section-container-header">
        <h1>Review</h1>
        <p>Please Ensure All Details are True and Correct. Click on the Submit Button When Done.</p>
      </div>
      <div className="kyc-section-container-body">
        <KYCBoolean
          label="I have reviewed all details and they are true and correct"
          value={formData?.completedKYC}
          onChange={(e) => setFormData({ ...formData, completedKYC: e })}
        />
      </div>
    </div>
  );
}

export default ReviewDetails;
