import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * This endpoint uses the Client Controller found in the .NET API
 */
const riskToleranceApi = createApi({
  reducerPath: "riskToleranceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/risktolerance`,
    credentials: "include",
  }),
  tagTypes: ["RiskTolerance"],
  endpoints: (builder) => ({
    // Gets all clients from the database
    getRiskToleranceResponse: builder.query({
      query: (responseId) => ({
        url: `/${responseId}`,
        method: "GET",
        cache: "no-cache",
      }),
    }),
    getRiskToleranceResponseByClient: builder.query({
      query: (clientId) => ({
        url: `/client/${clientId}`,
        method: "GET",
        cache: "no-cache",
      }),
    }),
    getRiskToleranceResponsePDF: builder.query({
      query: (responseId) => ({
        url: `/pdf/${responseId}`,
        method: "GET",
        responseHandler: async (response) =>
          window.open(window.URL.createObjectURL(await response.blob())),
        cache: "no-cache",
      }),
    }),

    createMagicLink: builder.mutation({
      query: (createMagicLinkRequest) => ({
        url: `/createMagicLink`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createMagicLinkRequest,
      }),
    }),
    getMagicLinkInfo: builder.mutation({
      query: (clientRiskLinkCode) => ({
        url: `/getMagicLink/${clientRiskLinkCode}`,
        method: "POST",
      }),
    }),
    useMagicLinkInfo: builder.mutation({
      query: (clientRiskLinkCode) => ({
        url: `/useMagicLink/${clientRiskLinkCode}`,
        method: "POST",
      }),
    }),
    sendForSignature: builder.mutation({
      query: (riskResponseId) => ({
        url: `/sendForSignature/${riskResponseId}`,
        method: "POST",
      }),
      invalidatesTags: ["RiskTolerance"],
    }),
    downloadSignedDocument: builder.query({
      query: (participantId) => ({
        url: `/downloadParticipantSignedRiskTolerance/${participantId}`,
        method: "GET",
        responseHandler: async (response) => {
          return await response.blob();
        },
        //window.open(window.URL.createObjectURL(await response.blob())),
        // responseHandler: async (response) => {
        //   var hiddenElement = document.createElement("a");
        //   var url = window.URL || window.webkitURL;
        //   var blob = await response.blob();
        //   var blobPDF = url.createObjectURL(blob);
        //   hiddenElement.href = blobPDF;
        //   hiddenElement.target = "_blank";
        //   hiddenElement.download = `${participantId.slice(0, 6)}_RiskTolerance_Signed.zip`;
        //   hiddenElement.click();
        // },
        cache: "no-cache",
      }),
    }),
    downloadUnsignedRTQByParticipantId: builder.query({
      query: (participantId: string) => ({
        url: `/pdf/participant/${participantId}`,
        method: "GET",
        responseHandler: async (response) => {
          var hiddenElement = document.createElement("a");
          var url = window.URL || window.webkitURL;
          var blob = await response.blob();
          var blobPDF = url.createObjectURL(blob);
          hiddenElement.href = blobPDF;
          hiddenElement.target = "_blank";
          hiddenElement.download = `${participantId.slice(0, 6)}_UnsignedRTQ.pdf`;
          hiddenElement.click();
        },
        cache: "no-cache",
      }),
    }),
  }),
});

export const {
  useGetRiskToleranceResponseQuery,
  useLazyGetRiskToleranceResponseQuery,
  useGetRiskToleranceResponsePDFQuery,
  useLazyGetRiskToleranceResponsePDFQuery,
  useCreateMagicLinkMutation,
  useGetMagicLinkInfoMutation,
  useUseMagicLinkInfoMutation,
  useSendForSignatureMutation,
  useGetRiskToleranceResponseByClientQuery,
  useLazyGetRiskToleranceResponseByClientQuery,
  useLazyDownloadSignedDocumentQuery,
  useLazyDownloadUnsignedRTQByParticipantIdQuery,
} = riskToleranceApi;
export default riskToleranceApi;
