import { useGetParticipantsByHouseholdAndRoleQuery } from "apis/mainApi";
import { SIASelect } from "components/Shared";
import { GuidValues } from "constants/enums";
import { useEffect, useState } from "react";
import { SelectItem } from "types/Shared";

export function SelectExistingParticipantWithRole({
  roleName,
  role,
  householdId,
  setIsChoosingExisiting,
  addParticipant,
}: {
  roleName: string;
  role: string;
  householdId: string;
  setIsChoosingExisiting: React.Dispatch<React.SetStateAction<boolean>>;
  addParticipant: (participantId: string) => void;
}) {
  const [selectedParticipant, setSelectedParticipant] = useState<string>("");
  const [beneficiaries, setBeneficiaries] = useState<SelectItem[]>([]);
  const result = useGetParticipantsByHouseholdAndRoleQuery({
    householdId: householdId ? householdId : GuidValues.EMPTY_GUID,
    role: role,
  });

  useEffect(() => {
    if (!result.isLoading && result.isSuccess) {
      const tempBeneficiaries: SelectItem[] = [];
      result.data.result.forEach((participant: any) => {
        tempBeneficiaries.push({
          label: participant.firstName + " " + participant.lastName,
          value: participant.id,
        });
      });
      console.log(result);

      setBeneficiaries(tempBeneficiaries);
    }
  }, [result]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedParticipant) {
      addParticipant(selectedParticipant);
      setIsChoosingExisiting(false);
    }
  };

  return (
    <form className="existing-participant-form" onSubmit={handleSubmit}>
      <div className="existing-participant-form-header">Select Existing {roleName}</div>
      <div className="existing-participant-label-input-container">
        <SIASelect
          options={beneficiaries!}
          value={selectedParticipant}
          onChange={(e) => setSelectedParticipant(e)}
        />
      </div>
      <div className="action-btn-container">
        <button className="btn btn-warning" onClick={() => setIsChoosingExisiting(false)}>
          Cancel
        </button>
        <button className="btn btn-primary" type="submit">
          Add {roleName}
        </button>
      </div>
    </form>
  );
}
