import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { notify } from "utils";

/**
 * Simple function to validate the spouse contributer
 */
const ValidateEFTs = (data: CreateInvestmentAccountDto) => {
  if (!data.eftBankAccounts || data.eftBankAccounts.length < 1) {
    notify("Please add at least one EFT Bank Account", "error");
    return false;
  }

  return true;
};

export default ValidateEFTs;
