import React, { useEffect, useState } from "react";
import { AccountSectionProps } from "../types";
import { SelectItem } from "types/Shared";
import { ContributionAccountOptions, ContributionTypes } from "constants/selectOptions";
import { SIAInput, SIASelect } from "components/Shared";
import { ContributionFrequencies } from "assets/StaticData/EditClientOptions";

/**
 * @name    AccountSWP
 * @summary This component is used to generate SWP Details
 * @param   formData the new investment account form data
 * @param   setFormData the react hook form set form data function
 * @param   participant the participant state information
 * @returns React.Component
 */
function AccountSWP({ formData, setFormData, participant }: AccountSectionProps) {
  const handleAddSWP = () => {
    if (formData.swpDetails) {
      setFormData({
        ...formData,
        swpDetails: [
          ...formData.swpDetails,
          {
            type: 0,
            amount: 0,
            frequency: 0,
            startDate: "",
            bankAccountId: "",
            accountOption: 0,
          },
        ],
      });
    } else {
      setFormData({
        ...formData,
        swpDetails: [
          {
            type: 0,
            amount: 0,
            frequency: 0,
            startDate: "",
            bankAccountId: "",
            accountOption: 0,
          },
        ],
      });
    }
  };
  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>Systematic Withdrawl Plan</h1>
        <h3>Please specify SWPs</h3>
      </div>
      <div className="account-section-details-body">
        {formData.swpDetails && formData.swpDetails.length > 0 && (
          <SWPDetailsTable
            formData={formData}
            setFormData={setFormData}
            participant={participant}
          />
        )}
      </div>
      <div>
        <button className="btn btn-primary" onClick={handleAddSWP}>
          Add SWP
        </button>
      </div>
    </div>
  );
}

const SWPDetailsTable = ({ formData, setFormData, participant }: AccountSectionProps) => {
  // Bank Account Select Option
  const [bankAccountSelectOptions, setBankAccountSelectOptions] = useState<SelectItem[]>([]);

  const handleChange = (val: any, index: number, key: string) => {
    if (formData.swpDetails) {
      const swpDetails = [...formData.swpDetails];
      swpDetails[index][key] = val;
      setFormData({
        ...formData,
        swpDetails,
      });
    }
  };

  const handleDelete = (index: number) => {
    if (formData.swpDetails)
      setFormData({
        ...formData,
        swpDetails: formData.swpDetails.filter((_, i) => i !== index),
      });
  };

  const handleDetailChange = (val: any, index: number, key: string) => {
    if (formData.swpDetails) {
      const swpDetails = [...formData.swpDetails];
      swpDetails[index][key] = val;
      setFormData({
        ...formData,
        swpDetails,
      });
    }
  };

  useEffect(() => {
    // Can only use bank accounts found in the EFT bank accounts
    let bankAccounts: SelectItem[] = [];
    if (formData.eftBankAccounts) {
      for (const bankAccount of formData.eftBankAccounts) {
        // Find it in the participants object
        const account = participant?.bankAccounts.find((account) => account.id === bankAccount);
        if (account) {
          bankAccounts.push({
            label: `${account.institutionName} - ${account.accountNumber}`,
            value: account.id,
          });
        }
      }
    }

    setBankAccountSelectOptions(bankAccounts);
  }, [formData]);

  return (
    <div className="pac-swp-table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Amount</th>
            <th>Frequency</th>
            <th>Start Date</th>

            <th>Account Option</th>
            <th>Bank Account</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {formData.swpDetails &&
            formData.swpDetails.map((pacDetail, index) => (
              <tr key={index}>
                <td>
                  <SIASelect
                    value={pacDetail.type}
                    options={ContributionTypes}
                    onChange={(e) => handleDetailChange(e, index, "type")}
                  ></SIASelect>
                </td>
                <td>
                  <SIAInput
                    value={pacDetail.amount}
                    onChange={(e) => handleDetailChange(e.target.value, index, "amount")}
                    type="number"
                    isDollar={true}
                  ></SIAInput>
                </td>
                <td>
                  <SIASelect
                    value={pacDetail.frequency}
                    options={ContributionFrequencies}
                    onChange={(e) => handleDetailChange(e, index, "frequency")}
                  ></SIASelect>
                </td>
                {/* <td>{pacDetail.frequency}</td> */}
                <td>
                  <SIAInput
                    value={pacDetail.startDate}
                    onChange={(e) => handleDetailChange(e.target.value, index, "startDate")}
                    type="date"
                  ></SIAInput>
                </td>
                <td>
                  <SIASelect
                    value={pacDetail.accountOption}
                    options={ContributionAccountOptions}
                    onChange={(e) => handleDetailChange(e, index, "accountOption")}
                  ></SIASelect>
                </td>
                <td>
                  <SIASelect
                    options={bankAccountSelectOptions}
                    value={pacDetail.bankAccountId}
                    onChange={(e) => handleChange(e, index, "bankAccountId")}
                  ></SIASelect>
                </td>
                <td>
                  <button className="btn btn-danger" onClick={() => handleDelete(index)}>
                    <i className="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccountSWP;
