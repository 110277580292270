import { useLazyGetParticipantBankAccountsQuery } from "apis/mainApi";
import { ParticipantDetailsSectionProps } from "../types";
import { useEffect, useState } from "react";
import { BankAccountDto } from "types";
import { useNavigate } from "react-router-dom";
import { DefaultBankInstitutions, DefaultInstitutionMapping } from "assets/StaticData/BankAccount";
import { useLazyDownloadVoidChequeQuery } from "apis/bankAccountApi";

const ParticipantBankAccounts = ({ participant }: ParticipantDetailsSectionProps) => {
  // Navigation
  const navigate = useNavigate();
  // Bank Account State
  const [accounts, setAccounts] = useState<BankAccountDto[]>();
  // Query to get participant Investment Accounts
  const [accountQuery, accountsResult] = useLazyGetParticipantBankAccountsQuery();
  // Query for the Investment Accounts
  useEffect(() => {
    if (participant?.id) {
      accountQuery(participant.id);
    }
  }, [participant]);

  // Accounts
  // check the result of the query and store state if all is well
  useEffect(() => {
    if (accountsResult.isSuccess) {
      const result = accountsResult.data;
      if (result?.isSuccess) {
        const accounts: BankAccountDto[] = result.result;
        setAccounts(accounts);
      }
    }
  }, [accountsResult]);

  // Main Component
  return (
    <div className="accounts-container">
      <div className="accounts-container-header">
        <p>Bank Accounts</p>
        <button
          className="btn btn-primary"
          onClick={() => {
            navigate(`/createBankAccount/${participant.id}`);
          }}
        >
          Add New Bank Account
        </button>
      </div>
      <div className="accounts-container-body">
        {accounts?.map((account) => {
          return <BankAccountContainer account={account} key={account.id} />;
        })}
      </div>
    </div>
  );
};

const BankAccountContainer = ({ account }: { account: BankAccountDto }) => {
  // Query Hook for the Bank Accounts
  const [voidChequeDownloadQuery, voidChequeDownloadResult] = useLazyDownloadVoidChequeQuery();

  const handleBankAccountDownload = async () => {
    voidChequeDownloadQuery(account.id);
  };

  return (
    <div className="account-container">
      <div className="account-container-information">
        <p className="text-primary" style={{ fontSize: "1.2rem" }}>
          {account.accountName} {account.isPrimaryAccount && <span>(Primary)</span>}
        </p>
        <p>{account.institutionName}</p>
        <p>
          <span className="text-bold">Account Number: </span>
          {account.accountNumber}
        </p>
        <p>
          <span className="text-bold">Institution Number: </span>
          {account.institutionNumber}
        </p>
        <p>
          <span className="text-bold">Transit Number: </span>
          {account.transitNumber}
        </p>
      </div>
      <div className="account-container-action-items">
        <button className="btn" onClick={handleBankAccountDownload}>
          <i className="bi bi-download" style={{ marginLeft: "1rem" }}></i>
        </button>
      </div>
    </div>
  );
};

export default ParticipantBankAccounts;
