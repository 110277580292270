import {
  useLazyDownloadAMLDocumentQuery,
  useLazyGetAMLDocumentsByParticipantQuery,
} from "apis/documentApi";
import { useEffect, useState } from "react";
import { ParticipantDetailsSectionProps } from "../types";
import { AMLDocument } from "types/Document";
import "../styles/participantDocuments.scss";
import { AMLDocumentTypes } from "constants/mappings";
import { convertDateToString } from "utils";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

function ParticipantDocuments({ participant }: ParticipantDetailsSectionProps) {
  // Navigation Hook
  const navigate = useNavigate();
  // State to track participant AML documents
  const [documents, setDocuments] = useState<AMLDocument[]>();
  // Query to get participant AML documents
  const [amlDocumentQuery, amlDocumentResult] = useLazyGetAMLDocumentsByParticipantQuery();
  // Get participant AML documents
  useEffect(() => {
    if (participant) {
      amlDocumentQuery(participant.id);
    }
  }, [participant]);

  useEffect(() => {
    if (amlDocumentResult.isSuccess && amlDocumentResult.data) {
      var amlDocuments = amlDocumentResult.data.result;
      setDocuments(amlDocuments);
    }
  }, [amlDocumentResult]);

  useEffect(() => {
    console.log(documents);
  }, [documents]);

  return (
    <div className="document-container">
      <Tooltip id="document-tooltip" />
      <div className="document-container-header">
        <p>AML Documents</p>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              navigate(`/amlDocumentUpload/${participant.id}/`);
            }}
          >
            Add New AML Document
          </button>
        </div>
      </div>
      <div className="document-container-body">
        {documents?.map((document) => {
          return <AMLDocumentCard document={document} />;
        })}
      </div>
    </div>
  );
}

const AMLDocumentCard = ({ document }: { document: AMLDocument }) => {
  const [documentQuery, documentResult] = useLazyDownloadAMLDocumentQuery();

  const downloadDocumentHandler = () => {
    documentQuery(document.id);
  };

  return (
    <div className="document-card">
      <div className="document-card-header">
        <div className="document-basic">
          <p className="document-card-id-type">{AMLDocumentTypes[document.idType]}</p>
          <p>
            <span className="document-card-label">ID Number: </span>
            {document.idNumber}
          </p>
          <p>
            <span className="document-card-label">Expiry Date: </span>
            {convertDateToString(document.idExpirationDate)}
          </p>
          <p>
            <span className="document-card-label">Place of Issuance: </span>{" "}
            {document.placeOfIssuance}
          </p>
        </div>
        <div className="document-action-items-container">
          <a
            data-tooltip-id="document-tooltip"
            data-tooltip-content="Download AML Document"
            style={{ marginLeft: "1rem" }}
            onClick={downloadDocumentHandler}
          >
            <i className="bi bi-download"></i>
          </a>
        </div>
      </div>
      <div className="document-card-body">
        <p>{document.description}</p>
      </div>
    </div>
  );
};

export default ParticipantDocuments;
