import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { notify } from "utils";

/**
 * Simple function to validate the spouse contributer
 */
const ValidateSpouseContributer = (data: CreateInvestmentAccountDto) => {
  if (!data.spouseContributers || data.spouseContributers.length < 1) {
    notify("Please add at least one spouse contributer", "error");
    return false;
  }

  return true;
};

export default ValidateSpouseContributer;
