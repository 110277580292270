import { AccountSectionProps } from "../types";
import AccountInput from "./AccountInput";
import { InvestmentAccountSubType } from "constants/enums";

/**
 * @name         AccountIncomeFundDetails
 * @summary      This component is responsible for displaying and adding income fund details to the account.
 *               Most notably, it is responsible for the minimum payment and payment above minimum. If the account
 *               is a locked in account, it will also display the maximum payment.
 * @param        formData: the new investment account form data
 * @param        setFormData: the react hook form set form data function
 * @param        participant: the participant state information
 * @returns      React.Component
 */
function AccountIncomeFundDetails({ formData, setFormData, participant }: AccountSectionProps) {
  /**
   * @name            handleIncomeFundDetailChange
   * @summary         Handles the change of the income fund details
   * @param e         The value of the change
   * @param attrName  The attribute name of the change
   * @returns         void
   */
  const handleIncomeFundDetailChange = (e: string, attrName: string) => {
    const val = Number(e);
    const temp = { ...formData };

    if (temp.incomeFundInformation) {
      temp.incomeFundInformation[attrName] = val;
    } else {
      temp.incomeFundInformation = {
        minPayment: 0,
        paymentAboveMin: 0,
      };

      temp.incomeFundInformation[attrName] = Number(val);
    }
    setFormData(temp);
  };

  /**
   * @name    handleLockedInAccountChange
   * @summary Handles the change of the locked in account
   * @param e The value of the input change
   */
  const handleLockedInAccountChange = (e: string) => {
    const val = Number(e);
    const temp = { ...formData };
    if (temp.lifInformation) {
      temp.lifInformation.maxPayment = val;
    } else {
      temp.lifInformation = {
        maxPayment: val,
      };
    }
    setFormData(temp);
  };

  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>Income Fund Details</h1>
        <h3>Please fill required fields for the income fund account</h3>
      </div>
      <div className="account-section-details-body">
        <AccountInput
          label="Minimum Payment Amount ($)"
          value={formData?.incomeFundInformation?.minPayment!}
          onChange={(e) => handleIncomeFundDetailChange(e, "minPayment")}
          type="number"
        />
        <AccountInput
          label="Payment Above Minimum ($)"
          value={formData?.incomeFundInformation?.paymentAboveMin!}
          onChange={(e) => handleIncomeFundDetailChange(e, "paymentAboveMin")}
          type="number"
        />
        {formData.subType == InvestmentAccountSubType.LOCKED_IN && (
          <AccountInput
            label="Maximum Payment"
            value={formData?.lifInformation?.maxPayment!}
            onChange={handleLockedInAccountChange}
            type="number"
          />
        )}
      </div>
    </div>
  );
}

export default AccountIncomeFundDetails;
