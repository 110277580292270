import { useLazyDownloadSignedDocumentQuery } from "apis/riskToleranceApi";
import WorkflowStatus from "../../WorkflowStatus";
import { useRiskToleranceReviewedMutation } from "apis/mainApi";
import { useState } from "react";
import { ParticipantDetailsSectionProps } from "features/participant/types";
import { notify } from "utils";

interface DocumentItemProps extends ParticipantDetailsSectionProps {
  // Use state for blob
  setBlobData: React.Dispatch<React.SetStateAction<Blob | null>>;
}

/**
 * Component to display the status of the RTQ being reviewed by the advisor
 * Also downloads the signed RTQ document from the participant, if the document
 * is not signed, then an error message is displayed
 * @param param0
 * @returns
 */
const RTQReviewedByAdvisorItem = ({ participant, setBlobData }: DocumentItemProps) => {
  const completedPreviousTask = participant?.accountHolderWorkflow?.rtqSignedByParticipant;
  const completedTask = participant?.accountHolderWorkflow?.rtqReviewedByAdvisor;
  const completedText = "Questionaire Reviewed";
  const notCompletedText = "Questionaire Not Reviewed";
  const completeTooltipText = "Click to Review Questionaire";
  const notCompleteTooltipText = "Click to Review Questionaire";
  const [loading, setLoading] = useState(false);

  const [downloadRiskToleranceSigned] = useLazyDownloadSignedDocumentQuery();

  const completeHandler = async () => {
    setLoading(true);
    const data = await downloadRiskToleranceSigned(participant.id);
    if (data.data && data.isSuccess) {
      var blob = data.data;
      setBlobData(blob);
    } else {
      console.log(data.error);
      notify("Participant has not signed Risk Tolerance Questionaire", "error");
    }
    setLoading(false);
  };

  return (
    <>
      <WorkflowStatus
        completedPreviousTask={completedPreviousTask}
        completedTask={completedTask}
        completeTooltipText={completeTooltipText}
        notCompleteTooltipText={notCompleteTooltipText}
        completedText={completedText}
        notCompletedText={notCompletedText}
        completeActionHander={completeHandler}
        notCompleteActionHandler={completeHandler}
        notCompleteIconClass="bi-bookmark-star"
        completeIconClass="bi-bookmark-star-fill"
        isLoading={loading}
      />
    </>
  );
};

export default RTQReviewedByAdvisorItem;
