import { KYCSectionProps } from "../types";
import {
  ClientCitizenshipOptions,
  ClientTitleOptions,
  MaritalStatusOptions,
} from "constants/selectOptions";
import { convertDateToString } from "utils";
import KYCInput from "./KYCInput";
import KYCSelect from "./KYCSelect";
import KYCBoolean from "./KYCBoolean";
import KYCCountrySelect from "./KYCCountrySelect";

function KYCGeneralDetails({ formData, setFormData }: KYCSectionProps) {
  const handleInputChange = (val: number | string | boolean, attrName: string) => {
    let updatedGeneralDetails = { ...formData.generalDetails };
    // Check if attr exists in general details
    if (attrName in updatedGeneralDetails) {
      updatedGeneralDetails[attrName] = val;
    }

    setFormData({ ...formData, generalDetails: updatedGeneralDetails });
  };

  return (
    <div className="kyc-section-container">
      <div className="kyc-section-container-header">
        <h1>General Details</h1>
        <p>Please Fill Out All Required Fields</p>
      </div>
      <div className="kyc-section-container-body">
        <KYCBoolean
          label="Is the Participant an Individual?"
          value={formData.generalDetails.isIndividual}
          onChange={(e) => handleInputChange(e, "isIndividual")}
        />

        <KYCSelect
          label="Title"
          value={formData.generalDetails.title}
          options={ClientTitleOptions}
          onChange={(e) => handleInputChange(e, "title")}
        />
        <KYCSelect
          label="Marital Status"
          value={formData.generalDetails.maritalStatus}
          options={MaritalStatusOptions}
          onChange={(e) => handleInputChange(e, "maritalStatus")}
        />
        <KYCInput
          label="First Name"
          value={formData.generalDetails.firstName}
          onChange={(e) => handleInputChange(e, "firstName")}
        />
        <KYCInput
          label="Middle Initials"
          value={formData.generalDetails.middleInitial}
          onChange={(e) => handleInputChange(e, "middleInitial")}
        />
        <KYCInput
          label="Last Name"
          value={formData.generalDetails.lastName}
          onChange={(e) => handleInputChange(e, "lastName")}
        />
        <KYCInput
          label="Social Insurance Number"
          value={formData.generalDetails.sin}
          onChange={(e) => handleInputChange(e, "sin")}
        />
        <KYCInput
          label="Date of Birth"
          value={convertDateToString(formData.generalDetails.dateOfBirth)}
          onChange={(e) => handleInputChange(e, "dateOfBirth")}
          type="date"
        />
        <KYCInput
          label="Number of Dependants"
          value={formData.generalDetails.numDependents}
          onChange={(e) => handleInputChange(e, "numDependents")}
          type="number"
        />
        <KYCInput
          label="Home Phone #"
          value={formData.generalDetails.homePhone}
          onChange={(e) => handleInputChange(e, "homePhone")}
          placeholder="555-555-5555"
        />
        <KYCInput
          label="Business Phone #"
          value={formData.generalDetails.businessPhone}
          onChange={(e) => handleInputChange(e, "businessPhone")}
          placeholder="555-555-5555"
        />
        <KYCInput
          label="Business Phone Ext."
          value={formData.generalDetails.businessPhoneExt}
          onChange={(e) => handleInputChange(e, "businessPhoneExt")}
        />
        <KYCInput
          label="Cell Phone #"
          value={formData.generalDetails.cellPhone}
          onChange={(e) => handleInputChange(e, "cellPhone")}
          placeholder="555-555-5555"
        />
        <KYCInput
          label="Email"
          value={formData.generalDetails.email}
          onChange={(e) => handleInputChange(e, "email")}
          placeholder="example@example.com"
        />
        <KYCCountrySelect
          label="Citizenship"
          value={formData.generalDetails.citizenship}
          onChange={(e) => handleInputChange(e, "citizenship")}
        />
        <KYCCountrySelect
          label="Other Citizenship (If Applicable)"
          value={formData.generalDetails.otherCitizenship}
          onChange={(e) => handleInputChange(e, "otherCitizenship")}
        />
        <KYCInput
          label="Language of Correspondence"
          value={formData.generalDetails.language}
          onChange={(e) => handleInputChange(e, "language")}
        />
      </div>
    </div>
  );
}

export default KYCGeneralDetails;
