import { RadioButtonContainerProps } from "features/participant/types";

const KYCBoolean = ({
  label,
  value,
  disabled = false,
  onChange,
  hasTooltip,
  tooltipText,
}: RadioButtonContainerProps) => {
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === "true";
    onChange?.(newValue);
  };

  return (
    <div className="kyc-label-input-container">
      <div className="kyc-label-container">
        <label>{label}</label>
        {hasTooltip && (
          <a data-tooltip-id="kyc-tooltip" data-tooltip-content={tooltipText}>
            <i className="bi bi-info-circle-fill"></i>
          </a>
        )}
      </div>
      <div className="kyc-input-container">
        <div>
          <input
            type="radio"
            id={`${label}-true`}
            name={label}
            value="true"
            disabled={disabled}
            checked={value === true}
            onChange={handleRadioChange}
          />
          <label htmlFor={`${label}-true`}>Yes</label>
        </div>
        <div>
          <input
            type="radio"
            id={`${label}-false`}
            name={label}
            value="false"
            disabled={disabled}
            checked={value === false}
            onChange={handleRadioChange}
          />
          <label htmlFor={`${label}-false`}>No</label>
        </div>
      </div>
    </div>
  );
};

export default KYCBoolean;
