export enum EmploymentStatus {
  EMPLOYED = 1,
  SELF_EMPLOYED = 2,
  RETIRED = 3,
  STUDENT = 4,
  BUSINESS_OWNER = 5,
  OTHER = 6,
  UNEMPLOYED = 7,
  HOMEMAKER = 8,
}
