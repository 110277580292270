import { RiskToleranceSection } from "../../types";

const riskSections: RiskToleranceSection[] = [
  {
    id: 1,
    header: "Investor Stage of Life",
    question:
      "Which phase of asset accumulation or decumulation best describes you?",
    possibleAnswers: [
      {
        answer: "Early accumulation (20+ years until retirement)",
        score: 12,
      },
      {
        answer: "Accumulation (10-20 years until retirement)",
        score: 10,
      },
      {
        answer: "Pre-Retirement (0-10 years until retirement)",
        score: 8,
      },
      {
        answer: "Early Retirement (0-10 years into retirement)",
        score: 6,
      },
      {
        answer: "Mid Retirement (10-20 years into retirement)",
        score: 4,
      },
      {
        answer: "Late retirement (20+ years into retirement)",
        score: 2,
      },
    ],
    isTable: false,
  },
  {
    id: 2,
    header: "Primary Goal",
    question:
      "Your primary goal defines your main intentions for your investment portfolio. Secondary goals and objectives should be discussed with your Portfolio manager and will be factored into investment decisions. Your primary goal is:",
    possibleAnswers: [
      {
        answer: "Long term capital appreciation",
        score: 9,
      },
      {
        answer: "Current Income generation and capital appreciation",
        score: 7,
      },
      {
        answer: "Current Income Generation",
        score: 5,
      },
      {
        answer: "Capital Preservation and Income Generation",
        score: 3,
      },
      {
        answer: "Capital Preservation and Income Generation",
        score: 1,
      },
    ],
    isTable: false,
  },
  {
    id: 3,
    header: "Time Frame",
    question:
      "Your time frame should reflect how long you have before you need to draw funds from your portfolio.",
    possibleAnswers: [
      {
        answer: "More than 20 years",
        score: 9,
      },
      {
        answer: "Within 11-20 years",
        score: 7,
      },
      {
        answer: "Within 5-10 years",
        score: 5,
      },
      {
        answer: "Within 1-4 years",
        score: 3,
      },
      {
        answer: "Currently withdrawing funds from portfolio",
        score: 1,
      },
    ],
    isTable: false,
  },
  {
    id: 4,
    header: "Risk Tolerance",
    question:
      "You need to consider your willingness to accept risk within your portfolio and the effects of permanent loss of capital. Higher returns can also mean higher risk of loss of capital. Given your discussion with your Portfolio Manager, which statement best describes your portfolio goal?",
    possibleAnswers: [
      {
        answer:
          "Potentially increase my portfolio’s value while accepting a higher level of risk",
        score: 10,
      },
      {
        answer:
          "Potentially increase my portfolio’s value while accepting a moderate level of risk",
        score: 8,
      },
      {
        answer:
          "Income is my primary concern and capital appreciation is secondary",
        score: 6,
      },
      {
        answer: "The safety of my principal is my primary concern",
        score: 4,
      },
    ],
    isTable: false,
  },
  {
    id: 5,
    header: "Investment Goals",
    question:
      "Which statement best describes your approach to investing to achieve your goals:",
    possibleAnswers: [
      {
        answer:
          "Seeking maximum long-term growth while accepting maximum risk with the probability of large principal fluctuations",
        score: 10,
      },
      {
        answer:
          "Seeking long term growth while accepting moderate to high levels of risk and significant principal fluctuations",
        score: 8,
      },
      {
        answer:
          "Seeking moderate long-term growth while accepting moderate levels of risk and principal fluctuations",
        score: 6,
      },
      {
        answer:
          "Seeking to slightly increase portfolio value while minimizing levels of risk and principal fluctuations",
        score: 4,
      },
      {
        answer:
          "Seeking to achieve a relatively stable portfolio value with minimal levels of risk and principal fluctuations",
        score: 2,
      },
    ],
    isTable: false,
  },
  {
    id: 6,
    header: "Volatility of individual securities",
    question:
      "The value of investments can and will fluctuate over both the long and short term. How would you feel if an investment you had purchased with the goal of holding for the long term lost 20% of its value during the first year?",
    possibleAnswers: [
      {
        answer:
          "I would not be overly concerned given my long-time horizon and investment philosophy",
        score: 10,
      },
      {
        answer:
          "I would be concerned, but I would not consider selling my investment",
        score: 8,
      },
      {
        answer:
          "I would be concerned, and I may consider selling my investment",
        score: 6,
      },
      {
        answer:
          "I would be very concerned and would consider selling my investment",
        score: 4,
      },
    ],
    isTable: false,
  },
  {
    id: 7,
    header: "Variability of returns",
    question:
      "Understanding that investments may move up or down in value over time, which of the following hypothetical portfolios below would make you most comfortable?",
    possibleAnswers: [
      {
        answer: "A",
        score: 2,
      },
      {
        answer: "B",
        score: 4,
      },
      {
        answer: "C",
        score: 6,
      },
      {
        answer: "D",
        score: 8,
      },
      {
        answer: "E",
        score: 10,
      },
    ],
    isTable: true,
    tableData: [
      {
        id: 1,
        "Scenario ": "A",
        "Year 1": "3%",
        "Year 2": "3%",
        "Year 3": "3%",
        "Year 4": "3%",
        "Year 5": "3%",
        "Average Annual Rate of Return": "3%",
      },
      {
        id: 2,
        "Scenario ": "B",
        "Year 1": "2%",
        "Year 2": "6%",
        "Year 3": "6%",
        "Year 4": "-1%",
        "Year 5": "7%",
        "Average Annual Rate of Return": "4%",
      },
      {
        id: 3,
        "Scenario ": "C",
        "Year 1": "-6%",
        "Year 2": "7%",
        "Year 3": "21%",
        "Year 4": "2%",
        "Year 5": "8%",
        "Average Annual Rate of Return": "6%",
      },
      {
        id: 4,
        "Scenario ": "D",
        "Year 1": "14%",
        "Year 2": "-9%",
        "Year 3": "-4%",
        "Year 4": "28%",
        "Year 5": "18%",
        "Average Annual Rate of Return": "8.5%",
      },
      {
        id: 5,
        "Scenario ": "E",
        "Year 1": "-15%",
        "Year 2": "-9%",
        "Year 3": "18%",
        "Year 4": "40%",
        "Year 5": "31%",
        "Average Annual Rate of Return": "11%",
      },
    ],
  },
  {
    id: 8,
    header: "Risk of Loss",
    question:
      "Below are 3 hypothetical portfolios which exhibit potential scenarios for downside risk. Based on your level of comfort as the investor, please select which portfolio most closely aligns with your ability to accept losses in any given 12-month period:",
    possibleAnswers: [
      {
        answer: "Portfolio A",
        score: 8,
      },
      {
        answer: "Portfolio B",
        score: 6,
      },
      {
        answer: "Portfolio C",
        score: 4,
      },
      {
        answer:
          "The above portfolios exhibit more potential volatility than I am comfortable with",
        score: 1,
      },
    ],
    isTable: true,
    tableData: [
      {
        id: 1,
        Portfolio: "A",
        "Chance of losing money in any 1-year period ": "3 in 10",
        "Chance of losing > 10% in any 1-year period": "1 in 10",
        "Max expected loss in any 1-year period": "-20%",
        "Potential Annual Return": "10%",
      },
      {
        id: 2,
        Portfolio: "B",
        "Chance of losing money in any 1-year period ": "2 in 10",
        "Chance of losing > 10% in any 1-year period": "1 in 10",
        "Max expected loss in any 1-year period": "-10%",
        "Potential Annual Return": "8%",
      },
      {
        id: 3,
        Portfolio: "C",
        "Chance of losing money in any 1-year period ": "1 in 10",
        "Chance of losing > 10% in any 1-year period": "None",
        "Max expected loss in any 1-year period": "-5%",
        "Potential Annual Return": "6%",
      },
    ],
  },
  {
    id: 9,
    header: "Investment Experience",
    question: "What is your overall investment experience?",
    possibleAnswers: [
      {
        answer:
          "High – I have been an active participant in the stock market and understand that all investments including international markets can be volatile and unpredictable",
        score: 8,
      },
      {
        answer:
          "Medium – I have some experience investing in mutual funds, ETFs, stocksand bonds",
        score: 5,
      },
      {
        answer:
          "Low – I have very little experience outside of savings accounts, moneymarket funds and/or GICs",
        score: 3,
      },
      {
        answer: "None",
        score: 1,
      },
    ],
    isTable: false,
  },
  {
    id: 10,
    header: "Annual Distribution needs",
    question:
      "Based on your CURRENT situation, what percentage of your portfolio value do you anticipate will need to be distributed to you on an annual basis?",
    possibleAnswers: [
      {
        answer:
          "I will not need distributions from this portfolio at this time",
        score: 10,
      },
      {
        answer: "0% - 5% annually",
        score: 5,
      },
      {
        answer:
          "Over 5% annually, including the possibility of having to withdraw principal",
        score: 3,
      },
    ],
    isTable: false,
  },
  {
    id: 11,
    header: "Living Expenses",
    question:
      "In the event of unforeseen circumstances, some individuals are forced to draw income from their investment portfolios to meet living expenses. In such an instance, how many months of living expenses could be covered by your current liquid investments, held outside your investment portfolio?",
    possibleAnswers: [
      {
        answer: "More than 12 months or not a concern",
        score: 9,
      },
      {
        answer: "Between 4 and 12 months",
        score: 6,
      },
      {
        answer: "Less than 4 months or already withdrawing",
        score: 3,
      },
    ],
    isTable: false,
  },
  {
    id: 12,
    header: "Household Income",
    question:
      "When assessing your risk tolerance and determining your asset allocation, household income is a determining factor in investment suitability. Please estimate your total household income, including earnings and investment income from all sources.",
    possibleAnswers: [
      {
        answer: "More than $500,000",
        score: 9,
      },
      {
        answer: "$250,000 to $499,000",
        score: 7,
      },
      {
        answer: "$100,000 to $249,000",
        score: 5,
      },
      {
        answer: "Less than $100,000",
        score: 3,
      },
    ],
    isTable: false,
  },
  {
    id: 13,
    header: "Future Earnings",
    question: "In the next 5 years, you expect your earned income to:",
    possibleAnswers: [
      {
        answer: "Increase significantly",
        score: 8,
      },
      {
        answer: "Increase modestly",
        score: 6,
      },
      {
        answer: "Stay about the same",
        score: 4,
      },
      {
        answer: "Decrease",
        score: 2,
      },
    ],
    isTable: false,
  },
  {
    id: 14,
    header: "Savings",
    question:
      "The percentage of your total income that you currently save is approximately:",
    possibleAnswers: [
      {
        answer: "Greater than 20%",
        score: 10,
      },
      {
        answer: "10% to 20%",
        score: 6,
      },
      {
        answer: "2% to 10%",
        score: 4,
      },
      {
        answer: "I do not currently save any income",
        score: 1,
      },
    ],
    isTable: false,
  },
  {
    id: 15,
    header: "Estate Planning",
    question:
      "Estate planning is an important factor in the construction and risk tolerance of an investment portfolio. What are your expectations with respect to future gifting?",
    possibleAnswers: [
      {
        answer:
          "I am in the accumulation phase of my life cycle and therefore not applicable currently",
        score: 8,
      },
      {
        answer:
          "I plan to gift more than 50% of my current investible assets to my heirs or charity",
        score: 6,
      },
      {
        answer:
          "I plan to gift less than 50% of my current investible assets to my heirs or charity",
        score: 4,
      },
      {
        answer: "I plan to draw down all the assets in this portfolio",
        score: 2,
      },
    ],
    isTable: false,
  },
];

export default riskSections;
