import { useNavigate, useParams } from "react-router-dom";
import "../styles/document.scss";
import { ApiResponseModel, Participant } from "types";
import { useEffect, useState } from "react";
import { useLazyGetClientByIdQuery } from "apis/mainApi";
import SIAInput from "components/Shared/SIAInput";
import withAuth from "HOC/withAuth";
import { CreateParticipantDocumentDto } from "../types/CreateParticipantDocumentDto";
import { SIASelect } from "components/Shared";
import { AMLDocumentTypeOptions } from "constants/selectOptions";
import { useUploadParticipantAMLDocumentMutation, useLazyGetIncompleteAMLDocumentsQuery } from "apis/documentApi";
import { notify } from "utils";
import { GuidValues } from "constants/enums";
import { set } from "lodash";

const UploadAMLDocumentPage = () => {
  const { participantId } = useParams();
  const navigate = useNavigate();
  const [uploadDocumentInputs, setUploadDocumentInputs] = useState<CreateParticipantDocumentDto>({
    id: GuidValues.EMPTY_GUID,
    idType: 0,
    idNumber: "",
    idExpirationDate: "",
    placeOfIssuance: "",
    imageBytes: null,
    participantId: participantId,
    isComplete: false,
  });

  const [participant, setParticipant] = useState<Participant>();
  const [clientQuery, clientResult] = useLazyGetClientByIdQuery();
  const [documentQuery, documentResult] = useLazyGetIncompleteAMLDocumentsQuery();

  const [uploadDocumentPost] = useUploadParticipantAMLDocumentMutation();

  useEffect(() => {
    if (participantId) {
      clientQuery(participantId);
      documentQuery(participantId);
    }
  }, [participantId]);

  useEffect(() => {
    if (clientResult.isSuccess && clientResult.data.result) {
      setParticipant(clientResult.data.result);
    }
  }, [clientResult]);

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    setUploadDocumentInputs({ ...uploadDocumentInputs, imageBytes: file });
  };

  useEffect(() => {
    if (documentResult.isSuccess && documentResult.data.result) {
      const document = documentResult.data.result;
      if (document) {
        setUploadDocumentInputs({
          id: document.id,
          idType: document.idType,
          idNumber: document.idNumber,
          idExpirationDate: document.idExpirationDate.split("T")[0],
          placeOfIssuance: document.placeOfIssuance,
          imageBytes: null,
          participantId: document.participantId,
          isComplete: document.isComplete,
        });
      }
    }
  }, [documentResult]);

  const handleSaveDraft = async () => {
    uploadDocumentInputs.isComplete = false;
    const formData = createFormData(uploadDocumentInputs);

    const result: ApiResponseModel = await uploadDocumentPost(formData);
    if (result.data && result.data.isSuccess) {
      notify("AML Document Saved Uploaded", "success");
      navigate(-1);
    } else {
      notify("An Error Has Occured", "error");
      console.log(result);
    }
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    uploadDocumentInputs.isComplete = true;
    setUploadDocumentInputs({ ...uploadDocumentInputs, isComplete: true });
    const formData = createFormData(uploadDocumentInputs);

    const result: ApiResponseModel = await uploadDocumentPost(formData);
    if (result.data && result.data.isSuccess) {
      notify("AML Document Successfully Uploaded", "success");
      navigate(-1);
    } else {
      notify("An Error Has Occured", "error");
      console.log(result);
    }
  };

  return (
    <div className="main-document-container">
      <div className="container-header">
        <h1>Upload AML Document</h1>
        {participant && (
          <h3>
            {participant.generalDetails.firstName} {participant.generalDetails.lastName}
          </h3>
        )}
      </div>
      <div className="container-divider"></div>
      <div className="container-body">
        <div className="container-body-header">
          <h1>Please Fill Out The Required Fields and Upload Document.</h1>
          <button className="btn btn-primary" style={{ marginTop: "1rem" }} onClick={handleSaveDraft}>
            Save Draft
          </button>
        </div>
        <form className="document-form" onSubmit={handleFormSubmit}>
          <div className="document-form-input-container">
            <label>Identification Type</label>
            <SIASelect
              value={uploadDocumentInputs.idType}
              onChange={(e) => setUploadDocumentInputs({ ...uploadDocumentInputs, idType: e })}
              options={AMLDocumentTypeOptions}
            />
          </div>
          <div className="document-form-input-container">
            <label>Identification Number</label>
            <SIAInput
              value={uploadDocumentInputs.idNumber}
              name="IdNumber"
              type="text"
              onChange={(e) => setUploadDocumentInputs({ ...uploadDocumentInputs, idNumber: e.target.value })}
            />
          </div>
          <div className="document-form-input-container">
            <label>Place of Issuance</label>
            <SIAInput
              value={uploadDocumentInputs.placeOfIssuance}
              type="text"
              onChange={(e) =>
                setUploadDocumentInputs({
                  ...uploadDocumentInputs,
                  placeOfIssuance: e.target.value,
                })
              }
            />
          </div>
          <div className="document-form-input-container">
            <label>Expiry Date</label>
            <SIAInput
              value={uploadDocumentInputs.idExpirationDate}
              type="date"
              onChange={(e) =>
                setUploadDocumentInputs({
                  ...uploadDocumentInputs,
                  idExpirationDate: e.target.value,
                })
              }
            />
          </div>
          <div className="document-form-input-container">
            <label>File Upload</label>
            <SIAInput value="" type="file" onChange={handleFileInputChange} accept="image/*" />
          </div>
          <div>
            <button className="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withAuth(UploadAMLDocumentPage);

function createFormData(uploadDocumentInputs: CreateParticipantDocumentDto) {
  const formData = new FormData();
  console.log(uploadDocumentInputs);
  formData.append("Id", uploadDocumentInputs.id!);
  formData.append("IdType", uploadDocumentInputs.idType.toString());
  formData.append("IdNumber", uploadDocumentInputs.idNumber);
  formData.append("PlaceOfIssuance", uploadDocumentInputs.placeOfIssuance);
  formData.append("IdExpirationDate", uploadDocumentInputs.idExpirationDate);
  formData.append("ImageBytes", uploadDocumentInputs.imageBytes!);
  formData.append("ParticipantId", uploadDocumentInputs.participantId!);
  formData.append("IsComplete", uploadDocumentInputs.isComplete.toString());
  return formData;
}
