import { GuidValues } from "constants/enums";
import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { InvestmentAccountDto } from "types/InvestmentAccount";
/**
 * Investment Account with no data set
 *
 * @author Teddy kalp
 */
export const EmptyInvestmentAccount: InvestmentAccountDto = {
  id: GuidValues.EMPTY_GUID,
  type: 0,
  subType: 0,
  currency: 0,
  isJointAccount: false,
  allowBeneficiarys: false,
  beneficiaries: [],
  allowEFT: false,
  eftBankAccounts: [],
  allowTransfers: false,
  transferDetails: [],
  allowPAC: false,
  pacDetails: [],
  allowSWP: false,
  swpDetails: [],
  isBorrowingToInvest: false,
  intendedUseOfAccount: 0,
  intendedUseOfAccountOther: "",
  sourceOfFunds: 0,
  sourceOfFundsOther: "",
  itfBeneficiaries: [],
  incomeFundInformation: null,
  lifInformation: null,
  respBeneficiaries: [],
  accountHolders: [],
  trustees: [],
  spouseContributers: [],
  allowSuccessorAnnuitants: false,
  successorAnnuitants: [],
  isComplete: false
};
