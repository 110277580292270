import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { checkEmptyValue, notify } from "utils";

const ValidateSWPDetails = (accountData: CreateInvestmentAccountDto) => {
  const swpDetails = accountData.swpDetails;
  if (swpDetails === undefined || swpDetails === null || swpDetails.length === 0) {
    notify("Please enter a SWP Detail", "error");
    return false;
  }

  for (let i = 0; i < swpDetails.length; i++) {
    const swpDetail = swpDetails[i];
    if (checkEmptyValue(swpDetail.type)) {
      notify("Please enter a type for all SWP Details", "error");
      return false;
    }
    if (checkEmptyValue(swpDetail.amount)) {
      notify("Please enter an amount for all SWP Details", "error");
      return false;
    }
    if (checkEmptyValue(swpDetail.frequency)) {
      notify("Please enter a frequency for all SWP Details", "error");
      return false;
    }
    if (checkEmptyValue(swpDetail.startDate)) {
      notify("Please enter a start date for all SWP Details", "error");
      return false;
    }
    if (checkEmptyValue(swpDetail.accountOption)) {
      notify("Please enter an account option for all SWP Details", "error");
      return false;
    }
    if (checkEmptyValue(swpDetail.bankAccountId)) {
      notify("Please select a bank account for all SWP Details", "error");
      return false;
    }
  }

  return true;
};

export default ValidateSWPDetails;
