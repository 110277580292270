import {
  useCreatePowerOfAttorneyMutation,
  useLazyGetParticipantsByHouseholdQuery,
} from "apis/mainApi";
import { CitizenshipType, ParticipantRole } from "constants/enums";
import React, { useEffect, useState } from "react";
import { ApiResponseModel, Participant } from "types";
import { SelectItem } from "types/Shared";
import { KYCSectionProps } from "../types";
import KYCSelect from "./KYCSelect";
import KYCInput from "./KYCInput";
import { ClientCitizenshipOptions, MaritalStatusOptions } from "constants/selectOptions";
import { AddressTypes, EmploymentTypes } from "assets/StaticData/EditClientOptions";
import KYCCountrySelect from "./KYCCountrySelect";
import KYCRegionSelect from "./KYCRegionSelect";
import KYCBoolean from "./KYCBoolean";
import { InsiderDetails } from "features/participant/components/ParticipantDetails";
import { notify } from "utils";
import ValidatePowerOfAttorney from "../utils/ValidatePowerOfAttorney";

function KYCPowerOfAttorney({ formData, setFormData }: KYCSectionProps) {
  // State to track the creation of a new power of attorney
  const [createNewPowerOfAttorney, setCreateNewPowerOfAttorney] = useState<boolean>(false);

  // Query to retireve all participants in a household if the spouse is a participant
  const [participantsHouseholdQuery, participantsHouseholdResult] =
    useLazyGetParticipantsByHouseholdQuery();

  // Select Items for the participants in the household if the spouse is a participant
  const [participantSelectItems, setParticipantSelectItems] = useState<SelectItem[]>([]);

  // Query to retireve all participants in a household if the spouse is a participant
  useEffect(() => {
    if (formData?.householdId) {
      participantsHouseholdQuery(formData.householdId);
    }
  }, [formData?.householdId]);

  // Set the select items for the participants in the household
  useEffect(() => {
    if (participantsHouseholdResult.isSuccess && participantsHouseholdResult.data) {
      const participants = participantsHouseholdResult.data.result;
      if (participants.length > 0) {
        CreateParticipantSelectItems(participants, setParticipantSelectItems);
      }
    }
  }, [participantsHouseholdResult]);

  const handlePowerOfAttorneyChange = (e: any) => {
    setFormData({ ...formData, powerOfAttorneyId: e });
  };

  return (
    <div className="kyc-section-container">
      <div className="kyc-section-container-header">
        <h1>Power of Attorney Details</h1>
        <p>Please Fill Out All Required Fields</p>
      </div>
      <div className="kyc-section-container-body">
        <KYCSelect
          label="Select Power of Attorney"
          value={formData?.powerOfAttorneyId}
          options={participantSelectItems}
          onChange={(e) => handlePowerOfAttorneyChange(e)}
        />
        {!createNewPowerOfAttorney && (
          <div className="create-new-btn-cont">
            <button className="create-new-btn" onClick={() => setCreateNewPowerOfAttorney(true)}>
              Create New Power of Attorney
            </button>
          </div>
        )}
        {createNewPowerOfAttorney && (
          <CreateNewPowerOfAttorney
            participant={formData}
            setParticipant={setFormData}
            setCreateNewPowerOfAttorney={setCreateNewPowerOfAttorney}
          />
        )}
      </div>
    </div>
  );
}

/**
 * Creates the select items for the power of attorneys in the household
 * @param participants
 * @param setParticipantSelectItems
 */
function CreateParticipantSelectItems(
  participants: any,
  setParticipantSelectItems: React.Dispatch<React.SetStateAction<SelectItem[]>>
) {
  const selectItems: SelectItem[] = [];
  participants.forEach((participant: Participant) => {
    if (participant.roles.includes(ParticipantRole.POWER_OF_ATTORNEY)) {
      selectItems.push({
        label: `${participant?.generalDetails?.firstName} ${participant?.generalDetails?.lastName}`,
        value: participant?.id,
      });
    }
  });
  setParticipantSelectItems(selectItems);
}

interface CreateNewPowerOfAttorneyProps {
  participant: Participant;
  setParticipant: React.Dispatch<React.SetStateAction<Participant>>;
  setCreateNewPowerOfAttorney: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateNewPowerOfAttorney = ({
  participant,
  setCreateNewPowerOfAttorney,
  setParticipant,
}: CreateNewPowerOfAttorneyProps) => {
  // Hate this but the type is too complex to create
  const [powerOfAttorney, setPowerOfAttorney] = useState<any>({
    householdId: participant.householdId,
  });

  const [createPowerOfAttorney] = useCreatePowerOfAttorneyMutation();

  const handleInputChange = (val: any, attrName: string, subAttrName: string) => {
    const copyPoA: any = { ...powerOfAttorney };
    if (copyPoA[attrName!] == undefined) {
      copyPoA[attrName!] = {};
    }
    copyPoA[attrName!][subAttrName!] = val;
    setPowerOfAttorney(copyPoA);
  };

  const handleCreatePowerOfAttorney = async () => {
    // Validate the Inputs
    const valid = ValidatePowerOfAttorney(powerOfAttorney);

    if (valid) {
      // Create the power of attorney
      const newPowerOfAttorney: ApiResponseModel = await createPowerOfAttorney(powerOfAttorney);
      if (newPowerOfAttorney.data && newPowerOfAttorney.data.isSuccess) {
        notify("Power of Attorney Created", "success");
        setCreateNewPowerOfAttorney(false);
      } else if (newPowerOfAttorney.data && !newPowerOfAttorney.data.isSuccess) {
        notify("An Error Has Occured");
        console.log(newPowerOfAttorney.data?.errorMessages);
      } else if (newPowerOfAttorney.error) {
        notify("An Error Has Occured");
        console.log(newPowerOfAttorney.error);
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="regulatory-divider"></div>
      <div className="cancel-btn-cont">
        <button className="btn btn-danger" onClick={() => setCreateNewPowerOfAttorney(false)}>
          Cancel
        </button>
      </div>
      <p className="kyc-section-divider-header" style={{ textAlign: "center", margin: "1rem" }}>
        General Details
      </p>
      <KYCInput
        label="First Name"
        value={
          powerOfAttorney?.generalDetails?.firstName
            ? powerOfAttorney?.generalDetails?.firstName
            : ""
        }
        onChange={(e) => handleInputChange(e, "generalDetails", "firstName")}
      />
      <KYCInput
        label="Last Name"
        value={
          powerOfAttorney?.generalDetails?.lastName ? powerOfAttorney?.generalDetails?.lastName : ""
        }
        onChange={(e) => handleInputChange(e, "generalDetails", "lastName")}
      />
      <KYCInput
        label="Email"
        value={powerOfAttorney?.generalDetails?.email ? powerOfAttorney?.generalDetails?.email : ""}
        onChange={(e) => handleInputChange(e, "generalDetails", "email")}
        placeholder="example@example.com"
      />
      <KYCInput
        label="Phone Number"
        value={
          powerOfAttorney?.generalDetails?.cellPhone
            ? powerOfAttorney?.generalDetails?.cellPhone
            : ""
        }
        onChange={(e) => handleInputChange(e, "generalDetails", "cellPhone")}
        placeholder="111-111-1111"
      />
      <KYCInput
        label="Date of Birth"
        value={
          powerOfAttorney?.generalDetails?.dateOfBirth
            ? powerOfAttorney?.generalDetails?.dateOfBirth
            : ""
        }
        onChange={(e) => handleInputChange(e, "generalDetails", "dateOfBirth")}
        type="date"
      />
      <KYCSelect
        label="Citizenship"
        value={
          powerOfAttorney?.generalDetails?.citizenship
            ? powerOfAttorney?.generalDetails?.citizenship
            : ""
        }
        onChange={(e) => handleInputChange(e, "generalDetails", "citizenship")}
        options={ClientCitizenshipOptions}
      />
      <KYCSelect
        label="Marital Status"
        value={
          powerOfAttorney?.generalDetails?.maritalStatus
            ? powerOfAttorney?.generalDetails?.maritalStatus
            : ""
        }
        onChange={(e) => handleInputChange(e, "generalDetails", "maritalStatus")}
        options={MaritalStatusOptions}
      />
      <div className="regulatory-divider"></div>
      <p className="kyc-section-divider-header" style={{ textAlign: "center", margin: "1rem" }}>
        Address Details
      </p>
      <KYCSelect
        label="Type of Address"
        value={
          powerOfAttorney?.permanentAddressDetails?.addressType
            ? powerOfAttorney?.permanentAddressDetails?.addressType
            : ""
        }
        onChange={(e) => handleInputChange(e, "permanentAddressDetails", "addressType")}
        options={AddressTypes}
      />
      <KYCInput
        label="Apt/Unit/Suite #"
        value={
          powerOfAttorney?.permanentAddressDetails?.aptUnitSuiteNumber
            ? powerOfAttorney?.permanentAddressDetails?.aptUnitSuiteNumber
            : ""
        }
        onChange={(e) => handleInputChange(e, "permanentAddressDetails", "aptUnitSuiteNumber")}
        type="number"
      />
      <KYCInput
        label="Street Number"
        value={
          powerOfAttorney?.permanentAddressDetails?.streetNumber
            ? powerOfAttorney?.permanentAddressDetails?.streetNumber
            : ""
        }
        onChange={(e) => handleInputChange(e, "permanentAddressDetails", "streetNumber")}
        type="number"
      />
      <KYCInput
        label="Street Name"
        value={
          powerOfAttorney?.permanentAddressDetails?.streetName
            ? powerOfAttorney?.permanentAddressDetails?.streetName
            : ""
        }
        onChange={(e) => handleInputChange(e, "permanentAddressDetails", "streetName")}
        placeholder="Example Road NW"
      />
      <KYCCountrySelect
        label="Country"
        value={
          powerOfAttorney?.permanentAddressDetails?.country
            ? powerOfAttorney?.permanentAddressDetails?.country
            : ""
        }
        onChange={(e) => handleInputChange(e, "permanentAddressDetails", "country")}
      />
      <KYCRegionSelect
        label="Region"
        value={
          powerOfAttorney?.permanentAddressDetails?.region
            ? powerOfAttorney?.permanentAddressDetails?.region
            : ""
        }
        country={powerOfAttorney?.permanentAddressDetails?.country}
        onChange={(e) => handleInputChange(e, "permanentAddressDetails", "region")}
      />
      <KYCInput
        label="City"
        value={
          powerOfAttorney?.permanentAddressDetails?.city
            ? powerOfAttorney?.permanentAddressDetails?.city
            : ""
        }
        onChange={(e) => handleInputChange(e, "permanentAddressDetails", "city")}
      />
      <KYCInput
        label="Postal Code"
        value={
          powerOfAttorney?.permanentAddressDetails?.postalCode
            ? powerOfAttorney?.permanentAddressDetails?.postalCode
            : ""
        }
        onChange={(e) => handleInputChange(e, "permanentAddressDetails", "postalCode")}
      />
      <div className="regulatory-divider"></div>
      <p className="kyc-section-divider-header" style={{ textAlign: "center", margin: "1rem" }}>
        Employment Details
      </p>
      <KYCSelect
        label="Status"
        value={
          powerOfAttorney?.employmentDetails?.status
            ? powerOfAttorney?.employmentDetails?.status
            : ""
        }
        onChange={(e) => handleInputChange(e, "employmentDetails", "status")}
        options={EmploymentTypes}
      />
      <KYCInput
        label="Occupation"
        value={
          powerOfAttorney?.employmentDetails?.occupation
            ? powerOfAttorney?.employmentDetails?.occupation
            : ""
        }
        onChange={(e) => handleInputChange(e, "employmentDetails", "occupation")}
      />
      <KYCInput
        label="Employer Name"
        value={
          powerOfAttorney?.employmentDetails?.name ? powerOfAttorney?.employmentDetails?.name : ""
        }
        onChange={(e) => handleInputChange(e, "employmentDetails", "name")}
      />
      <KYCInput
        label="Type of Business"
        value={
          powerOfAttorney?.employmentDetails?.businessType
            ? powerOfAttorney?.employmentDetails?.businessType
            : ""
        }
        onChange={(e) => handleInputChange(e, "employmentDetails", "businessType")}
      />
      <KYCInput
        label="Length in Years"
        value={
          powerOfAttorney?.employmentDetails?.lengthInYears
            ? powerOfAttorney?.employmentDetails?.lengthInYears
            : ""
        }
        onChange={(e) => handleInputChange(e, "employmentDetails", "lengthInYears")}
        type="number"
      />
      <div className="regulatory-divider"></div>
      <p className="kyc-section-divider-header" style={{ textAlign: "center", margin: "1rem" }}>
        Regulatory Details
      </p>
      <KYCBoolean
        label="Is the PoA an Insider?"
        value={powerOfAttorney?.regulatoryDetails?.isInsider}
        onChange={(e) => handleInputChange(e, "regulatoryDetails", "isInsider")}
      />
      {powerOfAttorney?.regulatoryDetails?.isInsider && (
        <InsiderDetails
          participant={powerOfAttorney}
          editMode={true}
          setParticipant={setPowerOfAttorney}
          insiderAttrName="insiderDetails"
        />
      )}
      <div className="regulatory-divider"></div>
      <KYCBoolean
        label="Is the PoA an 10% Shareholder?"
        value={powerOfAttorney?.regulatoryDetails?.is10PercentShareholder}
        onChange={(e) => handleInputChange(e, "regulatoryDetails", "is10PercentShareholder")}
      />
      {powerOfAttorney?.regulatoryDetails?.is10PercentShareholder && (
        <InsiderDetails
          participant={powerOfAttorney}
          editMode={true}
          setParticipant={setPowerOfAttorney}
          insiderAttrName="shareholder10Details"
        />
      )}
      <div className="regulatory-divider"></div>
      <KYCBoolean
        label="Is the PoA an 20% Shareholder?"
        value={powerOfAttorney?.regulatoryDetails?.is20PercentShareholder}
        onChange={(e) => handleInputChange(e, "regulatoryDetails", "is20PercentShareholder")}
      />
      {powerOfAttorney?.regulatoryDetails?.is20PercentShareholder && (
        <InsiderDetails
          participant={powerOfAttorney}
          editMode={true}
          setParticipant={setPowerOfAttorney}
          insiderAttrName="shareholder20Details"
        />
      )}
      <div className="regulatory-divider"></div>
      <div className="submit-btn-cont">
        <button onClick={handleCreatePowerOfAttorney}>Create</button>
      </div>
    </div>
  );
};

export default KYCPowerOfAttorney;
