import React from "react";
import { useNavigate } from "react-router-dom";

const CompleteWizard: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 p-4">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-md text-center">
        <p className="text-xl font-semibold mb-4">The Risk Tolerance Questionnaire has been completed.</p>
        <p>
          <span className="text-primary font-bold cursor-pointer hover:underline" onClick={() => navigate(-1)}>
            Go Back to Participant
          </span>
        </p>
      </div>
    </div>
  );
};

export default CompleteWizard;
