import React, { useEffect, useState } from "react";
import { AccountSectionProps } from "../types";
import { SelectItem } from "types/Shared";
import { SIAInput, SIASelect } from "components/Shared";
import { ContributionAccountOptions, ContributionTypes } from "constants/selectOptions";
import { ContributionFrequencies } from "assets/StaticData/EditClientOptions";
import { convertDateToString } from "utils";

/**
 * @name    AccountPAC
 * @summary This component is responsible for rendering the PAC Details table and adding new PAC details
 * @param   formData the new investment account form data
 * @param   setFormData the react hook form set form data function
 * @param   participant the participant state information
 * @returns React.Component
 */
function AccountPAC({ formData, setFormData, participant, viewMode }: AccountSectionProps) {
  const handleAddPAC = () => {
    if (formData.pacDetails) {
      setFormData({
        ...formData,
        pacDetails: [
          ...formData.pacDetails,
          {
            type: 0,
            amount: 0,
            frequency: 0,
            startDate: "",
            bankAccountId: "",
            accountOption: 0,
          },
        ],
      });
    } else {
      setFormData({
        ...formData,
        pacDetails: [
          {
            type: 0,
            amount: 0,
            frequency: 0,
            startDate: "",
            bankAccountId: "",
            accountOption: 0,
          },
        ],
      });
    }
  };

  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>Pre-Authorized Contributions</h1>
        <h3>Please specify all Pre-Authorizations</h3>
      </div>
      <div className="account-section-details-body">
        {formData.pacDetails && formData.pacDetails.length > 0 && (
          <PACDetailsTable
            formData={formData}
            setFormData={setFormData}
            participant={participant}
            viewMode={viewMode}
          />
        )}
      </div>
      <div>
        {!viewMode && (
          <button className="btn btn-primary" onClick={handleAddPAC}>
            Add PAC
          </button>
        )}
      </div>
    </div>
  );
}

const PACDetailsTable = ({ formData, setFormData, participant, viewMode }: AccountSectionProps) => {
  // Bank Account Select Option
  const [bankAccountSelectOptions, setBankAccountSelectOptions] = useState<SelectItem[]>([]);

  const handleChange = (val: any, index: number, key: string) => {
    if (formData.pacDetails) {
      const pacDetails = [...formData.pacDetails];
      const selectedDetail = { ...pacDetails[index] };
      selectedDetail[key] = val;
      pacDetails[index] = selectedDetail;

      setFormData({
        ...formData,
        pacDetails,
      });
    }
  };

  const handleDelete = (index: number) => {
    if (formData.pacDetails)
      setFormData({
        ...formData,
        pacDetails: formData.pacDetails.filter((_, i) => i !== index),
      });
  };

  const handleDetailChange = (val: any, index: number, key: string) => {
    if (formData.pacDetails) {
      const pacDetails = [...formData.pacDetails];
      const selectedDetail = { ...pacDetails[index] };
      selectedDetail[key] = val;
      pacDetails[index] = selectedDetail;

      setFormData({
        ...formData,
        pacDetails,
      });
    }
  };

  useEffect(() => {
    // Can only use bank accounts found in the EFT bank accounts
    let bankAccounts: SelectItem[] = [];
    if (formData.eftBankAccounts) {
      for (const bankAccount of formData.eftBankAccounts) {
        // Find it in the participants object
        const account = participant?.bankAccounts.find((account) => account.id === bankAccount);
        if (account) {
          bankAccounts.push({
            label: `${account.institutionName} - ${account.accountNumber}`,
            value: account.id,
          });
        }
      }
    }

    setBankAccountSelectOptions(bankAccounts);
  }, [formData]);

  return (
    <div className="pac-swp-table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Amount</th>
            <th>Frequency</th>
            <th>Start Date</th>

            <th>Account Option</th>
            <th>Bank Account</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {formData.pacDetails &&
            formData.pacDetails.map((pacDetail, index) => (
              <tr key={index}>
                <td>
                  <SIASelect
                    value={pacDetail.type}
                    options={ContributionTypes}
                    onChange={(e) => handleDetailChange(e, index, "type")}
                  ></SIASelect>
                </td>
                <td>
                  <SIAInput
                    value={pacDetail.amount}
                    onChange={(e) => handleDetailChange(e.target.value, index, "amount")}
                    type="number"
                    isDollar={true}
                  ></SIAInput>
                </td>
                <td>
                  <SIASelect
                    value={pacDetail.frequency}
                    options={ContributionFrequencies}
                    onChange={(e) => handleDetailChange(e, index, "frequency")}
                  ></SIASelect>
                </td>
                {/* <td>{pacDetail.frequency}</td> */}
                <td>
                  <SIAInput
                    value={convertDateToString(pacDetail.startDate)}
                    onChange={(e) => handleDetailChange(e.target.value, index, "startDate")}
                    type="date"
                  ></SIAInput>
                </td>
                <td>
                  <SIASelect
                    value={pacDetail.accountOption}
                    options={ContributionAccountOptions}
                    onChange={(e) => handleDetailChange(e, index, "accountOption")}
                  ></SIASelect>
                </td>
                <td>
                  <SIASelect
                    options={bankAccountSelectOptions}
                    value={pacDetail.bankAccountId}
                    onChange={(e) => handleChange(e, index, "bankAccountId")}
                  ></SIASelect>
                </td>
                <td>
                  {!viewMode && (
                    <button className="btn btn-danger" onClick={() => handleDelete(index)}>
                      <i className="bi bi-trash"></i>
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccountPAC;
