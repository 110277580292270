import withAuth from "HOC/withAuth";
import "../styles/kyc.scss";
import React, { useEffect, useState } from "react";
import { useLazyGetClientByIdQuery, useUpdateNewClientMutation } from "apis/mainApi";
import { ApiResponseModel, Participant } from "types";
import { useNavigate, useParams } from "react-router-dom";
import { StepFormWizard } from "components/Shared";
import KYCGeneralDetails from "./KYCGeneralDetails";
import { ValidateAddressDetails, ValidateGeneralDetails } from "../utils";
import { notify, sleep } from "utils";
import KYCAddressDetails from "./KYCAddressDetails";
import KYCEmploymentDetails from "./KYCEmploymentDetails";
import ValidateEmploymentDetails from "../utils/ValidateEmploymentDetails";
import KYCSpouseDetails from "./KYCSpouseDetails";
import { Tooltip } from "react-tooltip";
import ValidateSpouseDetails from "../utils/ValidateSpouseDetails";
import KYCNetWorthDetails from "./KYCNetWorthDetails";
import ValidateFinancialDetails from "../utils/ValidateClientNetWorthDetails";
import KYCRegulatoryDetails from "./KYCRegulatoryDetails";
import ValidateRegulatoryDetails from "../utils/ValidateRegulatoryDetails";
import KYCTrustedContact from "./KYCTrustedContact";
import ValidateTrustedContactDetails from "../utils/ValidateTrustedContactDetails";
import KYCPowerOfAttorney from "./KYCPowerOfAttorney";
import ReviewDetails from "./ReviewDetails";
import { ReturnIconSvg } from "assets/svg";

function KnowYourClientPage() {
  const navigate = useNavigate();
  const [participant, setParticipant] = useState<Participant>();
  const [participantCopy, setParticipantCopy] = useState<Participant>();
  const [startIndex, setStartIndex] = useState<number>(0);
  const [clientQuery, clientResult] = useLazyGetClientByIdQuery();
  const [updateClient] = useUpdateNewClientMutation();
  const { participantId } = useParams();

  useEffect(() => {
    if (participantId) {
      clientQuery(participantId);
    }
  }, [participantId]);

  useEffect(() => {
    if (clientResult.isSuccess && clientResult.data.result) {
      setParticipant(clientResult.data.result);
      setParticipantCopy(clientResult.data.result);
    }
  }, [clientResult]);

  useEffect(() => {
    if (participant) {
      if (ValidateGeneralDetails(participant)) {
        setStartIndex(1);
      }
    }
  }, [participant]);

  /**
   * This function will be called when the user clicks the next button
   * This will save the current form data and then move to the next step
   */
  const handleNextStepChange = async () => {
    if (participantCopy) {
      setParticipantCopy({ ...participantCopy, completedKYC: false });
      const result: ApiResponseModel = await updateClient(participantCopy);
      if (result.data?.isSuccess) {
        notify("Participant Details Saved");
      } else if (!result.data?.isSuccess) {
        console.log(participantCopy.mailingAddressDetails);
        notify("An Error occurred with saving", "error");
        console.log(result);
      }
    }
  };

  const handleSubmit = async () => {
    if (participantCopy) {
      setParticipantCopy({ ...participantCopy, completedKYC: true });

      const result: ApiResponseModel = await updateClient(participantCopy);
      if (result.data?.isSuccess) {
        notify("KYC Successfully Completed!");
        navigate(-1);
      }
    }
  };

  return (
    <div className="kyc-container">
      <Tooltip id="kyc-tooltip" />
      <div className="kyc-container-header">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ReturnIconSvg />
        </button>
        <h1>SIA Know Your Client Application</h1>
        {participant && (
          <h3>
            {participant.generalDetails.firstName} {participant.generalDetails.lastName}
          </h3>
        )}
      </div>
      <div className="kyc-container-body">
        <StepFormWizard
          startIndex={0}
          steps={[
            {
              label: "General Details",
              subtitle: "",
              content: KYCGeneralDetails,
              validator: ValidateGeneralDetails,
            },
            {
              label: "Address Details",
              subtitle: "",
              content: KYCAddressDetails,
              validator: ValidateAddressDetails,
            },
            {
              label: "Employment Details",
              subtitle: "",
              content: KYCEmploymentDetails,
              validator: ValidateEmploymentDetails,
            },
            // Conditional Step
            ...(participantCopy?.generalDetails.maritalStatus == "Married" ||
            participantCopy?.generalDetails.maritalStatus == "Common Law"
              ? [
                  {
                    label: "Spouse Details",
                    subtitle: "",
                    content: KYCSpouseDetails,
                    validator: ValidateSpouseDetails,
                  },
                ]
              : []),
            {
              label: "Financial Details",
              subtitle: "",
              content: KYCNetWorthDetails,
              validator: ValidateFinancialDetails,
            },
            {
              label: "Regulation Details",
              subtitle: "",
              content: KYCRegulatoryDetails,
              validator: ValidateRegulatoryDetails,
            },
            ...(participantCopy?.regulatoryDetails?.hasPowerOfAttorney
              ? [
                  {
                    label: "Power of Attorney Details",
                    subtitle: "",
                    content: KYCPowerOfAttorney,
                    validator: () => {
                      return true;
                    },
                  },
                ]
              : []),
            {
              label: "Trusted Contact Details",
              subtitle: "",
              content: KYCTrustedContact,
              validator: ValidateTrustedContactDetails,
            },
            {
              label: "Review",
              subtitle: "",
              content: ReviewDetails,
              validator: () => {
                return true;
              },
            },
          ]}
          formData={participantCopy}
          setFormData={setParticipantCopy}
          nextText="Save and Continue"
          submitForm={handleSubmit}
          handleStepChange={handleNextStepChange}
        />
      </div>
      {/* <button
        onClick={() => {
          console.log(participantCopy);
        }}
      >
        See Data
      </button> */}
    </div>
  );
}

export default withAuth(KnowYourClientPage);
