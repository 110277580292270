import { InvestmentAccountSubType } from "constants/enums";
import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { checkEmptyValue, notify } from "utils";

const ValidateIncomeFundDetails = (accountData: CreateInvestmentAccountDto) => {
  const incomeFundInfo = accountData.incomeFundInformation;
  if (checkEmptyValue(incomeFundInfo?.minPayment)) {
    notify("Please enter a minimum payment", "error");
    return false;
  }
  if (checkEmptyValue(incomeFundInfo?.paymentAboveMin)) {
    notify("Please enter a payment above minimum", "error");
    return false;
  }
  if (
    accountData.subType == InvestmentAccountSubType.LOCKED_IN &&
    checkEmptyValue(accountData?.lifInformation?.maxPayment)
  ) {
    notify("Please enter a locked in max amount", "error");
    return false;
  }
};

export default ValidateIncomeFundDetails;
