import { IconProps } from "./IconProps";
import SvgContainer from "./SvgContainer";

const BeneficiaryMale = ({ className, active, onClick }: IconProps) => {
  const activeSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M141.000000,210.000000 
   C94.000000,210.000000 47.500004,210.000000 1.000006,210.000000 
   C1.000004,140.333344 1.000004,70.666679 1.000002,1.000016 
   C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
   C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
   C187.166672,210.000000 164.333328,210.000000 141.000000,210.000000 
M135.834244,153.332047 
   C134.998627,152.845367 133.924438,152.547623 133.365463,151.841843 
   C129.195831,146.577179 130.067032,140.286041 131.841797,134.923050 
   C135.464310,123.976601 140.295349,113.427177 144.697983,102.742805 
   C147.670181,95.529846 151.871826,87.836105 147.432480,80.544579 
   C144.394165,75.554237 144.031357,70.925812 144.004013,65.748558 
   C143.982910,61.752831 144.099701,57.753914 143.980225,53.761654 
   C143.676682,43.618690 139.475952,35.199902 130.790298,30.125719 
   C119.942932,23.788668 108.537895,18.391447 97.236900,12.868238 
   C96.093193,12.309265 93.541977,13.179729 92.439705,14.225555 
   C86.041191,20.296463 79.580254,26.349827 73.710716,32.918697 
   C64.901474,42.777519 62.319172,54.616543 63.826927,67.615746 
   C64.262627,71.372131 65.212814,75.082085 62.213875,78.946526 
   C59.068066,83.000237 57.538380,88.268211 60.014591,93.586082 
   C61.906361,97.648811 64.223244,101.529106 65.900909,105.672783 
   C69.331573,114.146225 71.939812,122.988144 75.838791,131.227814 
   C81.127487,142.404343 79.816360,148.329498 68.371895,154.927094 
   C62.587898,155.954239 56.714870,156.634964 51.041656,158.093124 
   C43.350567,160.069916 35.624779,162.172440 28.269833,165.111740 
   C22.640537,167.361389 22.065475,173.010239 21.968073,178.486404 
   C21.884109,183.206818 24.405878,185.766479 28.542194,187.160980 
   C34.388626,189.132004 40.147778,191.482056 46.124046,192.925766 
   C65.748482,197.666458 85.661407,199.935684 105.892029,199.016830 
   C118.352600,198.450882 130.820892,198.054611 144.120499,197.550507 
   C132.852783,184.938217 133.865402,170.103226 145.398743,155.203537 
   C142.195938,154.783615 139.179153,154.388077 135.834244,153.332047 
M177.227539,154.107605 
   C171.186050,149.965286 172.873169,143.731094 173.522415,137.770691 
   C171.414566,137.586502 169.944901,137.496826 168.485031,137.324203 
   C159.306564,136.238739 159.307281,136.232635 159.703033,145.169037 
   C157.858368,144.781937 156.047165,144.401871 153.880188,143.947144 
   C153.880188,146.576096 153.880188,148.626678 153.880188,150.630722 
   C155.360550,150.855377 156.547546,151.035507 158.646118,151.353989 
   C155.933426,153.658752 153.568069,155.073929 152.015945,157.109253 
   C148.643448,161.531662 144.540451,165.872833 142.775604,170.955414 
   C137.648300,185.721573 144.676468,195.181686 160.256042,195.507523 
   C165.906219,195.625687 171.579285,195.498062 177.217880,195.122177 
   C185.633072,194.561234 190.803696,188.950485 191.173752,180.445374 
   C191.660263,169.264389 186.499542,161.012985 177.227539,154.107605 
z"
      />
      <path
        fill="#231F20"
        opacity="1.000000"
        stroke="none"
        d="
M69.232910,154.640701 
   C79.816360,148.329498 81.127487,142.404343 75.838791,131.227814 
   C71.939812,122.988144 69.331573,114.146225 65.900909,105.672783 
   C64.223244,101.529106 61.906361,97.648811 60.014591,93.586082 
   C57.538380,88.268211 59.068066,83.000237 62.213875,78.946526 
   C65.212814,75.082085 64.262627,71.372131 63.826927,67.615746 
   C62.319172,54.616543 64.901474,42.777519 73.710716,32.918697 
   C79.580254,26.349827 86.041191,20.296463 92.439705,14.225555 
   C93.541977,13.179729 96.093193,12.309265 97.236900,12.868238 
   C108.537895,18.391447 119.942932,23.788668 130.790298,30.125719 
   C139.475952,35.199902 143.676682,43.618690 143.980225,53.761654 
   C144.099701,57.753914 143.982910,61.752831 144.004013,65.748558 
   C144.031357,70.925812 144.394165,75.554237 147.432480,80.544579 
   C151.871826,87.836105 147.670181,95.529846 144.697983,102.742805 
   C140.295349,113.427177 135.464310,123.976601 131.841797,134.923050 
   C130.067032,140.286041 129.195831,146.577179 133.365463,151.841843 
   C133.924438,152.547623 134.998627,152.845367 135.836365,153.967285 
   C133.823700,156.059998 131.828354,157.545273 129.791061,158.970581 
   C111.901497,171.486374 93.739555,171.577652 75.434181,159.979599 
   C73.154625,158.535294 71.289871,156.436325 69.232910,154.640701 
z"
      />
      <path
        fill="#2C7EF3"
        opacity="1.000000"
        stroke="none"
        d="
M68.802399,154.783905 
   C71.289871,156.436325 73.154625,158.535294 75.434181,159.979599 
   C93.739555,171.577652 111.901497,171.486374 129.791061,158.970581 
   C131.828354,157.545273 133.823700,156.059998 136.000427,154.297546 
   C139.179153,154.388077 142.195938,154.783615 145.398743,155.203537 
   C133.865402,170.103226 132.852783,184.938217 144.120499,197.550507 
   C130.820892,198.054611 118.352600,198.450882 105.892029,199.016830 
   C85.661407,199.935684 65.748482,197.666458 46.124046,192.925766 
   C40.147778,191.482056 34.388626,189.132004 28.542194,187.160980 
   C24.405878,185.766479 21.884109,183.206818 21.968073,178.486404 
   C22.065475,173.010239 22.640537,167.361389 28.269833,165.111740 
   C35.624779,162.172440 43.350567,160.069916 51.041656,158.093124 
   C56.714870,156.634964 62.587898,155.954239 68.802399,154.783905 
z"
      />
      <path
        fill="#07833C"
        opacity="1.000000"
        stroke="none"
        d="
M177.510773,154.350189 
   C186.499542,161.012985 191.660263,169.264389 191.173752,180.445374 
   C190.803696,188.950485 185.633072,194.561234 177.217880,195.122177 
   C171.579285,195.498062 165.906219,195.625687 160.256042,195.507523 
   C144.676468,195.181686 137.648300,185.721573 142.775604,170.955414 
   C144.540451,165.872833 148.643448,161.531662 152.015945,157.109253 
   C153.568069,155.073929 155.933426,153.658752 158.646118,151.353989 
   C156.547546,151.035507 155.360550,150.855377 153.880188,150.630722 
   C153.880188,148.626678 153.880188,146.576096 153.880188,143.947144 
   C156.047165,144.401871 157.858368,144.781937 159.703033,145.169037 
   C159.307281,136.232635 159.306564,136.238739 168.485031,137.324203 
   C169.944901,137.496826 171.414566,137.586502 173.522415,137.770691 
   C172.873169,143.731094 171.186050,149.965286 177.510773,154.350189 
M169.206863,162.248154 
   C166.196594,160.635696 160.220612,162.329514 158.790146,165.429825 
   C157.081146,169.133835 159.292053,171.357361 162.207214,173.131454 
   C163.481873,173.907150 164.975937,174.424713 166.047226,175.410614 
   C168.209366,177.400497 168.731888,179.457993 165.292252,181.251984 
   C164.652054,179.697449 164.106415,178.372498 163.458328,176.798798 
   C161.867126,176.891205 160.277740,176.983490 158.688354,177.075790 
   C158.347168,177.553741 158.005981,178.031708 157.664810,178.509674 
   C160.142639,180.942612 162.424500,183.656784 165.233627,185.614334 
   C165.952194,186.115067 168.443604,184.383331 169.940079,183.402084 
   C176.328659,179.213089 176.150299,175.063751 169.503143,171.330856 
   C168.347519,170.681885 166.872238,170.295853 166.110107,169.338287 
   C165.340668,168.371521 165.274506,166.845016 164.956665,165.756882 
   C168.184464,166.871857 170.475662,167.663300 172.766861,168.454758 
   C173.174133,167.801880 173.581406,167.149017 173.988678,166.496140 
   C172.619919,165.228912 171.251160,163.961670 169.206863,162.248154 
z"
      />
      <path
        fill="#F1F8F4"
        opacity="1.000000"
        stroke="none"
        d="
M169.544632,162.471298 
   C171.251160,163.961670 172.619919,165.228912 173.988678,166.496140 
   C173.581406,167.149017 173.174133,167.801880 172.766861,168.454758 
   C170.475662,167.663300 168.184464,166.871857 164.956665,165.756882 
   C165.274506,166.845016 165.340668,168.371521 166.110107,169.338287 
   C166.872238,170.295853 168.347519,170.681885 169.503143,171.330856 
   C176.150299,175.063751 176.328659,179.213089 169.940079,183.402084 
   C168.443604,184.383331 165.952194,186.115067 165.233627,185.614334 
   C162.424500,183.656784 160.142639,180.942612 157.664810,178.509674 
   C158.005981,178.031708 158.347168,177.553741 158.688354,177.075790 
   C160.277740,176.983490 161.867126,176.891205 163.458328,176.798798 
   C164.106415,178.372498 164.652054,179.697449 165.292252,181.251984 
   C168.731888,179.457993 168.209366,177.400497 166.047226,175.410614 
   C164.975937,174.424713 163.481873,173.907150 162.207214,173.131454 
   C159.292053,171.357361 157.081146,169.133835 158.790146,165.429825 
   C160.220612,162.329514 166.196594,160.635696 169.544632,162.471298 
z"
      />
    </svg>
  );

  const passiveSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M139.000000,210.000000 
   C92.666672,210.000000 46.833340,210.000000 1.000006,210.000000 
   C1.000004,140.333344 1.000004,70.666679 1.000002,1.000015 
   C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
   C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
   C186.500000,210.000000 163.000000,210.000000 139.000000,210.000000 
M184.772324,196.652969 
   C195.895676,191.879532 195.978256,182.369431 194.411072,171.897446 
   C193.104431,163.166519 186.372620,158.175888 180.913513,152.240952 
   C179.098694,150.267929 177.816406,147.494644 177.142853,144.868622 
   C176.453842,142.182373 177.866272,138.306992 176.417206,136.645126 
   C174.910583,134.917221 171.068939,135.265778 168.268982,134.617310 
   C160.879166,132.905823 158.840485,134.198730 157.465317,141.563828 
   C151.538712,142.599869 150.054886,147.095703 153.581192,154.075089 
   C149.055542,157.131516 144.508118,155.057877 140.074799,154.065063 
   C134.275894,152.766418 131.755722,149.897842 132.106689,144.140305 
   C132.393692,139.431778 132.937424,134.524780 134.571823,130.156418 
   C138.136963,120.627716 142.459412,111.381790 146.490845,102.028267 
   C150.117508,93.613876 152.718719,85.304924 146.527451,76.753159 
   C145.301880,75.060318 145.153778,72.368858 145.064667,70.115944 
   C144.860641,64.957428 144.838058,59.779198 145.033432,54.620018 
   C145.451126,43.589676 140.464371,35.261108 131.381210,29.917931 
   C120.191154,23.335379 108.426933,17.728884 96.723846,11.602665 
   C88.428383,19.954924 80.392784,27.934076 72.521378,36.072048 
   C71.078987,37.563290 70.058167,39.600193 69.262657,41.556015 
   C65.206726,51.527771 63.528091,61.769539 65.881523,72.491707 
   C66.202637,73.954689 65.990273,76.101013 65.106064,77.173149 
   C57.723431,86.124878 60.686298,94.647820 65.727829,103.241280 
   C67.302155,105.924782 68.808807,108.689095 69.963142,111.569992 
   C73.137749,119.492897 75.999306,127.541519 79.192146,135.456726 
   C82.350372,143.286133 76.935432,153.386383 68.877197,155.078506 
   C57.180470,157.534637 45.482841,160.077042 33.951038,163.194626 
   C26.823339,165.121567 22.997086,171.051575 23.003176,178.004669 
   C23.007545,182.994247 26.581253,186.398605 33.889297,189.091599 
   C53.402248,196.282043 73.872414,198.814850 94.327156,199.669861 
   C110.250694,200.335449 126.296341,198.021332 142.291183,197.090866 
   C145.329926,196.914078 148.384781,196.998566 151.432281,196.991455 
   C162.292923,196.966110 173.153580,196.953262 184.772324,196.652969 
z"
      />
      <path
        fill="#929497"
        opacity="1.000000"
        stroke="none"
        d="
M184.393280,196.794327 
   C173.153580,196.953262 162.292923,196.966110 151.432281,196.991455 
   C148.384781,196.998566 145.329926,196.914078 142.291183,197.090866 
   C126.296341,198.021332 110.250694,200.335449 94.327156,199.669861 
   C73.872414,198.814850 53.402248,196.282043 33.889297,189.091599 
   C26.581253,186.398605 23.007545,182.994247 23.003176,178.004669 
   C22.997086,171.051575 26.823339,165.121567 33.951038,163.194626 
   C45.482841,160.077042 57.180470,157.534637 68.877197,155.078506 
   C76.935432,153.386383 82.350372,143.286133 79.192146,135.456726 
   C75.999306,127.541519 73.137749,119.492897 69.963142,111.569992 
   C68.808807,108.689095 67.302155,105.924782 65.727829,103.241280 
   C60.686298,94.647820 57.723431,86.124878 65.106064,77.173149 
   C65.990273,76.101013 66.202637,73.954689 65.881523,72.491707 
   C63.528091,61.769539 65.206726,51.527771 69.262657,41.556015 
   C70.058167,39.600193 71.078987,37.563290 72.521378,36.072048 
   C80.392784,27.934076 88.428383,19.954924 96.723846,11.602665 
   C108.426933,17.728884 120.191154,23.335379 131.381210,29.917931 
   C140.464371,35.261108 145.451126,43.589676 145.033432,54.620018 
   C144.838058,59.779198 144.860641,64.957428 145.064667,70.115944 
   C145.153778,72.368858 145.301880,75.060318 146.527451,76.753159 
   C152.718719,85.304924 150.117508,93.613876 146.490845,102.028267 
   C142.459412,111.381790 138.136963,120.627716 134.571823,130.156418 
   C132.937424,134.524780 132.393692,139.431778 132.106689,144.140305 
   C131.755722,149.897842 134.275894,152.766418 140.074799,154.065063 
   C144.508118,155.057877 149.055542,157.131516 153.581192,154.075089 
   C150.054886,147.095703 151.538712,142.599869 157.465317,141.563828 
   C158.840485,134.198730 160.879166,132.905823 168.268982,134.617310 
   C171.068939,135.265778 174.910583,134.917221 176.417206,136.645126 
   C177.866272,138.306992 176.453842,142.182373 177.142853,144.868622 
   C177.816406,147.494644 179.098694,150.267929 180.913513,152.240952 
   C186.372620,158.175888 193.104431,163.166519 194.411072,171.897446 
   C195.978256,182.369431 195.895676,191.879532 184.393280,196.794327 
z"
      />
    </svg>
  );

  return (
    <SvgContainer
      className={className}
      active={active}
      SvgPassive={passiveSvg}
      SvgActive={activeSvg}
      onClick={onClick}
    />
  );
};

export default BeneficiaryMale;
