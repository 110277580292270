import {
  useArchiveInvestmentAccountMutation,
  useGetInvestmentAccountHolderQuery,
  useLazyGetInvestmentAccountHolderQuery,
} from "apis/investmentAccountApi";
import { ParticipantDetailsSectionProps } from "../types";
import { useEffect, useState } from "react";
import { ApiResponseModel, Participant } from "types";
import { InvestmentAccountDto } from "types/InvestmentAccount";
import "../styles/participantAccounts.scss";
import { InvestmentAccountSubTypeMapping, InvestmentAccountTypeMapping } from "constants/mappings";
import { useNavigate } from "react-router-dom";
import { notify } from "utils";
import { ParticipantBasicInformation } from "types/Participant";

function ParticipantAccounts({ participant }: ParticipantDetailsSectionProps) {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<InvestmentAccountDto[]>();
  // Query to get participant Investment Accounts
  const [accountQuery, accountsResult] = useLazyGetInvestmentAccountHolderQuery();
  // Query for the Investment Accounts
  useEffect(() => {
    if (participant?.id) {
      accountQuery(participant.id);
    }
  }, [participant]);
  // check the result of the query and store state if all is well
  useEffect(() => {
    if (accountsResult.isSuccess) {
      const result = accountsResult.data;
      if (result?.isSuccess) {
        const accounts: InvestmentAccountDto[] = result.result;
        setAccounts(accounts);
      }
    }
  }, [accountsResult]);

  // Main Component
  return (
    <div className="accounts-container">
      <div className="accounts-container-header">
        <p>Active Account Holder Accounts</p>
        <button
          className="btn btn-primary"
          onClick={() => {
            navigate(`/createInvestmentAccount/${participant.id}`);
          }}
        >
          Add New Investment Account
        </button>
      </div>
      <div className="accounts-container-body">
        {accounts?.map((account) => {
          return <AccountContainer account={account} participant={participant} key={account.id} />;
        })}
      </div>
    </div>
  );
}

const AccountContainer = ({
  account,
  participant,
}: {
  account: InvestmentAccountDto;
  participant: Participant;
}) => {
  const navigate = useNavigate();
  const [archiveAccount] = useArchiveInvestmentAccountMutation();

  /**
   * Event Handleer to Archive an account
   */
  const handleArchiveAccount = async () => {
    if (
      window.confirm(
        "Are you sure you want to archive this account? Once it is archived, it can only be brought back by an admin."
      )
    ) {
      const archiveResult: ApiResponseModel = await archiveAccount(account.id);
      if (archiveResult.data?.isSuccess) {
        notify("Account archived successfully", "success");
      } else {
        console.log(archiveResult);
        notify("Account archived failed", "error");
      }
    }
  };

  return (
    <div className="account-container">
      <div className="account-information">
        <p className="account-attribute">
          <span className="label">SIA Identity</span>: {account.id}
        </p>
        <p className="account-attribute">
          <span className="label">Type</span>: {InvestmentAccountTypeMapping[account.type]}
        </p>
        <p className="account-attribute">
          <span className="label">Subtype</span>: {InvestmentAccountSubTypeMapping[account.subType]}
        </p>
      </div>
      <div className="account-actions">
        <div className="action-btn-container">
          <button
            className="btn btn-primary"
            onClick={() => navigate(`/investmentAccount/${participant.id}/${account.id}`)}
          >
            View More Details
          </button>
          <button className="btn btn-warning" onClick={handleArchiveAccount}>
            Archive Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default ParticipantAccounts;
