import React from "react";
import KYCInput from "./KYCInput";
import { KYCSectionProps } from "../types";

function KYCNetWorthDetails({ formData, setFormData }: KYCSectionProps) {
  const handleNetWorthChange = (val: any, attrName: string) => {
    let netWorthDetails = { ...formData.netWorthDetails };
    netWorthDetails[attrName] = val;
    setFormData({ ...formData, netWorthDetails: netWorthDetails });
  };

  return (
    <div className="kyc-section-container">
      <div className="kyc-section-container-header">
        <h1>Financial Details</h1>
        <p>Please Fill Out All Required Fields</p>
      </div>
      <div className="kyc-section-container-body">
        <p className="kyc-section-divider-header">Net Worth Information</p>

        <KYCInput
          label="Estimated Net Liquid Assets"
          value={formData?.netWorthDetails?.liquidAssets}
          onChange={(e) => handleNetWorthChange(e, "liquidAssets")}
          type="number"
          isDollar={true}
        />
        <KYCInput
          label="Estimated Fixed Assets"
          value={formData?.netWorthDetails?.fixedAssets}
          onChange={(e) => handleNetWorthChange(e, "fixedAssets")}
          type="number"
          isDollar={true}
        />
        <KYCInput
          label="Annual Income"
          value={formData?.netWorthDetails?.annualIncome}
          onChange={(e) => handleNetWorthChange(e, "annualIncome")}
          type="number"
          isDollar={true}
        />
        <div className="regulatory-divider"></div>
        <p className="kyc-section-divider-header">External Investments</p>
        <KYCInput
          label="Equities Value"
          value={formData?.netWorthDetails?.equitiesValue}
          onChange={(e) => handleNetWorthChange(e, "equitiesValue")}
          type="number"
          isDollar={true}
        />
        <KYCInput
          label="Fixed Income Value"
          value={formData?.netWorthDetails?.fixedIncomeValue}
          onChange={(e) => handleNetWorthChange(e, "fixedIncomeValue")}
          type="number"
          isDollar={true}
        />
        <KYCInput
          label="Cash and Equivalents Value"
          value={formData?.netWorthDetails?.cashEquivalentValue}
          onChange={(e) => handleNetWorthChange(e, "cashEquivalentValue")}
          type="number"
          isDollar={true}
        />
        <KYCInput
          label="Commodities Value"
          value={formData?.netWorthDetails?.commoditiesValue}
          onChange={(e) => handleNetWorthChange(e, "commoditiesValue")}
          type="number"
          isDollar={true}
        />
        <KYCInput
          label="Real Estate Value"
          value={formData?.netWorthDetails?.realEstateValue}
          onChange={(e) => handleNetWorthChange(e, "realEstateValue")}
          type="number"
          isDollar={true}
        />
      </div>
    </div>
  );
}

export default KYCNetWorthDetails;
