import { ParticipantDetailsSectionProps } from "features/participant/types";
import { useEffect, useState } from "react";
import DocumentContainer from "../../DocumentContainer";
import RTQSentToParticipantItem from "./RTQSentToParticipantItem";
import RTQCompletedParticipantItem from "./RTQCompletedParticipantItem";
import RTQSignedParticipantItem from "./RTQSignedParticipantItem";
import RTQSentForSignatureParticipantItem from "./RTQSentForSignatureParticipantItem";
import RTQReviewedByAdvisorItem from "./RTQReviewedByAdvisor";
import RTQMarkAndReviewRiskParameters from "./RTQMarkAndReviewRiskParameters";
import { Spinner } from "reactstrap";
import {
  useLazyDownloadUnsignedRTQByParticipantIdQuery,
  useLazyGetRiskToleranceResponsePDFQuery,
} from "apis/riskToleranceApi";
import { ApiResponseModel } from "types";
import { notify } from "utils";

/**
 * This component attempts to track the Risk Tolerance Substeps as follows
 * 1. RTQ Sent to Participant
 * 2. RTQ Completed by Participant
 * 3. RTQ Signed by Participant
 * 4. RTQ Reviewed by Advisor
 * @param param0
 * @returns
 */
const RTQWorkflowItem = ({ participant }: ParticipantDetailsSectionProps) => {
  // Blob Data for the Signed RIsk Tolerance Document
  const [blobData, setBlobData] = useState<Blob | null>(null);
  // Status Text of where we are in the process
  const [statusText, setStatusText] = useState("");
  // Setting the status text based on the participant
  useEffect(() => {
    if (!participant?.accountHolderWorkflow?.rtqSentToParticipant) {
      setStatusText("Advisor Needs to Send Questionaire to Client");
    } else if (!participant?.accountHolderWorkflow?.rtqCompletedByParticipant) {
      setStatusText("Client Needs to Complete Questionaire");
    } else if (!participant?.accountHolderWorkflow?.rtqSentForSignature) {
      setStatusText("Awaiting One Span to Send Questionaire for Signature");
    } else if (!participant?.accountHolderWorkflow?.rtqSignedByParticipant) {
      setStatusText("Client Needs to Sign Questionaire");
    } else if (!participant?.accountHolderWorkflow?.rtqReviewedByAdvisor) {
      setStatusText("Advisor Needs to Review Questionaire and assign Risk Paramters");
    } else {
      setStatusText("All Actions Items Are Completed For This Step. No Further Action Required");
    }
  }, [participant]);

  // State of download if applicable
  const [isDownloading, setIsDownloading] = useState(false);

  // Download Unsigned RTQ Functionality
  const [downloadRTQ] = useLazyDownloadUnsignedRTQByParticipantIdQuery();

  const handleDownloadRTQ = async () => {
    setIsDownloading(true);
    const { data, error }: ApiResponseModel = await downloadRTQ(participant.id);
    if (error) {
      console.log(error);
      notify("An error has occured!", "error");
    }
    setIsDownloading(false);
  };

  return (
    <div className="workflow-item">
      <div className="workflow-item-header">
        <p className="workflow-item-header-text">
          SIA Risk Tolerance Questionaire{" "}
          {participant?.accountHolderWorkflow?.rtqReviewedByAdvisor ? (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="All Action Items Are Completed for this Step"
            >
              <i className="bi bi-check-circle-fill complete"></i>
            </a>
          ) : (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="Action Items Left To Complete"
            >
              <i className="bi bi-exclamation-diamond not-complete"></i>
            </a>
          )}
        </p>
        <div className="action-items-container">
          {participant?.accountHolderWorkflow?.rtqReviewedByAdvisor && (
            <>
              <a
                data-tooltip-id="workflow-tooltip"
                data-tooltip-content="Download Unsigned Individual Account Opening Documents"
                style={{ marginRight: "1rem" }}
                onClick={handleDownloadRTQ}
              >
                <i style={{ fontSize: "1.5rem" }} className="bi bi-download"></i>
              </a>
              {isDownloading && <Spinner />}
            </>
          )}
        </div>
      </div>
      <div className="workflow-item-status-container">{statusText}</div>
      <div className="workflow-items-status-container">
        <RTQSentToParticipantItem participant={participant} />
        <RTQCompletedParticipantItem participant={participant} />
        <RTQSentForSignatureParticipantItem participant={participant} />
        <RTQSignedParticipantItem participant={participant} />
        <RTQReviewedByAdvisorItem participant={participant} setBlobData={setBlobData} />
      </div>
      {blobData && (
        <>
          <div className="divider"></div>
          <DocumentContainer blobData={blobData} setBlobData={setBlobData} />
          <RTQMarkAndReviewRiskParameters participant={participant} setBlobData={setBlobData} />
        </>
      )}
    </div>
  );
};

export default RTQWorkflowItem;
