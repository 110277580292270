import { checkEmptyValue, notify } from "../../../utils";
import validateSIN from "../../../utils/validateSIN";
import { Participant } from "../../../types";

/**
 * Function that validates a client's financial details
 * @param client
 * @returns
 **/

const ValidateFinancialDetails = (client: Participant) => {
  if (
    client.netWorthDetails.liquidAssets < 0 ||
    (!client.netWorthDetails.liquidAssets && client.netWorthDetails.liquidAssets !== 0)
  ) {
    notify("Please Enter a Valid Liquid Asset Value", "error");
    return false;
  }
  if (
    client.netWorthDetails.fixedAssets < 0 ||
    (!client.netWorthDetails.fixedAssets && client.netWorthDetails.fixedAssets !== 0)
  ) {
    notify("Please Enter a Valid Fixed Asset Value", "error");
    return false;
  }
  if (
    client.netWorthDetails.equitiesValue < 0 ||
    (!client.netWorthDetails.equitiesValue && client.netWorthDetails.equitiesValue !== 0)
  ) {
    notify("Please Enter a Valid Equities Value", "error");
    return false;
  }
  if (
    client.netWorthDetails.fixedIncomeValue < 0 ||
    (!client.netWorthDetails.fixedIncomeValue && client.netWorthDetails.fixedIncomeValue !== 0)
  ) {
    notify("Please Enter a Valid Fixed Income Value", "error");
    return false;
  }
  if (
    client.netWorthDetails.realEstateValue < 0 ||
    (!client.netWorthDetails.realEstateValue && client.netWorthDetails.realEstateValue !== 0)
  ) {
    notify("Please Enter a Valid Real Estate Value", "error");
    return false;
  }
  if (
    client.netWorthDetails.commoditiesValue < 0 ||
    (!client.netWorthDetails.commoditiesValue && client.netWorthDetails.commoditiesValue !== 0)
  ) {
    notify("Please Enter a Valid Commodities Value", "error");
    return false;
  }
  if (
    client.netWorthDetails.cashEquivalentValue < 0 ||
    (!client.netWorthDetails.cashEquivalentValue &&
      client.netWorthDetails.cashEquivalentValue !== 0)
  ) {
    notify("Please Enter a Valid Cash Equivalent Value", "error");
    return false;
  }

  return true;
};

export default ValidateFinancialDetails;
