import { useNavigate } from "react-router-dom";
import WorkflowStatus from "../../WorkflowStatus";
import { ParticipantDetailsSectionProps } from "features/participant/types";

/**
 * This component is responsible for displaying the status of the RTQ Completed by Participant Workflow Item
 */
const RTQCompletedParticipantItem = ({ participant }: ParticipantDetailsSectionProps) => {
  const navigate = useNavigate();
  const completedPreviousTask = participant?.accountHolderWorkflow?.rtqSentToParticipant;
  const completedTask = participant?.accountHolderWorkflow?.rtqCompletedByParticipant;
  const completedText = "Questionaire Completed By Client";
  const notCompletedText = "Questionaire Not Completed By Client";
  const completeTooltipText = "Click to View Questionaire Results";
  const notCompleteTooltipText = "Click to View Questionaire Progress";

  const actionHandler = () => {
    navigate(`/riskToleranceForm/${participant.id}/`);
  };
  // If the Previous Task is completed, then we display content with actions
  return (
    <WorkflowStatus
      completedPreviousTask={completedPreviousTask}
      completedTask={completedTask}
      completeTooltipText={completeTooltipText}
      notCompleteTooltipText={notCompleteTooltipText}
      completedText={completedText}
      notCompletedText={notCompletedText}
      completeActionHander={actionHandler}
      notCompleteActionHandler={actionHandler}
      completeIconClass="bi-file-earmark-check-fill"
      notCompleteIconClass="bi-file-text-fill"
    />
  );
};

export default RTQCompletedParticipantItem;
