import { useNavigate } from "react-router-dom";
import WorkflowStatus from "../../WorkflowStatus";
import { ParticipantDetailsSectionProps } from "features/participant/types";

const IPSFormCompleted = ({ participant }: ParticipantDetailsSectionProps) => {
  const navigate = useNavigate();
  const completedPreviousTask = participant?.accountHolderWorkflow?.tradingAccountCompleted;
  const completedTask = participant?.accountHolderWorkflow?.ipsCompleted;
  const completedText = "IPS is Complete";
  const notCompletedText = "Complete IPS";
  const completeTooltipText = "";
  const notCompleteTooltipText = "Click to Complete IPS";

  const actionHandler = () => {
    navigate(`/createIPS/${participant.id}/`);
    //console.log("nada");
  };

  // If the Previous Task is completed, then we display content with actions
  return (
    <WorkflowStatus
      completedPreviousTask={completedPreviousTask}
      completedTask={completedTask}
      completeTooltipText={completeTooltipText}
      notCompleteTooltipText={notCompleteTooltipText}
      completedText={completedText}
      notCompletedText={notCompletedText}
      completeActionHander={actionHandler}
      notCompleteActionHandler={actionHandler}
      completeIconClass="bi-file-earmark-check-fill"
      notCompleteIconClass="bi-file-text-fill"
    />
  );
};

export default IPSFormCompleted;
