import { CountryDropdown } from "react-country-region-selector";
import { SIASelectProps } from "./SIASelect";

function SIACountrySelect({ value, disabled, options, onChange, name, id }: SIASelectProps) {
  return (
    <div className="input-container">
      <CountryDropdown
        priorityOptions={["CA", "US"]}
        value={value ? value.toString() : ""}
        onChange={(e) => onChange?.(e)}
        disabled={disabled}
        name={name}
        id={id ? `${id}-select` : ""}
      />
      <div className="underline"></div>
    </div>
  );
}

export default SIACountrySelect;
