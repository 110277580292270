import { TransferDetail } from "features/createInvestmentAccount/types/CreateInvestmentAccountDto";
import { checkEmptyValue, notify } from "utils";

/**
 * @name            ValidateTransferDetail
 * @summary         Validates the transfer detail object
 * @param           {
 *                     transferDetail: The transfer detail object
 *                 }
 * @returns         boolean
 */
const ValidateTransferDetail = (transferDetail: TransferDetail) => {
  if (checkEmptyValue(transferDetail.deliveringInstitution)) {
    notify("Please enter a delivering institution", "error");
    return false;
  }
  if (checkEmptyValue(transferDetail.institutionCountry)) {
    notify("Please enter a country", "error");
    return false;
  }
  if (checkEmptyValue(transferDetail.institutionAddress)) {
    notify("Please enter an address", "error");
    return false;
  }
  // if (checkEmptyValue(transferDetail.institutionDealerNumber)) {
  //   notify("Please enter a valid dealer number", "error");
  //   return false;
  // }

  // Check Transfer Instructions
  if (transferDetail.instructions.length === 0) {
    notify("Please enter at least one transfer instruction", "error");
    return false;
  }

  // Check Transfer Instructions
  for (let i = 0; i < transferDetail.instructions.length; i++) {
    const instruction = transferDetail.instructions[i];
    if (checkEmptyValue(instruction.currency)) {
      notify("Please enter a currency for all transfer instructions", "error");
      return false;
    }
    if (checkEmptyValue(instruction.institutionAccountNumber)) {
      notify("Please enter an account number for all transfer instructions", "error");
      return false;
    }
    if (checkEmptyValue(instruction.transferType)) {
      notify("Please enter a transfer type for all transfer instructions", "error");
      return false;
    }
  }

  return true;
};

export default ValidateTransferDetail;
