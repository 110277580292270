import React, { useEffect } from "react";
import { AccountSectionProps } from "../types";
import {
  CurrencyOptions,
  DefaultInvestMentAccountSubTypes,
  DefaultInvestMentAccountTypes,
  IntendedUseOfAccounts,
} from "assets/StaticData";
import { BeneficiaryTypeRequiredAccounts } from "assets/StaticData/InvestmentAccount";
import {
  JointTypeRequiredAccounts,
  SuccessorAnnuitantTypeRequiredAccounts,
} from "assets/StaticData/InvestmentAccount/AccountTypeRequiredData";
import { SourceOfFundsOptions } from "assets/StaticData/EditClientOptions";
import AccountBoolean from "./AccountBoolean";
import AccountSelect from "./AccountSelect";

/**
 * @name          AccountGeneralDetails
 * @summary       A helper component that is used on the Create Trading Account Page to display and edit the general details.
 *                Some of the options here will result in other components being displayed on the page.
 * @param         {
 *                  formData: the new investment account form data,
 *                  setFormData: the react hook form set form data function,
 *                  participant: the participant state information
 *                }
 * @returns       React.Component
 * @param param0
 * @returns
 */
function AccountGeneralDetails({
  formData,
  setFormData,
  participant,
  viewMode,
}: AccountSectionProps) {
  const handleDetailChange = (value: any, attrName: string) => {
    setFormData({ ...formData, [attrName]: value });
  };

  /**
   * Handles the Beneficiary Designation Change, if applicable
   */
  const handleDesignationChange = (e: any, designation: string) => {
    const tempAccount = { ...formData };
    if (designation == "successor") {
      tempAccount.allowBeneficiarys = false;
      tempAccount.allowSuccessorAnnuitants = true;
      tempAccount.allowEstate = false;

      // We need to reset the beneficiarys
      tempAccount.beneficiarys = [];

      // If the participant has a spouse in the system, we need to add them as a successor
      if (
        participant &&
        participant.spouseDetails &&
        participant.spouseDetails?.participantId &&
        participant.spouseDetails?.isClient
      ) {
        tempAccount.successorAnnuitants = [participant.spouseDetails?.participantId];
      }
    } else if (designation == "beneficiary") {
      // Beneficiaries will be added
      tempAccount.allowBeneficiarys = true;
      tempAccount.allowSuccessorAnnuitants = false;
      tempAccount.allowEstate = false;

      // Reset the successor annuitants
      tempAccount.successorAnnuitants = [];
    } else {
      tempAccount.allowBeneficiarys = false;
      tempAccount.allowSuccessorAnnuitants = false;
      tempAccount.allowEstate = true;

      // Reset both the beneficiarys and successor annuitants
      tempAccount.successorAnnuitants = [];
      tempAccount.beneficiarys = [];
    }

    setFormData(tempAccount);
  };

  useEffect(() => {
    if (formData.allowPAC || formData.allowSWP) {
      const temp = { ...formData };
      temp.allowEFT = true;
      setFormData(temp);
    }
  }, [formData.allowPAC, formData.allowSWP, formData.allowEFT]);

  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>General Details</h1>
        <h3>Please Fill Out All Required Fields</h3>
      </div>
      <div className="account-section-details-body">
        <AccountSelect
          label="Account Type"
          value={formData.type}
          options={DefaultInvestMentAccountTypes}
          onChange={(e) => handleDetailChange(e, "type")}
          disabled={viewMode}
        />
        <AccountSelect
          label="Account Subtype"
          value={formData.subType}
          options={DefaultInvestMentAccountSubTypes[formData.type]}
          onChange={(e) => handleDetailChange(e, "subType")}
          disabled={viewMode}
        />
        {BeneficiaryTypeRequiredAccounts.includes(Number(formData.type)) && (
          <div className="account-beneficiary-designation-container">
            <div className="label-container">
              <label>Beneficiary Designation</label>
            </div>
            <div className="designations-container">
              {SuccessorAnnuitantTypeRequiredAccounts.includes(Number(formData.type)) && (
                <div>
                  <input
                    type="radio"
                    name="account-designation"
                    onClick={(e) => handleDesignationChange(e, "successor")}
                    checked={formData.allowSuccessorAnnuitants}
                    disabled={viewMode}
                  />
                  <label>Successor Annuitant</label>
                </div>
              )}

              <div>
                <input
                  type="radio"
                  name="account-designation"
                  onClick={(e) => handleDesignationChange(e, "beneficiary")}
                  checked={formData.allowBeneficiarys!}
                  disabled={viewMode}
                />
                <label>Beneficiary</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="account-designation"
                  onClick={(e) => handleDesignationChange(e, "estate")}
                  checked={formData.allowEstate!}
                  disabled={viewMode}
                />
                <label>Estate</label>
              </div>
            </div>
          </div>
        )}
        <AccountSelect
          label="Currency"
          value={formData.currency}
          options={CurrencyOptions}
          onChange={(e) => handleDetailChange(e, "currency")}
          disabled={viewMode}
        />
        <AccountSelect
          label="Intended Use Of Account"
          value={formData.intendedUseOfAccount}
          options={IntendedUseOfAccounts}
          onChange={(e) => handleDetailChange(e, "intendedUseOfAccount")}
          disabled={viewMode}
        />
        <AccountSelect
          label="Source Of Funds"
          value={formData.sourceOfFunds}
          options={SourceOfFundsOptions}
          onChange={(e) => handleDetailChange(e, "sourceOfFunds")}
          disabled={viewMode}
        />
        <AccountBoolean
          label="Borrowing to Invest?"
          value={formData.isBorrowingToInvest}
          onChange={(e) => handleDetailChange(e, "isBorrowingToInvest")}
          disabled={viewMode}
        />
        <AccountBoolean
          label="Allow EFT?"
          value={formData.allowEFT}
          onChange={(e) => handleDetailChange(e, "allowEFT")}
          hasTooltip={true}
          tooltipText="Electronic Funds Transfer. If Allow PAC or Allow SWP is selected, this will be automatically selected."
          disabled={viewMode}
        />
        {JointTypeRequiredAccounts.includes(Number(formData.type)) && (
          <AccountBoolean
            label="Is Joint Account?"
            value={formData.isJointAccount}
            onChange={(e) => handleDetailChange(e, "isJointAccount")}
            disabled={viewMode}
          />
        )}
        <AccountBoolean
          label="Allow Transfer?"
          value={formData.allowTransfers}
          onChange={(e) => handleDetailChange(e, "allowTransfers")}
          disabled={viewMode}
        />
        <AccountBoolean
          label="Allow PACs?"
          value={formData.allowPAC}
          onChange={(e) => handleDetailChange(e, "allowPAC")}
          disabled={viewMode}
        />
        <AccountBoolean
          label="Allow SWPs?"
          value={formData.allowSWP}
          onChange={(e) => handleDetailChange(e, "allowSWP")}
          disabled={viewMode}
        />
      </div>
    </div>
  );
}

export default AccountGeneralDetails;
