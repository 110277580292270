import React from "react";
import { KYCSectionProps } from "../types";
import KYCBoolean from "./KYCBoolean";
import KYCSelect from "./KYCSelect";
import KYCInput from "./KYCInput";
import { AddressTypes } from "assets/StaticData";
import {
  ParticipantCountrySelectProps,
  ParticipantRegionSelectProps,
} from "features/participant/types";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import KYCRegionSelect from "./KYCRegionSelect";
import KYCCountrySelect from "./KYCCountrySelect";
import { checkEmptyValue } from "utils";
import { AddressDetails } from "types/Participant";

function KYCAddressDetails({ formData, setFormData }: KYCSectionProps) {
  const handlePermanentAddressInputChange = (val: number | string | boolean, attrName: string) => {
    let updatedAddressDetails = { ...formData.permanentAddressDetails };
    updatedAddressDetails[attrName] = val;
    setFormData({ ...formData, permanentAddressDetails: updatedAddressDetails });
  };

  const handleMailingAddressInputChange = (val: number | string | boolean, attrName: string) => {
    const numericFields = ["aptUnitSuiteNumber", "streetNumber"];
    let updatedAddressDetails = { ...formData.mailingAddressDetails };
    if (val.toString().trim().length == 0 && numericFields.includes(attrName)) {
      updatedAddressDetails[attrName] = "0"; // temp fix to help with null values for int fields
      setFormData({ ...formData, mailingAddressDetails: updatedAddressDetails });
      return;
    }

    updatedAddressDetails[attrName] = val;
    setFormData({ ...formData, mailingAddressDetails: updatedAddressDetails });
  };

  const handleThirdPartyAddressInputChange = (val: number | string | boolean, attrName: string) => {
    let updatedAddressDetails = { ...formData.thirdPartyAddressDetails };
    updatedAddressDetails[attrName] = val;
    setFormData({ ...formData, thirdPartyAddressDetails: updatedAddressDetails });
  };

  return (
    <div className="kyc-section-container">
      <div className="kyc-section-container-header">
        <h1>Address Details</h1>
        <p>Please Fill Out All Required Fields</p>
      </div>
      <div className="kyc-section-container-body">
        {/* Permanent Address Sections */}
        <p className="kyc-section-divider-header">Permanent Address Details</p>
        <KYCSelect
          label="Address Type"
          value={formData?.permanentAddressDetails?.addressType}
          options={AddressTypes}
          onChange={(e) => handlePermanentAddressInputChange(e, "addressType")}
        />
        <KYCInput
          label="Apt/ Unit/ Suite #"
          value={formData?.permanentAddressDetails?.aptUnitSuiteNumber}
          onChange={(e) => handlePermanentAddressInputChange(e, "aptUnitSuiteNumber")}
          type="number"
        />
        <KYCInput
          label="Street #"
          value={formData?.permanentAddressDetails?.streetNumber}
          onChange={(e) => handlePermanentAddressInputChange(e, "streetNumber")}
          type="number"
        />
        <KYCInput
          label="Street Name"
          value={formData?.permanentAddressDetails?.streetName}
          onChange={(e) => handlePermanentAddressInputChange(e, "streetName")}
        />
        <KYCCountrySelect
          label="Country"
          value={formData?.permanentAddressDetails?.country}
          onChange={(e) => handlePermanentAddressInputChange(e, "country")}
        />
        <KYCRegionSelect
          label="Region"
          value={formData?.permanentAddressDetails?.region}
          onChange={(e) => handlePermanentAddressInputChange(e, "region")}
          country={formData?.permanentAddressDetails?.country}
        />
        <KYCInput
          label="City"
          value={formData?.permanentAddressDetails?.city}
          onChange={(e) => handlePermanentAddressInputChange(e, "city")}
        />
        {["Canada", "United States"].includes(formData?.permanentAddressDetails?.country) && (
          <KYCInput
            label={
              formData?.permanentAddressDetails?.country == "Canada" ? "Postal Code" : "Zip Code"
            }
            value={formData?.permanentAddressDetails?.postalCode}
            onChange={(e) => handlePermanentAddressInputChange(e, "postalCode")}
          />
        )}
        <KYCBoolean
          label="Is the Mailing Address the same as the Permanent Address?"
          value={formData?.permanentAddressDetails?.isSameAsMailingAddress}
          onChange={(e) => handlePermanentAddressInputChange(e, "isSameAsMailingAddress")}
        />
        <KYCBoolean
          label="Has Third Party Mailing Address?"
          value={formData?.permanentAddressDetails?.hasThirdPartyAddress}
          onChange={(e) => handlePermanentAddressInputChange(e, "hasThirdPartyAddress")}
        />
        {/* Mailing Address Sections */}
        {!formData?.permanentAddressDetails?.isSameAsMailingAddress && (
          <>
            <p className="kyc-section-divider-header">Mailing Address Details</p>
            <KYCSelect
              label="Address Type"
              value={formData?.mailingAddressDetails?.addressType}
              options={AddressTypes}
              onChange={(e) => handleMailingAddressInputChange(e, "addressType")}
            />
            <KYCInput
              label="Apt/ Unit/ Suite #"
              value={formData?.mailingAddressDetails?.aptUnitSuiteNumber}
              onChange={(e) => handleMailingAddressInputChange(e, "aptUnitSuiteNumber")}
              type="number"
            />
            <KYCInput
              label="Street #"
              value={formData?.mailingAddressDetails?.streetNumber}
              onChange={(e) => handleMailingAddressInputChange(e, "streetNumber")}
              type="number"
            />
            <KYCInput
              label="Street Name"
              value={formData?.mailingAddressDetails?.streetName}
              onChange={(e) => handleMailingAddressInputChange(e, "streetName")}
            />
            <KYCCountrySelect
              label="Country"
              value={formData?.mailingAddressDetails?.country}
              onChange={(e) => handleMailingAddressInputChange(e, "country")}
            />
            <KYCRegionSelect
              label="Region"
              value={formData?.mailingAddressDetails?.region}
              onChange={(e) => handleMailingAddressInputChange(e, "region")}
              country={formData?.mailingAddressDetails?.country}
            />
            <KYCInput
              label="City"
              value={formData?.mailingAddressDetails?.city}
              onChange={(e) => handleMailingAddressInputChange(e, "city")}
            />
            {["Canada", "United States"].includes(formData?.mailingAddressDetails?.country) && (
              <KYCInput
                label={
                  formData?.mailingAddressDetails?.country == "Canada" ? "Postal Code" : "Zip Code"
                }
                value={formData?.mailingAddressDetails?.postalCode}
                onChange={(e) => handleMailingAddressInputChange(e, "postalCode")}
              />
            )}
          </>
        )}
        {/* Third Parth Address Sections */}
        {formData?.permanentAddressDetails?.hasThirdPartyAddress && (
          <>
            <p className="kyc-section-divider-header">Third Party Address Details</p>
            <KYCSelect
              label="Address Type"
              value={formData?.thirdPartyAddressDetails?.addressType}
              options={AddressTypes}
              onChange={(e) => handleThirdPartyAddressInputChange(e, "addressType")}
            />
            <KYCInput
              label="Apt/ Unit/ Suite #"
              value={formData?.thirdPartyAddressDetails?.aptUnitSuiteNumber}
              onChange={(e) => handleThirdPartyAddressInputChange(e, "aptUnitSuiteNumber")}
              type="number"
            />
            <KYCInput
              label="Street #"
              value={formData?.thirdPartyAddressDetails?.streetNumber}
              onChange={(e) => handleThirdPartyAddressInputChange(e, "streetNumber")}
              type="number"
            />
            <KYCInput
              label="Street Name"
              value={formData?.thirdPartyAddressDetails?.streetName}
              onChange={(e) => handleThirdPartyAddressInputChange(e, "streetName")}
            />
            <KYCCountrySelect
              label="Country"
              value={formData?.thirdPartyAddressDetails?.country}
              onChange={(e) => handleThirdPartyAddressInputChange(e, "country")}
            />
            <KYCRegionSelect
              label="Region"
              value={formData?.thirdPartyAddressDetails?.region}
              onChange={(e) => handleThirdPartyAddressInputChange(e, "region")}
              country={formData?.thirdPartyAddressDetails?.country}
            />
            <KYCInput
              label="City"
              value={formData?.thirdPartyAddressDetails?.city}
              onChange={(e) => handleThirdPartyAddressInputChange(e, "city")}
            />
            {["Canada", "United States"].includes(formData?.thirdPartyAddressDetails?.country) && (
              <KYCInput
                label={
                  formData?.thirdPartyAddressDetails?.country == "Canada"
                    ? "Postal Code"
                    : "Zip Code"
                }
                value={formData?.thirdPartyAddressDetails?.postalCode}
                onChange={(e) => handleThirdPartyAddressInputChange(e, "postalCode")}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default KYCAddressDetails;
