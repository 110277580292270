import { useLazyGetParticipantsByHouseholdQuery } from "apis/mainApi";
import { MiniLoader } from "components/Shared";
import Modal from "components/Shared/Modal";
import withAuth from "HOC/withAuth";
import { ReturnIconSvg } from "assets/svg";
import { ParticipantRoleMapping } from "constants/mappings/ParticipantRoleMapping";
import CreateNewClientForm from "features/householdParticipants/components/createNewClientForm";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Participant } from "types";

const HouseholdParticipantsPage = () => {
  const [participantHouseholdQuery, participantHouseholdsResult] =
    useLazyGetParticipantsByHouseholdQuery();

  // What will be in the view
  const [viewParticipants, setViewParticipants] = useState<Participant[]>([]);
  // What is coming in the database
  const [dbHouseholdParticipants, setDBHouseholdParticipants] = useState<Participant[]>([]);
  // Loading State
  const [loading, setLoading] = useState(false);
  // No results state
  const [noResults, setNoResults] = useState(false);
  // State for Create Client Modal
  const [showCreateParticipantModal, setShowCreateParticipantModal] = useState(false);
  // State for the backdrop
  const [showModalBackdrop, setShowModalBackdrop] = useState(false);
  // Search Text State
  const [searchText, setSearchText] = useState("");
  // Navigation State
  const navigate = useNavigate();
  // Get the householdId from the parameters
  const { householdId } = useParams();

  // Query the database based on the householdId coming from the paramters
  useEffect(() => {
    if (householdId) {
      setLoading(true);
      participantHouseholdQuery(householdId);
    }
  }, [householdId]);

  // Set the viewHouseholds and the database participants households
  useEffect(() => {
    if (participantHouseholdsResult.data && participantHouseholdsResult.isSuccess) {
      setDBHouseholdParticipants(participantHouseholdsResult.data.result);
      setViewParticipants(participantHouseholdsResult.data.result);
      if (participantHouseholdsResult.data.result.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
      setLoading(false);
    }
  }, [participantHouseholdsResult.data]);

  // Set up the effect for the search text
  useEffect(() => {
    if (searchText.length > 0) {
      const filteredParticipants = dbHouseholdParticipants.filter((participant) => {
        const fullName = `${participant.generalDetails.firstName} ${participant.generalDetails.lastName}`;
        return fullName.toLowerCase().includes(searchText.toLowerCase());
      });
      setViewParticipants(filteredParticipants);
    } else {
      setViewParticipants(dbHouseholdParticipants);
    }
  }, [searchText]);

  /// Handle the Create Client Modal
  const handleCreateNewParticipantModalOpen = () => {
    setShowModalBackdrop(true);
    setShowCreateParticipantModal(true);
  };

  return (
    <div className="main-page-list-container">
      {showModalBackdrop && <div className="backdrop"></div>}
      <div className="main-page-list-header">
        <div className="main-page-list-information">
          <div className="main-page-list-information-back-btn-container">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <ReturnIconSvg />
            </button>
          </div>

          <p className="main-page-list-header-text">Household Participants</p>
          <p className="main-page-list-subheader-text">View Household Participants</p>
          <button className="add-new-btn" onClick={handleCreateNewParticipantModalOpen}>
            Add New
          </button>
        </div>
        <div className="main-page-list-search-container">
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Participants..."
          ></input>
        </div>
      </div>
      <div className="hr"></div>
      <div className="main-list-container">
        {viewParticipants.length > 0 &&
          viewParticipants.map((participant, index) => {
            return <HouseholdParticipantItem participant={participant} key={index} />;
          })}
        {loading && (
          <div className="loading-container">
            <MiniLoader />{" "}
          </div>
        )}
        {noResults && <p>No Participants Found For Household</p>}
      </div>
      <Modal
        children={
          <CreateNewClientForm
            householdId={householdId == undefined ? "" : householdId}
            closeModal={() => {
              setShowCreateParticipantModal(false);
              setShowModalBackdrop(false);
            }}
          />
        }
        show={showCreateParticipantModal}
        modelTitle="Add New Household Participant"
        setModalBackdrop={setShowModalBackdrop}
        setModelDisplay={setShowCreateParticipantModal}
      ></Modal>
    </div>
  );
};

interface HouseholdParticipantItemProps {
  participant: Participant;
}

const HouseholdParticipantItem = ({ participant }: HouseholdParticipantItemProps) => {
  const navigate = useNavigate();

  const fullName = `${participant.generalDetails.firstName} ${participant.generalDetails.lastName}`;
  return (
    <div className="main-list-row-item" onClick={() => navigate(`/participant/${participant.id}`)}>
      <div className="main-list-row-information">
        <p className="primary-info">{fullName}</p>
      </div>
      <div className="main-list-row-actions">
        <div className="roles-container">
          {participant.roles.map((role, index) => {
            return <p>{ParticipantRoleMapping[role.toLowerCase()]}</p>;
          })}
        </div>
      </div>
    </div>
  );
};

// Todo: Move to this to the assets folder

export default withAuth(HouseholdParticipantsPage);
