import React, { useEffect, useState } from "react";
import KYCBoolean from "./KYCBoolean";
import { KYCSectionProps } from "../types";
import { useLazyGetParticipantsByHouseholdQuery } from "apis/mainApi";
import { SelectItem } from "types/Shared";
import { Participant } from "types";
import KYCSelect from "./KYCSelect";
import KYCInput from "./KYCInput";
import { GuidValues } from "constants/enums";

function KYCSpouseDetails({ formData, setFormData }: KYCSectionProps) {
  // State for tracking is spouse is an existing participant
  const [isSpouseExistingParticipant, setIsSpouseExistingParticipant] = useState<boolean>();

  // Query to retireve all participants in a household if the spouse is a participant
  const [participantsHouseholdQuery, participantsHouseholdResult] =
    useLazyGetParticipantsByHouseholdQuery();

  // Select Items for the participants in the household if the spouse is a participant
  const [participantSelectItems, setParticipantSelectItems] = useState<SelectItem[]>([]);

  // Query to retireve all participants in a household if the spouse is a participant
  useEffect(() => {
    if (formData?.householdId && formData?.spouseDetails?.isClient) {
      participantsHouseholdQuery(formData.householdId);
    }
    if (formData?.spouseDetails?.participantId) {
      setIsSpouseExistingParticipant(true);
    }
  }, [formData?.householdId, formData?.spouseDetails?.isClient]);

  // Set the select items for the participants in the household
  useEffect(() => {
    if (participantsHouseholdResult.isSuccess && participantsHouseholdResult.data) {
      const participants = participantsHouseholdResult.data.result;
      if (participants.length > 0) {
        CreateParticipantSelectItems(participants, setParticipantSelectItems);
      }
    }
  }, [participantsHouseholdResult]);

  const handleInputChange = (val: number | string | boolean, attrName: string) => {
    let updatedSpouseDetails = { ...formData.spouseDetails };
    updatedSpouseDetails[attrName] = val;
    setFormData({ ...formData, spouseDetails: updatedSpouseDetails });
  };

  // Handle the change of the spouse being an existing participant
  // Need to set tjhe participant ID to empty if the spouse is not an existing participant
  const handleExistingSpouseChange = (val: boolean) => {
    setIsSpouseExistingParticipant(val);
    let updatedSpouseDetails = { ...formData.spouseDetails };
    if (val === false) {
      updatedSpouseDetails.participantId = GuidValues.EMPTY_GUID;
    }

    setFormData({ ...formData, spouseDetails: updatedSpouseDetails });
  };

  return (
    <div className="kyc-section-container">
      <div className="kyc-section-container-header">
        <h1>Spouse Details</h1>
        <p>Please Fill Out All Required Fields</p>
      </div>
      <div className="kyc-section-container-body">
        <KYCBoolean
          label="Is Spouse an SIA Participant?"
          value={formData?.spouseDetails?.isClient}
          onChange={(e) => handleInputChange(e, "isClient")}
          tooltipText="If the spouse is or will be an SIA participant, please select 'Yes'. If the spouse is not an SIA participant, please select 'No'."
          hasTooltip={true}
        />
        {formData?.spouseDetails?.isClient && (
          <KYCBoolean
            label="Is Spouse an Existing Participant?"
            value={isSpouseExistingParticipant}
            onChange={(e) => handleExistingSpouseChange(e)}
          />
        )}
        {isSpouseExistingParticipant && (
          <KYCSelect
            label="Select Participant"
            value={formData?.spouseDetails?.participantId}
            options={participantSelectItems}
            onChange={(e) => handleInputChange(e, "participantId")}
          />
        )}
        {(isSpouseExistingParticipant === false || formData?.spouseDetails?.isClient === false) && (
          <>
            <KYCInput
              label="Salutation"
              value={formData?.spouseDetails?.salutation}
              onChange={(e) => handleInputChange(e, "salutation")}
            />
            <KYCInput
              label="First Name"
              value={formData?.spouseDetails?.firstName}
              onChange={(e) => handleInputChange(e, "firstName")}
            />
            <KYCInput
              label="Middle Initial"
              value={formData?.spouseDetails?.middleInitial}
              onChange={(e) => handleInputChange(e, "middleInitial")}
            />
            <KYCInput
              label="Last Name"
              value={formData?.spouseDetails?.lastName}
              onChange={(e) => handleInputChange(e, "lastName")}
            />
            <KYCInput
              label="Social Insurance Number"
              value={formData?.spouseDetails?.sin}
              onChange={(e) => handleInputChange(e, "sin")}
            />
            <KYCInput
              label="Gender"
              value={formData?.spouseDetails?.gender}
              onChange={(e) => handleInputChange(e, "gender")}
            />
            <KYCInput
              label="Employer Name"
              value={formData?.spouseDetails?.employerName}
              onChange={(e) => handleInputChange(e, "employerName")}
            />
            <KYCInput
              label="Occupation"
              value={formData?.spouseDetails?.occupation}
              onChange={(e) => handleInputChange(e, "occupation")}
            />
            <KYCInput
              label="Type of Business"
              value={formData?.spouseDetails?.typeOfBusiness}
              onChange={(e) => handleInputChange(e, "typeOfBusiness")}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default KYCSpouseDetails;

/**
 * Creates the select items for the participants in the household
 * @param participants
 * @param setParticipantSelectItems
 */
function CreateParticipantSelectItems(
  participants: any,
  setParticipantSelectItems: React.Dispatch<React.SetStateAction<SelectItem[]>>
) {
  const selectItems: SelectItem[] = [];
  participants.forEach((participant: Participant) => {
    selectItems.push({
      label: `${participant.generalDetails.firstName} ${participant.generalDetails.lastName}`,
      value: participant.id,
    });
  });
  setParticipantSelectItems(selectItems);
}
