import React from "react";
import "./styles/Modal.scss";
interface ModalProps {
  id?: string;
  show: boolean;
  children: React.ReactNode;
  modelTitle: string;
  setModalBackdrop: React.Dispatch<React.SetStateAction<boolean>>;
  setModelDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

function Modal(props: ModalProps) {
  const cancelButtonHandler = () => {
    props.setModalBackdrop(false);
    props.setModelDisplay(false);
  };

  return (
    <div
      style={{
        transform: props.show ? "translateY(0%)" : "translateY(-300%)",
      }}
      className="modal-container"
    >
      <div className="modal-header" id={props.id}>
        <p className="modal-header-text">{props.modelTitle}</p>
        <div className="modal-header-cancel">
          <button
            className="cancel-button"
            onClick={() => cancelButtonHandler()}
            style={{ background: "transparent", border: "none" }}
          >
            <i className="bi bi-x-circle-fill cancel-icon"></i>
          </button>
        </div>
      </div>
      {props.children}
      {/* <div className="modal-footer">Footer</div> */}
    </div>
  );
}

export default Modal;
