import { Participant } from "types";
import {
  checkEmptyValue,
  isValidUSZip,
  notify,
  validateEmail,
  validatePhone,
  validatePostalCode,
} from "utils";

const ValidateTrustedContactDetails = (participant: Participant) => {
  const fieldsToValidate = [
    {
      field: participant.trustedContactDetails.firstName,
      errorMessage: "Please Enter a First Name",
    },
    {
      field: participant.trustedContactDetails.lastName,
      errorMessage: "Please Enter a Last Name",
    },
    {
      field: participant.trustedContactDetails.email,
      errorMessage: "Please Enter a Valid Email",
      validator: validateEmail,
    },
    {
      field: participant.trustedContactDetails.phoneNumber,
      errorMessage: "Please Enter a Valid Phone Number",
      validator: validatePhone,
    },
    {
      field: participant.trustedContactDetails.relationshipType,
      errorMessage: "Please Enter a Relationship",
    },
    {
      field: participant.trustedContactDetails.mailingAddressType,
      errorMessage: "Please Enter an Address Type",
    },
    {
      field: participant.trustedContactDetails.mailingAddressAptUnitSuiteNumber,
      errorMessage: "Please Enter an Address",
    },
    {
      field: participant.trustedContactDetails.mailingAddressStreetNumber,
      errorMessage: "Please Enter a Valid Street Number",
    },
    {
      field: participant.trustedContactDetails.mailingAddressStreetName,
      errorMessage: "Please Enter a Valid Street Name",
    },
    {
      field: participant.trustedContactDetails.mailingAddressStreetType,
      errorMessage: "Please Enter a Valid Street Type",
    },
    {
      field: participant.trustedContactDetails.mailingAddressStreetDirection,
      errorMessage: "Please Enter a Valid Street Direction",
    },
    {
      field: participant.trustedContactDetails.mailingAddressCity,
      errorMessage: "Please Enter a Valid City",
    },
    {
      field: participant.trustedContactDetails.mailingAddressRegion,
      errorMessage: "Please Enter a Valid Region",
    },
    {
      field: participant.trustedContactDetails.mailingAddressCountry,
      errorMessage: "Please Enter a Valid Country",
    },
  ];

  // Perform the validation
  for (const fieldData of fieldsToValidate) {
    if (checkEmptyValue(fieldData.field)) {
      notify(fieldData.errorMessage, "error");
      return false;
    }

    if (fieldData.validator && !fieldData.validator(fieldData.field)) {
      notify(fieldData.errorMessage, "error");
      return false;
    }
  }

  // Special Validation for Postal Code and Zip Code
  if (
    participant.trustedContactDetails.mailingAddressCountry === "Canada" &&
    (checkEmptyValue(participant.trustedContactDetails.mailingAddressPostalCode) ||
      !validatePostalCode(participant.trustedContactDetails.mailingAddressPostalCode))
  ) {
    notify("Please Enter a Valid Postal Code", "error");
    return false;
  }

  if (
    participant.trustedContactDetails.mailingAddressCountry === "United States" &&
    (checkEmptyValue(participant.trustedContactDetails.mailingAddressPostalCode) ||
      !isValidUSZip(participant.trustedContactDetails.mailingAddressPostalCode))
  ) {
    notify("Please Enter a Valid Zip Code", "error");
    return false;
  }

  return true;
};

export default ValidateTrustedContactDetails;
