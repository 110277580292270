import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/createBankAccount.scss";
import { useCreateBankAccountMutation, useLazyGetClientByIdQuery } from "apis/mainApi";
import { ApiResponseModel, Participant } from "types";
import { useUploadParticipantAMLDocumentMutation } from "apis/documentApi";
import { BankAccount } from "types/Participant/Participant";
import { emptyBankAccount } from "constants/emptyStates";
import { SIACountrySelect, SIAInput, SIARegionSelect, SIASelect } from "components/Shared";
import { inputHelper, notify } from "utils";
import {
  DefaultBankInstitutionNumbers,
  DefaultBankInstitutions,
  DefaultInstitutionMapping,
  ReverseInsitutionMapping,
} from "assets/StaticData/BankAccount";
import KYCCountrySelect from "features/knowYourClient/components/KYCCountrySelect";
import SIABoolean from "components/Shared/SIABoolean";
import withAuth from "HOC/withAuth";
import { ValidateBankAccountDetails } from "../utils";
import { CurrencyOptions } from "assets/StaticData";
import { useLazyGetIncompleteAccountsQuery } from "apis/bankAccountApi";
const CreateBankAccountPage = () => {
  const { participantId } = useParams();
  const [participant, setParticipant] = useState<Participant>();
  const [clientQuery, clientResult] = useLazyGetClientByIdQuery();

  useEffect(() => {
    if (participantId) {
      clientQuery(participantId);
    }
  }, [participantId]);

  useEffect(() => {
    if (clientResult.isSuccess && clientResult.data.result) {
      setParticipant(clientResult.data.result);
    }
  }, [clientResult]);

  return (
    <div className="main-create-bank-container">
      <div className="container-header">
        <h1>Create Bank Account</h1>
        {participant && (
          <h3>
            {participant.generalDetails.firstName} {participant.generalDetails.lastName}
          </h3>
        )}
      </div>
      <div className="container-divider"></div>
      <div className="container-body">
        <CreateBankAccountForm participant={participant!} />
      </div>
    </div>
  );
};

interface CreateBankAccountFormProps {
  participant: Participant;
}

const CreateBankAccountForm = ({ participant }: CreateBankAccountFormProps) => {
  const [bankAccountDetails, setBankAccountDetails] = useState<BankAccount>(emptyBankAccount);
  const handleBankAccountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tempData = inputHelper(e, bankAccountDetails);
    setBankAccountDetails(tempData);
  };

  const handleBankAccountSelectChange = (value: any, attrName: string) => {
    if (attrName == "institutionName") {
      const institutionNumber = DefaultBankInstitutionNumbers[value];
      setBankAccountDetails((prev) => ({ ...prev, institutionNumber }));
    }
    setBankAccountDetails((prev) => ({ ...prev, [attrName]: value }));
  };

  const [createBankAccount] = useCreateBankAccountMutation();
  const [getIncompleteQuery, getIncompleteResult] = useLazyGetIncompleteAccountsQuery();

  useEffect(() => {
    if (participant) {
      getIncompleteQuery(participant.id);
    }
  }, [participant]);

  useEffect(() => {
    if (getIncompleteResult.isSuccess && getIncompleteResult.data.result) {
      const bankAccount = getIncompleteResult.data.result;
      if (bankAccount) {
        setBankAccountDetails({
          ...bankAccount,
          institutionName: ReverseInsitutionMapping[bankAccount.institutionName],
        });
      }
    }
  }, [getIncompleteResult]);

  // Navigate function from react-router-dom
  const navigate = useNavigate();

  const handleSaveDraft = async (e: React.FormEvent) => {
    e.preventDefault();
    bankAccountDetails.isComplete = false;
    var formData = createBankAccountFormData(bankAccountDetails, participant);
    console.log(formData);
    const result: ApiResponseModel = await createBankAccount(formData);

    if (result.data?.isSuccess) {
      notify("Bank Account Saved as Draft", "success");
      navigate(`/participant/${participant.id}`);
    } else if (result.error) {
      console.log(result.error);
      notify("Error Saving Bank Account as Draft", "error");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Validate the Form
    if (!ValidateBankAccountDetails(bankAccountDetails)) {
      return;
    }

    if (!bankAccountDetails.voidCheque) {
      notify("Please Upload a Void Cheque", "error");
      return;
    }

    bankAccountDetails.isComplete = true;
    var formData = createBankAccountFormData(bankAccountDetails, participant);
    const result: ApiResponseModel = await createBankAccount(formData);

    if (result.data?.isSuccess) {
      notify("Bank Account Created Successfully", "success");
      setBankAccountDetails(emptyBankAccount);
      navigate(`/participant/${participant.id}`);
    } else if (result.error) {
      console.log(result.error);
      notify("Error Creating Bank Account", "error");
    }
  };

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    setBankAccountDetails({ ...bankAccountDetails, voidCheque: file });
  };

  return (
    <form className="container-form" onSubmit={handleSubmit}>
      <button className="btn btn-primary" onClick={handleSaveDraft}>
        Save Draft
      </button>
      <div className="account-input-container">
        <label>Account Name</label>
        <SIAInput
          value={bankAccountDetails.accountName}
          type="text"
          onChange={handleBankAccountInputChange}
          name="accountName"
        />
      </div>
      <div className="account-input-container">
        <label>Institution Name</label>
        <SIASelect
          value={bankAccountDetails.institutionName}
          onChange={(e) => handleBankAccountSelectChange(e, "institutionName")}
          options={DefaultBankInstitutions}
          name="institutionName"
        />
      </div>
      <div className="account-input-container">
        <label>Address</label>
        <SIAInput
          value={bankAccountDetails.address}
          type="text"
          onChange={handleBankAccountInputChange}
          name="address"
        />
      </div>
      <div className="account-input-container">
        <label>Country</label>
        <SIACountrySelect
          value={bankAccountDetails.country}
          name="country"
          onChange={(e) => handleBankAccountSelectChange(e, "country")}
        />
      </div>
      <div className="account-input-container">
        <label>Region</label>
        <SIARegionSelect
          value={bankAccountDetails.region}
          name="country"
          onChange={(e) => handleBankAccountSelectChange(e, "region")}
          country={bankAccountDetails.country}
        />
      </div>
      {["Canada", "United States"].includes(bankAccountDetails.country) && (
        <div className="account-input-container">
          <label>{bankAccountDetails.country === "Canada" ? "Postal Code" : "Zip Code"} </label>
          {(bankAccountDetails.country === "Canada" || bankAccountDetails.country === "United States") && (
            <SIAInput
              value={bankAccountDetails.postalCode}
              name="postalCode"
              onChange={handleBankAccountInputChange}
              type="text"
            />
          )}
        </div>
      )}
      <div className="account-input-container">
        <label>City</label>
        <SIAInput value={bankAccountDetails.city} type="text" onChange={handleBankAccountInputChange} name="city" />
      </div>
      <div className="account-input-container">
        <label>Transit Number</label>
        <SIAInput
          value={bankAccountDetails.transitNumber}
          type="text"
          onChange={handleBankAccountInputChange}
          name="transitNumber"
        />
      </div>
      <div className="account-input-container">
        <label>Institution Number</label>
        <SIAInput
          value={bankAccountDetails.institutionNumber}
          type="text"
          onChange={handleBankAccountInputChange}
          name="institutionNumber"
        />
      </div>
      <div className="account-input-container">
        <label>Account Number</label>
        <SIAInput
          value={bankAccountDetails.accountNumber}
          type="text"
          onChange={handleBankAccountInputChange}
          name="accountNumber"
        />
      </div>
      <div className="account-input-container">
        <label>Primary Account</label>
        <SIABoolean
          value={bankAccountDetails.isPrimaryAccount}
          onChange={(e) => handleBankAccountSelectChange(e, "isPrimaryAccount")}
          label="isPrimaryAccount"
        />
      </div>
      <div className="account-input-container">
        <label>Currency</label>
        <SIASelect
          value={bankAccountDetails.currency}
          onChange={(e) => handleBankAccountSelectChange(e, "currency")}
          options={CurrencyOptions}
          name="currency"
        />
      </div>
      <div className="account-input-container">
        <label>Upload Void Chque</label>
        <SIAInput value="" type="file" onChange={handleFileInputChange} accept="image/*" />
      </div>
      <div className="account-btn-container">
        <button className="btn btn-primary">Create Bank Account</button>
      </div>
    </form>
  );
};

export default withAuth(CreateBankAccountPage);

function createBankAccountFormData(bankAccountDetails: BankAccount, participant: Participant) {
  console.log(bankAccountDetails);
  var formData = new FormData();
  formData.append("Id", bankAccountDetails.id);
  formData.append("InstitutionNumber", bankAccountDetails.institutionNumber);
  formData.append("AccountName", bankAccountDetails.accountName);
  formData.append("AccountNumber", bankAccountDetails.accountNumber);
  formData.append("Address", bankAccountDetails.address);
  formData.append("City", bankAccountDetails.city);
  formData.append("Region", bankAccountDetails.region);
  formData.append("Country", bankAccountDetails.country);
  formData.append("InstitutionName", DefaultInstitutionMapping[bankAccountDetails.institutionName]);
  formData.append("PostalCode", bankAccountDetails.postalCode);
  formData.append("TransitNumber", bankAccountDetails.transitNumber);
  formData.append("IsPrimaryAccount", bankAccountDetails.isPrimaryAccount.toString());
  formData.append("Currency", bankAccountDetails.currency.toString());
  formData.append("ParticipantId", participant.id);
  formData.append("VoidChequeBytes", bankAccountDetails.voidCheque!);
  formData.append("IsComplete", bankAccountDetails.isComplete.toString());
  return formData;
}

function getKeyByValue(object: any, value: string): string | undefined {
  return Object.keys(object).find((key) => object[key] === value);
}
