import { useNavigate } from "react-router-dom";
import WorkflowStatus from "../../WorkflowStatus";
import { ParticipantDetailsSectionProps } from "features/participant/types";

const RTQSentForSignatureParticipantItem = ({ participant }: ParticipantDetailsSectionProps) => {
  const navigate = useNavigate();
  const completedPreviousTask = participant?.accountHolderWorkflow?.rtqCompletedByParticipant;
  const completedTask = participant?.accountHolderWorkflow?.rtqSentForSignature;
  const completedText = "Sent For Client Signature";
  const notCompletedText = "Not Sent For Client Signature";
  const completeTooltipText = "";
  const notCompleteTooltipText = "";

  const actionHandler = () => {};

  return (
    <WorkflowStatus
      completedPreviousTask={completedPreviousTask}
      completedTask={completedTask}
      completeTooltipText={completeTooltipText}
      notCompleteTooltipText={notCompleteTooltipText}
      completedText={completedText}
      notCompletedText={notCompletedText}
      completeActionHander={actionHandler}
      notCompleteActionHandler={actionHandler}
      notCompleteIconClass="bi-envelope-check"
      completeIconClass="bi-envelope-check-fill"
      noCursor={true}
    />
  );
};

export default RTQSentForSignatureParticipantItem;
