import { ParticipantRegionSelectProps } from "features/participant/types";
import { RegionDropdown } from "react-country-region-selector";

const KYCRegionSelect = ({
  label,
  value,
  disabled = false,
  onChange,
  country,
}: ParticipantRegionSelectProps) => {
  return (
    <div className="kyc-label-input-container">
      <div className="kyc-label-container">
        <label>{label}</label>
      </div>
      <div className="kyc-input-container">
        <div className="input-container">
          <RegionDropdown
            country={country ? country : ""}
            value={value ? value.toString() : ""}
            onChange={(e) => onChange?.(e)}
            disabled={disabled}
          />
          <div className="underline"></div>
        </div>
      </div>
    </div>
  );
};

export default KYCRegionSelect;
