import {
  BeneficiaryTypeRequiredAccounts,
  JointTypeRequiredAccounts,
} from "assets/StaticData/InvestmentAccount";
import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { checkEmptyValue, notify } from "utils";

/**
 * Validate function that checks the general details
 * of the investment account, either a create or update
 * @param data
 * @returns
 */
const ValidateGeneralDetails = (data: CreateInvestmentAccountDto) => {
  console.log(data);
  if (checkEmptyValue(data.type)) {
    notify("Please select account type", "error");
    return false;
  }
  if (checkEmptyValue(data.subType)) {
    notify("Please select account sub type", "error");
    return false;
  }

  // Check if the Beneficiary Designation Options is selected
  if (
    BeneficiaryTypeRequiredAccounts.includes(Number(data.type)) &&
    !data.allowBeneficiarys &&
    !data.allowSuccessorAnnuitants &&
    !data.allowEstate
  ) {
    notify("Please select Beneficiary Designation Options", "error");
    return false;
  }

  if (checkEmptyValue(data.currency)) {
    notify("Please select currency", "error");
    return false;
  }

  if (checkEmptyValue(data.intendedUseOfAccount)) {
    notify("Please select intended use of account", "error");
    return false;
  }

  if (checkEmptyValue(data.sourceOfFunds)) {
    notify("Please select source of funds", "error");
    return false;
  }

  if (data.isBorrowingToInvest == undefined || data.isBorrowingToInvest == null) {
    notify("Please select borrowing to invest", "error");
    return false;
  }

  if (data.allowEFT == undefined || data.allowEFT == null) {
    notify("Please select allow EFT", "error");
    return false;
  }

  if (
    JointTypeRequiredAccounts.includes(Number(data.type)) &&
    (data.isJointAccount == undefined || data.isJointAccount == null)
  ) {
    notify("Please select joint account", "error");
    return false;
  }

  if (data.allowTransfers == undefined || data.allowTransfers == null) {
    notify("Please select allow transfers", "error");
    return false;
  }

  if (data.allowPAC == undefined || data.allowPAC == null) {
    notify("Please select allow PAC", "error");
    return false;
  }

  if (data.allowSWP == undefined || data.allowSWP == null) {
    notify("Please select allow SWP", "error");
    return false;
  }

  return true;
};

export default ValidateGeneralDetails;
