import { SpinningLoader } from "components/Shared";
import { WorkflowStatusProps } from "features/participant/types";

const WorkflowStatus = ({
  completedPreviousTask,
  completedTask,
  completeTooltipText,
  notCompleteTooltipText,
  completedText,
  notCompletedText,
  completeActionHander,
  notCompleteActionHandler,
  completeIconClass,
  notCompleteIconClass,
  isLoading,
  noCursor,
}: WorkflowStatusProps) => {
  if (completedPreviousTask) {
    return (
      <div className="workflow-item-status-container">
        <a
          data-tooltip-id="workflow-tooltip"
          data-tooltip-content={completedTask ? completeTooltipText : notCompleteTooltipText}
        >
          <button
            className={
              `status-btn ` +
              (completedTask ? `complete-status-btn ` : `not-complete-status-btn `) +
              (noCursor ? `no-cursor` : ``)
            }
            onClick={completedTask ? completeActionHander : notCompleteActionHandler}
          >
            <i className={`bi ${completedTask ? completeIconClass : notCompleteIconClass}`}></i>
            {completedTask ? completedText : notCompletedText}
            {isLoading && <SpinningLoader />}
          </button>
        </a>
      </div>
    );
  }
  // If it is not completed and the previous task is not completed, then we display content without actions
  else {
    return (
      <div className="workflow-item-status-container bg-gray-50">
        <a data-tooltip-id="workflow-tooltip" data-tooltip-content="Previous Task Not Completed">
          <button className="status-btn previous-step-not-completed-status-btn" disabled>
            <i className="bi bi-file-text-fill"></i>
            {notCompletedText}
          </button>
        </a>
      </div>
    );
  }
};

export default WorkflowStatus;
