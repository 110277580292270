import { ParticipantDetailsSectionProps } from "features/participant/types";
import { useEffect, useState } from "react";
import IPSFormCompleted from "./IPSFormComplete";
import { useLazyDownloadIPSUnsignedDocumentQuery } from "apis/ipsApi";
import { isXMLDoc } from "jquery";
import { Spinner } from "reactstrap";

/**
 * This is the Know Your Client Workflow Item List
 * 1. Know Your Client Form Completed
 * 2. Know Your Client Documents Uploaded
 * @param param0
 * @returns
 */
const IPSWorkflowItem = ({ participant }: ParticipantDetailsSectionProps) => {
  // Status Text of where we are in the process
  const [statusText, setStatusText] = useState("");
  // Download the IPS Document
  const [downloadIPS] = useLazyDownloadIPSUnsignedDocumentQuery();

  const allStepsCompleted = participant?.accountHolderWorkflow?.ipsCompleted;
  const previousStepCompleted = participant?.accountHolderWorkflow?.tradingAccountCompleted;
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadIPS = async () => {
    setIsDownloading(true);
    await downloadIPS(participant.id);
    setIsDownloading(false);
  };

  useEffect(() => {
    if (!participant?.accountHolderWorkflow?.tradingAccountCompleted) {
      setStatusText("Previous Step Not Completed");
    } else if (!participant?.accountHolderWorkflow?.ipsCompleted) {
      setStatusText("Investment Policy Statement Must Be Completed");
    } else {
      setStatusText("All Actions Items Are Completed For This Step. No Further Action Required");
    }
  }, [participant]);
  return (
    <div className="workflow-item">
      <div className="workflow-item-header">
        <p className="workflow-item-header-text">
          SIA Investment Policy Statement
          {!previousStepCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="Previous Step Not Completed"
            >
              <i className="bi bi-x-circle no-action"></i>
            </a>
          )}
          {previousStepCompleted && !allStepsCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="All Action Items Are Completed for this Step"
            >
              <i className="bi bi-exclamation-diamond not-complete"></i>
            </a>
          )}
          {previousStepCompleted && allStepsCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="Action Items Left To Complete"
            >
              <i className="bi bi-check-circle-fill complete"></i>
            </a>
          )}
        </p>
        {participant?.accountHolderWorkflow?.ipsCompleted && (
          <>
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="Download Unsigned IPS Document"
              style={{ marginRight: "1rem" }}
              onClick={handleDownloadIPS}
            >
              <i style={{ fontSize: "1.5rem" }} className="bi bi-download"></i>
            </a>
            {isDownloading && <Spinner />}
          </>
        )}
      </div>
      <div className="workflow-item-status-container">{statusText}</div>
      <div className="workflow-items-status-container">
        <IPSFormCompleted participant={participant} />
      </div>
    </div>
  );
};

export default IPSWorkflowItem;
