import React, { useState } from "react";
import { AccountSectionProps } from "../types";
import { ParticipantRole } from "constants/enums";
import { ParticipantTableComponent } from "./ParticipantTable";
import { CreateNewParticipantWithRole } from "./CreateNewParticipantWithRole";
import { SelectExistingParticipantWithRole } from "./SelectExistingParticipantWithRole";

/**
 * @name          AccountSpouseContributer
 * @summary       This component is responsible for display and adding spouse contributers to the account if allowed.
 * @param         formData the new investment account form data
 * @param         setFormData the react hook form set form data function
 * @param         participant the participant state information
 * @returns       React.Component
 */
function AccountSpouseContributer({ formData, setFormData, participant }: AccountSectionProps) {
  // State for create a new participant
  const [isCreatingNewParticipant, setIsCreatingNewParticipant] = useState(false);
  // State for existing participant
  const [isChoosingExistingParticipant, setIsChoosingExistingParticipant] = useState(false);
  // Handle Spousal Contributer Add
  const handleSpousalContributerAdd = (participantId: string) => {
    // Check if the beneficiary is a spouse
    const tempData = { ...formData };
    // If there is nothing there, create an empty array with the participant id
    if (!tempData.spouseContributers) {
      tempData.spouseContributers = [participantId];
    } else if (tempData.spouseContributers && tempData.spouseContributers.includes(participantId)) {
      return;
    } else {
      tempData.spouseContributers = [...tempData.spouseContributers, participantId];
    }

    setFormData(tempData);
  };

  // Handle removal of spousal contributer
  const handleSpousalContributerRemove = (index: number) => {
    const tempData = { ...formData };
    tempData.spouseContributers?.splice(index, 1);
    setFormData(tempData);
  };

  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>Spousal Contributers</h1>
        <h3>Please Select The Spousal Contributer(s) for the account</h3>
      </div>
      <ParticipantTableComponent
        participantIds={formData.spouseContributers!}
        handleDelete={handleSpousalContributerRemove}
        canDeleteFirst={true}
      />
      {!isChoosingExistingParticipant && !isCreatingNewParticipant && (
        <div className="action-section-btn-container">
          <button
            className="btn btn-primary"
            onClick={() => setIsChoosingExistingParticipant(true)}
          >
            Add Existing Participant
          </button>
          <button className="btn btn-primary" onClick={() => setIsCreatingNewParticipant(true)}>
            Create & Add New Spousal Contributer
          </button>
        </div>
      )}
      <div className="account-section-body">
        {isCreatingNewParticipant && (
          <CreateNewParticipantWithRole
            roleName="Spousal Contributer"
            role={ParticipantRole.SPOUSE_CONTRIBUTER}
            householdId={participant?.householdId!}
            setIsCreatingNewParticipant={setIsCreatingNewParticipant}
            handleBeneficiaryAdd={handleSpousalContributerAdd}
          />
        )}
        {isChoosingExistingParticipant && (
          <SelectExistingParticipantWithRole
            roleName="Spousal Contributer"
            role={ParticipantRole.SPOUSE_CONTRIBUTER}
            householdId={participant?.householdId!}
            setIsChoosingExisiting={setIsChoosingExistingParticipant}
            addParticipant={handleSpousalContributerAdd}
          />
        )}
      </div>
    </div>
  );
}

export default AccountSpouseContributer;
