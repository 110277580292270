import { useRiskToleranceReviewedMutation } from "apis/mainApi";
import SIAInput from "components/Shared/SIAInput";
import { ParticipantDetailsSectionProps } from "features/participant/types";
import { useState } from "react";
import { ApiResponseModel } from "types";
import { notify } from "utils";

interface RiskParametersDto {
  lowRiskInvestmentPercent: number;
  mediumRiskInvestmentPercent: number;
  highRiskInvestmentPercent: number;
}

interface RTQMarkAndReviewRiskParametersProps extends ParticipantDetailsSectionProps {
  // Use state for blob
  setBlobData: React.Dispatch<React.SetStateAction<Blob | null>>;
}

/**
 * Component that goes with the Risk Tolerance Review Substep.
 * Allows an advisor to review AND set the risk paramters of their clients.
 * @param param0
 * @returns
 */
const RTQMarkAndReviewRiskParameters = ({
  participant,
  setBlobData,
}: RTQMarkAndReviewRiskParametersProps) => {
  // API Endpoint to Review Risk Tolerance
  const [reviewRiskTolerance] = useRiskToleranceReviewedMutation();
  // We need it from the data base
  const [riskParameters, setRiskParameters] = useState<RiskParametersDto>({
    lowRiskInvestmentPercent: participant?.riskTolerance?.lowRiskInvestmentPercent
      ? participant.riskTolerance.lowRiskInvestmentPercent
      : 0,
    mediumRiskInvestmentPercent: participant?.riskTolerance?.mediumRiskInvestmentPercent
      ? participant.riskTolerance.mediumRiskInvestmentPercent
      : 0,
    highRiskInvestmentPercent: participant?.riskTolerance?.highRiskInvestmentPercent
      ? participant.riskTolerance.highRiskInvestmentPercent
      : 0,
  });

  // Handlers for change of the risk parameters
  const handleLowRiskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRiskParameters({ ...riskParameters, lowRiskInvestmentPercent: +e.target.value });
  };

  const handleMediumRiskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRiskParameters({ ...riskParameters, mediumRiskInvestmentPercent: +e.target.value });
  };

  const handleHighRiskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRiskParameters({ ...riskParameters, highRiskInvestmentPercent: +e.target.value });
  };

  // Form Submit
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Calculate the total % of the risk parameters
    const totalPercent =
      riskParameters.lowRiskInvestmentPercent +
      riskParameters.mediumRiskInvestmentPercent +
      riskParameters.highRiskInvestmentPercent;

    // Total Percent of the risk parameters must be 100%
    if (totalPercent !== 100) {
      notify("Risk Parameters Must Add Up to 100%", "error");
      return;
    }

    // Submit if the checks pass
    const params = {
      participantId: participant.id,
      riskParameters: riskParameters,
    };

    const result: ApiResponseModel = await reviewRiskTolerance(params);
    if (result.data && result.data.isSuccess) {
      notify("Risk Parameters Updated Successfully", "success");
      setBlobData(null);
    } else {
      notify("Error Updating Risk Parameters", "error");
      console.log(result.error);
    }
  };

  return (
    <div className="risk-parameters-container">
      <p className="risk-parameters-container-header">
        Please Specify Risk Parameters Based on Questionaire Results
      </p>
      <p className="risk-parameters-container-subheader">
        Total Percent:{" "}
        {riskParameters.highRiskInvestmentPercent +
          riskParameters.mediumRiskInvestmentPercent +
          riskParameters.lowRiskInvestmentPercent}
        %
      </p>
      <form onSubmit={handleFormSubmit}>
        <div className="risk-parameter-input-container">
          <label>% Low Risk</label>
          <SIAInput
            value={riskParameters.lowRiskInvestmentPercent}
            type="number"
            onChange={handleLowRiskChange}
          />
        </div>
        <div className="risk-parameter-input-container">
          <label>% Medium Risk</label>
          <SIAInput
            value={riskParameters.mediumRiskInvestmentPercent}
            type="number"
            onChange={handleMediumRiskChange}
          />
        </div>
        <div className="risk-parameter-input-container">
          <label>% High Risk</label>
          <SIAInput
            value={riskParameters.highRiskInvestmentPercent}
            type="number"
            onChange={handleHighRiskChange}
          />
        </div>
        <div className="risk-parameter-button-container">
          <button>Submit Risk Parameters</button>
        </div>
      </form>
    </div>
  );
};

export default RTQMarkAndReviewRiskParameters;
