import { useEffect, useState } from "react";
import "../styles/main-styles.scss";
import withAuth from "HOC/withAuth";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { ApiResponseModel, Participant } from "types";
import { StepFormWizard } from "components/Shared";
import { CreateInvestmentAccountDto, InvestmentAccountDto } from "types/InvestmentAccount";
import { EmptyInvestmentAccount } from "constants/emptyStates";
import AccountGeneralDetails from "./AccountGeneralDetails";
import { useLazyGetClientByIdQuery } from "apis/mainApi";
import AccountJointDetails from "./AccountJointDetails";
import { BeneficiaryTypeRequiredAccounts, JointTypeRequiredAccounts } from "assets/StaticData/InvestmentAccount";
import AccountBeneficiaries from "./AccountBeneficiaries";
import AccountSuccessorAnnuitant from "./AccountSuccessorAnnuitant";
import { SuccessorAnnuitantTypeRequiredAccounts } from "assets/StaticData/InvestmentAccount/AccountTypeRequiredData";
import AccountRESPBeneficiaries from "./AccountRESPBeneficiaries";
import { InvestmentAccountSubType, InvestmentAccountType } from "constants/enums";
import AccountSpouseContributer from "./AccountSpouseContributer";
import AccountEFTDetails from "./AccountEFTDetails";
import AccountTransferDetails from "./AccountTransferDetails";
import AccountPAC from "./AccountPAC";
import {
  ValidateBeneficiaries,
  ValidateEFTs,
  ValidateIncomeFundDetails,
  ValidateJointAccountDetails,
  ValidatePACDetails,
} from "../utils";
import AccountSWP from "./AccountSWP";
import ValidateSWPDetails from "../utils/ValidateSWPDetails";
import AccountIncomeFundDetails from "./AccountIncomeFundDetails";
import AccountITF from "./AccountITF";
import {
  useCreateOrUpdateAccountMutation,
  useLazyGetIncompleteInvestmentAccountQuery,
} from "apis/investmentAccountApi";
import { notify } from "utils";
import AccountReview from "./AccountReview";
import { ReturnIconSvg } from "assets/svg";
import ValidateGeneralDetails from "../utils/ValidateGeneralDetails";
import ValidateSpouseContributer from "../utils/ValidateSpouseContributer";

import cloneDeep from "lodash/cloneDeep";

/**
 * @name           CreateTradingAccountPage
 * @summary        This page is primarily the point of contact for creating a new investment account.
 *                 All of the steps involved will be conditionally rendered based on various parameters for the
 *                 investment account.
 *                 All of the rules can be found in the documentation provided with this project.
 * @todo           Write the Investment Account Opening Documentation.
 * @returns        React.Component
 */
function CreateTradingAccountPage() {
  const navigate = useNavigate();
  const { participantId } = useParams();
  const [participant, setParticipant] = useState<Participant>();
  const [hasIncompleteAccount, setHasIncompleteAccount] = useState<boolean>(false);
  const [newInvestmentAccount, setNewInvestmentAccount] = useState<InvestmentAccountDto>(EmptyInvestmentAccount);

  const [participantQuery, participantResult] = useLazyGetClientByIdQuery();
  const [incompleteInvestmentAccountQuery, incompleteResult] = useLazyGetIncompleteInvestmentAccountQuery();
  const [createInvestmentAccount] = useCreateOrUpdateAccountMutation();

  useEffect(() => {
    if (participantId) {
      participantQuery(participantId);
    }
  }, [participantId]);

  useEffect(() => {
    if (participantResult.isSuccess && participantResult.data.result) {
      const participant: Participant = { ...participantResult.data.result };
      setParticipant(participant);

      incompleteInvestmentAccountQuery(participant.id);

      if (!newInvestmentAccount.accountHolders) {
        setNewInvestmentAccount({
          ...newInvestmentAccount,
          accountHolders: [participant.id],
        });
      } else if (!newInvestmentAccount.accountHolders.includes(participant.id)) {
        setNewInvestmentAccount({
          ...newInvestmentAccount,
          accountHolders: [...newInvestmentAccount.accountHolders, participant.id],
        });
      }
    }
  }, [participantResult]);

  useEffect(() => {
    if (incompleteResult.isSuccess && incompleteResult.data.result) {
      const incompleteAccount: InvestmentAccountDto = cloneDeep(incompleteResult.data.result);
      console.log(incompleteAccount);
      setHasIncompleteAccount(true);
      setNewInvestmentAccount(incompleteAccount);
    }
  }, [incompleteResult]);

  const handleSubmit = async () => {
    newInvestmentAccount.isComplete = true;
    const response: ApiResponseModel = await createInvestmentAccount(newInvestmentAccount);
    if (response.data && response.data.isSuccess) {
      notify("Investment Account Created Successfully", "success");
      navigate(-1);
    } else {
      notify("An error has occured", "error");
    }
  };

  const handleStepChange = async () => {
    newInvestmentAccount.isComplete = false;
    const response: ApiResponseModel = await createInvestmentAccount(newInvestmentAccount);
    if (response.data && response.data.isSuccess) {
      const newAccount: InvestmentAccountDto = { ...response.data.result } as InvestmentAccountDto;

      setNewInvestmentAccount(newAccount);
    } else {
      notify("An error has occured saving the account details", "error");
    }
  };

  const handleClearDetails = () => {
    var emptyAccount = cloneDeep(EmptyInvestmentAccount);
    emptyAccount.id = newInvestmentAccount.id;
    setNewInvestmentAccount(emptyAccount);
  };

  return (
    <div className="main-create-trade-container">
      <Tooltip id="trade-account-tooltip" />
      <div className="page-header-rtn-container" style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          style={{ background: "none", border: "none", outline: "none" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ReturnIconSvg />
        </button>
        <button className="btn btn-primary" onClick={handleClearDetails}>
          Reset Details
        </button>
      </div>
      <div className="main-create-trade-container-header">
        <h1>SIA Create Investment Account</h1>
        {participant && (
          <h3>
            {participant.generalDetails.firstName} {participant.generalDetails.lastName}
          </h3>
        )}
      </div>
      <div className="main-create-trade-container-body">
        <StepFormWizard
          startIndex={0}
          steps={[
            {
              label: "General Details",
              subtitle: "",
              content: AccountGeneralDetails,
              validator: ValidateGeneralDetails,
            },
            ...(JointTypeRequiredAccounts.includes(Number(newInvestmentAccount.type)) &&
            newInvestmentAccount.isJointAccount
              ? [
                  {
                    label: "Joint Account Details",
                    subtitle: "",
                    content: AccountJointDetails,
                    validator: ValidateJointAccountDetails,
                  },
                ]
              : []),
            ...(BeneficiaryTypeRequiredAccounts.includes(Number(newInvestmentAccount.type)) &&
            newInvestmentAccount.allowBeneficiarys
              ? [
                  {
                    label: "Beneficiary Details",
                    subtitle: "",
                    content: AccountBeneficiaries,
                    validator: ValidateBeneficiaries,
                  },
                ]
              : []),
            ...(SuccessorAnnuitantTypeRequiredAccounts.includes(Number(newInvestmentAccount.type)) &&
            newInvestmentAccount.allowSuccessorAnnuitants
              ? [
                  {
                    label: "Successor Annuitant",
                    subtitle: "",
                    content: AccountSuccessorAnnuitant,
                    validator: ValidateBeneficiaries,
                  },
                ]
              : []),
            ...(newInvestmentAccount.type == InvestmentAccountType.RESP
              ? [
                  {
                    label: "RESP Beneficiaries",
                    subtitle: "",
                    content: AccountRESPBeneficiaries,
                    validator: ValidateBeneficiaries,
                  },
                ]
              : []),
            ...(Number(newInvestmentAccount.type) == InvestmentAccountType.RSP &&
            Number(newInvestmentAccount.subType) == InvestmentAccountSubType.SPOUSAL
              ? [
                  {
                    label: "Spousal Contributers",
                    subtitle: "",
                    content: AccountSpouseContributer,
                    validator: ValidateSpouseContributer,
                  },
                ]
              : []),
            ...(newInvestmentAccount.allowEFT
              ? [
                  {
                    label: "EFT Details",
                    subtitle: "",
                    content: AccountEFTDetails,
                    validator: ValidateEFTs,
                  },
                ]
              : []),
            ...(newInvestmentAccount.allowTransfers
              ? [
                  {
                    label: "Transfer Details",
                    subtitle: "",
                    content: AccountTransferDetails,
                    validator: () => {
                      if (!newInvestmentAccount.transferDetails || newInvestmentAccount.transferDetails.length < 1) {
                        notify("Please add at least one transfer detail", "error");
                        return false;
                      }

                      return true;
                    },
                  },
                ]
              : []),
            ...(newInvestmentAccount.allowPAC
              ? [
                  {
                    label: "PAC Details",
                    subtitle: "",
                    content: AccountPAC,
                    validator: ValidatePACDetails,
                  },
                ]
              : []),
            ...(newInvestmentAccount.allowSWP
              ? [
                  {
                    label: "SWP Details",
                    subtitle: "",
                    content: AccountSWP,
                    validator: ValidateSWPDetails,
                  },
                ]
              : []),
            ...(newInvestmentAccount.type == InvestmentAccountType.CASH_IN_TRUST_FOR
              ? [
                  {
                    label: "ITF Details",
                    subtitle: "",
                    content: AccountITF,
                    validator: () => {
                      if (!newInvestmentAccount.trustees || newInvestmentAccount.trustees.length < 1) {
                        notify("Please add at least one trustee", "error");
                        return false;
                      }
                      if (!newInvestmentAccount.itfBeneficiaries || newInvestmentAccount.itfBeneficiaries.length < 1) {
                        notify("Please add at least one beneficiary", "error");
                        return false;
                      }

                      return true;
                    },
                  },
                ]
              : []),
            ...(newInvestmentAccount.type == InvestmentAccountType.RIF
              ? [
                  {
                    label: "Income Fund Details",
                    subtitle: "",
                    content: AccountIncomeFundDetails,
                    validator: ValidateIncomeFundDetails,
                  },
                ]
              : []),

            {
              label: "Review",
              subtitle: "",
              content: AccountReview,
            },
          ]}
          formData={newInvestmentAccount}
          setFormData={setNewInvestmentAccount}
          nextText="Continue"
          submitForm={handleSubmit}
          handleStepChange={handleStepChange}
          participant={participant}
        />
      </div>
    </div>
  );
}

export default withAuth(CreateTradingAccountPage);
