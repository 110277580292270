import { ParticipantInputProps } from "features/participant/types";

const KYCInput = ({
  label,
  value,
  type = "text",
  disabled = false,
  onChange,
  isDollar,
  hasTooltip,
  tooltipText,
  placeholder,
}: ParticipantInputProps) => (
  <div className="kyc-label-input-container">
    <div className="kyc-label-container ">
      <label>{label}</label>
      {hasTooltip && (
        <a data-tooltip-id="kyc-tooltip" data-tooltip-content="Test">
          <i className="bi bi-info-circle-fill"></i>
        </a>
      )}
    </div>
    <div className={`kyc-input-container ${isDollar ? "flex" : ""}`}>
      {isDollar && <span className="dollar-sign">$</span>}
      <div className="input-container">
        <input
          value={value || value == 0 ? value : ""}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => onChange?.(e.target.value)}
        />
        <div className="underline"></div>
      </div>
    </div>
  </div>
);

export default KYCInput;
