import { useSelector } from "react-redux";
//import { UserModel } from "../types";
import { RootState } from "../stores/store";
import { useDispatch } from "react-redux";
import AccessDenied from "../features/misc/AccessDenied";
import { useLazyGetAdvisorByUserIDQuery, useGetAdvisorByUserIDQuery } from "apis/advisorApi";
import { useEffect } from "react";
import { setAdvisorUser } from "../stores/Redux/advisorSlice";

/**
 * Higher Order Component that for Advisor Authentication
 * @param WrappedComponent
 * @returns
 */
const withAdvisorAuth = (WrappedComponent: any) => {
  return (props: any) => {
    // const dispatch = useDispatch();

    // const userData: UserModel = useSelector(
    //   (state: RootState) => state.userAuthStore
    // );

    // const { data, isLoading, isError, isSuccess, refetch } =
    //   useGetAdvisorByUserIDQuery(userData.user.id);

    // useEffect(() => {
    //   if (!isLoading && isSuccess && data.isSuccess) {
    //     const result = data.result;
    //     dispatch(setAdvisorUser({ id: result.id, IANumber: result.code }));
    //   }
    // }, [data]);

    return <WrappedComponent {...props} />;
  };
};

export default withAdvisorAuth;
