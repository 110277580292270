import { KYCSectionProps } from "../types";
import KYCSelect from "./KYCSelect";
import KYCInput from "./KYCInput";
import { EmploymentTypes } from "assets/StaticData/EditClientOptions";
import KYCCountrySelect from "./KYCCountrySelect";
import KYCRegionSelect from "./KYCRegionSelect";

/**
 * Participant Employee Details
 * @param param0
 * @returns
 */
function KYCEmploymentDetails({ formData, setFormData }: KYCSectionProps) {
  const numericFields = ["lengthInYears"];
  var employmentTypes: any = [1, 2, 5];
  const handleInputChange = (val: number | string | boolean, attrName: string) => {
    let updatedDetails = { ...formData.employmentDetails };
    if (val.toString().trim().length == 0 && numericFields.includes(attrName)) {
      updatedDetails[attrName] = "0"; // temp fix to help with null values for int fields
      setFormData({ ...formData, employmentDetails: updatedDetails });
      return;
    }
    // Check if attr exists in general details
    updatedDetails[attrName] = val;
    setFormData({ ...formData, employmentDetails: updatedDetails });
  };

  return (
    <div className="kyc-section-container">
      <div className="kyc-section-container-header">
        <h1>Employment Details</h1>
        <p>Please Fill Out All Required Fields</p>
      </div>
      <div className="kyc-section-container-body">
        <KYCSelect
          label="Employment Status"
          value={formData?.employmentDetails?.status}
          options={EmploymentTypes}
          onChange={(e) => handleInputChange(e, "status")}
        />
        <KYCInput
          label="Occupation"
          value={formData?.employmentDetails?.occupation}
          onChange={(e) => handleInputChange(e, "occupation")}
          disabled={!employmentTypes.includes(Number(formData?.employmentDetails?.status))}
        />
        <KYCInput
          label="Employer Name"
          value={formData?.employmentDetails?.name}
          onChange={(e) => handleInputChange(e, "name")}
          disabled={!employmentTypes.includes(Number(formData?.employmentDetails?.status))}
        />
        <KYCInput
          label="Type of Business"
          value={formData?.employmentDetails?.businessType}
          onChange={(e) => handleInputChange(e, "businessType")}
          disabled={!employmentTypes.includes(Number(formData?.employmentDetails?.status))}
        />
        <KYCInput
          label="Length of Employment (Years)"
          value={formData?.employmentDetails?.lengthInYears}
          onChange={(e) => handleInputChange(e, "lengthInYears")}
          type="number"
          disabled={!employmentTypes.includes(Number(formData?.employmentDetails?.status))}
        />
        <KYCInput
          label="Address of Employment"
          value={formData?.employmentDetails?.address}
          onChange={(e) => handleInputChange(e, "address")}
          disabled={!employmentTypes.includes(Number(formData?.employmentDetails?.status))}
        />
        <KYCCountrySelect
          label="Country"
          value={formData?.employmentDetails?.country}
          onChange={(e) => handleInputChange(e, "country")}
          disabled={!employmentTypes.includes(Number(formData?.employmentDetails?.status))}
        />
        <KYCRegionSelect
          label="Region"
          value={formData?.employmentDetails?.region}
          onChange={(e) => handleInputChange(e, "region")}
          country={formData?.employmentDetails?.country}
          disabled={!employmentTypes.includes(Number(formData?.employmentDetails?.status))}
        />
        <KYCInput
          label="City"
          value={formData?.employmentDetails?.city}
          onChange={(e) => handleInputChange(e, "city")}
          disabled={!employmentTypes.includes(Number(formData?.employmentDetails?.status))}
        />
        {["Canada", "United States"].includes(formData?.employmentDetails?.country) && (
          <KYCInput
            label={formData?.employmentDetails?.country == "Canada" ? "Postal Code" : "Zip Code"}
            value={formData?.employmentDetails?.postalCode}
            onChange={(e) => handleInputChange(e, "postalCode")}
          />
        )}
      </div>
    </div>
  );
}

export default KYCEmploymentDetails;
