import React, { useEffect } from "react";
import { Footer, Header } from "./components/Layout";
import { AuthUser } from "./features/auth/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./stores/store";
import { useLazyGetAdvisorByUserIDQuery } from "apis/advisorApi";
import { setAdvisorUser } from "./stores/Redux/advisorSlice";
import { useLazyGetHouseholdsByAdvisorQuery } from "apis/mainApi";
import { setHouseholds } from "./stores/Redux/householdSlice";
import { AppRoutes } from "./routes";
import "./app.scss";
import SIANavBar from "components/Layout/SIANavBar";
import { UserRoles } from "constants/enums";
/**
 * The main Application Container that houses all of the pages and routes.
 * On this page, we also collect the ID of the advisor that is logged in.
 * If no advisor is found, then will be set to nothing.
 * @returns
 * @todo We need to take out the functionality of the Storage here, and move it to either the routes or the pages.
 *       This will allow us to have a more modular approach to the application. This file should have minimal logic.
 *       Also let's move this component outside of this folder, into the root of the src folder.
 * @author Teddy K.
 */
function App() {
  const dispatch = useDispatch();
  const userData: AuthUser = useSelector((state: RootState) => state.userAuthStore);

  const [advisorQuery, advisor] = useLazyGetAdvisorByUserIDQuery();
  const [householdsQuery, households] = useLazyGetHouseholdsByAdvisorQuery();

  // Get the Advisor ID
  useEffect(() => {
    // Stores logged in advisor's information in the redux store
    if (userData.user.id != "" && userData.roles.includes(UserRoles.ADVISOR)) {
      advisorQuery(userData.user.id);
    }
  }, [userData]);

  // Set Advisor in Store
  useEffect(() => {
    if (advisor.isSuccess && !advisor.isFetching) {
      const dataFromApi = advisor.data;

      dispatch(
        setAdvisorUser({
          id: dataFromApi.result.id,
          IANumber: dataFromApi.result.number,
        })
      );

      householdsQuery(dataFromApi.result.id, false);
    } else if (advisor.isError && !advisor.isFetching) {
      dispatch(
        setAdvisorUser({
          id: "",
          IANumber: "",
        })
      );
    }
  }, [advisor]);

  // Get Household Data
  useEffect(() => {
    if (households.isSuccess) {
      dispatch(setHouseholds({ households: households.data.result }));
    }
  }, [households]);

  // We need to check authority here to see which type of view we want to return
  if (!userData.user.id) {
    return (
      <>
        <Header />
        <div className="main-body-container">
          <div className="sia-content-no-navbar">
            <AppRoutes />
          </div>
          <div className="sia-footer-container">
            <Footer />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Header />
        <div className="main-body-container">
          <div className="sia-navbar-container">
            <SIANavBar />
          </div>
          <div className="sia-content">
            <AppRoutes />
          </div>
        </div>
      </>
    );
  }
}

export default App;
