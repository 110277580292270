import { useEffect } from "react";
import { AccountSectionProps } from "../types";

/**
 * @name           AccountReview
 * @summary        This component is responsible for displaying the review details of the account.
 *                 In the background, this component will also clean up any data that needs to be sent to the API.
 * @param          formData the new investment account form data
 * @param          setFormData the react hook form set form data function
 * @param          participant the participant state information
 * @returns        React.Component
 */
function AccountReview({ formData, setFormData, participant }: AccountSectionProps) {
  // Clean up any flags or data here before submission
  useEffect(() => {
    const temp = { ...formData };
    if (temp.allowBeneficiarys == null) {
      temp.allowBeneficiarys = false;
    }
    if (temp.isJointAccount == null) {
      temp.isJointAccount = false;
    }

    setFormData(temp);
  }, []);

  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>Review Details</h1>
        <h3>Please Ensure All Required Fields Are Correct and Accurate</h3>
      </div>
    </div>
  );
}

export default AccountReview;
