import React, { useEffect, useState } from "react";
import { AccountSectionProps } from "../types";
import { ParticipantRole } from "constants/enums";
import { notify } from "utils";
import { ParticipantTableComponent } from "./ParticipantTable";
import { SelectExistingParticipantWithRole } from "./SelectExistingParticipantWithRole";

/**
 * @name      AccountSuccessorAnnuitant
 * @summary   This component is responsible for display and adding successor annuitants to the account if allowed.
 * @param     formData the new investment account form data
 * @param     setFormData the react hook form set form data function
 * @param     participant the participant state information
 * @returns   React.Component
 */
function AccountSuccessorAnnuitant({ formData, setFormData, participant }: AccountSectionProps) {
  // State to check if the account has a successor annuitant
  const [hasSuccessorAnnituiant, setHasSuccessorAnnuitant] = useState<boolean>(false);
  // Checks to see if the account has a successor annuitant
  useEffect(() => {
    const check = formData.successorAnnuitants && formData.successorAnnuitants.length > 0;
    setHasSuccessorAnnuitant(check!);
  }, [formData]);

  // Handling the sucessor add
  const handleSuccessorAnnuitantAdd = (participantId: string) => {
    const tempData = { ...formData };
    // If there is nothing there, create an empty array with the participant id
    if (!tempData.successorAnnuitants) {
      tempData.successorAnnuitants = [];
    } else if (
      tempData.successorAnnuitants &&
      tempData.successorAnnuitants.find((participant: string) => participant == participantId)
    ) {
      notify("Successor Annuitant already added", "error");
      return;
    } else {
      tempData.successorAnnuitants.push(participantId);
      setFormData({ ...formData, beneficiaries: tempData.beneficiaries });
      setHasSuccessorAnnuitant(true);
    }
  };

  // Handling the removal of a successor annuitant from a new account
  const handleRemove = (index: number) => {
    const tempData = { ...formData };
    if (tempData.successorAnnuitants) {
      tempData.successorAnnuitants.splice(index, 1);
      setFormData(tempData);
    }
    setHasSuccessorAnnuitant(false);
  };

  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>Successor Annuitant</h1>
        <h3>Please Select the Successor Annuitant for this Account</h3>
      </div>
      <ParticipantTableComponent
        participantIds={formData.successorAnnuitants!}
        handleDelete={handleRemove}
        canDeleteFirst={true}
      />
      <div className="account-section-body">
        {!hasSuccessorAnnituiant && (
          <SelectExistingParticipantWithRole
            roleName="Successor Annuitant"
            role={ParticipantRole.BENEFICIARY}
            householdId={participant?.householdId!}
            setIsChoosingExisiting={setHasSuccessorAnnuitant}
            addParticipant={handleSuccessorAnnuitantAdd}
          />
        )}
      </div>
    </div>
  );
}

export default AccountSuccessorAnnuitant;
