import React from "react";
import { SelectItem } from "types/Shared";
import "./styles/SIASelect.scss";
export interface SIASelectProps {
  value: any;
  disabled?: boolean;
  options?: SelectItem[];
  onChange?: (value: any) => void;
  name?: string;
  id?: string;
}

function SIASelect({ value, disabled, options, onChange, name, id }: SIASelectProps) {
  return (
    <div className="input-container" id={id ? `${id}-select-container` : ""}>
      <select
        value={value ? value : ""}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.value)}
        name={name}
        id={id ? `${id}-select` : ""}
      >
        <option value="">Option Not Selected</option>
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      <div className="underline"></div>
    </div>
  );
}

export default SIASelect;
