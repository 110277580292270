import { IPSSectionComponentProps } from "../types";

/**
 * Component for the Client Summary Section of the IPS
 * @param param0
 * @returns
 */
const IPSClientSummary = ({ formData, setFormData }: IPSSectionComponentProps) => {
  const handleClientSummaryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newVal = e.target.value;
    const temp = { ...formData, clientSummary: newVal };
    setFormData(temp);
  };

  return (
    <div className="ips-section-container">
      <div className="flex flex-col justify-center items-center">
        <div className="text-2xl font-bold text-center text-primary">Please Provide a Client Summary</div>
      </div>
      <div className="flex flex-col items-center min-h-[200px]">
        <textarea
          className="w-[95%] h-[20vh] p-4 focus:border-primary border-2 border-grey-300 focus:outline-none transition-all duration-200"
          value={formData.clientSummary}
          onChange={handleClientSummaryChange}
        />
      </div>
    </div>
  );
};

export default IPSClientSummary;
