import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * This endpoint uses the Client Controller found in the .NET API
 */
/// Here is a change
const advisorApi = createApi({
  reducerPath: "advisorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/advisor`,
    credentials: "include",
  }),
  tagTypes: ["Advisor"],
  endpoints: (builder) => ({
    // Gets consall clients from the database
    getAdvisorByUserID: builder.query({
      query: (applicationUserId: string) => ({
        url: `user/${applicationUserId}`,
        method: "GET",
        params: {
          userId: applicationUserId,
        },
      }),
      providesTags: ["Advisor"],
    }),
    // Gets all advisors, must have manager or admin access
    getAllAdvisors: builder.query({
      query: () => ({
        url: `/`,
        method: "GET",
      }),
      providesTags: ["Advisor"],
    }),
    createAdvisor: builder.mutation({
      query: (advisorParams) => ({
        url: `/`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: advisorParams,
      }),
      invalidatesTags: ["Advisor"],
    }),
    deleteAdvisor: builder.mutation({
      query: (advisorId) => ({
        url: `/`,
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
        },
        params: {
          advisorId: advisorId,
        },
      }),
      invalidatesTags: ["Advisor"],
    }),
  }),
});

export const {
  useGetAdvisorByUserIDQuery,
  useLazyGetAdvisorByUserIDQuery,
  useGetAllAdvisorsQuery,
  useCreateAdvisorMutation,
  useDeleteAdvisorMutation,
} = advisorApi;
export default advisorApi;
