// import BootstrapTable from "react-bootstrap-table-next";
// import "./styles/SIATable.scss";
export interface ColumnConfig {
  dataField: string;
  text: string;

  formatter?: (cell: any, row: any) => React.ReactNode;
  classes?: (cell: any, row: any) => string;
}

interface TableProps {
  data: any[];
  columns: ColumnConfig[];
  keyField?: string;
  tableClass?: string;
}

// function SIATable({ data, columns, keyField, tableClass }: TableProps) {
//   return (
//     <BootstrapTable
//       keyField={keyField ? keyField : "id"}
//       data={data}
//       columns={columns}
//       wrapperClasses={
//         tableClass ? `generic-sia-table ${tableClass}` : "generic-sia-table"
//       }
//     />
//   );
// }

// export default SIATable;

function SIATable({ data, columns, keyField, tableClass }: TableProps) {
  console.log(data);
  console.log(columns);
  console.log(keyField);
  console.log(tableClass);
  //return <div>Hello</div>;

  return (
    <div className={`overflow-x-auto ${tableClass} mb-2`}>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-blue-600">
          <tr>
            {columns.map((column) => (
              <th
                key={column.dataField}
                className="px-6 py-3 text-left text-xs font-large text-white uppercase tracking-wider text-center"
              >
                {column.text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={column.dataField} className="px-6 py-4 whitespace-nowrap text-center">
                  {row[column.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SIATable;
