import { ParticipantDetailsSectionProps } from "features/participant/types";
import { useNavigate } from "react-router-dom";
import WorkflowStatus from "../../WorkflowStatus";

const KYCDocumentsUploaded = ({ participant }: ParticipantDetailsSectionProps) => {
  const navigate = useNavigate();
  const completedPreviousTask = participant?.accountHolderWorkflow?.kycCompleted;
  const completedTask = participant?.accountHolderWorkflow?.kycDocumentsUploaded;
  const completedText = "Document Upload is Complete";
  const notCompletedText = "Document Upload is Incomplete";
  const completeTooltipText = "";
  const notCompleteTooltipText = "Click to Upload Documents";

  const actionHandler = () => {
    navigate(`/amlDocumentUpload/${participant.id}/`);
  };
  // If the Previous Task is completed, then we display content with actions
  return (
    <WorkflowStatus
      completedPreviousTask={completedPreviousTask}
      completedTask={completedTask}
      completeTooltipText={completeTooltipText}
      notCompleteTooltipText={notCompleteTooltipText}
      completedText={completedText}
      notCompletedText={notCompletedText}
      completeActionHander={actionHandler}
      notCompleteActionHandler={actionHandler}
      completeIconClass="bi-file-earmark-check-fill"
      notCompleteIconClass="bi-file-text-fill"
    />
  );
};

export default KYCDocumentsUploaded;
