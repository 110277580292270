import { checkEmptyValue, isValidUSZip, notify, validatePostalCode } from "../../../utils";
import { BankAccount } from "../../../types/Participant/Participant";

const ValidateBankAccountDetails = (account: BankAccount) => {
  if (checkEmptyValue(account.accountName)) {
    notify("Account Name is required", "error");
    return false;
  }
  if (checkEmptyValue(account.institutionName)) {
    notify("Institution Name is required", "error");
    return false;
  }
  if (checkEmptyValue(account.address)) {
    notify("Institution Address is required", "error");
    return false;
  }
  if (checkEmptyValue(account.city)) {
    notify("Institution City is required", "error");
    return false;
  }
  if (checkEmptyValue(account.region)) {
    notify("Institution Region is required", "error");
    return false;
  }
  if (checkEmptyValue(account.country)) {
    notify("Institution Country is required", "error");
    return false;
  }
  if (account.country == ("Canada" || "United States") && checkEmptyValue(account.postalCode)) {
    notify("Institution Post Code is required", "error");
    return false;
  } else if (account.country == "Canada" && !validatePostalCode(account.postalCode)) {
    notify("Postal Code is Invalid", "error");
    return false;
  } else if (account.country == "United States" && !isValidUSZip(account.postalCode)) {
    notify("Zip Code is Invalid", "error");
    return false;
  }
  if (checkEmptyValue(account.transitNumber) || account.transitNumber.length != 5) {
    notify("Enter Valid Transit Number", "error");
    return false;
  }
  if (checkEmptyValue(account.institutionNumber)) {
    notify("Institution Institution Number is required", "error");
    return false;
  }
  if (checkEmptyValue(account.accountNumber)) {
    notify("Institution Account Number is required", "error");
    return false;
  }

  return true;
};

export default ValidateBankAccountDetails;
