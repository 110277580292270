/**
 * This function will validate the beneficiaries
 * based on the choice that is given for the designation
 */

import { InvestmentAccountType } from "constants/enums";
import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { notify } from "utils";

const ValidateBeneficiaries = (data: CreateInvestmentAccountDto) => {
  // Regular Beneficiaries
  if (data.allowBeneficiarys) {
    if (!data.beneficiaries) {
      notify("Please add at least one beneficiary", "error");
      return false;
    } else if (data.beneficiaries.length < 1) {
      notify("Please add at least one beneficiary", "error");
      return false;
    }
    // Find the sum of the allocation amounts

    const allocationAmounts = data.beneficiaries.map((beneficiary) => beneficiary.allocation);
    // Find the sum of amounts
    const sumOfAmounts = allocationAmounts.reduce((a, b) => a + b, 0);
    if (sumOfAmounts != 100) {
      notify("The sum of the allocation amounts must be 100%", "error");
      return false;
    }
  }
  // Successor Annuitants
  if (data.allowSuccessorAnnuitants) {
    if (!data.successorAnnuitants) {
      notify("Please add at least one successor annuitant", "error");
      return false;
    } else if (data.successorAnnuitants.length < 1) {
      notify("Please add at least one successor annuitant", "error");
      return false;
    }
  }
  // RESP Beneficiaries
  if (data.type == InvestmentAccountType.RESP) {
    if (!data.respBeneficiaries) {
      notify("Please add at least one beneficiary", "error");
      return false;
    } else if (data.respBeneficiaries.length < 1) {
      notify("Please add at least one beneficiary", "error");
      return false;
    }
    // Find the sum of the allocation amounts

    const allocationAmounts = data.respBeneficiaries.map((beneficiary) => beneficiary.allocation);
    // Find the sum of amounts
    const sumOfAmounts = allocationAmounts.reduce((a, b) => a + b, 0);
    if (sumOfAmounts != 100) {
      notify("The sum of the allocation amounts must be 100%", "error");
      return false;
    }
  }

  return true;
};

export default ValidateBeneficiaries;
