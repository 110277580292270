import { ParticipantDetailsSectionProps } from "features/participant/types";
import RTQWorkflowItem from "./riskTolerance/RTQWorkflowItem";
import { KYCWorkflowItem } from "./kyc";
import AccountOpeningWorkflowItem from "./accountOpening";
import { IPSWorkflowItem } from "./ips";
import SIAPackageWorkflowItem from "./siaPackage";
import { useResetAccountHolderFlagsMutation } from "apis/mainApi";
import { ApiResponseModel } from "types";
import { notify } from "utils";
import { useState } from "react";
import { MiniLoader } from "components/Shared";
import { Spinner } from "reactstrap";

/**
 * Account Holder Workflow is a workflow that is only available to account holders.
 * Keeps track of the following steps
 * 1. Risk Tolerance Questionaire
 * 2. Know Your Client
 * 3. Account Opening Process (Bank Account + Investment Account)
 * 4. Client IPS
 * 5. SIA Package
 * 6. Later will add on the FCC Progress
 * @param param0
 * @returns
 */
const AccountHolderWorkflow = ({ participant }: ParticipantDetailsSectionProps) => {
  const [resetFlags] = useResetAccountHolderFlagsMutation();
  const [isResetting, setIsResetting] = useState(false);

  const handleResetFlags = async () => {
    setIsResetting(true);
    const result: ApiResponseModel = await resetFlags(participant.id);
    if (result.data?.isSuccess) {
      notify("Flags Successfully Reset!");
    } else if (!result.data?.isSuccess) {
      notify("An Error occurred while resetting", "error");
      console.log(result);
    }
    setIsResetting(false);
  };

  return (
    <div className="workflow-container">
      <div className="flex flex-col items-center">
        <div className="flex">
          <p className="text-lg font-bold">Account Holder Workflow</p>
          <a
            data-tooltip-id="workflow-tooltip"
            data-tooltip-content="This workflow is only available for account holders."
            style={{ marginLeft: "1rem" }}
          >
            <i className="bi bi-info-circle-fill"></i>
          </a>
        </div>
        <div className="m-1">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            onClick={handleResetFlags}
          >
            Reset Flags {isResetting && <Spinner size={"sm"} />}
          </button>
        </div>
      </div>
      <RTQWorkflowItem participant={participant} />
      <KYCWorkflowItem participant={participant} />
      <AccountOpeningWorkflowItem participant={participant} />
      <IPSWorkflowItem participant={participant} />
      <SIAPackageWorkflowItem participant={participant} />
    </div>
  );
};

export default AccountHolderWorkflow;
