// Imports
import { useState } from "react";
import { AccountSectionProps } from "../types";
import { ParticipantRole } from "constants/enums";
import { checkEmptyValue, notify } from "utils";
import { ParticipantBeneficiaryTableComponent } from "./ParticipantBeneficiaryTable";
import { CreateNewParticipantWithRole } from "./CreateNewParticipantWithRole";
import { SelectExistingParticipantWithRole } from "./SelectExistingParticipantWithRole";

/**
 * @name            AccountBeneficiaries
 * @summary         This component is responsible for display and adding beneficiaries to the account if allowed
 * @param           {
 *                    formData: the new investment account form data,
 *                    setFormData: the react hook form set form data function,
 *                    participant: the participant state information
 *                  }
 * @returns         React.Component
 */
function AccountBeneficiaries({
  formData,
  setFormData,
  participant,
  viewMode,
}: AccountSectionProps) {
  // State for create a new participant
  const [isCreatingNewParticipant, setIsCreatingNewParticipant] = useState(false);
  // State for chooising an existing participant
  const [isChoosingExistingParticipant, setIsChoosingExistingParticipant] = useState(false);

  // Here, there is an interesting rule about adding spouses as beneficiaries
  const handleBeneficiaryAdd = (participantId: string) => {
    if (checkEmptyValue(participantId)) {
      notify("Please enter valid beneficiary", "error");
    }
    // Check if the beneficiary is a spouse, we need to display a warning to the user
    if (
      participant &&
      participant.spouseDetails &&
      participant.spouseDetails?.participantId &&
      participant.spouseDetails?.participantId == participantId
    ) {
      if (
        !window.confirm(
          "Are you sure you want to add the participant's spouse as a beneficiary? Check with Primary Advisor of the Participant"
        )
      ) {
        return;
      }
    }

    const tempData = { ...formData };
    // If there is nothing there, create an empty array with the participant id
    if (!tempData.beneficiaries) {
      tempData.beneficiaries = [{ participantId: participantId, allocation: 100 }];
    } else if (
      tempData.beneficiaries &&
      tempData.beneficiaries.find((beneficiary) => beneficiary.participantId == participantId)
    ) {
      notify("Beneficiary already added", "error");

      return;
    } else {
      // find the current allocation of the beneficiaries
      const splitAllocation = 100 / (tempData.beneficiaries.length + 1);

      tempData.beneficiaries = [
        ...tempData.beneficiaries,
        { participantId: participantId, allocation: 0 },
      ];

      // loop through the beneficiaries and set the allocation to the split allocation
      tempData.beneficiaries.forEach((beneficiary) => {
        beneficiary.allocation = splitAllocation;
      });
    }

    setFormData({ ...formData, beneficiaries: tempData.beneficiaries });
    setIsChoosingExistingParticipant(false);
    setIsCreatingNewParticipant(false);
  };

  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>Account Beneficiaries</h1>
        <h3>Please List All Beneficiaries For This Account</h3>
      </div>
      <ParticipantBeneficiaryTableComponent
        account={formData!}
        beneficiaryType="beneficiaries"
        setAccount={setFormData}
        viewMode={viewMode}
      />
      {!isChoosingExistingParticipant && !isCreatingNewParticipant && !viewMode && (
        <div className="action-section-btn-container">
          <button
            className="btn btn-primary"
            onClick={() => setIsChoosingExistingParticipant(true)}
          >
            Add Existing Participant
          </button>
          <button className="btn btn-primary" onClick={() => setIsCreatingNewParticipant(true)}>
            Create & Add New Beneficiary
          </button>
        </div>
      )}

      <div className="account-section-body">
        {isCreatingNewParticipant && (
          <CreateNewParticipantWithRole
            roleName="Beneficiary"
            role={ParticipantRole.BENEFICIARY}
            householdId={participant?.householdId!}
            setIsCreatingNewParticipant={setIsCreatingNewParticipant}
            handleBeneficiaryAdd={handleBeneficiaryAdd}
          />
        )}
        {isChoosingExistingParticipant && (
          <SelectExistingParticipantWithRole
            roleName="Beneficiary"
            role={ParticipantRole.BENEFICIARY}
            householdId={participant?.householdId!}
            setIsChoosingExisiting={setIsChoosingExistingParticipant}
            addParticipant={handleBeneficiaryAdd}
          />
        )}
      </div>
    </div>
  );
}

export default AccountBeneficiaries;
