import { IconProps } from "./IconProps";
import SvgContainer from "./SvgContainer";

const DirectorFemale = ({ className, active, onClick }: IconProps) => {
  const activeSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
   M137.000000,210.000000 
       C91.333336,210.000000 46.166672,210.000000 1.000006,210.000000 
       C1.000004,140.333344 1.000004,70.666679 1.000002,1.000015 
       C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
       C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
       C185.833328,210.000000 161.666672,210.000000 137.000000,210.000000 
   M142.686432,136.276291 
       C136.844177,134.181519 131.001923,132.086746 125.288109,129.200378 
       C125.692284,127.863426 126.096451,126.526466 126.489082,125.227676 
       C142.790329,123.713493 158.554840,120.912415 170.681778,109.915138 
       C166.196503,103.358055 161.406723,97.714134 158.133774,91.296638 
       C150.638290,76.599754 151.504059,60.065384 149.042511,44.272221 
       C147.479126,34.241661 144.775467,24.698336 134.074173,21.208952 
       C126.962662,18.890093 119.520470,17.708487 113.309052,13.139506 
       C113.078056,12.969590 112.667427,13.010310 112.340385,13.009603 
       C108.016594,13.000252 103.680489,12.788050 99.371147,13.036892 
       C82.305084,14.022369 69.606140,22.558510 65.169037,40.005344 
       C61.549992,54.235592 59.623913,68.898926 57.011513,83.381836 
       C55.073410,94.126511 50.343971,103.153221 41.272755,109.594360 
       C51.107616,120.527740 67.040802,123.799232 81.629181,124.860657 
       C85.589134,125.148781 87.640045,125.924767 86.701508,130.720947 
       C82.160210,132.533386 77.618912,134.345840 72.276321,136.035767 
       C60.902393,139.362869 49.447884,142.441895 38.181873,146.101242 
       C30.210011,148.690628 24.028255,153.240311 22.119011,162.407440 
       C19.841656,173.342056 22.232517,179.841949 32.350506,184.627899 
       C38.557911,187.564102 45.112949,189.853790 51.665497,191.954697 
       C68.631546,197.394409 86.276421,198.604568 104.616150,199.955353 
       C105.483871,199.579758 106.351601,199.204178 108.122795,198.940369 
       C115.039993,198.628845 121.962753,198.408569 128.873245,197.987076 
       C144.011673,197.063705 158.727341,193.861420 172.597046,187.839005 
       C180.454590,184.427139 190.512283,182.673233 190.796555,170.625870 
       C191.003281,161.865753 189.296509,151.216797 179.015961,147.988556 
       C167.055084,144.232620 155.054947,140.601730 142.686432,136.276291 
   z"
      />
      <path
        fill="#231F20"
        opacity="1.000000"
        stroke="none"
        d="
   M87.159103,130.146011 
       C87.640045,125.924767 85.589134,125.148781 81.629181,124.860657 
       C67.040802,123.799232 51.107616,120.527740 41.273521,109.594818 
       C50.343971,103.153221 55.073410,94.126511 57.011513,83.381836 
       C59.623913,68.898926 61.549992,54.235592 65.169037,40.005344 
       C69.606140,22.558510 82.305084,14.022369 99.371147,13.036892 
       C103.680489,12.788050 108.016594,13.000252 112.340385,13.009603 
       C112.667427,13.010310 113.078056,12.969590 113.309052,13.139506 
       C119.520470,17.708487 126.962662,18.890093 134.074173,21.208952 
       C144.775467,24.698336 147.479126,34.241661 149.042511,44.272221 
       C151.504059,60.065384 150.638290,76.599754 158.133774,91.296638 
       C161.406723,97.714134 166.196503,103.358055 170.681778,109.915138 
       C158.554840,120.912415 142.790329,123.713493 126.489082,125.227676 
       C126.096451,126.526466 125.692284,127.863426 125.138397,129.991028 
       C119.270439,152.187836 113.552200,173.594009 107.553940,194.999786 
       C106.900536,194.998459 106.527161,194.997513 105.982788,194.658752 
       C102.530815,183.736359 99.101868,173.194809 96.010269,162.555222 
       C92.885864,151.802704 90.095688,140.953094 87.159103,130.146011 
   z"
      />
      <path
        fill="#231F21"
        opacity="1.000000"
        stroke="none"
        d="
   M103.900291,199.834030 
       C86.276421,198.604568 68.631546,197.394409 51.665497,191.954697 
       C45.112949,189.853790 38.557911,187.564102 32.350506,184.627899 
       C22.232517,179.841949 19.841656,173.342056 22.119011,162.407440 
       C24.028255,153.240311 30.210011,148.690628 38.181873,146.101242 
       C49.447884,142.441895 60.902393,139.362869 72.611076,136.427216 
       C65.794182,144.893280 58.642536,152.967896 50.571877,162.080139 
       C58.795044,162.080139 65.418800,161.843170 72.008072,162.215927 
       C74.151115,162.337143 76.839874,163.516251 78.193863,165.124863 
       C86.324959,174.785248 94.197350,184.666641 101.995132,194.600586 
       C103.084526,195.988419 103.287163,198.072342 103.900291,199.834030 
   z"
      />
      <path
        fill="#232022"
        opacity="1.000000"
        stroke="none"
        d="
   M106.153786,194.996567 
       C106.527161,194.997513 106.900536,194.998459 107.704605,195.286713 
       C109.123535,194.863937 110.309273,194.320145 111.069199,193.417786 
       C118.026833,185.156067 125.307732,177.121887 131.661591,168.413727 
       C135.271072,163.466797 139.203171,161.742462 145.031738,162.209824 
       C150.476273,162.646393 155.983353,162.303177 162.526810,162.303177 
       C154.538452,153.399490 147.542618,145.602066 140.081711,137.286270 
       C141.782150,137.076019 142.426987,136.996292 143.071838,136.916565 
       C155.054947,140.601730 167.055084,144.232620 179.015961,147.988556 
       C189.296509,151.216797 191.003281,161.865753 190.796555,170.625870 
       C190.512283,182.673233 180.454590,184.427139 172.597046,187.839005 
       C158.727341,193.861420 144.011673,197.063705 128.873245,197.987076 
       C121.962753,198.408569 115.039993,198.628845 107.605713,198.551086 
       C106.777016,197.106720 106.465401,196.051651 106.153786,194.996567 
   z"
      />
      <path
        fill="#2C7DF1"
        opacity="1.000000"
        stroke="none"
        d="
   M105.982788,194.658752 
       C106.465401,196.051651 106.777016,197.106720 107.153976,198.495193 
       C106.351601,199.204178 105.483871,199.579758 104.258224,199.894684 
       C103.287163,198.072342 103.084526,195.988419 101.995132,194.600586 
       C94.197350,184.666641 86.324959,174.785248 78.193863,165.124863 
       C76.839874,163.516251 74.151115,162.337143 72.008072,162.215927 
       C65.418800,161.843170 58.795044,162.080139 50.571877,162.080139 
       C58.642536,152.967896 65.794182,144.893280 73.011719,136.488464 
       C77.618912,134.345840 82.160210,132.533386 86.930298,130.433472 
       C90.095688,140.953094 92.885864,151.802704 96.010269,162.555222 
       C99.101868,173.194809 102.530815,183.736359 105.982788,194.658752 
   z"
      />
      <path
        fill="#2C7DF1"
        opacity="1.000000"
        stroke="none"
        d="
   M142.879135,136.596436 
       C142.426987,136.996292 141.782150,137.076019 140.081711,137.286270 
       C147.542618,145.602066 154.538452,153.399490 162.526810,162.303177 
       C155.983353,162.303177 150.476273,162.646393 145.031738,162.209824 
       C139.203171,161.742462 135.271072,163.466797 131.661591,168.413727 
       C125.307732,177.121887 118.026833,185.156067 111.069199,193.417786 
       C110.309273,194.320145 109.123535,194.863937 107.984634,195.287109 
       C113.552200,173.594009 119.270439,152.187836 125.074173,130.386826 
       C131.001923,132.086746 136.844177,134.181519 142.879135,136.596436 
   z"
      />
    </svg>
  );

  const passiveSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M136.000000,210.000000 
   C90.666672,210.000000 45.833340,210.000000 1.000006,210.000000 
   C1.000004,140.333344 1.000004,70.666679 1.000002,1.000015 
   C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
   C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
   C185.500000,210.000000 161.000000,210.000000 136.000000,210.000000 
M157.479874,193.003052 
   C162.615005,191.317184 168.019104,190.179230 172.823929,187.820496 
   C180.554230,184.025589 190.197662,182.336273 190.974380,170.451538 
   C191.456406,163.075836 188.597946,153.109680 181.900101,149.554230 
   C176.609741,146.745926 170.758148,144.900574 165.019638,143.049744 
   C151.829208,138.795456 138.555740,134.798660 125.262703,130.680847 
   C125.799385,128.247635 126.135597,126.723305 126.452400,125.287010 
   C146.840591,123.693535 164.867661,117.437149 171.627548,107.820999 
   C169.733902,106.336357 167.249985,105.017189 165.651382,102.997330 
   C162.821777,99.422050 160.077789,95.640564 158.095322,91.558159 
   C150.955154,76.854729 151.506653,60.561939 149.060120,44.901367 
   C146.800888,30.439814 143.248779,22.564146 127.231750,19.088171 
   C124.430840,18.480326 121.671860,17.536362 119.015488,16.449505 
   C116.794525,15.540792 114.828110,13.417494 112.615036,13.200960 
   C107.456627,12.696246 102.150131,12.471737 97.024094,13.106531 
   C83.053551,14.836603 72.604279,21.517487 66.833580,35.032158 
   C60.909935,48.905048 60.705021,63.822803 58.082813,78.287926 
   C55.850071,90.604630 52.291988,101.957397 41.001152,109.739891 
   C42.882473,111.261436 44.605766,112.767921 46.441792,114.121490 
   C57.088314,121.970375 69.555054,124.021240 82.258400,124.888672 
   C86.358940,125.168678 87.698067,126.502106 86.600586,130.832016 
   C80.815681,132.838684 74.833260,135.020950 68.781548,136.990295 
   C56.957699,140.838013 44.979820,144.245605 33.284431,148.444595 
   C23.611322,151.917557 22.358957,160.307938 21.829077,169.418655 
   C21.317577,178.213303 26.652708,181.916260 33.103420,184.982971 
   C51.611904,193.782059 71.404762,197.572495 91.664009,198.891724 
   C101.331390,199.521240 111.146278,199.746323 120.763725,198.811172 
   C132.799545,197.640900 144.701035,195.089050 157.479874,193.003052 
z"
      />
      <path
        fill="#929497"
        opacity="1.000000"
        stroke="none"
        d="
M157.070190,193.068314 
   C144.701035,195.089050 132.799545,197.640900 120.763725,198.811172 
   C111.146278,199.746323 101.331390,199.521240 91.664009,198.891724 
   C71.404762,197.572495 51.611904,193.782059 33.103420,184.982971 
   C26.652708,181.916260 21.317577,178.213303 21.829077,169.418655 
   C22.358957,160.307938 23.611322,151.917557 33.284431,148.444595 
   C44.979820,144.245605 56.957699,140.838013 68.781548,136.990295 
   C74.833260,135.020950 80.815681,132.838684 86.600586,130.832016 
   C87.698067,126.502106 86.358940,125.168678 82.258400,124.888672 
   C69.555054,124.021240 57.088314,121.970375 46.441792,114.121490 
   C44.605766,112.767921 42.882473,111.261436 41.001152,109.739891 
   C52.291988,101.957397 55.850071,90.604630 58.082813,78.287926 
   C60.705021,63.822803 60.909935,48.905048 66.833580,35.032158 
   C72.604279,21.517487 83.053551,14.836603 97.024094,13.106531 
   C102.150131,12.471737 107.456627,12.696246 112.615036,13.200960 
   C114.828110,13.417494 116.794525,15.540792 119.015488,16.449505 
   C121.671860,17.536362 124.430840,18.480326 127.231750,19.088171 
   C143.248779,22.564146 146.800888,30.439814 149.060120,44.901367 
   C151.506653,60.561939 150.955154,76.854729 158.095322,91.558159 
   C160.077789,95.640564 162.821777,99.422050 165.651382,102.997330 
   C167.249985,105.017189 169.733902,106.336357 171.627548,107.820999 
   C164.867661,117.437149 146.840591,123.693535 126.452400,125.287010 
   C126.135597,126.723305 125.799385,128.247635 125.262703,130.680847 
   C138.555740,134.798660 151.829208,138.795456 165.019638,143.049744 
   C170.758148,144.900574 176.609741,146.745926 181.900101,149.554230 
   C188.597946,153.109680 191.456406,163.075836 190.974380,170.451538 
   C190.197662,182.336273 180.554230,184.025589 172.823929,187.820496 
   C168.019104,190.179230 162.615005,191.317184 157.070190,193.068314 
z"
      />
    </svg>
  );

  return (
    <SvgContainer
      className={className}
      active={active}
      SvgPassive={passiveSvg}
      SvgActive={activeSvg}
      onClick={onClick}
    />
  );
};

export default DirectorFemale;
