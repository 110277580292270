import { useState } from "react";
import { IconProps } from "./IconProps";
import SvgContainer from "./SvgContainer";

const AccountHolderFemale = ({ className, active, onClick }: IconProps) => {
  const activeSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
  M136.000000,210.000000 
     C90.666672,210.000000 45.833340,210.000000 1.000006,210.000000 
     C1.000004,140.333344 1.000004,70.666679 1.000002,1.000015 
     C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
     C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
     C185.500000,210.000000 161.000000,210.000000 136.000000,210.000000 
  M150.960663,151.504883 
     C150.960663,151.504883 150.984131,151.884216 150.078140,152.127274 
     C145.426773,152.137939 140.754272,151.905212 136.136826,152.306305 
     C134.735077,152.428055 132.543762,154.152328 132.347534,155.399597 
     C131.817505,158.768875 132.166107,162.276367 132.166107,166.030655 
     C135.305008,166.501755 138.016968,166.908783 140.728928,167.315796 
     C140.716080,167.591751 140.703247,167.867722 140.690399,168.143677 
     C138.220642,168.143677 135.750870,168.143677 133.307281,168.143677 
     C133.307281,176.065475 133.307281,183.450165 133.307281,190.790054 
     C143.810074,190.790054 153.997726,190.790054 164.220581,190.790054 
     C163.428314,184.826904 162.692719,179.290344 162.084778,174.714569 
     C162.964722,171.769073 163.697189,169.317215 164.687057,166.003723 
     C160.840042,166.003723 158.306152,166.003723 155.772263,166.003723 
     C155.795761,165.525620 155.819244,165.047501 155.842728,164.569397 
     C158.833710,164.722443 163.776352,163.913864 164.421295,165.222809 
     C165.897278,168.218399 167.884735,167.591919 170.089767,168.625610 
     C169.446747,169.385574 168.745880,170.104279 168.169739,170.911942 
     C163.115570,177.997269 163.727158,187.324554 169.609680,193.234375 
     C175.221313,198.872040 184.558090,199.769928 191.087128,195.299789 
     C197.833466,190.680862 200.342728,182.119934 197.148209,174.620987 
     C193.786438,166.729401 185.728256,162.787689 177.068176,165.032150 
     C174.594666,165.673233 172.289993,166.965759 169.333298,167.354126 
     C173.285187,163.171738 178.173431,161.807785 183.727570,162.762177 
     C186.560120,163.248886 188.989929,163.154465 188.766495,159.475983 
     C188.581390,156.428757 190.575699,151.924805 184.436005,152.118149 
     C179.288971,152.280243 174.130783,152.088089 169.255066,151.254440 
     C169.595032,148.997787 169.934998,146.741135 170.350998,143.979782 
     C164.880722,143.979782 160.419312,143.972397 155.957932,143.981613 
     C149.939758,143.994064 149.196457,145.066925 150.960663,151.504883 
  M128.087433,156.830109 
     C127.728645,153.060043 127.228516,149.295746 127.074646,145.517334 
     C127.004631,143.798050 127.099815,141.682846 128.019501,140.384201 
     C130.973938,136.212418 134.347733,132.337616 137.880280,127.950165 
     C139.689865,132.570175 141.019577,135.964996 142.670898,140.180954 
     C151.806580,126.165192 156.482986,111.896263 157.806992,96.855148 
     C159.059555,82.625580 158.415848,68.248024 153.933014,54.445259 
     C150.959076,45.288452 148.541351,35.791508 141.830750,28.344242 
     C127.833313,12.810216 103.355644,10.723060 85.848671,22.595926 
     C82.389359,24.941959 78.856094,27.476601 74.975967,28.840433 
     C67.664520,31.410351 62.162983,36.092316 58.048683,42.265762 
     C50.377987,53.775532 48.251804,67.179367 47.215969,80.508873 
     C46.452198,90.337318 47.318142,100.347244 48.101753,110.225235 
     C48.843258,119.572441 52.947464,127.216866 62.156616,131.998886 
     C62.156616,129.839539 62.156616,128.116928 62.156616,126.197311 
     C64.420288,127.897110 66.523674,129.177322 68.254028,130.844650 
     C70.983582,133.474762 73.995392,136.040222 75.930649,139.216232 
     C79.378517,144.874680 75.613144,149.025131 71.174339,153.011810 
     C66.842682,154.343430 62.555813,155.850372 58.170185,156.970734 
     C48.558865,159.426071 38.854004,161.523285 29.274435,164.091614 
     C24.296732,165.426147 20.282269,170.923553 20.002514,175.733765 
     C19.529449,183.867813 20.259163,185.854065 27.609180,188.768738 
     C46.510456,196.264069 66.465431,198.772949 86.582779,199.912445 
     C95.601021,200.423248 104.689346,200.349930 113.715080,199.909927 
     C124.977005,199.360901 136.207169,198.160248 147.451035,197.240921 
     C147.428406,196.827591 147.405762,196.414261 147.383118,196.000931 
     C143.135361,196.000931 138.881912,195.864151 134.641495,196.040466 
     C130.619385,196.207672 129.115662,194.648743 129.060104,190.507202 
     C128.913040,179.544601 128.333603,168.587814 128.087433,156.830109 
  z"
      />
      <path
        fill="#231F20"
        opacity="1.000000"
        stroke="none"
        d="
  M71.925140,152.927048 
     C75.613144,149.025131 79.378517,144.874680 75.930649,139.216232 
     C73.995392,136.040222 70.983582,133.474762 68.254028,130.844650 
     C66.523674,129.177322 64.420288,127.897110 62.156616,126.197311 
     C62.156616,128.116928 62.156616,129.839539 62.156616,131.998886 
     C52.947464,127.216866 48.843258,119.572441 48.101753,110.225235 
     C47.318142,100.347244 46.452198,90.337318 47.215969,80.508873 
     C48.251804,67.179367 50.377987,53.775532 58.048683,42.265762 
     C62.162983,36.092316 67.664520,31.410351 74.975967,28.840433 
     C78.856094,27.476601 82.389359,24.941959 85.848671,22.595926 
     C103.355644,10.723060 127.833313,12.810216 141.830750,28.344242 
     C148.541351,35.791508 150.959076,45.288452 153.933014,54.445259 
     C158.415848,68.248024 159.059555,82.625580 157.806992,96.855148 
     C156.482986,111.896263 151.806580,126.165192 142.670898,140.180954 
     C141.019577,135.964996 139.689865,132.570175 137.880280,127.950165 
     C134.347733,132.337616 130.973938,136.212418 128.019501,140.384201 
     C127.099815,141.682846 127.004631,143.798050 127.074646,145.517334 
     C127.228516,149.295746 127.728645,153.060043 127.644577,157.347717 
     C107.745743,167.753616 89.287979,166.468369 71.925140,152.927048 
  z"
      />
      <path
        fill="#2C7EF3"
        opacity="1.000000"
        stroke="none"
        d="
  M71.549744,152.969421 
     C89.287979,166.468369 107.745743,167.753616 127.563972,157.747009 
     C128.333603,168.587814 128.913040,179.544601 129.060104,190.507202 
     C129.115662,194.648743 130.619385,196.207672 134.641495,196.040466 
     C138.881912,195.864151 143.135361,196.000931 147.383118,196.000931 
     C147.405762,196.414261 147.428406,196.827591 147.451035,197.240921 
     C136.207169,198.160248 124.977005,199.360901 113.715080,199.909927 
     C104.689346,200.349930 95.601021,200.423248 86.582779,199.912445 
     C66.465431,198.772949 46.510456,196.264069 27.609180,188.768738 
     C20.259163,185.854065 19.529449,183.867813 20.002514,175.733765 
     C20.282269,170.923553 24.296732,165.426147 29.274435,164.091614 
     C38.854004,161.523285 48.558865,159.426071 58.170185,156.970734 
     C62.555813,155.850372 66.842682,154.343430 71.549744,152.969421 
  z"
      />
      <path
        fill="#252122"
        opacity="1.000000"
        stroke="none"
        d="
  M169.958282,167.912460 
     C167.884735,167.591919 165.897278,168.218399 164.421295,165.222809 
     C163.776352,163.913864 158.833710,164.722443 155.842728,164.569397 
     C155.819244,165.047501 155.795761,165.525620 155.772263,166.003723 
     C158.306152,166.003723 160.840042,166.003723 164.687057,166.003723 
     C163.697189,169.317215 162.964722,171.769073 162.084778,174.714569 
     C162.692719,179.290344 163.428314,184.826904 164.220581,190.790054 
     C153.997726,190.790054 143.810074,190.790054 133.307281,190.790054 
     C133.307281,183.450165 133.307281,176.065475 133.307281,168.143677 
     C135.750870,168.143677 138.220642,168.143677 140.690399,168.143677 
     C140.703247,167.867722 140.716080,167.591751 140.728928,167.315796 
     C138.016968,166.908783 135.305008,166.501755 132.166107,166.030655 
     C132.166107,162.276367 131.817505,158.768875 132.347534,155.399597 
     C132.543762,154.152328 134.735077,152.428055 136.136826,152.306305 
     C140.754272,151.905212 145.426773,152.137939 150.996460,152.064835 
     C156.957809,152.055954 162.000870,152.109512 167.190750,152.376694 
     C167.884232,152.411133 168.430878,152.231934 168.977524,152.052765 
     C174.130783,152.088089 179.288971,152.280243 184.436005,152.118149 
     C190.575699,151.924805 188.581390,156.428757 188.766495,159.475983 
     C188.989929,163.154465 186.560120,163.248886 183.727570,162.762177 
     C178.173431,161.807785 173.285187,163.171738 169.658157,167.671722 
     C169.983017,167.989304 169.958282,167.912460 169.958282,167.912460 
  M155.634903,167.114853 
     C154.149261,167.781998 152.663620,168.449142 150.754623,169.306412 
     C152.399475,169.926910 153.650894,170.078201 154.381363,170.775345 
     C154.973053,171.340057 154.739471,172.691650 155.239136,173.453339 
     C155.705246,174.163864 156.655273,174.556931 157.721207,175.327499 
     C157.206741,172.219910 156.822266,169.897476 155.634903,167.114853 
  M148.173691,169.978104 
     C147.848709,168.989166 147.523727,168.000229 147.198746,167.011292 
     C146.251709,167.640625 145.304672,168.269943 144.357620,168.899277 
     C145.412949,169.250946 146.468262,169.602615 148.173691,169.978104 
  z"
      />
      <path
        fill="#08843D"
        opacity="1.000000"
        stroke="none"
        d="
  M169.945450,167.974426 
     C172.289993,166.965759 174.594666,165.673233 177.068176,165.032150 
     C185.728256,162.787689 193.786438,166.729401 197.148209,174.620987 
     C200.342728,182.119934 197.833466,190.680862 191.087128,195.299789 
     C184.558090,199.769928 175.221313,198.872040 169.609680,193.234375 
     C163.727158,187.324554 163.115570,177.997269 168.169739,170.911942 
     C168.745880,170.104279 169.446747,169.385574 170.024017,168.269043 
     C169.958282,167.912460 169.983017,167.989304 169.945450,167.974426 
  M176.525314,184.149323 
     C173.374146,184.529129 173.199203,186.694702 175.051453,188.158371 
     C177.191574,189.849518 180.394272,191.814117 182.634827,191.332108 
     C185.178345,190.784927 188.253632,187.988785 188.995010,185.535034 
     C189.448273,184.034851 186.031006,181.263016 184.188461,179.209061 
     C183.049683,177.939636 181.603668,176.945816 180.295593,175.828262 
     C180.608398,175.509338 180.921188,175.190399 181.233994,174.871475 
     C182.717560,175.528763 184.180023,176.585953 185.694016,176.665756 
     C186.395630,176.702744 187.197586,174.836349 187.954056,173.832855 
     C185.683823,172.680984 183.237885,170.364029 181.188019,170.673187 
     C178.645538,171.056641 175.848648,173.226288 174.375885,175.434280 
     C173.825348,176.259628 176.537247,179.489380 178.064972,181.377365 
     C178.841446,182.336914 180.645340,182.500076 181.341705,183.480164 
     C182.115173,184.568680 182.156021,186.177750 182.515839,187.560181 
     C182.166367,187.603241 181.816895,187.646301 181.467438,187.689377 
     C180.073410,186.510498 178.679398,185.331619 176.525314,184.149323 
  z"
      />
      <path
        fill="#2C2829"
        opacity="1.000000"
        stroke="none"
        d="
  M169.116302,151.653595 
     C168.430878,152.231934 167.884232,152.411133 167.158417,151.993408 
     C163.780350,147.290741 158.330276,146.833130 154.502457,150.133041 
     C153.665634,150.854462 152.172607,150.814667 150.981796,151.125427 
     C149.196457,145.066925 149.939758,143.994064 155.957932,143.981613 
     C160.419312,143.972397 164.880722,143.979782 170.350998,143.979782 
     C169.934998,146.741135 169.595032,148.997787 169.116302,151.653595 
  z"
      />
      <path
        fill="#E9E9E9"
        opacity="1.000000"
        stroke="none"
        d="
  M150.971222,151.315155 
     C152.172607,150.814667 153.665634,150.854462 154.502457,150.133041 
     C158.330276,146.833130 163.780350,147.290741 167.011581,151.779785 
     C162.000870,152.109512 156.957809,152.055954 151.449448,151.943298 
     C150.984131,151.884216 150.960663,151.504883 150.971222,151.315155 
  z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
  M156.036346,167.344955 
     C156.822266,169.897476 157.206741,172.219910 157.721207,175.327499 
     C156.655273,174.556931 155.705246,174.163864 155.239136,173.453339 
     C154.739471,172.691650 154.973053,171.340057 154.381363,170.775345 
     C153.650894,170.078201 152.399475,169.926910 150.754623,169.306412 
     C152.663620,168.449142 154.149261,167.781998 156.036346,167.344955 
  z"
      />
      <path
        fill="#2C2829"
        opacity="1.000000"
        stroke="none"
        d="
  M147.848633,169.966187 
     C146.468262,169.602615 145.412949,169.250946 144.357620,168.899277 
     C145.304672,168.269943 146.251709,167.640625 147.198746,167.011292 
     C147.523727,168.000229 147.848709,168.989166 147.848633,169.966187 
  z"
      />
      <path
        fill="#EEF6F1"
        opacity="1.000000"
        stroke="none"
        d="
  M176.905334,184.151031 
     C178.679398,185.331619 180.073410,186.510498 181.467438,187.689377 
     C181.816895,187.646301 182.166367,187.603241 182.515839,187.560181 
     C182.156021,186.177750 182.115173,184.568680 181.341705,183.480164 
     C180.645340,182.500076 178.841446,182.336914 178.064972,181.377365 
     C176.537247,179.489380 173.825348,176.259628 174.375885,175.434280 
     C175.848648,173.226288 178.645538,171.056641 181.188019,170.673187 
     C183.237885,170.364029 185.683823,172.680984 187.954041,173.832855 
     C187.197586,174.836349 186.395630,176.702744 185.694016,176.665756 
     C184.180023,176.585953 182.717560,175.528763 181.233994,174.871475 
     C180.921188,175.190399 180.608398,175.509338 180.295593,175.828262 
     C181.603668,176.945816 183.049683,177.939636 184.188461,179.209061 
     C186.031006,181.263016 189.448273,184.034851 188.995010,185.535034 
     C188.253632,187.988785 185.178345,190.784927 182.634827,191.332108 
     C180.394272,191.814117 177.191574,189.849518 175.051453,188.158371 
     C173.199203,186.694702 173.374146,184.529129 176.905334,184.151031 
  z"
      />
    </svg>
  );

  const passiveSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
  M135.000000,210.000000
    C90.000000,210.000000 45.500004,210.000000 1.000006,210.000000
    C1.000004,140.333344 1.000004,70.666679 1.000002,1.000015
    C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005
    C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969
    C185.166672,210.000000 160.333328,210.000000 135.000000,210.000000
  M74.399002,199.000549
    C82.129738,199.541245 89.893318,200.812393 97.584190,200.466553
    C112.393295,199.800659 127.158264,198.181198 141.947769,197.037750
    C147.878647,196.579224 153.851959,196.563446 159.755234,195.896973
    C162.873749,195.544922 165.231903,194.946671 168.291199,197.387558
    C175.393036,203.053879 187.162491,202.305466 193.354935,195.961334
    C199.658813,189.503036 202.922165,179.659424 196.744659,171.331131
    C192.351379,165.408279 191.789505,159.649048 191.973038,153.113510
    C191.999268,152.179657 191.717377,151.237167 191.519928,149.906799
    C184.684052,149.906799 178.080841,149.906799 171.559937,149.906799
    C170.343414,142.000137 170.343414,142.000122 162.432938,142.000000
    C159.933197,141.999969 157.433472,141.999908 154.933731,142.000031
    C146.892776,142.000397 146.892776,142.000412 145.298737,149.999954
    C140.749847,149.999954 136.110626,150.016937 131.471558,149.995575
    C125.902000,149.969910 123.380226,146.882767 125.875145,141.763885
    C128.222244,136.948288 131.997528,132.828781 135.818359,127.455299
    C137.890121,132.457123 139.267395,135.782272 141.045258,140.074539
    C142.037445,138.610580 142.434784,138.073746 142.781219,137.505859
    C151.995682,122.401497 156.194855,105.735703 156.867691,88.305603
    C157.463669,72.867088 153.842041,57.979729 148.757111,43.417164
    C139.483246,16.858047 111.127075,8.351043 88.672508,20.128805
    C82.991577,23.108541 77.658096,26.798927 71.846359,29.469389
    C63.725300,33.200966 57.226051,38.458252 53.726387,46.737701
    C44.264961,69.121437 42.476238,92.312759 47.600044,115.954010
    C48.949139,122.178734 52.105450,127.801643 58.633945,130.668961
    C59.363243,129.051559 59.945503,127.760262 60.827217,125.804855
    C64.980209,129.793533 68.980484,133.496231 72.818626,137.360001
    C76.867790,141.436218 76.694649,145.543594 72.716827,149.812225
    C66.961342,155.988480 58.986176,156.176712 51.560284,158.071472
    C42.388969,160.411606 33.163898,162.781204 24.327065,166.107712
    C18.425762,168.329193 18.046854,174.349548 17.957176,180.013535
    C17.877558,185.042099 21.533104,187.014725 25.089554,188.444489
    C40.596840,194.678650 56.933189,197.363144 74.399002,199.000549
  z"
      />
      <path
        fill="#929497"
        opacity="1.000000"
        stroke="none"
        d="
  M73.936226,199.000305
    C56.933189,197.363144 40.596840,194.678650 25.089554,188.444489
    C21.533104,187.014725 17.877558,185.042099 17.957176,180.013535
    C18.046854,174.349548 18.425762,168.329193 24.327065,166.107712
    C33.163898,162.781204 42.388969,160.411606 51.560284,158.071472
    C58.986176,156.176712 66.961342,155.988480 72.716827,149.812225
    C76.694649,145.543594 76.867790,141.436218 72.818626,137.360001
    C68.980484,133.496231 64.980209,129.793533 60.827217,125.804855
    C59.945503,127.760262 59.363243,129.051559 58.633945,130.668961
    C52.105450,127.801643 48.949139,122.178734 47.600044,115.954010
    C42.476238,92.312759 44.264961,69.121437 53.726387,46.737701
    C57.226051,38.458252 63.725300,33.200966 71.846359,29.469389
    C77.658096,26.798927 82.991577,23.108541 88.672508,20.128805
    C111.127075,8.351043 139.483246,16.858047 148.757111,43.417164
    C153.842041,57.979729 157.463669,72.867088 156.867691,88.305603
    C156.194855,105.735703 151.995682,122.401497 142.781219,137.505859
    C142.434784,138.073746 142.037445,138.610580 141.045258,140.074539
    C139.267395,135.782272 137.890121,132.457123 135.818359,127.455299
    C131.997528,132.828781 128.222244,136.948288 125.875145,141.763885
    C123.380226,146.882767 125.902000,149.969910 131.471558,149.995575
    C136.110626,150.016937 140.749847,149.999954 145.298737,149.999954
    C146.892776,142.000412 146.892776,142.000397 154.933731,142.000031
    C157.433472,141.999908 159.933197,141.999969 162.432938,142.000000
    C170.343414,142.000122 170.343414,142.000137 171.559937,149.906799
    C178.080841,149.906799 184.684052,149.906799 191.519928,149.906799
    C191.717377,151.237167 191.999268,152.179657 191.973038,153.113510
    C191.789505,159.649048 192.351379,165.408279 196.744659,171.331131
    C202.922165,179.659424 199.658813,189.503036 193.354935,195.961334
    C187.162491,202.305466 175.393036,203.053879 168.291199,197.387558
    C165.231903,194.946671 162.873749,195.544922 159.755234,195.896973
    C153.851959,196.563446 147.878647,196.579224 141.947769,197.037750
    C127.158264,198.181198 112.393295,199.800659 97.584190,200.466553
    C89.893318,200.812393 82.129738,199.541245 73.936226,199.000305
  z"
      />
    </svg>
  );

  return (
    <SvgContainer
      className={className}
      active={active}
      SvgPassive={passiveSvg}
      SvgActive={activeSvg}
      onClick={onClick}
    />
  );
};

export default AccountHolderFemale;
