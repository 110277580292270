// Bootstrap Libraries to be used
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@patternfly/react-core/dist/styles/base.css";
import "./tailwind.css";
// React
import React from "react";
import ReactDOM from "react-dom/client";
// Provider used for Storage and APIs
import { Provider } from "react-redux";
// BrowserRouter used for Routing
import { BrowserRouter } from "react-router-dom";
// Styles
import "./index.scss";
// Main App Contianer
import App from "./App";
import { store } from "./stores";
// Toaster Notifications
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "components/ScrollToTop";

// Initalize the Root Element
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  // <React.StrictMode> - This renders components twice on the page for error detection, will need to turn this back on for production
  // todo, this should only have the App and React.StrictMode component really, and the rest should be in the routes
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer />
      <App />
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);
