// todo: Put this in the types folder
interface DocumentContainerProps {
  blobData: Blob | null;
  setBlobData: React.Dispatch<React.SetStateAction<Blob | null>>;
}

/**
 * We will be able to use this compnent to embed the blob data into the page for different steps of the process
 * @param param0
 * @returns
 */
const DocumentContainer = ({ blobData, setBlobData }: DocumentContainerProps) => {
  return (
    <div className="document-container">
      <div className="document-container-header">
        <p className="document-container-header-text">Signed Risk Tolerance</p>
        <button onClick={() => setBlobData(null)} className="btn btn-danger">
          Close
        </button>
      </div>
      <embed
        src={blobData ? URL.createObjectURL(blobData) : ""}
        type="application/pdf"
        className="embedded-document"
      ></embed>
    </div>
  );
};

export default DocumentContainer;
