import { ParticipantCountrySelectProps } from "features/participant/types";
import { CountryDropdown } from "react-country-region-selector";

const KYCCountrySelect = ({
  label,
  value,
  disabled = false,
  onChange,
}: ParticipantCountrySelectProps) => {
  return (
    <div className="kyc-label-input-container">
      {label && (
        <div className="kyc-label-container">
          <label>{label}</label>
        </div>
      )}
      <div className="kyc-input-container">
        <div className="input-container">
          <CountryDropdown
            priorityOptions={["CA", "US"]}
            value={value ? value.toString() : ""}
            onChange={(e) => onChange?.(e)}
            disabled={disabled}
            labelType="full"
          />
          <div className="underline"></div>
        </div>
      </div>
    </div>
  );
};

export default KYCCountrySelect;
