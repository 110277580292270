import { useState } from "react";
import { IconProps } from "./IconProps";

const SvgContainer = ({ active, className, onClick, SvgActive, SvgPassive }: IconProps) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {active || hover ? SvgActive : SvgPassive}
    </div>
  );
};

export default SvgContainer;
