import WorkflowStatus from "../../WorkflowStatus";
import { ParticipantDetailsSectionProps } from "features/participant/types";
import {
  useCheckStatusOfSIAPackageMutation,
  useSendSIAPackageForSignatureMutation,
} from "apis/mainApi";
import { useState } from "react";
import { notify } from "utils";
const SIAPackageSentToParticipant = ({ participant }: ParticipantDetailsSectionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const completedPreviousTask = participant?.accountHolderWorkflow?.ipsCompleted;
  const completedTask =
    participant?.accountHolderWorkflow?.siaPackageSentToParticipantForCompletion;
  const completedText = "Package Has Been Sent";
  const notCompletedText = "Package Has Not Been Sent";
  const completeTooltipText = "";
  const notCompleteTooltipText = "Click to Send SIA Package";
  const [sendPackgage] = useSendSIAPackageForSignatureMutation();
  const [checkSignatureStatus] = useCheckStatusOfSIAPackageMutation();
  const actionHandler = async () => {
    setIsLoading(true);
    try {
      await sendPackgage(participant.id);
      await checkSignatureStatus(participant.id);
      notify("SIA Package has been successfully sent to the Participant");
    } catch (e) {
      notify("An Error Has Occurred With Sending the SIA Package");
    }

    setIsLoading(false);
  };

  // If the Previous Task is completed, then we display content with actions
  return (
    <WorkflowStatus
      completedPreviousTask={completedPreviousTask}
      completedTask={completedTask}
      completeTooltipText={completeTooltipText}
      notCompleteTooltipText={notCompleteTooltipText}
      completedText={completedText}
      notCompletedText={notCompletedText}
      completeActionHander={actionHandler}
      notCompleteActionHandler={actionHandler}
      completeIconClass="bi-file-earmark-check-fill"
      notCompleteIconClass="bi-file-text-fill"
      isLoading={isLoading}
    />
  );
};

export default SIAPackageSentToParticipant;
