import { configureStore } from "@reduxjs/toolkit";
import { authSliceReducer } from "./Redux/userAuthSlice";
import { advisorSliceReducer } from "./Redux/advisorSlice";
import { householdSliceReducer } from "./Redux/householdSlice";
import {
  authApi,
  userApi,
  clientApi,
  advisorApi,
  newClientApi,
  householdApi,
  riskToleranceApi,
  investmentAccountApi,
  ipsApi,
  documentApi,
  bankAccountApi,
} from "apis";
import { newClientRiskSliceReducer } from "./Redux/newClientRiskSlice";

const store = configureStore({
  reducer: {
    userAuthStore: authSliceReducer,
    advisorStore: advisorSliceReducer,
    householdStore: householdSliceReducer,
    newClientRiskStore: newClientRiskSliceReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [advisorApi.reducerPath]: advisorApi.reducer,
    [newClientApi.reducerPath]: newClientApi.reducer,
    [householdApi.reducerPath]: householdApi.reducer,
    [riskToleranceApi.reducerPath]: riskToleranceApi.reducer,
    [investmentAccountApi.reducerPath]: investmentAccountApi.reducer,
    [ipsApi.reducerPath]: ipsApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [bankAccountApi.reducerPath]: bankAccountApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authApi.middleware)
      .concat(userApi.middleware)
      .concat(clientApi.middleware)
      .concat(advisorApi.middleware)
      .concat(newClientApi.middleware)
      .concat(householdApi.middleware)
      .concat(riskToleranceApi.middleware)
      .concat(investmentAccountApi.middleware)
      .concat(ipsApi.middleware)
      .concat(documentApi.middleware)
      .concat(bankAccountApi.middleware),
});

// Used to get the type of the state that the store uses
export type RootState = ReturnType<typeof store.getState>;

export default store;
