import { useEffect, useState } from "react";
import { AccountSectionProps } from "../types";
import { InvestmentAccountSubType, ParticipantRole } from "constants/enums";
import { checkEmptyValue, notify } from "utils";
import { ParticipantBeneficiaryTableComponent } from "./ParticipantBeneficiaryTable";
import { CreateNewParticipantWithRole } from "./CreateNewParticipantWithRole";
import { SelectExistingParticipantWithRole } from "./SelectExistingParticipantWithRole";

/**
 * @name           AccountRESPBeneficiaries
 * @summary        This component is responsible for display and adding beneficiaries to the account if allowed.
 *                 This section will be available to RESP accounts only.
 * @param          formData the new investment account form data
 * @param          setFormData the react hook form set form data function
 * @param          participant the participant state information
 * @returns        React.Component
 */
function AccountRESPBeneficiaries({ formData, setFormData, participant }: AccountSectionProps) {
  // State for adding another beneficiary to the account
  const [canAddBeneficiary, setCanAddBeneficiary] = useState(false);
  // State for create a new participant
  const [isCreatingNewParticipant, setIsCreatingNewParticipant] = useState(false);
  // State for chooising an existing participant
  const [isChoosingExistingParticipant, setIsChoosingExistingParticipant] = useState(false);

  // Need to track if you can add a beneficiary or not depending on the type of RESP account you are opening
  useEffect(() => {
    console.log("change");
    // Tests if we can add a beneficiary
    const canAdd =
      !formData.respBeneficiaries ||
      (formData.respBeneficiaries &&
        formData.subType == InvestmentAccountSubType.INDIVIDUAL &&
        formData.respBeneficiaries.length < 1) ||
      formData.subType == InvestmentAccountSubType.FAMILY;
    console.log(formData.respBeneficiaries);
    setCanAddBeneficiary(canAdd);
  }, [formData.respBeneficiaries, formData.subType]);

  // Adding a RESP Beneficiary to the Account
  const handleBeneficiaryAdd = (participantId: string) => {
    if (checkEmptyValue(participantId)) {
      notify("Please enter valid beneficiary", "error");
    }
    // Check if the beneficiary is a spouse
    const tempData = { ...formData };
    // If there is nothing there, create an empty array with the participant id
    if (!tempData.respBeneficiaries) {
      tempData.respBeneficiaries = [{ participantId: participantId, allocation: 100 }];
    } else if (
      tempData.respBeneficiaries &&
      tempData.respBeneficiaries.find((beneficiary) => beneficiary.participantId == participantId)
    ) {
      notify("Beneficiary already added", "error");

      return;
    } else {
      // find the current allocation of the beneficiaries
      const splitAllocation = 100 / (tempData.respBeneficiaries.length + 1);

      tempData.respBeneficiaries = [
        ...tempData.respBeneficiaries,
        { participantId: participantId, allocation: 0 },
      ];

      // loop through the beneficiaries and set the allocation to the split allocation
      tempData.respBeneficiaries.forEach((beneficiary) => {
        beneficiary.allocation = splitAllocation;
      });
    }

    // Setting the new resp beneficiaries
    setFormData({ ...formData, respBeneficiaries: tempData.respBeneficiaries });

    // Resetting the state
    setIsChoosingExistingParticipant(false);
    setIsCreatingNewParticipant(false);
  };

  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>RESP Beneficiaries</h1>
        <h3>Please List All Beneficiaries For This Account</h3>
      </div>
      <ParticipantBeneficiaryTableComponent
        account={formData!}
        beneficiaryType="respBeneficiaries"
        setAccount={setFormData}
      />
      {canAddBeneficiary && !isChoosingExistingParticipant && !isCreatingNewParticipant && (
        <div className="action-section-btn-container">
          <button
            className="btn btn-primary"
            onClick={() => setIsChoosingExistingParticipant(true)}
          >
            Add Existing Participant
          </button>
          <button className="btn btn-primary" onClick={() => setIsCreatingNewParticipant(true)}>
            Create & Add New RESP Beneficiary
          </button>
        </div>
      )}
      <div className="account-section-body">
        {isCreatingNewParticipant && (
          <CreateNewParticipantWithRole
            roleName="RESP Beneficiary"
            role={ParticipantRole.BENEFICIARY}
            householdId={participant?.householdId!}
            setIsCreatingNewParticipant={setIsCreatingNewParticipant}
            handleBeneficiaryAdd={handleBeneficiaryAdd}
          />
        )}
        {isChoosingExistingParticipant && (
          <SelectExistingParticipantWithRole
            roleName="RESP Beneficiary"
            role={ParticipantRole.BENEFICIARY}
            householdId={participant?.householdId!}
            setIsChoosingExisiting={setIsChoosingExistingParticipant}
            addParticipant={handleBeneficiaryAdd}
          />
        )}
      </div>
    </div>
  );
}

export default AccountRESPBeneficiaries;
