import React, { useEffect, useState } from "react";
import { AccountSectionProps } from "../types";
import { Participant } from "types";
import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { SelectItem } from "types/Shared";
import { SIASelect } from "components/Shared";
import { notify } from "utils";

/**
 * @name           AccountEFTDetails
 * @summary        This component is responsible for displaying and adding EFT details to the account
 * @param          formData: the new investment account form data
 * @param          setFormData: the react hook form set form data function
 * @param          participant: the participant state information
 * @returns        React.Component
 */
function AccountEFTDetails({ formData, setFormData, participant, viewMode }: AccountSectionProps) {
  const [isSelectingBankAccount, setIsSelectingBankAccount] = React.useState(false);
  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>Electronic Funds Transfer Details</h1>
        <h3>Please specify all bank accounts for the EFT</h3>
      </div>
      {formData.eftBankAccounts && formData.eftBankAccounts.length > 0 && (
        <EFTDetails formData={formData} setFormData={setFormData} participant={participant} viewMode={viewMode} />
      )}

      {!isSelectingBankAccount && !viewMode && (
        <div className="action-section-btn-container">
          <button className="btn btn-primary" onClick={() => setIsSelectingBankAccount(true)}>
            Add Existing Bank Account
          </button>
        </div>
      )}
      <div className="account-section-body">
        {isSelectingBankAccount && (
          <SelectBankAccounts
            participant={participant!}
            account={formData}
            setAccount={setFormData}
            setIsChoosingExisiting={setIsSelectingBankAccount}
          />
        )}
      </div>
    </div>
  );
}

/**
 * @name            SelectBankAccounts
 * @summary         This component is responsible for selecting the bank accounts for the EFT
 * @param           participant: the participant state information
 * @param           account: the new investment account form data
 * @param           setAccount: the react hook form set form data function
 * @param           setIsChoosingExisiting: the state function to set if the user is choosing an existing bank account
 * @returns         React.Component
 */
const SelectBankAccounts = ({
  participant,
  account,
  setAccount,
  setIsChoosingExisiting,
}: {
  participant: Participant;
  account: CreateInvestmentAccountDto;
  setAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
  setIsChoosingExisiting: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [bankAccount, setBankAccount] = useState("");
  const [selectBankAccounts, setSelectBankAccounts] = useState<SelectItem[]>([]);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!bankAccount) {
      notify("Please select a bank account", "error");
      return;
    }

    const temp = { ...account };
    if (!temp.eftBankAccounts) {
      temp.eftBankAccounts = [bankAccount];
    } else {
      if (temp.eftBankAccounts.includes(bankAccount)) {
        notify("Bank account already exists", "error");
        return;
      }

      temp.eftBankAccounts.push(bankAccount);
    }

    setAccount(temp);
    setIsChoosingExisiting(false);
  };

  useEffect(() => {
    if (participant && participant.bankAccounts && participant.bankAccounts.length > 0) {
      const bankAccounts = participant.bankAccounts.map((bankAccount) => {
        return {
          value: bankAccount.id,
          label: `${bankAccount.institutionName} - ${bankAccount.accountNumber}`,
        };
      });

      setSelectBankAccounts(bankAccounts);
    }
  }, [participant]);

  return (
    <form className="existing-participant-form" onSubmit={handleSubmit}>
      <div className="existing-participant-form-header">Select Existing Bank Account</div>
      <div className="existing-participant-label-input-container">
        <SIASelect options={selectBankAccounts} value={bankAccount} onChange={(e) => setBankAccount(e)} />
      </div>
      <div className="action-btn-container">
        <button className="btn btn-warning" onClick={() => setIsChoosingExisiting(false)}>
          Cancel
        </button>
        <button className="btn btn-primary">Add Account</button>
      </div>
    </form>
  );
};

/**
 * @name            EFTDetails
 * @summary         This component is responsible for displaying the EFT details (i.e. the bank accounts the user has selected for this account)
 * @param           formData: the new investment account form data
 * @param           setFormData: the react hook form set form data function
 * @param           participant: the participant state information
 * @returns         React.Component
 */
const EFTDetails = ({ formData, setFormData, participant, viewMode }: AccountSectionProps) => {
  const handleDelete = (index: number) => {
    if (window.confirm("Are you sure you want to delete this bank account?")) {
      const tempAccount = { ...formData };
      if (tempAccount.eftBankAccounts) {
        const eftAccounts = [...tempAccount.eftBankAccounts];
        eftAccounts.splice(index, 1);
        tempAccount.eftBankAccounts = eftAccounts;
        setFormData(tempAccount);
      }
    }
  };

  return (
    <div className="participant-table-container">
      <table>
        <thead>
          <tr>
            <th>Account Name</th>
            <th>Institution Name</th>
            <th>Account Number</th>
            <th>Institution Number</th>
            <th>Transit Number</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {formData.eftBankAccounts?.map((bankAccount, index) => {
            const account = participant?.bankAccounts.find((account) => account.id === bankAccount);
            if (account) {
              return (
                <tr key={index}>
                  <td>{account.accountName}</td>
                  <td>{account.institutionName}</td>
                  <td>{account.accountNumber}</td>
                  <td>{account.institutionNumber}</td>
                  <td>{account.transitNumber}</td>
                  <td>
                    {!viewMode && (
                      <button className="btn btn-danger" onClick={() => handleDelete(index)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    )}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AccountEFTDetails;
