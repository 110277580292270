import { Participant } from "types";
import {
  checkEmptyValue,
  isValidUSZip,
  notify,
  validateEmail,
  validatePhone,
  validatePostalCode,
} from "utils";

/**
 * Big Ass Validator for Power of Attorney Details
 * @param participant
 * @returns
 */
const ValidatePowerOfAttorney = (participant: Participant) => {
  // General Detail Validation
  if (checkEmptyValue(participant?.generalDetails?.firstName)) {
    notify("Please Enter a First Name", "error");
    return false;
  }
  if (checkEmptyValue(participant?.generalDetails?.lastName)) {
    notify("Please Enter a Last Name", "error");
    return false;
  }
  if (
    checkEmptyValue(participant?.generalDetails?.email) ||
    !validateEmail(participant?.generalDetails?.email)
  ) {
    notify("Please Enter a Email", "error");
    return false;
  }
  if (
    checkEmptyValue(participant?.generalDetails?.cellPhone) ||
    !validatePhone(participant?.generalDetails?.cellPhone)
  ) {
    notify("Please Enter a Phone Number", "error");
    return false;
  }
  if (checkEmptyValue(participant?.generalDetails?.dateOfBirth)) {
    notify("Please Enter a Date of Birth", "error");
    return false;
  }
  if (checkEmptyValue(participant?.generalDetails?.citizenship)) {
    notify("Please Select a Citizenship", "error");
    return false;
  }

  if (checkEmptyValue(participant?.generalDetails?.maritalStatus)) {
    notify("Please Select a Marital Status", "error");
    return false;
  }
  // Address Validation Function
  if (checkEmptyValue(participant?.permanentAddressDetails?.addressType)) {
    notify("Please Select an Address Type", "error");
    return false;
  }
  if (checkEmptyValue(participant?.permanentAddressDetails?.streetNumber)) {
    notify("Please Enter a Street Number", "error");
    return false;
  }
  if (checkEmptyValue(participant?.permanentAddressDetails?.streetName)) {
    notify("Please Enter a Street Name", "error");
    return false;
  }
  if (checkEmptyValue(participant?.permanentAddressDetails?.city)) {
    notify("Please Enter a City", "error");
    return false;
  }
  if (checkEmptyValue(participant?.permanentAddressDetails?.region)) {
    notify("Please Enter a Province", "error");
    return false;
  }
  if (checkEmptyValue(participant?.permanentAddressDetails?.country)) {
    notify("Please Select a Country", "error");
    return false;
  }

  if (
    participant?.permanentAddressDetails?.country === "Canada" &&
    (checkEmptyValue(participant?.permanentAddressDetails?.postalCode) ||
      !validatePostalCode(participant?.permanentAddressDetails?.postalCode))
  ) {
    notify("Please Enter a Valid Postal Code", "error");
    return false;
  }

  if (
    participant?.permanentAddressDetails?.country === "United States" &&
    (checkEmptyValue(participant?.permanentAddressDetails?.postalCode) ||
      !isValidUSZip(participant?.permanentAddressDetails?.postalCode))
  ) {
    notify("Please Enter a Valid Zip Code", "error");
    return false;
  }
  // Employment Validation Functions
  if (checkEmptyValue(participant?.employmentDetails?.status)) {
    notify("Please Select an Employment Status", "error");
    return false;
  }
  if (checkEmptyValue(participant?.employmentDetails?.occupation)) {
    notify("Please Enter an Occupation", "error");
    return false;
  }
  if (checkEmptyValue(participant?.employmentDetails?.name)) {
    notify("Please Enter an Employer Name", "error");
    return false;
  }
  if (checkEmptyValue(participant?.employmentDetails?.businessType)) {
    notify("Please Enter a Business Type", "error");
    return false;
  }
  if (checkEmptyValue(participant?.employmentDetails?.lengthInYears)) {
    notify("Please Enter a Length of Employment", "error");
    return false;
  }

  // Regulatory Detailks
  if (participant?.regulatoryDetails?.isInsider == (undefined || null)) {
    notify("Please confirm if the client is an Insider", "error");
    return false;
  }

  // Insider Verification
  if (participant?.regulatoryDetails?.isInsider) {
    if (
      participant?.regulatoryDetails?.insiderDetails == null ||
      participant?.regulatoryDetails?.insiderDetails.length == 0
    ) {
      notify("Please fill in the insider details", "error");
      return false;
    }

    for (var i = 0; i < participant?.regulatoryDetails?.insiderDetails?.length; i++) {
      if (checkEmptyValue(participant?.regulatoryDetails?.insiderDetails[i]?.symbol)) {
        notify(`Please fill in the insider symbol ${i + 1}`, "error");
        return false;
      }
      if (checkEmptyValue(participant?.regulatoryDetails?.insiderDetails[i]?.companyName)) {
        notify(`Please fill in the insider company ${i + 1}`, "error");
        return false;
      }
    }
  } else if (participant?.regulatoryDetails?.isInsider != false) {
    notify("Please confirm if the client is an Insider", "error");
    return false;
  }

  // 10% Shareholder Verification
  if (participant?.regulatoryDetails?.is10PercentShareholder) {
    if (
      participant?.regulatoryDetails?.shareholder10Details == null ||
      participant?.regulatoryDetails?.shareholder10Details.length == 0
    ) {
      notify("Please fill in the 10% Shareholder details", "error");
      return false;
    }

    for (var i = 0; i < participant?.regulatoryDetails?.shareholder10Details?.length; i++) {
      if (checkEmptyValue(participant?.regulatoryDetails?.shareholder10Details[i]?.symbol)) {
        notify(`Please fill in the 10% Shareholder symbol ${i + 1}`, "error");
        return false;
      }
      if (checkEmptyValue(participant?.regulatoryDetails?.shareholder10Details[i]?.companyName)) {
        notify(`Please fill in the 10% Shareholder company ${i + 1}`, "error");
        return false;
      }
    }
  } else if (participant?.regulatoryDetails?.is10PercentShareholder != false) {
    notify("Please confirm if the client is a 10% Shareholder", "error");
    return false;
  }

  // 20% Shareholder Verification
  if (participant?.regulatoryDetails?.is20PercentShareholder) {
    if (
      participant?.regulatoryDetails?.shareholder20Details == null ||
      participant?.regulatoryDetails?.shareholder20Details.length == 0
    ) {
      notify("Please fill in the 20% Shareholder details", "error");
      return false;
    }

    for (var i = 0; i < participant?.regulatoryDetails?.shareholder20Details?.length; i++) {
      if (checkEmptyValue(participant?.regulatoryDetails?.shareholder20Details[i]?.symbol)) {
        notify(`Please fill in the 20% Shareholder symbol ${i + 1}`, "error");
        return false;
      }
      if (checkEmptyValue(participant?.regulatoryDetails?.shareholder20Details[i]?.companyName)) {
        notify(`Please fill in the 20% Shareholder company ${i + 1}`, "error");
        return false;
      }
    }
  } else if (participant?.regulatoryDetails?.is20PercentShareholder != false) {
    notify("Please confirm if the client is a 20% Shareholder", "error");
    return false;
  }
  return true;
};

export default ValidatePowerOfAttorney;
