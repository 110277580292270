import { ParticipantInputProps } from "features/participant/types";
import { SIAInput } from "components/Shared";

/**
 * @name                    AccountInput
 * @summary                 A helper component that is used on the Create Trading Account Page to display a input value
 *                          for the form.
 * @param {string}          label: the label for the input.
 * @param {string}          value: the value of the input
 * @param {((value: string) => void) | undefined}  onChange: optional function to handle the change of the input
 * @param {boolean}        hasTooltip: boolean to check if the component has a tooltip
 * @param {string}         tooltipText: the text for the tooltip
 * @param {string}         placeholder: the placeholder for the input
 * @param {string}         type: the type of the input
 * @param {boolean}        disabled: boolean to check if the input is disabled
 * @param {boolean}        isDollar: boolean to check if the input is a dollar value
 * @returns                React.Component
 */
const AccountInput = ({
  label,
  value,
  type = "text",
  disabled = false,
  onChange,
  isDollar,
  hasTooltip,
  tooltipText,
  placeholder,
}: ParticipantInputProps) => {
  return (
    <div className="account-section-detail">
      <div className="label-container">
        <label>{label}</label>
        {hasTooltip && (
          <a data-tooltip-id="trade-account-tooltip" data-tooltip-content="Test">
            <i className="bi bi-info-circle-fill"></i>
          </a>
        )}
      </div>
      <SIAInput
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        type={type}
        isDollar={isDollar}
      />
    </div>
  );
};

export default AccountInput;
