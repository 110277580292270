import SIABoolean from "components/Shared/SIABoolean";
import { AccountBooleanProps } from "../types";

/**
 * @name          AccountBoolean
 * @summary       A helper component that is used on the Create Trading Account Page to display a boolean value
 *                for the form.
 * @param         {
 *                  label: the label for the boolean value,
 *                  value: the value of the boolean,
 *                  onChange: the function to handle the change of the boolean,
 *                  hasTooltip: boolean to check if the component has a tooltip,
 *                  tooltipText: the text for the tooltip
 *                }
 * @returns       React.Component
 */
export function AccountBoolean({
  label,
  value,
  onChange,
  hasTooltip,
  tooltipText,
  disabled,
}: AccountBooleanProps) {
  return (
    <div className="account-section-detail">
      <div className="label-container">
        <label>{label}</label>
        {hasTooltip && (
          <a data-tooltip-id="trade-account-tooltip" data-tooltip-content={tooltipText}>
            <i className="bi bi-info-circle-fill"></i>
          </a>
        )}
      </div>
      <SIABoolean value={value} onChange={(e) => onChange?.(e)} label={label} disabled={disabled} />
    </div>
  );
}

export default AccountBoolean;
