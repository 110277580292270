import React from "react";
import SvgContainer from "./SvgContainer";
import { IconProps } from "./IconProps";

function ThirdPartyFemale({ className, active, onClick }: IconProps) {
  const activeSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M136.000000,210.000000 
	C90.666672,210.000000 45.833340,210.000000 1.000006,210.000000 
	C1.000004,140.333344 1.000004,70.666679 1.000002,1.000015 
	C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
	C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
	C185.500000,210.000000 161.000000,210.000000 136.000000,210.000000 
M115.986588,199.030243 
	C115.986588,199.030243 116.004921,199.097107 116.897095,199.009171 
	C120.144478,198.672653 123.429070,198.542023 126.632668,197.963181 
	C139.050201,195.719574 151.107620,192.341049 162.292725,186.280930 
	C173.924911,179.978561 176.856491,174.794418 174.883377,161.804688 
	C174.121948,156.791916 172.659027,151.987259 168.687943,147.917938 
	C163.307739,142.404694 156.363647,140.400375 149.387314,136.899536 
	C149.680923,136.019928 149.714539,134.869461 150.306793,134.300995 
	C160.587570,124.433273 158.688370,113.838089 153.059189,102.401657 
	C150.713837,97.636734 150.058136,91.966728 149.083939,86.626213 
	C148.439102,83.091347 148.888443,79.364502 148.331161,75.804504 
	C147.055664,67.656212 145.302734,59.582485 144.035583,51.433090 
	C141.912582,37.779720 135.711212,26.668514 124.262619,18.720018 
	C115.082703,12.346608 104.957405,10.473033 93.906624,11.925248 
	C83.266609,13.323483 74.135506,17.458948 68.093063,26.354383 
	C63.039459,33.794090 58.929703,41.895817 54.670246,49.848076 
	C50.581738,57.481171 49.637733,65.694824 52.503265,73.875710 
	C55.813435,83.325981 53.621609,91.678909 48.718163,99.778954 
	C42.653851,109.796646 41.511566,119.733749 48.920158,129.713867 
	C50.626255,132.012146 51.803585,134.702927 53.225235,137.117233 
	C53.225235,137.117233 53.256332,137.182785 52.693436,137.812210 
	C48.925018,139.210403 45.164131,140.629303 41.386688,142.002686 
	C32.356033,145.285980 25.380779,150.507050 24.138178,160.836487 
	C23.782673,163.791702 24.113667,166.823074 23.981874,169.813004 
	C23.717709,175.806061 26.212227,180.252731 31.455355,183.030624 
	C36.708900,185.814056 41.924587,188.758316 47.422009,190.974335 
	C60.872162,196.396149 74.895638,199.409424 89.986664,199.364761 
	C89.675613,197.936127 89.452721,196.481415 89.039169,195.083099 
	C86.379509,186.090088 83.678116,177.109451 81.709358,168.273483 
	C95.311157,174.124466 108.675507,172.892975 121.895569,167.709427 
	C119.483948,176.990936 117.044640,186.265488 114.702248,195.564438 
	C114.460258,196.525116 114.795547,197.631210 114.857712,199.299286 
	C115.256805,199.213104 115.655899,199.126938 115.986588,199.030243 
z"
      />
      <path
        fill="#231F20"
        opacity="1.000000"
        stroke="none"
        d="
M121.881119,166.926682 
	C108.675507,172.892975 95.311157,174.124466 81.185646,167.860657 
	C77.259453,155.405548 73.856964,143.363281 70.413544,131.176132 
	C68.649239,131.822739 66.813812,132.522552 64.959839,133.169235 
	C61.053120,134.531937 57.136692,135.866791 53.224251,137.213089 
	C51.803585,134.702927 50.626255,132.012146 48.920158,129.713867 
	C41.511566,119.733749 42.653851,109.796646 48.718163,99.778954 
	C53.621609,91.678909 55.813435,83.325981 52.503265,73.875710 
	C49.637733,65.694824 50.581738,57.481171 54.670246,49.848076 
	C58.929703,41.895817 63.039459,33.794090 68.093063,26.354383 
	C74.135506,17.458948 83.266609,13.323483 93.906624,11.925248 
	C104.957405,10.473033 115.082703,12.346608 124.262619,18.720018 
	C135.711212,26.668514 141.912582,37.779720 144.035583,51.433090 
	C145.302734,59.582485 147.055664,67.656212 148.331161,75.804504 
	C148.888443,79.364502 148.439102,83.091347 149.083939,86.626213 
	C150.058136,91.966728 150.713837,97.636734 153.059189,102.401657 
	C158.688370,113.838089 160.587570,124.433273 150.306793,134.300995 
	C149.714539,134.869461 149.680923,136.019928 149.212585,137.469559 
	C148.796860,138.251740 148.555862,138.463898 148.065384,138.440369 
	C142.514435,135.789795 137.212967,133.374893 132.289886,131.132355 
	C128.637115,143.693771 125.259117,155.310226 121.881119,166.926682 
z"
      />
      <path
        fill="#231F20"
        opacity="1.000000"
        stroke="none"
        d="
M89.440956,199.759109 
	C74.895638,199.409424 60.872162,196.396149 47.422009,190.974335 
	C41.924587,188.758316 36.708900,185.814056 31.455355,183.030624 
	C26.212227,180.252731 23.717709,175.806061 23.981874,169.813004 
	C24.113667,166.823074 23.782673,163.791702 24.138178,160.836487 
	C25.380779,150.507050 32.356033,145.285980 41.386688,142.002686 
	C45.164131,140.629303 48.925018,139.210403 53.256699,137.868622 
	C52.826244,139.419815 51.994038,141.058838 50.810455,142.384079 
	C46.042755,147.722397 41.142471,152.942108 36.349674,158.258392 
	C35.331814,159.387436 34.559673,160.738022 32.952145,163.004929 
	C41.170769,163.004929 48.099190,162.876434 55.016735,163.093842 
	C56.780743,163.149277 59.099533,163.793579 60.163876,165.028687 
	C70.047554,176.498062 79.712524,188.155930 89.440956,199.759109 
z"
      />
      <path
        fill="#231F20"
        opacity="1.000000"
        stroke="none"
        d="
M149.411285,137.735443 
	C156.363647,140.400375 163.307739,142.404694 168.687943,147.917938 
	C172.659027,151.987259 174.121948,156.791916 174.883377,161.804688 
	C176.856491,174.794418 173.924911,179.978561 162.292725,186.280930 
	C151.107620,192.341049 139.050201,195.719574 126.632668,197.963181 
	C123.429070,198.542023 120.144478,198.672653 116.290192,198.709656 
	C123.886978,188.212341 132.221054,178.115158 140.214661,167.755280 
	C142.603012,164.659912 145.077255,163.387039 148.955566,163.559814 
	C155.627213,163.857025 162.321671,163.642044 170.350555,163.642044 
	C162.301788,154.522949 155.308334,146.599503 148.314880,138.676041 
	C148.555862,138.463898 148.796860,138.251740 149.411285,137.735443 
z"
      />
      <path
        fill="#2D7CEF"
        opacity="1.000000"
        stroke="none"
        d="
M148.065384,138.440369 
	C155.308334,146.599503 162.301788,154.522949 170.350555,163.642044 
	C162.321671,163.642044 155.627213,163.857025 148.955566,163.559814 
	C145.077255,163.387039 142.603012,164.659912 140.214661,167.755280 
	C132.221054,178.115158 123.886978,188.212341 115.844109,198.753632 
	C116.004921,199.097107 115.986588,199.030243 115.773712,198.779114 
	C115.328209,198.575272 115.095589,198.622559 114.862968,198.669830 
	C114.795547,197.631210 114.460258,196.525116 114.702248,195.564438 
	C117.044640,186.265488 119.483948,176.990936 121.888351,167.318054 
	C125.259117,155.310226 128.637115,143.693771 132.289886,131.132355 
	C137.212967,133.374893 142.514435,135.789795 148.065384,138.440369 
z"
      />
      <path
        fill="#2D7DF1"
        opacity="1.000000"
        stroke="none"
        d="
M89.713806,199.561935 
	C79.712524,188.155930 70.047554,176.498062 60.163876,165.028687 
	C59.099533,163.793579 56.780743,163.149277 55.016735,163.093842 
	C48.099190,162.876434 41.170769,163.004929 32.952145,163.004929 
	C34.559673,160.738022 35.331814,159.387436 36.349674,158.258392 
	C41.142471,152.942108 46.042755,147.722397 50.810455,142.384079 
	C51.994038,141.058838 52.826244,139.419815 53.538147,137.553894 
	C53.256332,137.182785 53.225235,137.117233 53.224739,137.165161 
	C57.136692,135.866791 61.053120,134.531937 64.959839,133.169235 
	C66.813812,132.522552 68.649239,131.822739 70.413544,131.176132 
	C73.856964,143.363281 77.259453,155.405548 80.826180,167.786285 
	C83.678116,177.109451 86.379509,186.090088 89.039169,195.083099 
	C89.452721,196.481415 89.675613,197.936127 89.713806,199.561935 
z"
      />
      <path
        fill="#231F20"
        opacity="1.000000"
        stroke="none"
        d="
M114.860336,198.984558 
	C115.095589,198.622559 115.328209,198.575272 115.807907,198.784363 
	C115.655899,199.126938 115.256805,199.213104 114.860336,198.984558 
z"
      />
    </svg>
  );

  const passiveSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M136.000000,210.000000 
	C90.666672,210.000000 45.833340,210.000000 1.000006,210.000000 
	C1.000004,140.333344 1.000004,70.666679 1.000002,1.000015 
	C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
	C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
	C185.500000,210.000000 161.000000,210.000000 136.000000,210.000000 
M151.856918,100.492035 
	C151.237015,98.467636 150.294312,96.480164 150.057724,94.411919 
	C149.347122,88.199829 149.188507,81.916557 148.301392,75.734688 
	C147.114578,67.464561 145.322250,59.283222 144.012695,51.028416 
	C141.776108,36.930069 135.020493,25.553280 123.095238,17.795252 
	C114.155495,11.979458 104.061218,10.333603 93.383385,11.946370 
	C83.002457,13.514290 73.883171,17.489580 68.044113,26.359053 
	C62.633694,34.577415 57.782112,43.256969 53.623920,52.171650 
	C51.105606,57.570629 49.767597,63.675278 51.848957,69.939514 
	C55.660267,81.410347 54.046722,91.980247 47.472538,102.369858 
	C41.115376,112.416489 42.942829,122.514931 50.210690,131.723404 
	C51.474808,133.325073 52.588776,135.045258 54.098213,137.169678 
	C49.606827,138.909241 45.666351,140.476425 41.696297,141.964691 
	C32.993290,145.227173 25.380022,149.651230 24.462601,160.124161 
	C23.312878,173.248962 22.475950,178.383041 34.223709,185.031693 
	C52.977734,195.645538 73.713333,199.196136 94.755302,200.794434 
	C102.677643,201.396179 110.787643,199.775757 118.793297,198.979462 
	C134.310074,197.436081 149.162048,193.404968 162.876770,185.912842 
	C166.846939,183.744049 171.507355,181.079391 173.502518,177.376480 
	C180.133286,165.070175 171.906036,145.916916 158.723465,141.016327 
	C155.532257,139.830017 152.406570,138.467377 148.873093,137.032455 
	C149.973343,135.355927 150.647675,134.063187 151.564499,132.974716 
	C157.255112,126.218697 159.982025,118.898781 156.119278,110.244995 
	C154.778870,107.242035 153.420029,104.247307 151.856918,100.492035 
z"
      />
      <path
        fill="#929497"
        opacity="1.000000"
        stroke="none"
        d="
M151.963257,100.870430 
	C153.420029,104.247307 154.778870,107.242035 156.119278,110.244995 
	C159.982025,118.898781 157.255112,126.218697 151.564499,132.974716 
	C150.647675,134.063187 149.973343,135.355927 148.873093,137.032455 
	C152.406570,138.467377 155.532257,139.830017 158.723465,141.016327 
	C171.906036,145.916916 180.133286,165.070175 173.502518,177.376480 
	C171.507355,181.079391 166.846939,183.744049 162.876770,185.912842 
	C149.162048,193.404968 134.310074,197.436081 118.793297,198.979462 
	C110.787643,199.775757 102.677643,201.396179 94.755302,200.794434 
	C73.713333,199.196136 52.977734,195.645538 34.223709,185.031693 
	C22.475950,178.383041 23.312878,173.248962 24.462601,160.124161 
	C25.380022,149.651230 32.993290,145.227173 41.696297,141.964691 
	C45.666351,140.476425 49.606827,138.909241 54.098213,137.169678 
	C52.588776,135.045258 51.474808,133.325073 50.210690,131.723404 
	C42.942829,122.514931 41.115376,112.416489 47.472538,102.369858 
	C54.046722,91.980247 55.660267,81.410347 51.848957,69.939514 
	C49.767597,63.675278 51.105606,57.570629 53.623920,52.171650 
	C57.782112,43.256969 62.633694,34.577415 68.044113,26.359053 
	C73.883171,17.489580 83.002457,13.514290 93.383385,11.946370 
	C104.061218,10.333603 114.155495,11.979458 123.095238,17.795252 
	C135.020493,25.553280 141.776108,36.930069 144.012695,51.028416 
	C145.322250,59.283222 147.114578,67.464561 148.301392,75.734688 
	C149.188507,81.916557 149.347122,88.199829 150.057724,94.411919 
	C150.294312,96.480164 151.237015,98.467636 151.963257,100.870430 
z"
      />
    </svg>
  );

  return (
    <SvgContainer
      className={className}
      active={active}
      SvgPassive={passiveSvg}
      SvgActive={activeSvg}
      onClick={onClick}
    />
  );
}

export default ThirdPartyFemale;
