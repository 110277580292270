import { useState } from "react";
import { ParticipantTableComponent } from "./ParticipantTable";
import { AccountSectionProps } from "../types";
import { SelectExistingParticipantWithRole } from "./SelectExistingParticipantWithRole";
import { ParticipantRole } from "constants/enums";
import { CreateNewParticipantWithRole } from "./CreateNewParticipantWithRole";

/**
 * @name           AccountITF
 * @summary        This component is responsible for displaying and adding ITF details to the account.
 *                 Allows for user to set the trustees and beneficiaries for the account.
 * @param          formData the new investment account form data. Refer to CreateInvestmentAccountDto for more information
 * @param          setFormData the react hook form set form data function
 * @param          participant the participant state information
 * @returns        React.Component
 */
function AccountITF({ formData, setFormData, participant }: AccountSectionProps) {
  // State for creating a new trustee
  const [isCreatingTrustee, setIsCreatingTrustee] = useState<boolean>(false);
  // State for creating a new beneficiary
  const [isCreatingBeneficiary, setIsCreatingBeneficiary] = useState<boolean>(false);
  // State for choosing an existing trustee
  const [isChoosingExistingTrustee, setIsChoosingExistingTrustee] = useState<boolean>(false);
  // State for choosing an existing beneficiary
  const [isChoosingExistingBeneficiary, setIsChoosingExistingBeneficiary] =
    useState<boolean>(false);

  const handleTrusteeAdd = (participantId: string) => {
    const tempData = { ...formData };
    if (!tempData.trustees) {
      tempData.trustees = [participantId];
    } else if (tempData.trustees && tempData.trustees.includes(participantId)) {
      return;
    } else {
      tempData.trustees = [...tempData.trustees, participantId];
    }
    setFormData(tempData);
  };

  const handleBeneficiaryAdd = (participantId: string) => {
    const tempData = { ...formData };
    if (!tempData.itfBeneficiaries) {
      tempData.itfBeneficiaries = [participantId];
    } else if (tempData.itfBeneficiaries && tempData.itfBeneficiaries.includes(participantId)) {
      return;
    } else {
      tempData.itfBeneficiaries = [...tempData.itfBeneficiaries, participantId];
    }
    setFormData(tempData);
  };

  const handleDeleteTrustee = (index: number) => {
    const tempData = { ...formData };
    tempData.trustees?.splice(index, 1);
    setFormData(tempData);
  };

  const handleDeleteBeneficiary = (index: number) => {
    const tempData = { ...formData };
    tempData.itfBeneficiaries?.splice(index, 1);
    setFormData(tempData);
  };

  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>In Trust For Trustees and Beneficiaries</h1>
        <h3>Please Select the Trustee and Beneficiary</h3>
      </div>
      {formData.trustees && formData.trustees.length > 0 && (
        <>
          <p style={{ fontSize: "1.2rem", margin: "0.5rem" }}>Trustee</p>
          <ParticipantTableComponent
            participantIds={formData.trustees!}
            handleDelete={handleDeleteTrustee}
            canDeleteFirst={true}
          />
        </>
      )}
      {formData.itfBeneficiaries && formData.itfBeneficiaries.length > 0 && (
        <>
          <p style={{ fontSize: "1.2rem", margin: "0.5rem" }}>Beneficiary</p>
          <ParticipantTableComponent
            participantIds={formData.itfBeneficiaries!}
            handleDelete={handleDeleteBeneficiary}
            canDeleteFirst={true}
          />
        </>
      )}
      {!isChoosingExistingTrustee &&
        !isCreatingTrustee &&
        !isChoosingExistingBeneficiary &&
        !isCreatingBeneficiary &&
        formData.trustees?.length == 0 && (
          <div className="action-section-btn-container">
            <button className="btn btn-primary" onClick={() => setIsChoosingExistingTrustee(true)}>
              Add Existing Participant as Trustee
            </button>
            <button className="btn btn-primary" onClick={() => setIsCreatingTrustee(true)}>
              Create & Add New Trustee
            </button>
          </div>
        )}
      {!isChoosingExistingBeneficiary &&
        !isCreatingBeneficiary &&
        !isChoosingExistingTrustee &&
        !isCreatingTrustee &&
        (!formData.itfBeneficiaries || formData.itfBeneficiaries?.length == 0) && (
          <div className="action-section-btn-container">
            <button
              className="btn btn-primary"
              onClick={() => setIsChoosingExistingBeneficiary(true)}
            >
              Add Existing Participant as Beneficiary
            </button>
            <button className="btn btn-primary" onClick={() => setIsCreatingBeneficiary(true)}>
              Create & Add New Beneficiary
            </button>
          </div>
        )}
      <div className="account-section-body">
        {isCreatingTrustee && (
          <CreateNewParticipantWithRole
            roleName="Trustee"
            role={ParticipantRole.TRUSTEE}
            householdId={participant?.householdId!}
            setIsCreatingNewParticipant={setIsCreatingTrustee}
            handleBeneficiaryAdd={handleTrusteeAdd}
          />
        )}
        {isChoosingExistingTrustee && (
          <SelectExistingParticipantWithRole
            roleName="Trustee"
            role={ParticipantRole.TRUSTEE}
            householdId={participant?.householdId!}
            setIsChoosingExisiting={setIsChoosingExistingTrustee}
            addParticipant={handleTrusteeAdd}
          />
        )}
        {isCreatingBeneficiary && (
          <CreateNewParticipantWithRole
            roleName="Beneficiary"
            role={ParticipantRole.BENEFICIARY}
            householdId={participant?.householdId!}
            setIsCreatingNewParticipant={setIsCreatingBeneficiary}
            handleBeneficiaryAdd={handleBeneficiaryAdd}
          />
        )}
        {isChoosingExistingBeneficiary && (
          <SelectExistingParticipantWithRole
            roleName="Beneficiary"
            role={ParticipantRole.BENEFICIARY}
            householdId={participant?.householdId!}
            setIsChoosingExisiting={setIsChoosingExistingBeneficiary}
            addParticipant={handleBeneficiaryAdd}
          />
        )}
      </div>
    </div>
  );
}

export default AccountITF;
