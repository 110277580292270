/**
 * This Store will be used for the Logged In User
 * We can possibly implement an admin only functionality where we can use a different store for that
 */

import { createSlice } from "@reduxjs/toolkit";
import { Advisor } from "../../types/Advisor/Advisor";

export const emptyAdvisorState: Advisor = {
  id: "",
  iaNumber: "",
  userInformation: {
    user: {
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      city: "",
      region: "",
      country: "",
      isActive: false,
    },
    roles: [],
    claims: [],
  },
};

export const advisorSlice = createSlice({
  name: "advisorSlice",
  initialState: emptyAdvisorState,
  reducers: {
    setAdvisorUser: (state, action) => {
      state.id = action.payload.id;
      state.iaNumber = action.payload.IANumber;
    },
  },
});

export const { setAdvisorUser } = advisorSlice.actions;
export const advisorSliceReducer = advisorSlice.reducer;
