import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * This endpoint uses the Client Controller found in the .NET API
 */
const ipsApi = createApi({
  reducerPath: "ipsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/ips`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    // Gets all clients from the database
    downloadIPSUnsignedDocument: builder.query({
      query: (participantId: string) => ({
        url: `pdf/${participantId}`,
        method: "GET",
        responseHandler: async (response) => {
          var hiddenElement = document.createElement("a");
          var url = window.URL || window.webkitURL;
          var blob = await response.blob();
          var blobPDF = url.createObjectURL(blob);
          hiddenElement.href = blobPDF;
          hiddenElement.target = "_blank";
          hiddenElement.download = `${participantId.slice(0, 6)}_UnsignedIPS.pdf`;
          hiddenElement.click();
        },
      }),
    }),
    getIPSByParticipantId: builder.query({
      query: (participantId: string) => ({
        url: '?participantId=' + participantId,
        method: "GET",
      })
    })
  }),
});

export const { useLazyDownloadIPSUnsignedDocumentQuery , useLazyGetIPSByParticipantIdQuery} = ipsApi;
export default ipsApi;
