import { checkEmptyValue, isValidUSZip, notify, validatePostalCode } from "../../../utils";
import validateSIN from "../../../utils/validateSIN";
import { Participant } from "../../../types";

/**
 * Function that validates a client's employment details
 * @param client
 * @returns
 **/

const ValidateEmploymentDetails = (client: Participant) => {
  // Check Details of General Information
  if (checkEmptyValue(client?.employmentDetails?.status)) {
    notify("Please Select an Employment Status", "error");
    return false;
  }

  var employmentTypes: any = [1, 2, 5];
  if (employmentTypes.includes(Number(client?.employmentDetails?.status))) {
    if (checkEmptyValue(client?.employmentDetails?.occupation)) {
      notify("Please Enter an Occupation", "error");
      return false;
    }
    if (checkEmptyValue(client?.employmentDetails?.name)) {
      notify("Please Enter an Employer Name", "error");
      return false;
    }

    // Validate the Postal Code and/or Zip Code
    if (client.employmentDetails.country == "Canada") {
      if (checkEmptyValue(client.permanentAddressDetails.postalCode)) {
        notify("Please Enter a Postal Code", "error");
        return false;
      } else if (validatePostalCode(client.employmentDetails.postalCode) == false) {
        notify("Please Enter a Valid Postal Code", "error");
        return false;
      }
    } else if (client.employmentDetails.country == "United States") {
      if (checkEmptyValue(client.employmentDetails.postalCode)) {
        notify("Please Enter a Zip Code", "error");
        return false;
      } else if (isValidUSZip(client.employmentDetails.postalCode) == false) {
        notify("Please Enter a Valid Zip Code", "error");
        return false;
      }
    }
  }

  return true;
};

export default ValidateEmploymentDetails;
