import { AccountSectionProps } from "../types";
import AccountInput from "./AccountInput";
import { EmptyTransferDetail } from "constants/emptyStates";
import {
  CreateInvestmentAccountDto,
  Instruction,
  TransferDetail,
} from "features/createInvestmentAccount/types/CreateInvestmentAccountDto";
import AccountSelect from "./AccountSelect";
import { CurrencyMapping } from "constants/mappings";
import { SIAInput, SIASelect } from "components/Shared";
import { CurrencyOptions } from "assets/StaticData";
import { transferTypeOptions } from "constants/selectOptions";
import { ValidateTransferDetail } from "../utils";
import { useState } from "react";

/**
 * @name        AccountTransferDetails
 * @summary     This component of the CreateTradingAccountPage is responsible for managing the transfer details
 *              for the account. I.e. if the client want to transfer cash or kind from a different institution,
 *              a user will be able to that in this section of the form.
 * @param       formData The react state for the new account
 * @param       setFormData The react state function to set the new account
 * @param       participant The participant object for the client
 * @todo        Build out the Edit Functionality for the transfer details, not a priority right now
 * @returns     React.Component
 */
function AccountTransferDetails({
  formData,
  setFormData,
  participant,
  viewMode,
}: AccountSectionProps) {
  // State for creating a new transfer detail
  const [isCreatingNewTransferDetail, setIsCreatingNewTransferDetail] = useState(false);

  return (
    <div className="account-section-details-container">
      {/* <button onClick={() => console.log(formData?.transferDetails)}>View Transfer Detail</button> */}
      <div className="account-section-details-header">
        <h1>Transfer Details</h1>
        <h3>Please specify the transfer details required for this account</h3>
      </div>
      {formData.transferDetails && formData.transferDetails.length > 0 && (
        <TransferDetails account={formData} setAccount={setFormData} viewMode={viewMode} />
      )}
      {!isCreatingNewTransferDetail && !viewMode && (
        <div className="action-section-btn-container">
          <button className="btn btn-primary" onClick={() => setIsCreatingNewTransferDetail(true)}>
            Add New Transfer Detail
          </button>
        </div>
      )}

      <div className="account-section-details-body">
        {isCreatingNewTransferDetail && (
          <CreateTransferDetailForm
            account={formData}
            setAccount={setFormData}
            setIsCreatingNewTransferDetail={setIsCreatingNewTransferDetail}
          />
        )}
      </div>
    </div>
  );
}

/**
 * @name            TransferDetails
 * @summary         This component is responsible for displaying the transfer details
 *                  for the new trading account
 * @param           {
 *                      account: The New Account that is being created,
 *                      setAccount: The react state function to set the new account
 *                  }
 * @returns         React.Component
 */
const TransferDetails = ({
  account,
  setAccount,
  viewMode,
}: {
  account: CreateInvestmentAccountDto;
  setAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
  viewMode?: boolean;
}) => {
  const handleDeleteTransferDetail = (index: number) => {
    const temp = { ...account };
    if (temp.transferDetails && temp.transferDetails.length > 0) {
      temp.transferDetails.splice(index, 1);
      setAccount(temp);
    }
  };

  return (
    <div className="transfer-detail-container">
      <div className="transfer-detail-container-body">
        {account?.transferDetails?.map((transferDetail, index) => {
          return (
            <TransferDetailCard
              transferDetail={transferDetail}
              index={index}
              handleDeleteTransferDetail={handleDeleteTransferDetail}
              viewMode={viewMode}
            />
          );
        })}
      </div>
    </div>
  );
};

/**
 * @name            TransferDetailCard
 * @summary         This component is responsible for displaying the transfer detail card
 * @param           {
 *                      transferDetail: The transfer detail object,
 *                      index: The index of the transfer detail in the array,
 *                      handleDeleteTransferDetail: The function to delete the transfer detail from the new account
 *                  }
 * @returns         React.Component
 */
const TransferDetailCard = ({
  transferDetail,
  index,
  handleDeleteTransferDetail,
  viewMode,
}: {
  transferDetail: TransferDetail;
  index: number;
  handleDeleteTransferDetail: (index: number) => void;
  viewMode?: boolean;
}) => {
  return (
    <div className="transfer-detail-container-body-card">
      <div className="transfer-detail-container-body-card-header">
        <p>Transfer Detail {index + 1}</p>
      </div>
      <div className="transfer-detail-container-body-card-body">
        <div className="transfer-detail-information">
          <p>
            <span className="label">Delivering Institution:</span>{" "}
            {transferDetail.deliveringInstitution}
          </p>
          <p>
            <span className="label">Dealer Number:</span> {transferDetail.institutionDealerNumber}
          </p>
          <p>
            <span className="label">Institution Country:</span> {transferDetail.institutionCountry}
          </p>
          <p>
            <span className="label">Institution Address:</span> {transferDetail.institutionAddress}
          </p>
          <p>
            <span className="label">Institution Business Phone:</span>{" "}
            {transferDetail.institutionBusinessPhone}
          </p>
          <p>
            <span className="label">Institution Business Phone Ext:</span>{" "}
            {transferDetail.institutionBusinessPhoneExt}
          </p>
        </div>
        <div className="transfer-instruction-information">
          {transferDetail.instructions.map((instruction, index) => {
            return (
              <div>
                <p>Instruction #{index + 1}</p>
                <div>
                  <p>
                    <span className="label">Currency: </span>
                    {CurrencyMapping[instruction.currency]}
                  </p>
                  <p>
                    <span className="label">Account Number: </span>
                    {instruction.institutionAccountNumber}
                  </p>
                  <p>
                    <span className="label">Instruction Type: </span>
                    {instruction.transferType == 1 ? "Cash" : "Kind"}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="transfer-detail-container-actions">
        {/* <button className="btn btn-warning">Edit</button> */}
        {!viewMode && (
          <button className="btn btn-danger" onClick={() => handleDeleteTransferDetail(index)}>
            Delete
          </button>
        )}
      </div>
    </div>
  );
};

/**
 * @name            CreateTransferDetailForm
 * @summary         This component is responsible for creating a new transfer detail and adding it to the account
 * @param           {
 *                    account: The New Account that is being created,
 *                    setAccount: The react state function to set the new account,
 *                    setIsCreatingNewTransferDetail: The react state function to set the state of the parent component to show
 *                                                    that a new detail is being created
 *                  }
 * @returns         React.Component
 */
const CreateTransferDetailForm = ({
  account,
  setAccount,
  setIsCreatingNewTransferDetail,
}: {
  account: CreateInvestmentAccountDto;
  setAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
  setIsCreatingNewTransferDetail: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // State for the new transfer detail
  const [transferDetail, setTransferDetail] = useState<TransferDetail>(EmptyTransferDetail);
  // Handling the state change for the new instruction
  const handleChange = (value: any, attrName: string) => {
    setTransferDetail({ ...transferDetail, [attrName]: value });
  };

  // Adding a new empty transfer detail to the account
  const handleAddTransferDetailAdd = () => {
    const temp = { ...transferDetail };
    const emptyInstruction: Instruction = {
      currency: 0,
      institutionAccountNumber: "",
      transferType: 0,
    };

    if (temp.instructions.length === 0) {
      temp.instructions = [emptyInstruction];
    } else {
      temp.instructions.push(emptyInstruction);
    }

    setTransferDetail(temp);
  };

  /**
   * @summary Helper Function to prevent the form from submitting
   * @param e The form event
   */
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleFinalize = () => {
    if (!ValidateTransferDetail(transferDetail)) {
      return;
    }

    const temp = { ...account };
    if (!temp.transferDetails) {
      temp.transferDetails = [transferDetail];
    } else {
      const details = [...temp.transferDetails];
      details.push(transferDetail);
      temp.transferDetails = details;
    }
    setAccount(temp);
    setIsCreatingNewTransferDetail(false);
  };

  return (
    <form className="add-form" onSubmit={handleFormSubmit}>
      <p className="form-header">Add New Transfer Detail</p>
      <AccountInput
        value={transferDetail.deliveringInstitution}
        label="Delivering Institution"
        onChange={(e) => handleChange(e, "deliveringInstitution")}
      />
      <AccountSelect
        value={transferDetail.institutionCountry}
        label="Country of Institution"
        onChange={(e) => handleChange(e, "institutionCountry")}
        options={[]}
        isCountry={true}
      />
      <AccountInput
        value={transferDetail.institutionAddress}
        label="Institution Address"
        onChange={(e) => handleChange(e, "institutionAddress")}
      />
      <AccountInput
        value={transferDetail.institutionBusinessPhone}
        label="Business Phone"
        onChange={(e) => handleChange(e, "institutionBusinessPhone")}
        placeholder="555-555-5555"
      />
      <AccountInput
        value={transferDetail.institutionBusinessPhoneExt}
        label="Business Phone Ext."
        onChange={(e) => handleChange(e, "institutionBusinessPhoneExt")}
      />
      {transferDetail.instructions && transferDetail.instructions.length > 0 && (
        <TransferDetailTable
          transferDetail={transferDetail}
          setTransferDetail={setTransferDetail}
        />
      )}
      <div className="form-action-button-container">
        <button className="btn btn-danger" onClick={() => setIsCreatingNewTransferDetail(false)}>
          Cancel
        </button>
        <button className="btn btn-warning" onClick={handleAddTransferDetailAdd}>
          Add Instruction
        </button>
        <button className="btn btn-primary" type="submit" onClick={handleFinalize}>
          Finalize Transfer Detail
        </button>
      </div>
    </form>
  );
};

/**
 * @name           TransferDetailTable
 * @summary         Displays an editable table of transfer instructions for a newly created Transfer Detail
 * @param           {
 *                      transferDetail: The transfer detail object,
 *                      setTransferDetail: The react state function to set the transfer detail
 *                  }
 * @returns         React.Component
 */
const TransferDetailTable = ({
  transferDetail,
  setTransferDetail,
}: {
  transferDetail: TransferDetail;
  setTransferDetail: React.Dispatch<React.SetStateAction<TransferDetail>>;
}) => {
  const handleDetailDelete = (index: number) => {
    const temp = { ...transferDetail };
    temp.instructions.splice(index, 1);
    setTransferDetail(temp);
  };

  return (
    <div className="table-container">
      <div className="table-header">
        <p>Transfer Instructions</p>
      </div>
      <table>
        <tr>
          <th>Currency</th>
          <th>Account Number</th>
          <th>Transfer Type</th>
          <th></th>
        </tr>
        {transferDetail.instructions.map((instruction, index) => {
          return (
            <tr>
              <td>
                <SIASelect
                  value={instruction.currency}
                  options={CurrencyOptions}
                  onChange={(e) => {
                    const temp = { ...transferDetail };
                    temp.instructions[index].currency = e;
                    setTransferDetail(temp);
                  }}
                />
              </td>
              <td>
                <SIAInput
                  value={instruction.institutionAccountNumber}
                  onChange={(e) => {
                    const temp = { ...transferDetail };
                    temp.instructions[index].institutionAccountNumber = e.target.value;
                    setTransferDetail(temp);
                  }}
                  type="text"
                  placeholder="Account Number..."
                />
              </td>
              <td>
                <SIASelect
                  value={instruction.transferType}
                  options={transferTypeOptions}
                  onChange={(e) => {
                    const temp = { ...transferDetail };
                    temp.instructions[index].transferType = e;
                    setTransferDetail(temp);
                  }}
                />
              </td>
              <td>
                <button className="btn btn-danger" onClick={() => handleDetailDelete(index)}>
                  <i className="bi bi-trash"></i>
                </button>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default AccountTransferDetails;
