import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/createIPS.scss";
import { CreateIndividualPolicyStatementDto } from "../types";
import { useParams } from "react-router-dom";
import withAuth from "HOC/withAuth";
import { StepFormWizard } from "components/Shared";
import { useCreateIPSMutation, useLazyGetClientByIdQuery } from "apis/mainApi";
import { ApiResponseModel, Participant } from "types";
import { notify } from "utils";
import { CreateObjectiveDto } from "../types/CreateIndividualPolicyStatementDto";
import IPSClientSummary from "./IPSClientSummary";
import { validateClientSummary, validateInvestmentObjectives, validateLimits, validateTimeHorizon } from "../utils";
import IPSTimeHorizon from "./IPSTimeHorizon";
import IPSLimitsOnInvestment from "./IPSLimitsOnInvestments";
import IPSClientInvestmentObjectives from "./IPSInvestmentObjectives";
import IPSAccountFeeDescriptions from "./IPSAccountFeeDescriptions";
import IPSReviewDetails from "./IPSReviewDetails";
import IPSLegalRequirements from "./IPSLegalRequirements";
import IPSUniqueCircumstances from "./IPSUniqueCircumstances";
import { GuidValues } from "constants/enums";
import { useLazyGetIPSByParticipantIdQuery } from "apis/ipsApi";
import { cloneDeep } from "lodash";
// Create Empty IPS

const emptyObjective: CreateObjectiveDto = {
  upperLimit: 0,
  lowerLimit: 0,
  target: 0,
};

const emptyIPS: CreateIndividualPolicyStatementDto = {
  id: GuidValues.EMPTY_GUID,
  participantId: "",
  clientSummary: "",
  legalRegulatoryConsiderations: "",
  uniqueCircumstances: "",
  timeHorizion: 0,
  incomeRequirements: false,
  maxCommericialPaperPercentage: 0,
  maxSecurityIssuerPercentage: 0,
  cashAndEquivalentsObjectives: emptyObjective,
  fixedIncomeObjectives: emptyObjective,
  equityObjectives: emptyObjective,
  alternativeInvestmentsObjectives: emptyObjective,
  accountFeeDescriptions: [],
  isComplete: false,
};

/**
 * Main Component Page to Create IPS for a Client
 * @returns
 */
function CreateInvestmentPolicyStatement() {
  const [clientIPS, setClientIPS] = useState<CreateIndividualPolicyStatementDto>(emptyIPS);
  const [participant, setParticipant] = useState<Participant>();
  const [clientQuery, clientResult] = useLazyGetClientByIdQuery();
  const [ipsQuery, ipsResult] = useLazyGetIPSByParticipantIdQuery();
  const { participantId } = useParams();
  const [createIPS] = useCreateIPSMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (participantId) {
      setClientIPS({ ...clientIPS, participantId: participantId });
      clientQuery(participantId);
      ipsQuery(participantId);
    }
  }, [participantId]);

  useEffect(() => {
    if (clientResult.isSuccess && clientResult.data.result) {
      setParticipant(clientResult.data.result);
    }
  }, [clientResult]);

  useEffect(() => {
    if (ipsResult.isSuccess && ipsResult.data && ipsResult.data.result) {
      const existingIPS = cloneDeep(ipsResult.data.result);
      setClientIPS(existingIPS);
    }
  }, [ipsResult]);

  const submitNewIPS = async (isComplete: boolean, navigateBack: boolean) => {
    clientIPS.isComplete = isComplete;
    const result: ApiResponseModel = await createIPS(clientIPS);
    if (result.data?.isSuccess) {
      if (isComplete) notify("Successfully Created IPS", "success");
      if (navigateBack) navigate(-1);
    } else {
      notify("Error Occured With IPS Creation", "error");
      console.log(result);
    }
  };

  return (
    <div className="create-ips-full-container">
      <div className="create-ips-header">
        <p className="ips-container-header">SIA Investment Policy Statement</p>
        <p className="ips-container-subheader">
          Participant: {participant?.generalDetails.firstName} {participant?.generalDetails.lastName}
        </p>
      </div>
      <StepFormWizard
        startIndex={0}
        steps={[
          {
            label: "Client Summary",
            subtitle: "",
            content: IPSClientSummary,
            validator: validateClientSummary,
          },
          {
            label: "Time Horizon & Income Requirements",
            subtitle: "",
            content: IPSTimeHorizon,
            validator: validateTimeHorizon,
          },
          {
            label: "Limits on Investment",
            subtitle: "",
            content: IPSLimitsOnInvestment,
            validator: validateLimits,
          },
          {
            label: "Legal & Regulatory Considerations",
            subtitle: "",
            content: IPSLegalRequirements,
          },
          {
            label: "Unique Circumstances",
            subtitle: "",
            content: IPSUniqueCircumstances,
          },
          {
            label: "Investment Objectives",
            subtitle: "",
            content: IPSClientInvestmentObjectives,
            validator: validateInvestmentObjectives,
          },
          {
            label: "Account Fee Descriptions",
            subtitle: "",
            content: IPSAccountFeeDescriptions,
          },
          {
            label: "Review",
            subtitle: "",
            content: IPSReviewDetails,
          },
        ]}
        formData={clientIPS}
        handleStepChange={() => submitNewIPS(false, false)}
        setFormData={setClientIPS}
        submitForm={() => submitNewIPS(true, true)}
      />
    </div>
  );
}

export default withAuth(CreateInvestmentPolicyStatement);
