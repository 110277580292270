import "../styles/createRiskTolerance.scss";
import { useEffect, useState } from "react";
import RiskToleranceWizard from "./riskToleranceWizard";
import RiskWizardSections from "./riskWizardSections";
import withAuth from "HOC/withAuth";
import withAdvisorAuth from "HOC/withAdvisorAuth";
import { ApiResponseModel, RiskToleranceFormData, RiskToleranceWizardStep } from "types";
import SpecialNotesSection from "./specialNotesSection";
import { GuidValues, UserRoles } from "constants/enums";
import riskSections from "assets/StaticData/RiskToleranceSections";
import { useCreateRiskToleranceResponseMutation } from "apis/mainApi";
import { notify, sanitizeString } from "utils";
import CompleteWizard from "./completeWizard";
import { useParams } from "react-router-dom";
import { useSendForSignatureMutation } from "apis/riskToleranceApi";
import { AuthUser } from "features/auth/types";
import { useSelector } from "react-redux";
import { RootState } from "stores/store";

const riskToleranceWizardSteps: RiskToleranceWizardStep[] = [
  {
    component: RiskWizardSections,
    startIndex: 0,
    endIndex: 3,
  },
  {
    component: RiskWizardSections,
    startIndex: 3,
    endIndex: 6,
  },
  {
    component: RiskWizardSections,
    startIndex: 6,
    endIndex: 7,
  },
  {
    component: RiskWizardSections,
    startIndex: 7,
    endIndex: 8,
  },
  {
    component: RiskWizardSections,
    startIndex: 8,
    endIndex: 10,
  },
  {
    component: RiskWizardSections,
    startIndex: 10,
    endIndex: 12,
  },
  {
    component: RiskWizardSections,
    startIndex: 12,
    endIndex: 15,
  },
  {
    component: SpecialNotesSection,
  },
];

/**
 * This Component will create and edit clients' risk tolerance questionaire
 * @returns
 */
const CreateRiskTolerance = () => {
  const { clientId } = useParams();
  const [currentRiskScore, setCurrentRiskScore] = useState(0);
  const [submitRisk] = useCreateRiskToleranceResponseMutation();
  const [sendRiskEmailForSignature] = useSendForSignatureMutation();
  const [isCompleted, setIsCompleted] = useState(false);
  const userData: AuthUser = useSelector((state: RootState) => state.userAuthStore);

  const [formData, setFormData] = useState<RiskToleranceFormData>({
    foClientId: clientId ? clientId : GuidValues.EMPTY_GUID,
    responseId: undefined,
    riskScoreSections: riskSections.map((section) => {
      return {
        sectionId: section.id,
        answerScore: 0,
      };
    }),
    specialNotes: "",
  });

  const handleStepChange = async (data: Partial<RiskToleranceFormData>) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));

    if (data.riskScoreSections) {
      var tempScore = 0;
      data.riskScoreSections.map((answer) => {
        tempScore += answer.answerScore;
      });

      setCurrentRiskScore(tempScore);
    }
  };

  // Updating the RTQ Form whenever a selection changes
  useEffect(() => {
    const updateRiskForm = async () => {
      if (formData.foClientId != GuidValues.EMPTY_GUID && formData.responseId != undefined) {
        const riskSubmissionResponse: ApiResponseModel = await submitRisk(formData);

        if (riskSubmissionResponse.error) {
          console.log(riskSubmissionResponse.error);
        }

        if (formData.responseId == GuidValues.EMPTY_GUID) {
          handleStepChange({
            responseId: riskSubmissionResponse.data?.result.id,
          });
        }
      }
    };

    updateRiskForm();
  }, [formData]);

  /**
   * Form Submission Handler
   */
  const handleRiskFormSubmit = async () => {
    const formAnswers = formData.riskScoreSections;
    const isZeroAnswers = formAnswers.filter((answer) => answer.answerScore == 0);

    if (isZeroAnswers.length > 0) {
      notify("Please Answer All Risk Tolerance Questions", "error");
      return;
    }

    formData.specialNotes = sanitizeString(formData.specialNotes);

    const riskSubmissionResponse: ApiResponseModel = await submitRisk(formData);
    if (riskSubmissionResponse.error) {
      notify("An error has occured", "error");
    }
    if (riskSubmissionResponse.data && riskSubmissionResponse.data.isSuccess) {
      notify("Successfully Submitted Risk Response", "success");
      setIsCompleted(true);

      if (!userData.roles.includes(UserRoles.DEMO)) {
        await sendForSignature();
      }
    }
  };

  const sendForSignature = async () => {
    const sendFormResult: ApiResponseModel = await sendRiskEmailForSignature(formData.responseId);
    if (sendFormResult.data?.isSuccess) {
      notify("Successfully Sent Email", "success");
    } else {
      console.log(sendFormResult);
      notify("An error has occured", "error");
    }
  };

  return (
    <div className="main-container">
      <h1 className="main-container-header">Client Risk Tolerance Wizard</h1>
      <RiskToleranceWizard
        steps={riskToleranceWizardSteps}
        formData={formData}
        setFormData={setFormData}
        handleStepChange={handleStepChange}
        currentRiskScore={currentRiskScore}
        handleRiskFormSubmit={handleRiskFormSubmit}
        isCompleted={isCompleted}
        CompletedComponent={CompleteWizard}
        showRiskScore={true}
      />
    </div>
  );
};

export default withAuth(withAdvisorAuth(CreateRiskTolerance));
