export default function sanitizeString(str: string) {
  // Define a regular expression to match dangerous characters
  const dangerousCharsRegex = /[&<>"'`=\/]/g;

  // Replace dangerous characters with their respective HTML entities
  const sanitizedString = str.replace(dangerousCharsRegex, function (match) {
    switch (match) {
      case "&":
        return "&amp;";
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case '"':
        return "&quot;";
      case "'":
        return "&#x27;";
      case "`":
        return "&#x60;";
      case "=":
        return "&#x3D;";
      case "/":
        return "&#x2F;";
      default:
        return match;
    }
  });

  return sanitizedString;
}
