import { useGetAuthorizedUserQuery } from "apis/userApi";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setLoggedInUser, emptyUserState } from "../stores/Redux/userAuthSlice";

import { MainLoader } from "../components/Shared";
import { UserRoles } from "constants/enums";

const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    const dispatch = useDispatch();
    const { data, isLoading, isError, isSuccess, refetch } = useGetAuthorizedUserQuery(null);
    useEffect(() => {
      refetch();
    }, [refetch]);

    useEffect(() => {
      if (!isLoading) {
        if (isSuccess && data) {
          dispatch(setLoggedInUser(data.result));
        } else if (isError) {
          dispatch(setLoggedInUser(emptyUserState));
        }
      }
    }, [data, dispatch, isError, isSuccess]);

    if (isLoading) {
      return <MainLoader />;
    }

    if (isSuccess && data && window.location.pathname == "/login") {
      const user = data.result;
      if (user.roles.includes(UserRoles.ADMIN) || user.roles.includes(UserRoles.MANAGER)) {
        window.location.replace("/advisors");
        return null;
      } else if (user.roles.includes(UserRoles.ADVISOR)) {
        window.location.replace("/households");
        return null;
      }
    }

    if (isError && window.location.pathname != "/login") {
      window.location.replace("/login");
      return null;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
