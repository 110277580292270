export const AMLDocumentTypes: Record<string | number, string> = {
  "1": "Passport",
  "2": "Provincial Driver's License",
  "3": "Birth Certificate",
  "4": "Citizenship Card",
  "5": "Permanent Resident Card",
  "6": "Certificate of Indian Status",
  "7": "Nexus Card",
  "8": "BC Services Card",
  "9": "Provincial Health Card",
  "10": "Provincial Photo ID",
  "11": "Canadian Armed Forces ID",
  "12": "Other",
};
