import React, { useState } from "react";
import "../styles/Login.scss";
import withAuth from "../../../HOC/withAuth";
import { ApiResponseModel } from "types";
import { useLoginUserMutation } from "apis/authApi";
import { notify } from "utils";
import { useNavigate } from "react-router-dom";
import { MiniLoader } from "components/Shared";
import { LoginDto } from "../types";
import { onInputBlur, onInputFocus, onLoginLabelClick } from "../utils";

const emptyLoginDto: LoginDto = {
  username: "",
  password: "",
};

const LoginPage = () => {
  // Login Information Required for the login
  const [loginInformation, setLoginInformation] = useState<LoginDto>(emptyLoginDto);
  // Loading State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Login Mutation (This does not use the MFA yet)
  const [loginUser] = useLoginUserMutation();
  // Navigation
  const navigate = useNavigate();

  const handleUsernamePasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const loginResponse: ApiResponseModel = await loginUser(loginInformation);
    if (!loginResponse.data?.isSuccess && loginResponse.data?.errorMessages) {
      // on error of login
      notify(loginResponse.data?.errorMessages[0], "error");
    } else if (loginResponse.data?.isSuccess) {
      // Handle Successful Username/Password Login
      const { requiresTwoFactorSetup, requiresTwoFactorVerification } = loginResponse.data.result;
      if (requiresTwoFactorSetup) {
        navigate("/setup-tfa");
      } else if (requiresTwoFactorVerification) {
        navigate("/verifyCode");
      } else {
        navigate("/");
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="login-container-new" id="login-container">
      <div className="border-wrap-container">
        <div className="login-main-content-container">
          <div className="login-header-container">
            <p className="login-header">Welcome Back</p>
            <p className="login-subheader">Advisor Portal</p>
          </div>
          <div className="login-form-container">
            <form className="login-form" onSubmit={handleUsernamePasswordSubmit}>
              <div className="login-form-input-cont">
                <label className="login-form-label" onClick={onLoginLabelClick}>
                  Email
                </label>
                <input
                  className="login-form-input"
                  onChange={(e) =>
                    setLoginInformation({ ...loginInformation, username: e.target.value })
                  }
                  value={loginInformation.username}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                  id="username-input"
                />
              </div>
              <div className="login-form-input-cont">
                <label className="login-form-label" onClick={onLoginLabelClick}>
                  Password
                </label>
                <input
                  className="login-form-input"
                  onChange={(e) =>
                    setLoginInformation({ ...loginInformation, password: e.target.value })
                  }
                  value={loginInformation.password}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                  type="password"
                  id="password-input"
                />
              </div>
              {(loginInformation.username || loginInformation.password) && !isLoading && (
                <div className="login-form-btn-submit-cont">
                  <button id="login-btn">Login</button>
                </div>
              )}
              {isLoading && (
                <div className="loading-container">
                  <MiniLoader />
                </div>
              )}
            </form>
          </div>
          <div className="login-footer">
            <p>Issues with SIA Account?</p>
            <p>Contact SIA Private Wealth Development Team</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(LoginPage);
