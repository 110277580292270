import { useGetParticipantBasicInformationQuery } from "apis/mainApi";
import { ParticipantTableProps } from "../types";
import { useEffect, useState } from "react";

export const ParticipantTableComponent = ({
  participantIds,
  handleDelete,
  canDeleteFirst,
  disabled,
}: ParticipantTableProps) => {
  return (
    <div className="participant-table-container">
      {participantIds && participantIds.length > 0 && (
        <table>
          <tr>
            <th>Participant ID</th>
            <th>Participant Name</th>
            <th></th>
          </tr>
          {participantIds.map((participantId, index) => {
            return (
              <tr>
                <td>{participantId}</td>
                <ParticipantTableNameCell participantId={participantId} />
                <td>
                  {(canDeleteFirst || index > 0) && !disabled && (
                    <button className="btn btn-danger" onClick={() => handleDelete(index)}>
                      <i className="bi bi-trash"></i>
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </table>
      )}
    </div>
  );
};

export const ParticipantTableNameCell = ({ participantId }: { participantId: string }) => {
  const result = useGetParticipantBasicInformationQuery(participantId);
  const [participantName, setParticipantName] = useState("");

  useEffect(() => {
    if (!result.isLoading && result.isSuccess) {
      setParticipantName(result.data.result?.firstName + " " + result.data.result?.lastName);
    }
  }, [result]);

  return <td>{participantName}</td>;
};
