import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./styles/Header.scss";
import { useDispatch } from "react-redux";
import { useExpiredTokenMutation } from "apis/authApi";
import { AuthUser } from "../../features/auth/types";
import { RootState } from "../../stores/store";
import { emptyUserState, setLoggedInUser } from "../../stores/Redux/userAuthSlice";

let siaLogo = require("assets/images/new-sia-wealth-logo.png");
/**
 * The Header Component of the application. Allows the user to navigate to different areas
 * of the application. Has a logout feature where the HTTP Only Access Token gets cleared
 * from the browser and thus the user loses access and is logged out.
 * @returns
 */
function Header() {
  const dispatch = useDispatch();
  const [generateExpiredToken] = useExpiredTokenMutation();
  // User Data if the user is logged in
  const userData: AuthUser = useSelector((state: RootState) => state.userAuthStore);
  // Navigatation Hook to go back to login if the user decides to logout
  const navigate = useNavigate();

  /**
   * Handles the Logout for a User, resets the Redux Store and
   * clears the HTTP Only Cookie
   */
  const handleLogout = async () => {
    await generateExpiredToken(null);
    dispatch(setLoggedInUser(emptyUserState));
    navigate("/login");
  };

  return (
    <div className="sia-header">
      <div className="sia-header-brand">
        <img src={siaLogo}></img>
      </div>
      <div className="sia-header-options">
        {userData.user.id && (
          <>
            <p>Welcome {userData.user.firstName}</p>
            <button onClick={async () => await handleLogout()} id="logout-user">
              <i className="bi bi-door-closed-fill"></i>
            </button>
          </>
        )}
      </div>
    </div>
  );

  // return (
  //   <div>
  //     <Navbar
  //       color="dark"
  //       dark={true}
  //       expand="md"
  //       style={{ padding: "1rem", paddingRight: "8rem" }}
  //     >
  //       <NavbarToggler onClick={toggle} />
  //       <Collapse isOpen={isOpen} navbar>
  //         <Nav className="me-auto" navbar>
  //           <NavItem className="nav-brand">
  //             <NavLink to="/households" className="nav-link">
  //               My Households <i className="bi bi-house"></i>
  //             </NavLink>
  //           </NavItem>
  //           <NavItem>
  //             <NavLink to="/documents" className="nav-link">
  //               My Documents <i className="bi bi-file-earmark-richtext"></i>
  //             </NavLink>
  //           </NavItem>
  //           {/* <NavItem>
  //             <NavLink to="/accounts" className="nav-link">
  //               Accounts
  //             </NavLink>
  //           </NavItem>
  //           <UncontrolledDropdown nav inNavbar>
  //             <DropdownToggle nav caret>
  //               Options
  //             </DropdownToggle>
  //             <DropdownMenu end>
  //               <DropdownItem>Option 1</DropdownItem>
  //               <DropdownItem>Option 2</DropdownItem>
  //               <DropdownItem divider />
  //               <DropdownItem>Reset</DropdownItem>
  //             </DropdownMenu>
  //           </UncontrolledDropdown> */}
  //         </Nav>
  //         <Nav navbar>
  //           <UncontrolledDropdown nav inNavbar>
  //             <DropdownToggle nav caret>
  //               <NavbarText>Welcome {userData.user.firstName}</NavbarText>{" "}
  //               <i className="bi bi-person-circle"></i>
  //             </DropdownToggle>
  //             <DropdownMenu>
  //               {userData.roles.indexOf("admin") != -1 && (
  //                 <DropdownItem>
  //                   {"Admin Panel"} <i className="bi bi-lock-fill"></i>
  //                 </DropdownItem>
  //               )}
  //               <DropdownItem>Option 2</DropdownItem>
  //               <DropdownItem divider />
  //               <DropdownItem onClick={handleLogout}>
  //                 Log out <i className="bi bi-door-closed-fill"></i>
  //               </DropdownItem>
  //             </DropdownMenu>
  //           </UncontrolledDropdown>
  //         </Nav>
  //       </Collapse>
  //     </Navbar>
  //   </div>
  // );
}

export default Header;
