import { ParticipantDetailsSectionProps } from "features/participant/types";
import { useEffect, useState } from "react";
import AccountOpeningBankAccountItem from "./AcountOpeningBackAccountItem";
import AccountOpeningTradingAccountItem from "./AccountOpeningTradingAccountItem";

/**
 * This is the Know Your Client Workflow Item List
 * 1. Know Your Client Form Completed
 * 2. Know Your Client Documents Uploaded
 * @param param0
 * @returns
 */
const AccountOpeningWorkflowItem = ({ participant }: ParticipantDetailsSectionProps) => {
  // Status Text of where we are in the process
  const [statusText, setStatusText] = useState("");
  const allStepsCompleted = participant?.accountHolderWorkflow?.tradingAccountCompleted;
  const previousStepCompleted = participant?.accountHolderWorkflow?.kycDocumentsUploaded;
  useEffect(() => {
    if (!participant?.accountHolderWorkflow?.kycDocumentsUploaded) {
      setStatusText("SIA KYC Not Completed");
    } else if (!participant?.accountHolderWorkflow?.bankAccountCompleted) {
      setStatusText("Bank Account Information Must be filled out");
    } else if (!participant?.accountHolderWorkflow?.tradingAccountCompleted) {
      setStatusText("Trading Account Information Must be filled out");
    } else {
      setStatusText("All Actions Items Are Completed For This Step. No Further Action Required");
    }
  }, [participant]);
  return (
    <div className="workflow-item">
      <div className="workflow-item-header">
        <p className="workflow-item-header-text">
          SIA Account Opening
          {!previousStepCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="Previous Step Not Completed"
            >
              <i className="bi bi-x-circle no-action"></i>
            </a>
          )}
          {previousStepCompleted && !allStepsCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="All Action Items Are Completed for this Step"
            >
              <i className="bi bi-exclamation-diamond not-complete"></i>
            </a>
          )}
          {previousStepCompleted && allStepsCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="Action Items Left To Complete"
            >
              <i className="bi bi-check-circle-fill complete"></i>
            </a>
          )}
        </p>
      </div>
      <div className="workflow-item-status-container">{statusText}</div>
      <div className="workflow-items-status-container">
        <AccountOpeningBankAccountItem participant={participant} />
        <AccountOpeningTradingAccountItem participant={participant} />
      </div>
    </div>
  );
};

export default AccountOpeningWorkflowItem;
