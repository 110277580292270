import withAuth from "HOC/withAuth";
import "../styles/participant.scss";
import { ReturnIconSvg } from "assets/svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddRolesToParticipantMutation,
  useLazyGetClientByIdQuery,
  useLazyGetParticipantBasicInformationQuery,
} from "apis/mainApi";
import { FormEvent, SetStateAction, useEffect, useState } from "react";
import { ApiResponseModel, Participant } from "types";
import { EmptyNewParticipant } from "constants/emptyStates";
import { MainLoader, MiniLoader } from "components/Shared";
import { ParticipantRoleMapping } from "constants/mappings/ParticipantRoleMapping";
import { ParticipantDetailsSectionProps, ParticipantProp } from "../types";
import $ from "jquery";
import maskString from "utils/maskString";
import { detailFormatter } from "../utils";
import { notify, numberWithCommas, sleep } from "utils";
import ParticipantDetails from "./ParticipantDetails";
import ParticipantWorkflows from "./ParticipantWorkflows";
import ParticipantAccounts from "./ParticipantAccounts";
import Modal from "components/Shared/Modal";
import ParticipantDocuments from "./ParticipantDocuments";
import { ParticipantRole, UserRoles } from "constants/enums";
import {
  AccountHolderFemale,
  BeneficiaryMale,
  DirectorFemale,
  PowerOfAttorneyFemale,
  ThirdPartyFemale,
  TrusteeMale,
} from "assets/icons";
import { ParticipantBasicInformation } from "types/Participant";
import ParticipantBankAccounts from "./ParticipantBankAccounts";
/**
 * This is the main participant page that will be displayed when a user clicks on a participant
 * from the household participants page or the participants page.
 * @returns
 */

interface ParticipantPageTabComponentProp {
  component: React.ComponentType<ParticipantDetailsSectionProps>;
}

/**
 * This component renders the entire participant page
 * We make two calls to the DB to get the participant basic information and the full participant information
 * The basic information is used for the header and the full participant information is used for the tabs
 * This allows a little bit of a speed-up when we update roles or other information
 * @returns
 */
const ParticipantPage = () => {
  // Navigation
  const navigate = useNavigate();
  // Participant Query
  const [participantBasicInformationQuery, participantBasicInformation] = useLazyGetParticipantBasicInformationQuery();

  const [participantQuery, participantQueryResult] = useLazyGetClientByIdQuery();

  // Participant State For the Header
  const [basicParticipant, setBasicParticipant] = useState<ParticipantBasicInformation>();
  // State for the participant result
  const [fullParticipant, setFullParticipant] = useState<Participant>();
  // Pariticipant Id from the parameters
  const { participantId } = useParams();
  // Loading State
  const [loading, setLoading] = useState(false);
  // Error State for the participant query
  const [error, setError] = useState(false);
  // Current Component State
  const [currentComponentStep, setCurrentComponentStep] = useState(0);
  // Needs to be loaded in here because the component prop is not a constant
  const pageComponents: ParticipantPageTabComponentProp[] = [
    ...(basicParticipant?.roles.includes(ParticipantRole.ACCOUNT_HOLDER.toLocaleLowerCase())
      ? [
          {
            component: ParticipantWorkflows,
          },
        ]
      : []),
    {
      component: ParticipantDetails,
    },
    ...(basicParticipant?.accountHolderWorkflow?.kycDocumentsUploaded
      ? [
          {
            component: ParticipantDocuments,
          },
        ]
      : []),
    ...(basicParticipant?.accountHolderWorkflow?.bankAccountCompleted
      ? [
          {
            component: ParticipantBankAccounts,
          },
        ]
      : []),
    ...(basicParticipant?.accountHolderWorkflow?.tradingAccountCompleted
      ? [
          {
            component: ParticipantAccounts,
          },
        ]
      : []),
  ];

  // Get the Current Compontent
  const CurrentComponent = pageComponents[currentComponentStep].component;

  // Use Effect when Participant Id is not null
  useEffect(() => {
    if (participantId) {
      setLoading(true);
      participantBasicInformationQuery(participantId);
      participantQuery(participantId);
    }
  }, [participantId]);

  // Use Effect for the Participant(s) result
  useEffect(() => {
    if (
      participantBasicInformation.data &&
      participantBasicInformation.isSuccess &&
      participantQueryResult.data &&
      participantQueryResult.isSuccess
    ) {
      //console.log(participantResult.data.result);
      setBasicParticipant(participantBasicInformation.data.result);
      setFullParticipant(participantQueryResult.data.result);
      setLoading(false);
      setError(false);
    } else {
      setError(true);
    }
  }, [participantBasicInformation.data, participantQueryResult.data]);

  return (
    <div className="main-participant-detail-page-container">
      {loading && <MainLoader />}
      {basicParticipant && <ParticipantPageHeader participant={basicParticipant} />}
      {fullParticipant && (
        <ParticipantPageTabs setCurrentComponentStep={setCurrentComponentStep} participant={fullParticipant} />
      )}
      {fullParticipant && <CurrentComponent participant={fullParticipant} />}
    </div>
  );
};

/**
 * Component for the participant page header
 * @todo: Create the Add Roles Feature
 * @todo: Get the household for the current Id
 * @param param0
 * @returns
 */
const ParticipantPageHeader = ({ participant }: ParticipantProp) => {
  const navigate = useNavigate();
  const [showAddRolesModal, setShowAddRolesModal] = useState(false);

  return (
    <div className="page-header">
      <div className="left-side-header">
        <div className="page-header-rtn-container">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <ReturnIconSvg />
          </button>
        </div>
        {participant && (
          <div className="state-information">
            <p className="page-header-primary-text">
              {participant.firstName} {participant.lastName}
            </p>
            <p className="page-header-secondary-text">{participant.id}</p>
            <p className="page-header-secondary-text">Household: {participant.householdName}</p>
          </div>
        )}
      </div>
      <div className="right-side-header">
        {participant && !showAddRolesModal && (
          <div className="participant-roles-container">
            <p className="container-title">Roles</p>
            {participant.roles.length == 0 && <p>None Found</p>}
            {participant.roles.map((role, index) => {
              return <p key={index}>{ParticipantRoleMapping[role.toLowerCase()]}</p>;
            })}
            <div className="participant-roles-container">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setShowAddRolesModal(true);
                }}
              >
                Add/Edit Role
              </button>
            </div>
          </div>
        )}
        {showAddRolesModal && <AddEditRolesForm participant={participant} setShowForm={setShowAddRolesModal} />}
      </div>
    </div>
  );
};

/**
 * Component for the Add/Edit Roles Form for a Participant
 * @param param0
 * @returns
 */
const AddEditRolesForm = ({
  participant,
  setShowForm,
}: {
  participant: ParticipantBasicInformation;
  setShowForm: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const [participantCopy, setParticipantCopy] = useState<ParticipantBasicInformation>({
    ...participant,
  });
  const [rolesMutation] = useAddRolesToParticipantMutation();
  const handleIconRoleChange = (roleGuid: string) => {
    if (participantCopy.roles.includes(roleGuid)) {
      const tempRoles = participantCopy.roles.filter((role) => role !== roleGuid);
      setParticipantCopy({ ...participantCopy, roles: tempRoles });
    } else {
      setParticipantCopy({
        ...participantCopy,
        roles: [...participantCopy.roles, roleGuid],
      });
    }
  };

  const handleSubmitRoleChange = async (e: FormEvent) => {
    e.preventDefault();
    // Create the payload
    const payload = {
      participantId: participantCopy.id,
      roles: participantCopy.roles,
    };

    // Call the mutation
    const result: ApiResponseModel = await rolesMutation(payload);
    if (result.data?.isSuccess) {
      notify("Roles Updated Successfully.", "success");
      setShowForm(false);
    } else {
      notify("Error on Roles Update", "error");
      console.log(result);
    }
  };

  return (
    <div className="add-roles-container">
      <form onSubmit={handleSubmitRoleChange}>
        <div className="form-group">
          <AccountHolderFemale
            className="role-icon"
            active={participantCopy.roles.includes(ParticipantRole.ACCOUNT_HOLDER.toLocaleLowerCase())}
            onClick={() => {
              handleIconRoleChange(ParticipantRole.ACCOUNT_HOLDER.toLocaleLowerCase());
            }}
          />
          <label>Account Holder</label>
        </div>
        <div className="form-group">
          <BeneficiaryMale
            className="role-icon"
            active={participantCopy.roles.includes(ParticipantRole.BENEFICIARY.toLocaleLowerCase())}
            onClick={() => {
              handleIconRoleChange(ParticipantRole.BENEFICIARY.toLocaleLowerCase());
            }}
          />
          <label>Beneficiary</label>
        </div>
        <div className="form-group">
          <DirectorFemale
            className="role-icon"
            active={participantCopy.roles.includes(ParticipantRole.DIRECTOR.toLocaleLowerCase())}
            onClick={() => {
              handleIconRoleChange(ParticipantRole.DIRECTOR.toLocaleLowerCase());
            }}
          />
          <label>Director</label>
        </div>
        <div className="form-group">
          <ThirdPartyFemale
            className="role-icon"
            active={participantCopy.roles.includes(ParticipantRole.THIRD_PARTY.toLocaleLowerCase())}
            onClick={() => {
              handleIconRoleChange(ParticipantRole.THIRD_PARTY.toLocaleLowerCase());
            }}
          />
          <label>Third Party</label>
        </div>
        <div className="form-group">
          <TrusteeMale
            className="role-icon"
            active={participantCopy.roles.includes(ParticipantRole.TRUSTEE.toLocaleLowerCase())}
            onClick={() => {
              handleIconRoleChange(ParticipantRole.TRUSTEE.toLocaleLowerCase());
            }}
          />
          <label>Trustee</label>
        </div>
        <div className="form-group">
          <PowerOfAttorneyFemale
            className="role-icon"
            active={participantCopy.roles.includes(ParticipantRole.POWER_OF_ATTORNEY.toLocaleLowerCase())}
            onClick={() => {
              handleIconRoleChange(ParticipantRole.POWER_OF_ATTORNEY.toLocaleLowerCase());
            }}
          />
          <label>Power of Attorney</label>
        </div>
        <button className="btn btn-warning" onClick={() => setShowForm(false)}>
          Cancel
        </button>
        <button className="btn btn-primary">Change Roles</button>
      </form>
    </div>
  );
};

interface ParticipantPageTabsProps {
  setCurrentComponentStep: React.Dispatch<SetStateAction<number>>;
  participant: Participant;
}

/**
 * Component that renders the tabs for the participant page
 * @param param0
 * @returns
 */
const ParticipantPageTabs = ({ setCurrentComponentStep, participant }: ParticipantPageTabsProps) => {
  const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    // Remove the active class from all the tabs using jquery
    $(".participant-page-tab").removeClass("active");
    // Add the active class to the component that was clicked
    $(e.currentTarget).parent().addClass("active");
    // Set the current component index
    setCurrentComponentStep(index);
  };

  return (
    <div className="participant-page-tabs">
      {participant?.roles.includes(ParticipantRole.ACCOUNT_HOLDER.toLocaleLowerCase()) && (
        <div className="participant-page-tab active">
          <button onClick={(e) => handleTabClick(e, 0)}>Workflows</button>
        </div>
      )}
      <div className="participant-page-tab">
        <button onClick={(e) => handleTabClick(e, 1)}>Details</button>
      </div>
      {participant?.accountHolderWorkflow?.kycDocumentsUploaded && (
        <div className="participant-page-tab">
          <button onClick={(e) => handleTabClick(e, 2)}>Documents</button>
        </div>
      )}
      {participant?.accountHolderWorkflow?.bankAccountCompleted && (
        <div className="participant-page-tab">
          <button onClick={(e) => handleTabClick(e, 3)}>Banking</button>
        </div>
      )}
      {participant?.accountHolderWorkflow?.tradingAccountCompleted && (
        <div className="participant-page-tab">
          <button onClick={(e) => handleTabClick(e, 4)}>Investments</button>
        </div>
      )}
    </div>
  );
};

export default withAuth(ParticipantPage);
