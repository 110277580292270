import React from "react";
import KYCBoolean from "./KYCBoolean";
import { KYCSectionProps } from "../types";
import { InsiderDetails } from "features/participant/components/ParticipantDetails";
import KYCInput from "./KYCInput";
import KYCCountrySelect from "./KYCCountrySelect";

function KYCRegulatoryDetails({ formData, setFormData }: KYCSectionProps) {
  // Handles the change of any boolean attribute in the regulatory details
  const handleRegulatoryDetailChange = (val: number | string | boolean, attrName: string) => {
    let regulatoryDetails = { ...formData.regulatoryDetails };

    regulatoryDetails[attrName] = val;
    setFormData({ ...formData, regulatoryDetails: regulatoryDetails });
  };

  // General handler for any insider detail attribute
  const handleGenericInsiderDetail = (
    val: string | number | boolean,
    insiderAttrName: string,
    attrName: string
  ) => {
    let tempData = { ...formData.regulatoryDetails };
    if (!tempData[insiderAttrName]) {
      tempData[insiderAttrName] = {};
    }

    tempData[insiderAttrName][attrName] = val;
    setFormData({ ...formData, regulatoryDetails: tempData });
  };

  return (
    <div className="kyc-section-container">
      <div className="kyc-section-container-header">
        <h1>Regulatory Details</h1>
        <p>Please Fill Out All Required Fields</p>
      </div>
      <div className="kyc-section-container-body">
        <KYCBoolean
          label="Does the Participant have a Power of Attorney?"
          value={formData?.regulatoryDetails?.hasPowerOfAttorney}
          onChange={(e) => handleRegulatoryDetailChange(e, "hasPowerOfAttorney")}
        />
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant an Insider?"
          value={formData?.regulatoryDetails?.isInsider}
          onChange={(e) => handleRegulatoryDetailChange(e, "isInsider")}
        />

        {formData?.regulatoryDetails?.isInsider && (
          <InsiderDetails
            participant={formData}
            editMode={true}
            setParticipant={setFormData}
            insiderAttrName="insiderDetails"
          />
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant a 10% Shareholder of a Company?"
          value={formData?.regulatoryDetails?.is10PercentShareholder}
          onChange={(e) => handleRegulatoryDetailChange(e, "is10PercentShareholder")}
        />
        {formData?.regulatoryDetails?.is10PercentShareholder && (
          <InsiderDetails
            participant={formData}
            editMode={true}
            setParticipant={setFormData}
            insiderAttrName="shareholder10Details"
          />
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant a 20% Shareholder of a Company?"
          value={formData?.regulatoryDetails?.is20PercentShareholder}
          onChange={(e) => handleRegulatoryDetailChange(e, "is20PercentShareholder")}
        />
        {formData?.regulatoryDetails?.is20PercentShareholder && (
          <InsiderDetails
            participant={formData}
            editMode={true}
            setParticipant={setFormData}
            insiderAttrName="shareholder20Details"
          />
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant an IIROC Member?"
          value={formData?.regulatoryDetails?.isIIROCMember}
          onChange={(e) => handleRegulatoryDetailChange(e, "isIIROCMember")}
          hasTooltip={true}
          tooltipText="IIROC oversees the registration of firms and individuals who provide advice and conduct securities trading in Canada, e.g. Fidelity Clearing Canada ULC"
        />
        {formData.regulatoryDetails?.isIIROCMember && (
          <>
            <KYCInput
              label="Name Of Firm"
              value={formData.regulatoryDetails?.iirocDetails?.nameOfFirm}
              onChange={(e) => handleGenericInsiderDetail(e, "iirocDetails", "nameOfFirm")}
            />
          </>
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant a Foreign PEP?"
          value={formData?.regulatoryDetails?.isForeignPEP}
          onChange={(e) => handleRegulatoryDetailChange(e, "isForeignPEP")}
          hasTooltip={true}
          tooltipText="A Politically Exposed Person (PEP) is an individual who holds a prominent governmental or political position in a country other than their own, typically associated with a higher risk of financial misconduct due to their access to public resources and power."
        />
        {formData.regulatoryDetails?.isForeignPEP && (
          <>
            <KYCCountrySelect
              label="Country"
              value={formData.regulatoryDetails?.foreignPEPDetails?.positionHeld}
              onChange={(e) => handleGenericInsiderDetail(e, "foreignPEPDetails", "positionHeld")}
            />
            <KYCInput
              label="Position Held"
              value={formData.regulatoryDetails?.foreignPEPDetails?.country}
              onChange={(e) => handleGenericInsiderDetail(e, "foreignPEPDetails", "country")}
            />
          </>
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant an Associate of a Foreign PEP?"
          value={formData?.regulatoryDetails?.isAssociateForeignPEP}
          onChange={(e) => handleRegulatoryDetailChange(e, "isAssociateForeignPEP")}
        />
        {formData.regulatoryDetails?.isAssociateForeignPEP && (
          <>
            <KYCInput
              label="Associate Name"
              value={formData.regulatoryDetails?.foreignAssociatePEPDetails?.associateName}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "associateName")
              }
            />
            <KYCCountrySelect
              label="Country"
              value={formData.regulatoryDetails?.foreignAssociatePEPDetails?.country}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "country")
              }
            />
            <KYCInput
              label="Organization"
              value={formData.regulatoryDetails?.foreignAssociatePEPDetails?.organizationName}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "organizationName")
              }
            />
            <KYCInput
              label="Job Title"
              value={formData.regulatoryDetails?.foreignAssociatePEPDetails?.jobTitle}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "jobTitle")
              }
            />
            <KYCInput
              label="Relationship"
              value={formData.regulatoryDetails?.foreignAssociatePEPDetails?.relationship}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "relationship")
              }
            />
            <KYCInput
              label="Position Held"
              value={formData.regulatoryDetails?.foreignAssociatePEPDetails?.positionHeld}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "positionHeld")
              }
            />
          </>
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant a Domestic PEP?"
          value={formData?.regulatoryDetails?.isDomesticPEP}
          onChange={(e) => handleRegulatoryDetailChange(e, "isDomesticPEP")}
        />
        {formData.regulatoryDetails?.isDomesticPEP && (
          <>
            <KYCInput
              label="Position Held"
              value={formData.regulatoryDetails?.domesticPEPDetails?.positionHeld}
              onChange={(e) => handleGenericInsiderDetail(e, "domesticPEPDetails", "positionHeld")}
            />
          </>
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant an Associate Domestic PEP?"
          value={formData?.regulatoryDetails?.isAssociateDomesticPEP}
          onChange={(e) => handleRegulatoryDetailChange(e, "isAssociateDomesticPEP")}
        />
        {formData.regulatoryDetails?.isAssociateDomesticPEP && (
          <>
            <KYCInput
              label="Associate Name"
              value={formData.regulatoryDetails?.domesticAssociatePEPDetails?.associateName}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "domesticAssociatePEPDetails", "associateName")
              }
            />
            <KYCInput
              label="Job Title"
              value={formData.regulatoryDetails?.domesticAssociatePEPDetails?.jobTitle}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "domesticAssociatePEPDetails", "jobTitle")
              }
            />
            <KYCInput
              label="Relationship"
              value={formData.regulatoryDetails?.domesticAssociatePEPDetails?.relationship}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "domesticAssociatePEPDetails", "relationship")
              }
            />
            <KYCInput
              label="Position Held"
              value={formData.regulatoryDetails?.domesticAssociatePEPDetails?.positionHeld}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "domesticAssociatePEPDetails", "positionHeld")
              }
            />
            <KYCInput
              label="Organization"
              value={formData.regulatoryDetails?.domesticAssociatePEPDetails?.organizationName}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "domesticAssociatePEPDetails", "organizationName")
              }
            />
          </>
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant a HIO?"
          value={formData?.regulatoryDetails?.isHIO}
          onChange={(e) => handleRegulatoryDetailChange(e, "isHIO")}
          hasTooltip={true}
          tooltipText="A Head of International Organization (HIO) is an individual who occupies a senior leadership position within a global or intergovernmental organization."
        />
        {formData.regulatoryDetails?.isHIO && (
          <>
            <KYCInput
              label="Job Title"
              value={formData.regulatoryDetails?.hioDetails?.jobTitle}
              onChange={(e) => handleGenericInsiderDetail(e, "hioDetails", "jobTitle")}
            />
            <KYCCountrySelect
              label="Country"
              value={formData.regulatoryDetails?.hioDetails?.country}
              onChange={(e) => handleGenericInsiderDetail(e, "hioDetails", "country")}
            />
            <KYCInput
              label="Name of Organization"
              value={formData.regulatoryDetails?.hioDetails?.nameOfOrganization}
              onChange={(e) => handleGenericInsiderDetail(e, "hioDetails", "nameOfOrganization")}
            />
          </>
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant an Associate of a HIO?"
          value={formData?.regulatoryDetails?.isAssociateHIO}
          onChange={(e) => handleRegulatoryDetailChange(e, "isAssociateHIO")}
        />
        {formData.regulatoryDetails?.isAssociateHIO && (
          <>
            <KYCInput
              label="Name Of Associate"
              value={formData.regulatoryDetails?.associateHIODetails?.nameOfAssociate}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "associateHIODetails", "nameOfAssociate")
              }
            />
            <KYCInput
              label="Job Title"
              value={formData.regulatoryDetails?.associateHIODetails?.jobTitle}
              onChange={(e) => handleGenericInsiderDetail(e, "associateHIODetails", "jobTitle")}
            />
            <KYCCountrySelect
              label="Country"
              value={formData.regulatoryDetails?.associateHIODetails?.country}
              onChange={(e) => handleGenericInsiderDetail(e, "associateHIODetails", "country")}
            />
            <KYCInput
              label="Name of Organization"
              value={formData.regulatoryDetails?.associateHIODetails?.nameOfOrganization}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "associateHIODetails", "nameOfOrganization")
              }
            />
          </>
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant an Associate of a Securities Firm Member?"
          value={formData?.regulatoryDetails?.isSecuritiesFirm}
          onChange={(e) => handleRegulatoryDetailChange(e, "isSecuritiesFirm")}
          hasTooltip={true}
          tooltipText="A Securities Firm Member is an individual who is a director, officer, partner, employee, or agent of a securities firm."
        />
        {formData?.regulatoryDetails?.isSecuritiesFirm && (
          <>
            <KYCInput
              label="Associate Name"
              value={formData.regulatoryDetails?.securitiesFirmInformation?.name}
              onChange={(e) => handleGenericInsiderDetail(e, "securitiesFirmInformation", "name")}
            />
            <KYCInput
              label="Relationship"
              value={formData.regulatoryDetails?.securitiesFirmInformation?.relationship}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "securitiesFirmInformation", "relationship")
              }
            />
            <KYCInput
              label="Company Name"
              value={formData.regulatoryDetails?.securitiesFirmInformation?.companyName}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "securitiesFirmInformation", "companyName")
              }
            />
            <KYCInput
              label="Company Position"
              value={formData.regulatoryDetails?.securitiesFirmInformation?.companyPosition}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "securitiesFirmInformation", "companyPosition")
              }
            />
          </>
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Is the Participant an Associate of a Broker or Dealer Member?"
          value={formData?.regulatoryDetails?.isBrokerDealer}
          onChange={(e) => handleRegulatoryDetailChange(e, "isBrokerDealer")}
          hasTooltip={true}
          tooltipText="A Broker or Dealer Member is an individual who is a director, officer, partner, employee, or agent of a broker or dealer."
        />
        {formData?.regulatoryDetails?.isBrokerDealer && (
          <>
            <KYCInput
              label="Associate Name"
              value={formData.regulatoryDetails?.brokerDealerInformation?.name}
              onChange={(e) => handleGenericInsiderDetail(e, "brokerDealerInformation", "name")}
            />
            <KYCInput
              label="Relationship"
              value={formData.regulatoryDetails?.brokerDealerInformation?.relationship}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "brokerDealerInformation", "relationship")
              }
            />
            <KYCInput
              label="Company Name"
              value={formData.regulatoryDetails?.brokerDealerInformation?.companyName}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "brokerDealerInformation", "companyName")
              }
            />
            <KYCInput
              label="Company Position"
              value={formData.regulatoryDetails?.brokerDealerInformation?.companyPosition}
              onChange={(e) =>
                handleGenericInsiderDetail(e, "brokerDealerInformation", "companyPosition")
              }
            />
          </>
        )}
        <div className="regulatory-divider"></div>
        <KYCBoolean
          label="Does Participant Trade With Other Firms?"
          value={formData?.regulatoryDetails?.isTradingWithOtherFirms}
          onChange={(e) => handleRegulatoryDetailChange(e, "isTradingWithOtherFirms")}
        />
        {formData?.regulatoryDetails?.isTradingWithOtherFirms && (
          <KYCInput
            label="Name Of Other Firm"
            value={formData.regulatoryDetails?.nameOfOtherFirm}
            onChange={(e) => handleRegulatoryDetailChange(e, "nameOfOtherFirm")}
          />
        )}
      </div>
    </div>
  );
}

export default KYCRegulatoryDetails;
