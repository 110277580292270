export const DefaultInstitutionMapping: any = {
  1: "BMO",
  2: "ScotiaBank",
  3: "RBC",
  4: "TD",
  5: "NationalBank",
  6: "CIBC",
  7: "HSBC",
  8: "CandianWesternBank",
  9: "LaurentianBank",
  10: "ManulifeBank",
  11: "ATBFinancial",
  12: "AlbertaCreditUnion",
  13: "Other",
};

export const ReverseInsitutionMapping: any = {
  BMO: 1,
  ScotiaBank: 2,
  RBC: 3,
  TD: 4,
  NationalBank: 5,
  CIBC: 6,
  HSBC: 7,
  CandianWesternBank: 8,
  LaurentianBank: 9,
  ManulifeBank: 10,
  ATBFinancial: 11,
  AlbertaCreditUnion: 12,
  Other: 13,
};

