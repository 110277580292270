import React from "react";
import SvgContainer from "./SvgContainer";
import { IconProps } from "./IconProps";

function TrusteeMale({ className, active, onClick }: IconProps) {
  const activeSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M117.000000,210.000000 
	C78.000000,210.000000 39.500004,210.000000 1.000005,210.000000 
	C1.000003,140.333344 1.000003,70.666679 1.000002,1.000015 
	C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
	C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
	C179.166672,210.000000 148.333328,210.000000 117.000000,210.000000 
M55.986332,42.902267 
	C51.378254,51.967514 50.440544,61.916340 50.167717,71.730522 
	C49.813705,84.465111 50.575310,97.237160 51.121525,109.981735 
	C51.185997,111.486130 52.583031,113.476051 53.934902,114.290230 
	C60.355083,118.156891 67.152687,121.097687 74.783592,119.983589 
	C77.005951,131.929230 66.339722,138.891708 57.240166,142.017181 
	C48.745087,144.935059 40.063053,147.474213 31.896946,151.144791 
	C20.154749,156.422806 18.986382,176.457840 29.951172,183.303391 
	C35.074211,186.501831 40.774742,189.072342 46.540379,190.891602 
	C68.235336,197.737183 90.330147,200.470444 113.202606,198.218185 
	C127.860428,196.774811 142.174927,194.314285 155.684570,188.772430 
	C163.260345,185.664719 172.659790,183.870804 173.952560,172.553726 
	C174.813965,165.012741 171.480072,155.182297 165.320282,150.657089 
	C157.346939,144.799591 147.566025,144.210678 138.826248,140.764481 
	C128.097717,136.534073 122.159950,133.923630 124.268814,121.946396 
	C130.242355,121.946396 136.162552,122.251961 142.028732,121.821503 
	C145.105255,121.595749 147.617081,119.855927 148.088623,116.033905 
	C149.001495,108.634628 150.267441,101.278900 151.946320,94.416847 
	C153.716949,94.645142 155.481949,95.002533 157.259155,95.080063 
	C166.425568,95.479958 167.908234,94.471695 166.599121,85.088173 
	C164.902939,72.930161 163.276764,60.750961 162.149765,48.532063 
	C161.821091,44.968456 162.385376,40.645420 164.246750,37.778297 
	C165.651108,35.615131 169.774750,35.217361 173.898087,33.542248 
	C170.189896,31.640028 167.173828,29.620443 163.855530,28.480862 
	C154.586166,25.297539 145.205063,22.435337 135.838882,19.540796 
	C132.861557,18.620682 129.620605,18.391102 126.799698,17.159676 
	C119.958466,14.173235 112.842758,12.978764 105.549736,13.838196 
	C96.595345,14.893410 87.724777,16.641005 78.793709,17.919798 
	C72.902176,18.763376 66.971931,19.346443 61.051678,19.977129 
	C53.947853,20.733898 46.785992,21.105021 39.744816,22.237335 
	C34.675690,23.052519 29.762924,24.840065 23.620527,26.509041 
	C35.442924,32.220520 45.717968,37.184464 55.986332,42.902267 
z"
      />
      <path
        fill="#231F20"
        opacity="1.000000"
        stroke="none"
        d="
M151.387024,93.905128 
	C150.267441,101.278900 149.001495,108.634628 148.088623,116.033905 
	C147.617081,119.855927 145.105255,121.595749 142.028732,121.821503 
	C136.162552,122.251961 130.242355,121.946396 124.268814,121.946396 
	C122.159950,133.923630 128.097717,136.534073 138.826248,140.764481 
	C147.566025,144.210678 157.346939,144.799591 165.320282,150.657089 
	C171.480072,155.182297 174.813965,165.012741 173.952560,172.553726 
	C172.659790,183.870804 163.260345,185.664719 155.684570,188.772430 
	C142.174927,194.314285 127.860428,196.774811 113.202606,198.218185 
	C90.330147,200.470444 68.235336,197.737183 46.540379,190.891602 
	C40.774742,189.072342 35.074211,186.501831 29.951172,183.303391 
	C18.986382,176.457840 20.154749,156.422806 31.896946,151.144791 
	C40.063053,147.474213 48.745087,144.935059 57.240166,142.017181 
	C66.339722,138.891708 77.005951,131.929230 74.783592,119.983589 
	C67.152687,121.097687 60.355083,118.156891 53.934902,114.290230 
	C52.583031,113.476051 51.185997,111.486130 51.121525,109.981735 
	C50.575310,97.237160 49.813705,84.465111 50.167717,71.730522 
	C50.440544,61.916340 51.378254,51.967514 56.351013,42.487503 
	C62.388420,44.793213 68.068275,47.498955 73.731781,50.238487 
	C78.969566,52.772083 84.349129,53.696354 90.146255,52.410004 
	C96.292351,51.046219 102.546143,50.168552 108.693794,48.810917 
	C119.829277,46.351780 130.920883,43.693947 142.016022,41.493568 
	C147.293869,53.361778 149.524719,65.640038 151.247345,78.480774 
	C151.278381,83.914368 151.332703,88.909744 151.387024,93.905128 
z"
      />
      <path
        fill="#2C7EF3"
        opacity="1.000000"
        stroke="none"
        d="
M151.666672,94.160988 
	C151.332703,88.909744 151.278381,83.914368 151.537781,78.213875 
	C153.319229,64.803619 157.095490,52.189434 153.287872,38.338329 
	C149.440125,39.288807 145.735474,40.203938 142.030807,41.119068 
	C130.920883,43.693947 119.829277,46.351780 108.693794,48.810917 
	C102.546143,50.168552 96.292351,51.046219 90.146255,52.410004 
	C84.349129,53.696354 78.969566,52.772083 73.731781,50.238487 
	C68.068275,47.498955 62.388420,44.793213 56.354355,42.110573 
	C45.717968,37.184464 35.442924,32.220520 23.620527,26.509041 
	C29.762924,24.840065 34.675690,23.052519 39.744816,22.237335 
	C46.785992,21.105021 53.947853,20.733898 61.051678,19.977129 
	C66.971931,19.346443 72.902176,18.763376 78.793709,17.919798 
	C87.724777,16.641005 96.595345,14.893410 105.549736,13.838196 
	C112.842758,12.978764 119.958466,14.173235 126.799698,17.159676 
	C129.620605,18.391102 132.861557,18.620682 135.838882,19.540796 
	C145.205063,22.435337 154.586166,25.297539 163.855530,28.480862 
	C167.173828,29.620443 170.189896,31.640028 173.898087,33.542248 
	C169.774750,35.217361 165.651108,35.615131 164.246750,37.778297 
	C162.385376,40.645420 161.821091,44.968456 162.149765,48.532063 
	C163.276764,60.750961 164.902939,72.930161 166.599121,85.088173 
	C167.908234,94.471695 166.425568,95.479958 157.259155,95.080063 
	C155.481949,95.002533 153.716949,94.645142 151.666672,94.160988 
z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M142.016022,41.493568 
	C145.735474,40.203938 149.440125,39.288807 153.287872,38.338329 
	C157.095490,52.189434 153.319229,64.803619 151.561066,77.775665 
	C149.524719,65.640038 147.293869,53.361778 142.016022,41.493568 
z"
      />
    </svg>
  );

  const passiveSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M125.000000,210.000000 
	C83.333336,210.000000 42.166672,210.000000 1.000005,210.000000 
	C1.000004,140.333344 1.000004,70.666679 1.000002,1.000015 
	C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
	C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
	C181.833328,210.000000 153.666672,210.000000 125.000000,210.000000 
M117.139038,14.001787 
	C87.259628,16.372499 57.718143,20.950134 28.310934,26.951267 
	C29.000435,28.233406 29.623726,28.735430 30.325243,29.076612 
	C39.213913,33.399620 48.134411,37.658077 56.986118,42.055130 
	C58.156555,42.636547 59.450714,43.716133 59.898243,44.870903 
	C61.485619,48.966843 62.502831,53.289276 64.173164,57.345913 
	C65.693062,61.037193 65.327812,64.287880 62.516171,66.861588 
	C59.699951,69.439491 59.666901,71.990662 60.920544,75.295006 
	C66.150063,89.078979 71.088142,102.974106 76.375366,116.735382 
	C79.523918,124.930214 78.422516,131.266342 71.704819,136.652802 
	C68.936729,138.872345 65.662422,140.707916 62.328659,141.923843 
	C55.095837,144.561844 47.667305,146.660065 40.335991,149.032135 
	C33.291962,151.311249 28.179865,155.586761 26.477581,163.097260 
	C26.007206,165.172562 26.121773,167.375458 25.719233,169.472076 
	C24.659992,174.989090 26.663101,180.867569 31.339670,183.586472 
	C35.595684,186.060852 40.069614,188.329926 44.705353,189.952972 
	C67.323318,197.871872 90.414650,201.525192 114.559174,199.211212 
	C130.570999,197.676666 146.133026,194.918976 160.938919,188.789719 
	C166.161575,186.627670 172.751175,183.935532 175.093246,179.550262 
	C178.558945,173.061111 178.049271,164.836594 174.175186,157.766266 
	C170.614182,151.267349 164.446030,148.231415 157.718674,146.015228 
	C149.961273,143.459702 142.011459,141.307678 134.573868,138.012802 
	C127.188171,134.740906 124.748093,126.354248 127.953018,119.092529 
	C132.796783,108.117546 137.578720,97.115303 142.414780,86.136917 
	C145.716385,78.641930 146.894470,71.359718 141.040375,64.383392 
	C140.225159,63.411903 139.950623,61.575249 140.146576,60.245289 
	C140.694489,56.526012 141.717346,52.879669 142.376724,49.173222 
	C143.717209,41.638096 147.042969,38.685925 156.610718,40.675484 
	C159.266464,50.228352 157.627670,59.656010 156.117813,69.113007 
	C154.953796,76.403831 153.756836,83.713364 153.225113,91.061653 
	C153.113876,92.598869 155.946457,95.805885 157.260208,95.721359 
	C161.516800,95.447502 166.773819,98.508209 169.888504,92.980179 
	C170.036972,92.716644 169.999100,92.323235 169.980103,91.994225 
	C169.846786,89.685547 169.794479,87.366661 169.533798,85.071777 
	C168.050842,72.016373 166.212982,58.992085 165.123184,45.904972 
	C164.543594,38.944729 166.977722,36.850136 173.813782,35.948044 
	C174.928238,35.800980 175.967667,35.085415 177.041763,34.632614 
	C177.105194,33.961414 177.168640,33.290215 177.232071,32.619011 
	C157.498703,26.416939 137.765350,20.214863 117.139038,14.001787 
z"
      />
      <path
        fill="#929497"
        opacity="1.000000"
        stroke="none"
        d="
M117.585510,14.007288 
	C137.765350,20.214863 157.498703,26.416939 177.232071,32.619011 
	C177.168640,33.290215 177.105194,33.961414 177.041763,34.632614 
	C175.967667,35.085415 174.928238,35.800980 173.813782,35.948044 
	C166.977722,36.850136 164.543594,38.944729 165.123184,45.904972 
	C166.212982,58.992085 168.050842,72.016373 169.533798,85.071777 
	C169.794479,87.366661 169.846786,89.685547 169.980103,91.994225 
	C169.999100,92.323235 170.036972,92.716644 169.888504,92.980179 
	C166.773819,98.508209 161.516800,95.447502 157.260208,95.721359 
	C155.946457,95.805885 153.113876,92.598869 153.225113,91.061653 
	C153.756836,83.713364 154.953796,76.403831 156.117813,69.113007 
	C157.627670,59.656010 159.266464,50.228352 156.610718,40.675484 
	C147.042969,38.685925 143.717209,41.638096 142.376724,49.173222 
	C141.717346,52.879669 140.694489,56.526012 140.146576,60.245289 
	C139.950623,61.575249 140.225159,63.411903 141.040375,64.383392 
	C146.894470,71.359718 145.716385,78.641930 142.414780,86.136917 
	C137.578720,97.115303 132.796783,108.117546 127.953018,119.092529 
	C124.748093,126.354248 127.188171,134.740906 134.573868,138.012802 
	C142.011459,141.307678 149.961273,143.459702 157.718674,146.015228 
	C164.446030,148.231415 170.614182,151.267349 174.175186,157.766266 
	C178.049271,164.836594 178.558945,173.061111 175.093246,179.550262 
	C172.751175,183.935532 166.161575,186.627670 160.938919,188.789719 
	C146.133026,194.918976 130.570999,197.676666 114.559174,199.211212 
	C90.414650,201.525192 67.323318,197.871872 44.705353,189.952972 
	C40.069614,188.329926 35.595684,186.060852 31.339670,183.586472 
	C26.663101,180.867569 24.659992,174.989090 25.719233,169.472076 
	C26.121773,167.375458 26.007206,165.172562 26.477581,163.097260 
	C28.179865,155.586761 33.291962,151.311249 40.335991,149.032135 
	C47.667305,146.660065 55.095837,144.561844 62.328659,141.923843 
	C65.662422,140.707916 68.936729,138.872345 71.704819,136.652802 
	C78.422516,131.266342 79.523918,124.930214 76.375366,116.735382 
	C71.088142,102.974106 66.150063,89.078979 60.920544,75.295006 
	C59.666901,71.990662 59.699951,69.439491 62.516171,66.861588 
	C65.327812,64.287880 65.693062,61.037193 64.173164,57.345913 
	C62.502831,53.289276 61.485619,48.966843 59.898243,44.870903 
	C59.450714,43.716133 58.156555,42.636547 56.986118,42.055130 
	C48.134411,37.658077 39.213913,33.399620 30.325243,29.076612 
	C29.623726,28.735430 29.000435,28.233406 28.310934,26.951267 
	C57.718143,20.950134 87.259628,16.372499 117.585510,14.007288 
z"
      />
    </svg>
  );

  return (
    <SvgContainer
      className={className}
      active={active}
      SvgPassive={passiveSvg}
      SvgActive={activeSvg}
      onClick={onClick}
    />
  );
}

export default TrusteeMale;
