interface SIABooleanProps {
  label: string;
  value: boolean | null | undefined;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

const SIABoolean = ({ label, value, disabled, onChange }: SIABooleanProps) => {
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === "true";
    onChange?.(newValue);
  };

  return (
    <div className="input-container">
      <div>
        <input
          type="radio"
          id={`${label}-true`}
          name={label}
          value="true"
          disabled={disabled}
          checked={value === true}
          onChange={handleRadioChange}
        />
        <label htmlFor={`${label}-true`}>Yes</label>
      </div>
      <div>
        <input
          type="radio"
          id={`${label}-false`}
          name={label}
          value="false"
          disabled={disabled}
          checked={value === false}
          onChange={handleRadioChange}
        />
        <label htmlFor={`${label}-false`}>No</label>
      </div>
    </div>
  );
};

export default SIABoolean;
