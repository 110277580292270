import {
  useCreateAdvisorMutation,
  useDeleteAdvisorMutation,
  useGetAllAdvisorsQuery,
} from "apis/advisorApi";
import { MainLoader, MiniLoader, SIAInput, SIASelect } from "components/Shared";
import Modal from "components/Shared/Modal";
import { UserRoles } from "constants/enums";
import { AuthUser } from "features/auth/types";
import withAuth from "HOC/withAuth";
import { FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "stores/store";
import { Advisor } from "types/Advisor";
import { notify } from "utils";
import "../styles/advisors.scss";
import { useGetAllUsersByRoleQuery } from "apis/userApi";
import { SelectItem } from "types/Shared";
import { ApiResponseModel } from "types";

function AdvisorsPage() {
  const userData: AuthUser = useSelector((state: RootState) => state.userAuthStore);
  const [dbAdvisors, setDbAdvisors] = useState<Advisor[]>([]);
  const [viewAdvisors, setViewAdvisors] = useState<Advisor[]>([]);
  const [searchText, setSearchText] = useState("");
  const [showModalBackdrop, setShowModalBackdrop] = useState(false);
  const [showCreateAdvisor, setShowCreateAdvisor] = useState(false);
  const { isError, isSuccess, data, isLoading, isFetching } = useGetAllAdvisorsQuery(null);

  useEffect(() => {
    if (isSuccess && data?.isSuccess) {
      const advisors: Advisor[] = data.result;
      setDbAdvisors(advisors);
      setViewAdvisors(advisors);
    } else if (isError) {
      notify("An error has occured when retrieving advisors", "error");
    }
  }, [data]);

  // Search Text Functionality
  // Set up the effect for the search text
  useEffect(() => {
    if (searchText.length > 0) {
      const filteredDbAdvisors = dbAdvisors.filter((advisor) => {
        return advisor.iaNumber.toLowerCase().includes(searchText.toLowerCase());
      });
      setViewAdvisors(filteredDbAdvisors);
    } else {
      setViewAdvisors(dbAdvisors);
    }
  }, [searchText, dbAdvisors]);

  return (
    <div className="main-page-list-container" id="advisors-page">
      {showModalBackdrop && <div className="backdrop"></div>}
      <div className="main-page-list-header">
        <div className="main-page-list-information">
          <p className="main-page-list-header-text">Advisors</p>
          <p className="main-page-list-subheader-text">All Advisors will show up here</p>
          {userData.roles.includes(UserRoles.ADMIN) && (
            <button
              id="add-advisor-btn"
              className="add-new-btn"
              onClick={() => {
                setShowCreateAdvisor(true);
                setShowModalBackdrop(true);
              }}
            >
              Add New
            </button>
          )}
        </div>
        <div className="main-page-list-search-container">
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Advisors..."
            id="search-advisors-input"
          ></input>
        </div>
      </div>
      <div className="hr"></div>
      <div className="main-list-container" id="advisors-list">
        {isLoading && (
          <div style={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}>
            {<MiniLoader />}
          </div>
        )}
        {viewAdvisors &&
          viewAdvisors.length > 0 &&
          viewAdvisors.map((advisor, index) => {
            return <AdvisorItem advisor={advisor} key={index} userData={userData} />;
          })}
      </div>
      <Modal
        id="create-advisor-modal"
        children={
          <CreateAdvisorForm
            setShowModal={setShowCreateAdvisor}
            setShowModalBackdrop={setShowModalBackdrop}
          />
        }
        show={showCreateAdvisor}
        modelTitle="Create New Advisor"
        setModalBackdrop={setShowModalBackdrop}
        setModelDisplay={setShowCreateAdvisor}
      ></Modal>
    </div>
  );
}

const AdvisorItem = ({ advisor, userData }: { advisor: Advisor; userData: AuthUser }) => {
  const navigate = useNavigate();

  const [deleteAdvisor] = useDeleteAdvisorMutation();

  const handleCreateAdvisor = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this advisor? Once delete, you cannot return them"
      )
    ) {
      const response: ApiResponseModel = await deleteAdvisor(advisor.id);
      if (response.data && response.data?.isSuccess) {
        notify("Advisor Deleted Successfully", "success");
      } else if (response.data && !response.data?.isSuccess) {
        if (response.data?.errorMessages && response.data?.errorMessages.length > 0) {
          response.data?.errorMessages.forEach((errorMessage) => {
            notify(errorMessage, "error");
          });
        } else {
          notify("An error occurred with deleting the advisor", "error");
        }
      } else if (response.error) {
        if (response.error.data?.errorMessages && response.error.data?.errorMessages.length > 0) {
          response.error.data?.errorMessages.forEach((errorMessage: string) => {
            notify(errorMessage, "error");
          });
        } else {
          notify("An error occurred with deleting the advisor", "error");
        }
      }
    }
  };

  return (
    <div className="main-list-row-item advisor-item">
      <div className="main-list-row-information">
        <p className="primary-info">{advisor.iaNumber}</p>
        <p>
          {advisor.userInformation.user.firstName} {advisor.userInformation.user.lastName}
        </p>
        <button
          style={{ marginTop: "0.5rem" }}
          className="btn btn-primary"
          onClick={() => navigate(`/households/${advisor.id}`)}
        >
          View Households
        </button>
      </div>
      <div className="main-list-action-items">
        <div>
          {/* <button className="btn btn-warning">Deactivate</button> */}
          {userData.roles.includes(UserRoles.ADMIN) && (
            <button
              className="btn btn-danger delete-advisor-btn"
              onClick={handleCreateAdvisor}
              id={`delete-advisor-${advisor.iaNumber}`}
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Component for the New Advisor Form
 * @param param0
 * @returns
 */
const CreateAdvisorForm = ({
  setShowModalBackdrop,
  setShowModal,
}: {
  setShowModalBackdrop: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [createError, setCreateError] = useState<string>("");
  const [advisorSelectItems, setAdvisorSelectItems] = useState<SelectItem[]>([]);
  const { isSuccess, isError, data, isLoading } = useGetAllUsersByRoleQuery(UserRoles.ADVISOR);
  const [formData, setFormData] = useState({
    iaNumber: "",
    applicationUserId: "",
  });
  // Create Advisor Mutation
  const [createAdvisor] = useCreateAdvisorMutation();
  // Use Effect to capture the results
  useEffect(() => {
    if (isSuccess && data?.isSuccess) {
      const advisors = data.result;
      const advisorSelectItems: SelectItem[] = advisors.map((advisor: AuthUser) => {
        return {
          label: `${advisor.user.firstName} ${advisor.user.lastName}`,
          value: advisor.user.id,
        };
      });

      setAdvisorSelectItems(advisorSelectItems);
    } else if (isError) {
      notify("An error occurred with retrieving the advisors", "error");
    } else if (!isLoading && !data?.isSuccess) {
      notify("An error occurred with retrieving the advisors", "error");
    }
  }, [data]);

  /**
   * Handler function to create a new advisor
   * @param e Form Event
   */
  const handleCreateAdvisor = async (e: FormEvent) => {
    e.preventDefault();
    if (!formData.iaNumber || !formData.applicationUserId) {
      setCreateError("Please fill out all the fields");
      return;
    }

    const advisor = {
      iaNumber: formData.iaNumber,
      applicationUserId: formData.applicationUserId,
    };

    const response: ApiResponseModel = await createAdvisor(advisor);
    if (response.data && response.data?.isSuccess) {
      notify("Advisor Created Successfully", "success");
      setShowModalBackdrop(false);
      setShowModal(false);
    } else if (response.data && !response.data?.isSuccess) {
      if (response.data?.errorMessages && response.data?.errorMessages.length > 0) {
        response.data?.errorMessages.forEach((errorMessage) => {
          notify(errorMessage, "error");
        });
      } else {
        notify("An error occurred with creating the advisor", "error");
      }
    } else if (response.error) {
      if (response.error.data?.errorMessages && response.error.data?.errorMessages.length > 0) {
        response.error.data?.errorMessages.forEach((errorMessage: string) => {
          notify(errorMessage, "error");
        });
      } else {
        notify("An error occurred with creating the advisor", "error");
      }
    }
  };

  return (
    <div className="create-advisor-form-container" id="create-advisors-form">
      <form className="create-advisor-form" onSubmit={handleCreateAdvisor}>
        <div className="create-advisor-form-control">
          <label>IA Number</label>
          <SIAInput
            value={formData.iaNumber}
            onChange={(e) => {
              setFormData({ ...formData, iaNumber: e.target.value });
            }}
            type="text"
            id="ia-number"
          />
        </div>
        <div className="create-advisor-form-control">
          <label>Application User</label>
          <SIASelect
            value={formData.applicationUserId}
            onChange={(val) => setFormData({ ...formData, applicationUserId: val })}
            options={advisorSelectItems}
            id="application-user"
          />
        </div>
        <div className="create-advisor-form-control-button">
          <button className="btn btn-primary" id="create-advisor-btn">
            Create
          </button>
        </div>
        <div className="create-advisor-form-error">
          {createError && <p id="advisor-error-message">{createError}</p>}
        </div>
      </form>
    </div>
  );
};

export default withAuth(AdvisorsPage);
