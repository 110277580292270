import { ParticipantSelectProps } from "features/participant/types";

const KYCSelect = ({
  label,
  value,
  options,
  disabled = false,
  onChange,
}: ParticipantSelectProps) => (
  <div className="kyc-label-input-container">
    <div className="kyc-label-container">
      <label>{label}</label>
    </div>
    <div className="kyc-input-container">
      <select
        value={value ? value : ""}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.value)}
      >
        <option value="">Option Not Selected</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="underline"></div>
    </div>
  </div>
);

export default KYCSelect;
