import { IconProps } from "./IconProps";
import SvgContainer from "./SvgContainer";

const PowerOfAttorneyFemale = ({ className, active, onClick }: IconProps) => {
  const activeSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M141.000000,210.000000 
	C94.000000,210.000000 47.500004,210.000000 1.000006,210.000000 
	C1.000004,140.333344 1.000004,70.666679 1.000002,1.000016 
	C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
	C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
	C187.166672,210.000000 164.333328,210.000000 141.000000,210.000000 
M189.140625,187.847412 
	C188.930954,187.627121 188.803146,187.366653 189.338455,186.578354 
	C189.478668,185.615067 190.082016,184.105804 189.688370,183.771912 
	C185.125107,179.901245 187.182236,174.711624 186.962982,170.090317 
	C186.801559,166.687820 185.802689,163.078506 189.401581,160.056824 
	C191.009293,158.706955 189.852570,155.978241 186.659866,155.984863 
	C171.522415,156.016281 156.384598,155.965302 141.247894,156.075378 
	C140.188278,156.083084 138.586243,156.935074 138.237274,157.798828 
	C137.895340,158.645172 138.734940,160.054733 139.243896,161.127975 
	C139.568344,161.812134 140.746475,162.410919 140.670486,162.917160 
	C139.679474,169.518539 143.568497,176.645660 138.456314,182.889038 
	C137.857178,183.620743 138.900345,185.697205 139.046478,187.536865 
	C138.815125,187.730103 138.724091,187.975388 138.039749,188.550125 
	C137.077194,189.834549 136.114624,191.118973 135.152054,192.403412 
	C136.415329,192.806351 137.676926,193.553726 138.942154,193.559906 
	C154.831360,193.637604 170.721100,193.608109 186.610703,193.606125 
	C188.368469,193.605911 190.126221,193.606094 191.883987,193.606094 
	C192.189194,193.069122 192.494400,192.532135 192.799622,191.995163 
	C191.604568,190.757462 190.409531,189.519745 189.140625,187.847412 
M141.071777,146.069519 
	C143.458817,144.626495 145.845856,143.183472 149.269119,141.113998 
	C143.528473,139.529922 138.803787,138.226196 133.707550,136.287369 
	C128.828033,134.497925 123.948509,132.708481 119.005959,130.041153 
	C119.005959,128.780106 119.005959,127.519051 119.005959,126.239342 
	C135.377151,125.238083 150.425186,121.796715 164.413589,110.219734 
	C162.429947,108.873299 160.585785,107.965828 159.177704,106.606583 
	C152.842911,100.491493 148.599304,93.049927 147.068100,84.418259 
	C144.773926,71.485710 143.060394,58.451244 140.980042,45.479347 
	C139.243500,34.651295 134.121857,26.826765 122.269913,24.956375 
	C119.753777,24.559299 116.495819,24.633862 114.945419,23.105577 
	C109.345642,17.585691 102.571983,17.534225 95.687141,18.056940 
	C82.642700,19.047310 71.520958,23.407490 64.633499,35.691956 
	C59.737671,44.424145 58.493713,53.953773 56.984062,63.518303 
	C55.593441,72.328697 54.530476,81.227753 52.472607,89.885582 
	C50.386219,98.663376 46.002113,106.357780 37.801609,111.396461 
	C50.400299,123.339027 66.035767,125.036697 82.004662,126.245224 
	C82.004662,127.881935 82.004662,129.358810 81.342155,131.168655 
	C77.153343,133.130463 72.964523,135.092255 67.964775,137.022537 
	C56.392811,140.338669 44.756386,143.450104 33.278324,147.064453 
	C28.174021,148.671753 23.341871,151.171982 21.464842,156.994812 
	C17.563620,169.096985 17.215393,176.967300 29.298498,182.941971 
	C44.339466,190.379211 60.499908,194.233017 77.152428,195.980453 
	C84.125046,196.712112 91.120544,197.225723 98.808044,197.966309 
	C99.659706,197.584610 100.511368,197.202911 101.898117,196.890747 
	C102.254097,196.857498 102.610069,196.824249 103.889000,196.981125 
	C113.467201,196.513733 123.045410,196.046341 131.477325,195.634872 
	C133.431671,189.744186 134.521500,184.859497 136.653549,180.482452 
	C138.790405,176.095490 138.483566,165.333374 135.229691,160.948929 
	C135.081116,159.834534 135.337875,158.356110 134.717560,157.665375 
	C130.960922,153.482376 133.262024,151.057053 136.943024,148.825653 
	C138.177017,148.077621 139.281540,147.116028 141.071777,146.069519 
M158.242737,138.690048 
	C150.727417,143.696167 142.656158,147.924347 135.522552,153.464600 
	C135.754898,153.797302 135.987228,154.130005 136.219559,154.462708 
	C154.792145,154.462708 173.364731,154.462708 191.937332,154.462708 
	C191.019363,152.496750 189.783081,151.517487 188.459488,150.675415 
	C181.868057,146.481964 175.119644,142.518356 168.699921,138.078506 
	C165.113480,135.598145 162.088531,135.434769 158.242737,138.690048 
z"
      />
      <path
        fill="#231F20"
        opacity="1.000000"
        stroke="none"
        d="
M135.228577,161.747467 
	C138.483566,165.333374 138.790405,176.095490 136.653549,180.482452 
	C134.521500,184.859497 133.431671,189.744186 131.477325,195.634872 
	C123.045410,196.046341 113.467201,196.513733 103.306290,196.707245 
	C102.330124,196.285370 101.936676,196.137390 101.344742,195.652466 
	C97.078690,182.201782 92.917778,169.115997 88.977402,155.964111 
	C86.483124,147.638916 84.317619,139.215210 82.004662,130.835693 
	C82.004662,129.358810 82.004662,127.881935 82.004662,126.245224 
	C66.035767,125.036697 50.400299,123.339027 37.801609,111.396461 
	C46.002113,106.357780 50.386219,98.663376 52.472607,89.885582 
	C54.530476,81.227753 55.593441,72.328697 56.984062,63.518303 
	C58.493713,53.953773 59.737671,44.424145 64.633499,35.691956 
	C71.520958,23.407490 82.642700,19.047310 95.687141,18.056940 
	C102.571983,17.534225 109.345642,17.585691 114.945419,23.105577 
	C116.495819,24.633862 119.753777,24.559299 122.269913,24.956375 
	C134.121857,26.826765 139.243500,34.651295 140.980042,45.479347 
	C143.060394,58.451244 144.773926,71.485710 147.068100,84.418259 
	C148.599304,93.049927 152.842911,100.491493 159.177704,106.606583 
	C160.585785,107.965828 162.429947,108.873299 164.413589,110.219734 
	C150.425186,121.796715 135.377151,125.238083 119.005959,126.239342 
	C119.005959,127.519051 119.005959,128.780106 118.772598,130.766846 
	C113.157524,151.915176 107.775810,172.337830 102.394089,192.760468 
	C104.329887,192.292557 105.571365,191.328018 106.550293,190.146042 
	C113.126343,182.206100 119.808884,174.345169 126.103973,166.186325 
	C128.575470,162.983093 131.201660,161.213959 135.228577,161.747467 
z"
      />
      <path
        fill="#231F21"
        opacity="1.000000"
        stroke="none"
        d="
M98.105652,197.838470 
	C91.120544,197.225723 84.125046,196.712112 77.152428,195.980453 
	C60.499908,194.233017 44.339466,190.379211 29.298498,182.941971 
	C17.215393,176.967300 17.563620,169.096985 21.464842,156.994812 
	C23.341871,151.171982 28.174021,148.671753 33.278324,147.064453 
	C44.756386,143.450104 56.392811,140.338669 68.300507,137.352600 
	C61.633648,145.391602 54.631050,153.100525 47.628452,160.809464 
	C54.989395,161.863754 61.455700,161.661255 67.895844,161.982788 
	C69.920609,162.083893 72.513329,163.057510 73.758049,164.543274 
	C81.747566,174.079987 89.480331,183.834305 97.152733,193.630478 
	C97.954407,194.654037 97.810715,196.418015 98.105652,197.838470 
z"
      />
      <path
        fill="#2C7CF0"
        opacity="1.000000"
        stroke="none"
        d="
M98.456848,197.902390 
	C97.810715,196.418015 97.954407,194.654037 97.152733,193.630478 
	C89.480331,183.834305 81.747566,174.079987 73.758049,164.543274 
	C72.513329,163.057510 69.920609,162.083893 67.895844,161.982788 
	C61.455700,161.661255 54.989395,161.863754 47.628452,160.809464 
	C54.631050,153.100525 61.633648,145.391602 68.705978,137.368362 
	C72.964523,135.092255 77.153343,133.130463 81.673409,131.002167 
	C84.317619,139.215210 86.483124,147.638916 88.977402,155.964111 
	C92.917778,169.115997 97.078690,182.201782 101.265518,195.853165 
	C101.384766,196.390778 101.363029,196.821213 101.363029,196.821213 
	C100.511368,197.202911 99.659706,197.584610 98.456848,197.902390 
z"
      />
      <path
        fill="#2F80F4"
        opacity="1.000000"
        stroke="none"
        d="
M139.182159,187.150253 
	C138.900345,185.697205 137.857178,183.620743 138.456314,182.889038 
	C143.568497,176.645660 139.679474,169.518539 140.670486,162.917160 
	C140.746475,162.410919 139.568344,161.812134 139.243896,161.127975 
	C138.734940,160.054733 137.895340,158.645172 138.237274,157.798828 
	C138.586243,156.935074 140.188278,156.083084 141.247894,156.075378 
	C156.384598,155.965302 171.522415,156.016281 186.659866,155.984863 
	C189.852570,155.978241 191.009293,158.706955 189.401581,160.056824 
	C185.802689,163.078506 186.801559,166.687820 186.962982,170.090317 
	C187.182236,174.711624 185.125107,179.901245 189.688370,183.771912 
	C190.082016,184.105804 189.478668,185.615067 188.606415,186.777313 
	C171.643631,187.034271 155.412903,187.092255 139.182159,187.150253 
M173.307571,168.654327 
	C173.307571,172.933853 173.307571,177.213364 173.307571,181.373962 
	C181.488251,182.883987 181.683945,182.736008 181.691925,175.557571 
	C181.695435,172.402695 181.720871,169.247482 181.685654,166.093002 
	C181.631958,161.283066 180.214676,160.085999 175.686615,161.132645 
	C171.593155,162.078827 173.974289,165.469284 173.307571,168.654327 
M167.999664,164.799911 
	C168.574982,160.843231 165.935532,161.173416 163.321762,161.115677 
	C160.267090,161.048172 159.753510,162.720917 159.827011,165.242142 
	C159.928818,168.734268 159.849243,172.231583 159.851700,175.726685 
	C159.856567,182.680420 160.628784,183.213303 168.000092,181.091110 
	C168.000092,176.026855 168.000092,170.881683 167.999664,164.799911 
M146.133545,168.655396 
	C146.133545,172.926041 146.133545,177.196671 146.133545,181.620560 
	C149.087311,181.620560 151.430557,181.620560 154.011429,181.620560 
	C154.011429,175.766068 154.121033,170.297592 153.927017,164.839905 
	C153.883682,163.620667 152.966278,161.615555 152.115158,161.417603 
	C150.504745,161.043106 147.885101,161.106369 147.088348,162.106186 
	C146.053619,163.404663 146.388382,165.794449 146.133545,168.655396 
z"
      />
      <path
        fill="#2C7DF1"
        opacity="1.000000"
        stroke="none"
        d="
M135.229126,161.348206 
	C131.201660,161.213959 128.575470,162.983093 126.103973,166.186325 
	C119.808884,174.345169 113.126343,182.206100 106.550293,190.146042 
	C105.571365,191.328018 104.329887,192.292557 102.394089,192.760468 
	C107.775810,172.337830 113.157524,151.915176 118.804115,131.205780 
	C123.948509,132.708481 128.828033,134.497925 133.632874,136.761826 
	C135.853882,140.241547 138.149567,143.246811 140.445251,146.252060 
	C139.281540,147.116028 138.177017,148.077621 136.943024,148.825653 
	C133.262024,151.057053 130.960922,153.482376 134.717560,157.665375 
	C135.337875,158.356110 135.081116,159.834534 135.229126,161.348206 
z"
      />
      <path
        fill="#060606"
        opacity="1.000000"
        stroke="none"
        d="
M158.545685,138.468658 
	C162.088531,135.434769 165.113480,135.598145 168.699921,138.078506 
	C175.119644,142.518356 181.868057,146.481964 188.459488,150.675415 
	C189.783081,151.517487 191.019363,152.496750 191.937332,154.462708 
	C173.364731,154.462708 154.792145,154.462708 136.219559,154.462708 
	C135.987228,154.130005 135.754898,153.797302 135.522552,153.464600 
	C142.656158,147.924347 150.727417,143.696167 158.545685,138.468658 
M165.791672,148.818420 
	C166.095261,146.965698 166.778076,145.049484 166.484619,143.296722 
	C166.389481,142.728546 163.422852,141.924805 162.340942,142.436554 
	C161.224503,142.964630 159.913422,145.836716 160.235001,146.164322 
	C161.511169,147.464478 163.392639,148.170486 165.791672,148.818420 
z"
      />
      <path
        fill="#191919"
        opacity="1.000000"
        stroke="none"
        d="
M189.214478,188.282043 
	C190.409531,189.519745 191.604568,190.757462 192.799622,191.995163 
	C192.494400,192.532135 192.189194,193.069122 191.883987,193.606094 
	C190.126221,193.606094 188.368469,193.605911 186.610703,193.606125 
	C170.721100,193.608109 154.831360,193.637604 138.942154,193.559906 
	C137.676926,193.553726 136.415329,192.806351 135.152054,192.403412 
	C136.114624,191.118973 137.077194,189.834549 138.858932,188.592102 
	C156.190231,188.516739 172.702362,188.399384 189.214478,188.282043 
z"
      />
      <path
        fill="#A4AEBC"
        opacity="1.000000"
        stroke="none"
        d="
M189.177551,188.064728 
	C172.702362,188.399384 156.190231,188.516739 139.225739,188.453400 
	C138.724091,187.975388 138.815125,187.730103 139.114319,187.343567 
	C155.412903,187.092255 171.643631,187.034271 188.315765,187.021149 
	C188.803146,187.366653 188.930954,187.627121 189.177551,188.064728 
z"
      />
      <path
        fill="#242022"
        opacity="1.000000"
        stroke="none"
        d="
M140.758514,146.160797 
	C138.149567,143.246811 135.853882,140.241547 133.818665,137.079376 
	C138.803787,138.226196 143.528473,139.529922 149.269119,141.113998 
	C145.845856,143.183472 143.458817,144.626495 140.758514,146.160797 
z"
      />
      <path
        fill="#242022"
        opacity="1.000000"
        stroke="none"
        d="
M101.630569,196.855988 
	C101.363029,196.821213 101.384766,196.390778 101.463989,196.190094 
	C101.936676,196.137390 102.330124,196.285370 102.844818,196.612183 
	C102.610069,196.824249 102.254097,196.857498 101.630569,196.855988 
z"
      />
      <path
        fill="#E8F1FE"
        opacity="1.000000"
        stroke="none"
        d="
M173.307785,168.183472 
	C173.974289,165.469284 171.593155,162.078827 175.686615,161.132645 
	C180.214676,160.085999 181.631958,161.283066 181.685654,166.093002 
	C181.720871,169.247482 181.695435,172.402695 181.691925,175.557571 
	C181.683945,182.736008 181.488251,182.883987 173.307571,181.373962 
	C173.307571,177.213364 173.307571,172.933853 173.307785,168.183472 
z"
      />
      <path
        fill="#FCFDFF"
        opacity="1.000000"
        stroke="none"
        d="
M167.999878,165.268219 
	C168.000092,170.881683 168.000092,176.026855 168.000092,181.091110 
	C160.628784,183.213303 159.856567,182.680420 159.851700,175.726685 
	C159.849243,172.231583 159.928818,168.734268 159.827011,165.242142 
	C159.753510,162.720917 160.267090,161.048172 163.321762,161.115677 
	C165.935532,161.173416 168.574982,160.843231 167.999878,165.268219 
z"
      />
      <path
        fill="#F6FAFF"
        opacity="1.000000"
        stroke="none"
        d="
M146.133606,168.184952 
	C146.388382,165.794449 146.053619,163.404663 147.088348,162.106186 
	C147.885101,161.106369 150.504745,161.043106 152.115158,161.417603 
	C152.966278,161.615555 153.883682,163.620667 153.927017,164.839905 
	C154.121033,170.297592 154.011429,175.766068 154.011429,181.620560 
	C151.430557,181.620560 149.087311,181.620560 146.133545,181.620560 
	C146.133545,177.196671 146.133545,172.926041 146.133606,168.184952 
z"
      />
      <path
        fill="#F6FAFF"
        opacity="1.000000"
        stroke="none"
        d="
M165.420746,148.957535 
	C163.392639,148.170486 161.511169,147.464478 160.235001,146.164322 
	C159.913422,145.836716 161.224503,142.964630 162.340942,142.436554 
	C163.422852,141.924805 166.389481,142.728546 166.484619,143.296722 
	C166.778076,145.049484 166.095261,146.965698 165.420746,148.957535 
z"
      />
    </svg>
  );

  const passiveSvg = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 209 209"
      enable-background="new 0 0 209 209"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M140.000000,210.000000 
	C93.333336,210.000000 47.166672,210.000000 1.000006,210.000000 
	C1.000004,140.333344 1.000004,70.666679 1.000002,1.000015 
	C70.666656,1.000010 140.333313,1.000010 209.999969,1.000005 
	C209.999985,70.666649 209.999985,140.333298 210.000000,209.999969 
	C186.833328,210.000000 163.666672,210.000000 140.000000,210.000000 
M145.533966,194.000000 
	C159.693634,193.999954 173.853790,194.053696 188.012299,193.925644 
	C189.620697,193.911102 191.219864,192.875183 192.823318,192.312866 
	C192.190125,190.981232 191.429169,189.694016 190.947983,188.309555 
	C189.571182,184.348175 187.344986,180.372711 187.239212,176.355728 
	C187.101212,171.114990 184.934097,165.313995 189.784256,160.503281 
	C191.249420,159.050018 191.139297,156.008530 192.016907,152.687332 
	C191.944031,152.632202 190.848953,151.686584 189.644043,150.911819 
	C183.010956,146.646713 176.242828,142.581299 169.740540,138.126938 
	C165.790955,135.421310 162.377365,135.385452 158.364456,137.990707 
	C155.561600,139.810379 153.014404,142.631271 148.738113,141.062836 
	C140.127884,137.904831 131.352341,135.198822 122.655518,132.275024 
	C119.636124,131.259918 117.754379,129.641220 119.682602,126.233398 
	C135.587708,125.058197 150.592575,121.848915 163.205536,111.108627 
	C162.560196,110.287010 162.227402,109.705040 161.752762,109.282310 
	C151.371216,100.036163 146.589294,88.116043 144.944107,74.608292 
	C143.753922,64.836105 142.409424,55.080452 140.973801,45.341000 
	C139.214035,33.402439 133.940598,27.778358 122.107918,24.929180 
	C119.738892,24.358744 116.565895,24.681740 115.100624,23.236877 
	C109.475281,17.689888 102.664856,17.495512 95.683983,18.055864 
	C85.828476,18.846956 76.546143,21.259233 69.498634,28.981184 
	C60.535645,38.801929 58.812683,51.177967 56.958626,63.582584 
	C55.627281,72.489975 54.225311,81.408852 52.286377,90.197189 
	C50.357262,98.941040 45.739231,106.226585 38.083206,111.292763 
	C44.380081,117.499039 51.607132,123.777191 82.891136,126.583427 
	C81.605179,128.758469 81.009903,131.224167 79.541069,131.970337 
	C75.476677,134.035110 71.123741,135.631897 66.747887,136.963562 
	C56.407093,140.110474 45.916245,142.774170 35.617699,146.047394 
	C24.232592,149.665924 19.635609,155.968246 19.003418,167.711899 
	C18.595715,175.285461 23.829065,181.285904 33.400425,185.024933 
	C56.157112,193.914734 79.639267,198.237198 104.100990,197.030548 
	C109.086693,196.784592 114.117630,197.238434 119.081818,196.822235 
	C127.589684,196.108932 136.061356,194.963821 145.533966,194.000000 
z"
      />
      <path
        fill="#929497"
        opacity="1.000000"
        stroke="none"
        d="
M145.041092,194.000000 
	C136.061356,194.963821 127.589684,196.108932 119.081818,196.822235 
	C114.117630,197.238434 109.086693,196.784592 104.100990,197.030548 
	C79.639267,198.237198 56.157112,193.914734 33.400425,185.024933 
	C23.829065,181.285904 18.595715,175.285461 19.003418,167.711899 
	C19.635609,155.968246 24.232592,149.665924 35.617699,146.047394 
	C45.916245,142.774170 56.407093,140.110474 66.747887,136.963562 
	C71.123741,135.631897 75.476677,134.035110 79.541069,131.970337 
	C81.009903,131.224167 81.605179,128.758469 82.891136,126.583427 
	C51.607132,123.777191 44.380081,117.499039 38.083206,111.292763 
	C45.739231,106.226585 50.357262,98.941040 52.286377,90.197189 
	C54.225311,81.408852 55.627281,72.489975 56.958626,63.582584 
	C58.812683,51.177967 60.535645,38.801929 69.498634,28.981184 
	C76.546143,21.259233 85.828476,18.846956 95.683983,18.055864 
	C102.664856,17.495512 109.475281,17.689888 115.100624,23.236877 
	C116.565895,24.681740 119.738892,24.358744 122.107918,24.929180 
	C133.940598,27.778358 139.214035,33.402439 140.973801,45.341000 
	C142.409424,55.080452 143.753922,64.836105 144.944107,74.608292 
	C146.589294,88.116043 151.371216,100.036163 161.752762,109.282310 
	C162.227402,109.705040 162.560196,110.287010 163.205536,111.108627 
	C150.592575,121.848915 135.587708,125.058197 119.682602,126.233398 
	C117.754379,129.641220 119.636124,131.259918 122.655518,132.275024 
	C131.352341,135.198822 140.127884,137.904831 148.738113,141.062836 
	C153.014404,142.631271 155.561600,139.810379 158.364456,137.990707 
	C162.377365,135.385452 165.790955,135.421310 169.740540,138.126938 
	C176.242828,142.581299 183.010956,146.646713 189.644043,150.911819 
	C190.848953,151.686584 191.944031,152.632202 192.016907,152.687332 
	C191.139297,156.008530 191.249420,159.050018 189.784256,160.503281 
	C184.934097,165.313995 187.101212,171.114990 187.239212,176.355728 
	C187.344986,180.372711 189.571182,184.348175 190.947983,188.309555 
	C191.429169,189.694016 192.190125,190.981232 192.823318,192.312866 
	C191.219864,192.875183 189.620697,193.911102 188.012299,193.925644 
	C173.853790,194.053696 159.693634,193.999954 145.041092,194.000000 
z"
      />
    </svg>
  );

  return (
    <SvgContainer
      className={className}
      active={active}
      SvgPassive={passiveSvg}
      SvgActive={activeSvg}
      onClick={onClick}
    />
  );
};

export default PowerOfAttorneyFemale;
