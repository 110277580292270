import { CreateBeneficiaryDto } from "features/createInvestmentAccount/types/CreateInvestmentAccountDto";
import { ParticipantTableNameCell } from "./ParticipantTable";
import { notify } from "utils";
import { ParticipantBeneficiaryTableProps } from "../types";

export const ParticipantBeneficiaryTableComponent = ({
  account,
  beneficiaryType,
  setAccount,
  viewMode,
}: ParticipantBeneficiaryTableProps) => {
  const allocationChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const tempAccount = { ...account };
    if (tempAccount[beneficiaryType]) {
      // Check if new allocation brings the total to above 100;
      const beneficiaries = [...tempAccount[beneficiaryType]!];
      const selectedBeneficiary = { ...beneficiaries[index] };
      selectedBeneficiary.allocation = Number(e.target.value);
      beneficiaries[index] = selectedBeneficiary;
      tempAccount[beneficiaryType] = beneficiaries;
      const totalAllocation = tempAccount[beneficiaryType].reduce(
        (acc: number, curr: CreateBeneficiaryDto) => {
          return acc + curr.allocation;
        },
        0
      );

      if (totalAllocation > 100) {
        notify("Total allocation cannot exceed 100%", "error");
        return;
      }

      setAccount(tempAccount);
    }
  };

  const totalAllocation = account[beneficiaryType].reduce(
    (acc: number, curr: CreateBeneficiaryDto) => {
      return acc + curr.allocation;
    },
    0
  );

  const handleDelete = (index: number) => {
    if (window.confirm("Are you sure you want to delete this beneficiary?")) {
      const tempAccount = { ...account };
      if (tempAccount[beneficiaryType]) {
        tempAccount[beneficiaryType].splice(index, 1);
        setAccount(tempAccount);
      }
    }
  };

  return (
    <div className="participant-table-container">
      <div className="table-header">
        <p>Total Allocation: {totalAllocation} %</p>
      </div>
      {account[beneficiaryType] && account[beneficiaryType].length > 0 && (
        <table>
          <tr>
            <th>Participant ID</th>
            <th>Participant Name</th>
            <th>Allocation</th>
            <th></th>
          </tr>
          {account[beneficiaryType].map((beneficiary: CreateBeneficiaryDto, index: number) => {
            return (
              <tr>
                <td>{beneficiary.participantId}</td>
                <ParticipantTableNameCell participantId={beneficiary.participantId} />
                <td>
                  <input
                    value={beneficiary.allocation}
                    onChange={(e) => allocationChange(e, index)}
                    disabled={viewMode}
                  ></input>
                </td>
                <td>
                  {!viewMode && (
                    <button className="btn btn-danger" onClick={() => handleDelete(index)}>
                      <i className="bi bi-trash"></i>
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </table>
      )}
    </div>
  );
};
