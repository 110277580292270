import { ParticipantDetailsSectionProps } from "features/participant/types";
import { useNavigate } from "react-router-dom";
import WorkflowStatus from "../../WorkflowStatus";

const SIAPackageSignedByCCO = ({ participant }: ParticipantDetailsSectionProps) => {
  const navigate = useNavigate();
  const completedPreviousTask =
    participant?.accountHolderWorkflow?.siaPackageSentToParticipantForCompletion;
  const completedTask = participant?.accountHolderWorkflow?.siaPackageSignedByCCO;
  const completedText = "CCO Has Signed Package";
  const notCompletedText = "CCO Has Not Signed Package";
  const completeTooltipText = "";
  const notCompleteTooltipText = "";

  const actionHandler = () => {
    //navigate(`/kyc/${participant.id}/`);
    //console.log("nada");
  };

  // If the Previous Task is completed, then we display content with actions
  return (
    <WorkflowStatus
      completedPreviousTask={completedPreviousTask}
      completedTask={completedTask}
      completeTooltipText={completeTooltipText}
      notCompleteTooltipText={notCompleteTooltipText}
      completedText={completedText}
      notCompletedText={notCompletedText}
      completeActionHander={actionHandler}
      notCompleteActionHandler={actionHandler}
      completeIconClass="bi-file-earmark-check-fill"
      notCompleteIconClass="bi-file-text-fill"
    />
  );
};

export default SIAPackageSignedByCCO;
