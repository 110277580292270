import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { SIASelectProps } from "./SIASelect";

interface SIARegionSelectProps extends SIASelectProps {
  country: string;
}

function SIARegionSelect({
  value,
  disabled,
  options,
  onChange,
  name,
  country,
  id,
}: SIARegionSelectProps) {
  return (
    <div className="input-container">
      <RegionDropdown
        value={value ? value.toString() : ""}
        onChange={(e) => onChange?.(e)}
        disabled={disabled}
        name={name}
        country={country}
        id={id ? `${id}-select` : ""}
      />
      <div className="underline"></div>
    </div>
  );
}

export default SIARegionSelect;
