import React, { useEffect, useState } from "react";
import withAuth from "../../../HOC/withAuth";
import withAdminAuth from "../../../HOC/withAdminAuth";
import "../styles/admin.scss";
import {
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useReactivateUserMutation,
  useUpdateUserMutation,
} from "apis/userApi";
import { AuthUser } from "features/auth/types";
import { checkEmptyValue, notify } from "utils";
import { SIACountrySelect, SIAInput, SIARegionSelect } from "components/Shared";
import { UserRoles } from "constants/enums";
import { useRegisterUserMutation } from "apis/userApi";
import { ApiResponseModel } from "types";
import { Tooltip } from "react-tooltip";

/**
 * This page will serve as the front-end to manage admin-related
 * functionality such as managing users, advisors, households, etc.
 * @returns
 */
function AdminManagePage() {
  const [addingUser, setIsAddingUser] = useState(false);
  const [editingUser, setIsEditingUser] = useState(false);
  const [editingUserId, setEditingUserId] = useState("");

  return (
    <div className="admin-container" id="admin-page">
      <Tooltip id="admin-tooltip" />
      <div className="admin-header">
        <h1>Admin Center</h1>
        <h3>All Admin Related Tools Will Show Up Here</h3>
      </div>
      <div className="hr"></div>
      <AdminUsers
        setIsAddingUser={setIsAddingUser}
        setIsEditingUser={setIsEditingUser}
        setEditingUserId={setEditingUserId}
      />
      {addingUser && <AdminCreateNewUserForm setIsAddingUser={setIsAddingUser} />}
      {editingUser && editingUserId && (
        <AdminEditUserForm
          userId={editingUserId}
          setIsEditingUser={setIsEditingUser}
          setEditingUserId={setEditingUserId}
        />
      )}
    </div>
  );
}

/**
 * Table of users for the application, allows an admin to deactivate or reactivate a user.
 * Can also permanently delete a user
 * @param param0
 * @returns
 */
function AdminUsers({
  setIsAddingUser,
  setIsEditingUser,
  setEditingUserId,
}: {
  setIsAddingUser: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditingUser: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingUserId: React.Dispatch<React.SetStateAction<string>>;
}) {
  // Users coming from the database
  const [users, setUsers] = useState<AuthUser[]>([]);
  // Users that can be filtered
  const [viewUsers, setViewUsers] = useState<AuthUser[]>([]);
  // State for search text
  const [searchText, setSearchText] = useState("");
  // Query for all the users
  const { isSuccess, isError, data } = useGetAllUsersQuery(null);
  // Deactivate User Mutation
  const [deactivateUser] = useDeactivateUserMutation();
  // Reactivate User Mutation
  const [reactivateUser] = useReactivateUserMutation();
  // Delete User Mutation
  const [deleteUser] = useDeleteUserMutation();
  // If we can get all of the users, we will set the users
  useEffect(() => {
    if (isSuccess && data?.isSuccess) {
      const users = data.result;
      setUsers(users);
      setViewUsers(users);
    } else if (isError) {
      notify("An error has occured when retrieving users", "error");
    }
  }, [data]);

  // If the search text changes, we will filter the users
  useEffect(() => {
    if (checkEmptyValue(searchText)) {
      setViewUsers(users);
    } else {
      const filteredUsers = users.filter((user) => {
        return (
          user.user.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
          user.user.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          user.user.email.toLowerCase().includes(searchText.toLowerCase()) ||
          user.user.country.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setViewUsers(filteredUsers);
    }
  }, [searchText, users]);

  /**
   * Deactivates a user, prevents them from logging in
   **/
  const handleDeactivateUser = async (userId: string) => {
    const deactivateResponse: ApiResponseModel = await deactivateUser(userId);
    if (deactivateResponse.data && deactivateResponse?.data?.isSuccess) {
      notify("User Successfully Deactivated", "success");
    } else {
      notify("Error Deactivating User", "error");
    }
  };

  /**
   * Re-activates a user, allows them to login again
   * @param userId
   */
  const handleReactivateUser = async (userId: string) => {
    const reactivateResponse: ApiResponseModel = await reactivateUser(userId);
    if (reactivateResponse.data && reactivateResponse?.data?.isSuccess) {
      notify("User Successfully Reactivated", "success");
    } else {
      notify("Error Reactivating User", "error");
    }
  };

  const handleSetEditinguUser = (userId: string) => {
    setEditingUserId(userId);
    setIsEditingUser(true);
  };

  /**
   * Deletes a user permanently from the database
   * There is a double confirm on this method to ensure the user knows what they are doing
   * @param userId
   * @returns
   */
  const handleDeleteUser = async (userId: string) => {
    if (window.confirm("Are you sure you want to permanently delete this user?")) {
      if (window.confirm("Are you really REALLY sure?") === false) return;
      const { data, error }: ApiResponseModel = await deleteUser(userId);
      if (data && data?.isSuccess) {
        notify("User Successfully Deleted", "success");
      } else if (error) {
        notify("Error Deleting User", "error");
        console.log(error);
      }
    }
  };

  return (
    <div className="users-table-container" id="admin-users-table">
      <div className="users-table-header">
        <div className="users-table-header-text">
          <h1>All Users</h1>
          <button className="btn btn-primary" onClick={() => setIsAddingUser(true)} id="admin-add-user">
            Add New
          </button>
        </div>
        <div>
          <input placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        </div>
      </div>
      <table className="users-table">
        <tr>
          <th>Id</th>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Country</th>
          <th>Region</th>
          <th>City</th>
          <th>Roles</th>
          <th>Status</th>
          <th style={{ textAlign: "center" }}>Actions</th>
        </tr>
        {viewUsers &&
          viewUsers.length > 0 &&
          viewUsers.map((user) => {
            return (
              <tr className="app-user-row" id={`app-user-row-${user.user.id}`}>
                <td className="app-user-row-id">{user.user.id}</td>
                <td className="app-user-row-email">{user.user.email}</td>
                <td className="app-user-row-first-name">{user.user.firstName}</td>
                <td className="app-user-row-last-namr">{user.user.lastName}</td>
                <td className="app-user-row-country">{user.user.country}</td>
                <td className="app-user-row-region">{user.user.region}</td>
                <td className="app-user-row-city">{user.user.city}</td>
                <td className="app-user-row-roles">{user.roles.join(",")}</td>
                <td className="app-user-row-active">{user.user.isActive ? "Active" : "Inactive"}</td>
                <td style={{ display: "flex", justifyContent: "space-evenly" }} className="app-user-row-actions">
                  <a data-tooltip-id="admin-tooltip" data-tooltip-content="Changes the Active Status of the User">
                    <button
                      className={user.user.isActive ? "btn btn-warning" : "btn btn-primary"}
                      onClick={
                        user.user.isActive
                          ? () => handleDeactivateUser(user.user.id)
                          : () => handleReactivateUser(user.user.id)
                      }
                    >
                      {user.user.isActive ? "Deactivate" : "Reactivate"}
                    </button>
                  </a>
                  <a data-tooltip-id="admin-tooltip" data-tooltip-content="Deleting a User From Existence">
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDeleteUser(user.user.id)}
                      id={`delete-user-btn-${user.user.id}`}
                    >
                      Delete
                    </button>
                  </a>
                  <a data-tooltip-id="admin-tooltip" data-tooltip-content="Editing User Information">
                    <button className="btn btn-info" onClick={() => handleSetEditinguUser(user.user.id)}>
                      Edit
                    </button>
                  </a>
                </td>
              </tr>
            );
          })}
      </table>
    </div>
  );
}

/**
 * A component that will allow the admin to create a new user with roles and claims
 */
const AdminCreateNewUserForm = ({
  setIsAddingUser,
}: {
  setIsAddingUser: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // Form Data for the New User Registration
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    city: "",
    region: "",
    country: "",
  });

  // Because Arrays are strange with state, we have store the roles seperate from the rest of the data
  const [newUserRoles, setNewUserRoles] = useState<string[]>([]);

  // Register Mutation
  const [registerUser] = useRegisterUserMutation();

  // Handler of the role change in the user create
  const handleRoleChange = (role: string) => {
    if (newUserRoles.includes(role)) {
      setNewUserRoles(newUserRoles.filter((r) => r !== role));
    } else {
      setNewUserRoles([...newUserRoles, role]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check if there are any empty values
    const emptyValues = Object.values(formData).filter((val) => checkEmptyValue(val));
    if (emptyValues.length > 0 || newUserRoles.length === 0) {
      notify("Please fill out all fields", "error");
      return;
    }
    // Check the password and confirm password
    if (formData.password !== formData.confirmPassword) {
      notify("Passwords do not match", "error");
      return;
    }

    // Create the user object
    const user = {
      email: formData.email,
      password: formData.password,
      firstName: formData.firstName,
      lastName: formData.lastName,
      city: formData.city,
      region: formData.region,
      country: formData.country,
      roles: newUserRoles,
    };

    // Register the user
    const registerResponse: ApiResponseModel = await registerUser(user);
    if (registerResponse.data && registerResponse?.data?.isSuccess) {
      notify("User Successfully Registered", "success");
    } else {
      notify("Error Registering User", "error");
    }

    // Close the form
    setIsAddingUser(false);
  };

  return (
    <div className="create-new-user-form-container" id="create-new-user-form">
      <div className="create-new-user-form-container-header">
        <h1>Create New User</h1>
        <button className="btn btn-danger" onClick={() => setIsAddingUser(false)}>
          Cancel
        </button>
      </div>
      <form className="create-new-user-form" onSubmit={handleSubmit}>
        <div className="create-new-user-form-control">
          <label>First Name</label>
          <SIAInput
            value={formData.firstName}
            type="text"
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            id="first-name"
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Last Name</label>
          <SIAInput
            value={formData.lastName}
            type="text"
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            id="last-name"
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Email (Username)</label>
          <SIAInput
            value={formData.email}
            type="text"
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            id="email"
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Password</label>
          <SIAInput
            value={formData.password}
            type="password"
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            id="password"
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Confirm Password</label>
          <SIAInput
            value={formData.confirmPassword}
            type="password"
            onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
            id="confirm-password"
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Country</label>
          <SIACountrySelect
            value={formData.country}
            onChange={(val) => setFormData({ ...formData, country: val })}
            id="country"
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Region</label>
          <SIARegionSelect
            value={formData.region}
            country={formData.country}
            onChange={(val) => setFormData({ ...formData, region: val })}
            id="region"
          />
        </div>
        <div className="create-new-user-form-control">
          <label>City</label>
          <SIAInput
            value={formData.city}
            type="text"
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            id="city"
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Roles</label>
          <div className="create-new-user-roles-container">
            <div className="role-container">
              <input
                type="radio"
                checked={newUserRoles.includes(UserRoles.ADMIN)}
                onClick={() => handleRoleChange(UserRoles.ADMIN)}
                id="admin-role"
              />
              <label>Admin</label>
            </div>
            <div className="role-container">
              <input
                type="radio"
                checked={newUserRoles.includes(UserRoles.MANAGER)}
                onClick={() => handleRoleChange(UserRoles.MANAGER)}
                id="manager-role"
              />
              <label>Manager</label>
            </div>
            <div className="role-container">
              <input
                type="radio"
                checked={newUserRoles.includes(UserRoles.ADVISOR)}
                onClick={() => handleRoleChange(UserRoles.ADVISOR)}
                id="advisor-role"
              />
              <label>Advisor</label>
            </div>
            <div className="role-container">
              <input
                type="radio"
                checked={newUserRoles.includes(UserRoles.TESTER)}
                onClick={() => handleRoleChange(UserRoles.TESTER)}
                id="tester-role"
              />
              <label>Tester</label>
            </div>
            <div className="role-container">
              <input
                type="radio"
                checked={newUserRoles.includes(UserRoles.DEMO)}
                onClick={() => handleRoleChange(UserRoles.DEMO)}
                id="tester-role"
              />
              <label>Demo</label>
            </div>
          </div>
        </div>
        <div className="form-button-container">
          <button className="btn btn-primary" id="create-user-btn">
            Add User
          </button>
        </div>
      </form>
    </div>
  );
};

/**
 * A component that will allow the admin to edit a user
 */
const AdminEditUserForm = ({
  userId,
  setEditingUserId,
  setIsEditingUser,
}: {
  userId: string;
  setIsEditingUser: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingUserId: React.Dispatch<React.SetStateAction<string>>;
}) => {
  // Form Data for the New User Registration
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    city: "",
    region: "",
    country: "",
  });
  // Update User Hook
  const [updateUser] = useUpdateUserMutation();
  // User Roles
  const [userRoles, setUserRoles] = useState<string[]>([]);
  // Get the user from the database
  const { isLoading, isError, isSuccess, data } = useGetUserByIdQuery(userId);
  // After we get the user, we will set the form data
  useEffect(() => {
    if (!isLoading && isSuccess && data?.isSuccess) {
      const user = data.result;
      setFormData({ ...formData, ...user.user });
      setUserRoles(user.roles);
    } else if (!isLoading && isError) {
      notify("Error Getting User", "error");
    }
  }, [data]);

  // Handle to Cancel the Edit functionality
  const handleCancelEdit = () => {
    setEditingUserId("");
    setIsEditingUser(false);
  };

  // Handler to update the user
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Validate all inputs
    const emptyValues = Object.values(formData).filter((val) => checkEmptyValue(val));
    if (emptyValues.length > 0 || userRoles.length === 0) {
      notify("Please fill out all fields", "error");
      return;
    }

    // Create the user object
    const user = {
      id: userId,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      city: formData.city,
      region: formData.region,
      country: formData.country,
      roles: userRoles,
    };

    // Update the user
    const updateResponse: ApiResponseModel = await updateUser(user);

    if (updateResponse.data && updateResponse?.data?.isSuccess) {
      notify("User Successfully Updated", "success");

      // Reset the flags
      setEditingUserId("");
      setIsEditingUser(false);
    } else {
      notify("Error Updating User", "error");
    }
  };

  // Handler of the role change in the user create
  const handleRoleChange = (role: string) => {
    if (userRoles.includes(role)) {
      setUserRoles(userRoles.filter((r) => r !== role));
    } else {
      setUserRoles([...userRoles, role]);
    }
  };

  return (
    <div className="create-new-user-form-container">
      <div className="create-new-user-form-container-header">
        <h1>Create New User</h1>
        <button className="btn btn-danger" onClick={handleCancelEdit}>
          Cancel
        </button>
      </div>
      <form className="create-new-user-form" onSubmit={handleSubmit}>
        <div className="create-new-user-form-control">
          <label>First Name</label>
          <SIAInput
            value={formData.firstName}
            type="text"
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Last Name</label>
          <SIAInput
            value={formData.lastName}
            type="text"
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Email (Username)</label>
          <SIAInput
            value={formData.email}
            type="text"
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Country</label>
          <SIACountrySelect value={formData.country} onChange={(val) => setFormData({ ...formData, country: val })} />
        </div>
        <div className="create-new-user-form-control">
          <label>Region</label>
          <SIARegionSelect
            value={formData.region}
            country={formData.country}
            onChange={(val) => setFormData({ ...formData, region: val })}
          />
        </div>
        <div className="create-new-user-form-control">
          <label>City</label>
          <SIAInput
            value={formData.city}
            type="text"
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
          />
        </div>
        <div className="create-new-user-form-control">
          <label>Roles</label>
          <div className="create-new-user-roles-container">
            <div className="role-container">
              <input
                type="radio"
                checked={userRoles.includes(UserRoles.ADMIN)}
                onClick={() => handleRoleChange(UserRoles.ADMIN)}
              />
              <label>Admin</label>
            </div>
            <div className="role-container">
              <input
                type="radio"
                checked={userRoles.includes(UserRoles.MANAGER)}
                onClick={() => handleRoleChange(UserRoles.MANAGER)}
              />
              <label>Manager</label>
            </div>
            <div className="role-container">
              <input
                type="radio"
                checked={userRoles.includes(UserRoles.ADVISOR)}
                onClick={() => handleRoleChange(UserRoles.ADVISOR)}
              />
              <label>Advisor</label>
            </div>
          </div>
        </div>
        <div className="form-button-container">
          <button className="btn btn-primary">Update User</button>
        </div>
      </form>
    </div>
  );
};

export default withAuth(withAdminAuth(AdminManagePage));
