import React from "react";
import { RiskToleranceFormData } from "../../../types";
import "../styles/specialNotes.scss";

interface RiskWizardGroupProps {
  propData: RiskToleranceFormData;
  onChange: (data: Partial<RiskToleranceFormData>) => void;
}

function SpecialNotesSection(props: RiskWizardGroupProps) {
  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange({ specialNotes: e.target.value });
  };

  return (
    <div className="risk-special-notes p-1">
      <p className="risk-special-notes-header font-semibold text-lg text-primary mb-1">Special Notes</p>
      <p className="risk-special-notes-subheader text-sm text-gray-600 mb-2">
        Anything else that you would like to add?
      </p>
      <textarea
        className="risk-special-notes-text-area w-full border border-gray-300 rounded-lg p-2"
        onChange={handleNotesChange}
        value={props.propData.specialNotes}
      />
    </div>
  );
}

export default SpecialNotesSection;
