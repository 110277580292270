import React from "react";
import { KYCSectionProps } from "../types";
import KYCInput from "./KYCInput";
import KYCCountrySelect from "./KYCCountrySelect";
import KYCRegionSelect from "./KYCRegionSelect";
import KYCSelect from "./KYCSelect";
import { AddressTypes } from "assets/StaticData";
import { sanitizeString } from "utils";

function KYCTrustedContact({ formData, setFormData }: KYCSectionProps) {
  const handleTrustedContactChange = (val: any, attrName: string) => {
    let trustedContactDetails = { ...formData.trustedContactDetails };
    trustedContactDetails[attrName] = sanitizeString(val);
    setFormData({ ...formData, trustedContactDetails: trustedContactDetails });
  };

  return (
    <div className="kyc-section-container">
      <div className="kyc-section-container-header">
        <h1>Trusted Contact Person Details</h1>
        <p>Please Fill Out All Required Fields</p>
      </div>
      <div className="kyc-section-container-body">
        <KYCInput
          label="First Name"
          value={formData?.trustedContactDetails?.firstName}
          onChange={(e) => handleTrustedContactChange(e, "firstName")}
        />
        <KYCInput
          label="Last Name"
          value={formData?.trustedContactDetails?.lastName}
          onChange={(e) => handleTrustedContactChange(e, "lastName")}
        />
        <KYCInput
          label="Email"
          value={formData?.trustedContactDetails?.email}
          onChange={(e) => handleTrustedContactChange(e, "email")}
          placeholder="example@gmail.com"
        />
        <KYCInput
          label="Phone Number"
          value={formData?.trustedContactDetails?.phoneNumber}
          onChange={(e) => handleTrustedContactChange(e, "phoneNumber")}
          placeholder="123-456-7890"
        />
        <KYCInput
          label="Relationship to Participant"
          value={formData?.trustedContactDetails?.relationshipType}
          onChange={(e) => handleTrustedContactChange(e, "relationshipType")}
        />
        <p className="kyc-section-divider-header">Mailing Address Details</p>
        <KYCSelect
          label="Address Type"
          value={formData?.trustedContactDetails?.mailingAddressType}
          options={AddressTypes}
          onChange={(e) => handleTrustedContactChange(e, "mailingAddressType")}
        />
        <KYCInput
          label="Civic # / RR #"
          value={formData?.trustedContactDetails?.mailingAddressStreetNumber}
          onChange={(e) => handleTrustedContactChange(e, "mailingAddressStreetNumber")}
          type="number"
        />
        <KYCInput
          label="Street Name / RR"
          value={formData?.trustedContactDetails?.mailingAddressStreetName}
          onChange={(e) => handleTrustedContactChange(e, "mailingAddressStreetName")}
          placeholder="Parkdale"
        />
        <KYCInput
          label="Street Type"
          value={formData?.trustedContactDetails?.mailingAddressStreetType}
          onChange={(e) => handleTrustedContactChange(e, "mailingAddressStreetType")}
          placeholder="Ave, St, Blvd, etc."
        />
        <KYCInput
          label="Street Direction / Type"
          value={formData?.trustedContactDetails?.mailingAddressStreetDirection}
          onChange={(e) => handleTrustedContactChange(e, "mailingAddressStreetDirection")}
          placeholder="N, S, E, W, etc."
        />
        <KYCInput
          label="Unit Type / Floor"
          value={formData?.trustedContactDetails?.mailingAddressUnitFloor}
          onChange={(e) => handleTrustedContactChange(e, "mailingAddressUnitFloor")}
          placeholder="Apt, Unit, etc."
        />
        <KYCInput
          label="Unit # / Apartment # / Floor #"
          value={formData?.trustedContactDetails?.mailingAddressAptUnitSuiteNumber}
          onChange={(e) => handleTrustedContactChange(e, "mailingAddressAptUnitSuiteNumber")}
          type="number"
        />
        <KYCCountrySelect
          label="Country"
          value={formData?.trustedContactDetails?.mailingAddressCountry}
          onChange={(e) => handleTrustedContactChange(e, "mailingAddressCountry")}
        />
        <KYCRegionSelect
          label="Region"
          value={formData?.trustedContactDetails?.mailingAddressRegion}
          country={formData?.trustedContactDetails?.mailingAddressCountry}
          onChange={(e) => handleTrustedContactChange(e, "mailingAddressRegion")}
        />
        <KYCInput
          label="City"
          value={formData?.trustedContactDetails?.mailingAddressCity}
          onChange={(e) => handleTrustedContactChange(e, "mailingAddressCity")}
        />
        {["Canada", "United States"].includes(
          formData?.trustedContactDetails?.mailingAddressCountry
        ) && (
          <KYCInput
            label={
              formData?.trustedContactDetails?.mailingAddressCountry === "Canada"
                ? "Postal Code"
                : "Zip Code"
            }
            value={formData?.trustedContactDetails?.mailingAddressPostalCode}
            onChange={(e) => handleTrustedContactChange(e, "mailingAddressPostalCode")}
          />
        )}
      </div>
    </div>
  );
}

export default KYCTrustedContact;
