import "./styles/SIAInput.scss";

interface SIAInputProps {
  value: string | number;
  type: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  name?: string;
  placeholder?: string;
  isDollar?: boolean;
  id?: string;
}

const SIAInput = ({
  value,
  type,
  disabled,
  onChange,
  accept,
  name,
  placeholder,
  isDollar,
  id,
}: SIAInputProps) => {
  if (type === "file") {
    return (
      <div className="input-container" id={id ? `${id}-container` : ""}>
        <input
          type={type}
          disabled={disabled}
          onChange={(e) => onChange?.(e)}
          accept={accept ? accept : ""}
          name={name ? name : ""}
          id={id ? `${id}-input` : ""}
        />
        <div className="underline"></div>
      </div>
    );
  }

  return (
    <div className="input-container" id={id ? `${id}-container` : ""}>
      {isDollar && <span className="dollar-sign">$</span>}
      <input
        value={value ? value : ""}
        type={type}
        disabled={disabled}
        onChange={(e) => onChange?.(e)}
        accept={accept ? accept : ""}
        name={name ? name : ""}
        placeholder={placeholder ? placeholder : ""}
        id={id ? `${id}-input` : ""}
      />
      <div className="underline"></div>
    </div>
  );
};

export default SIAInput;
