import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authURL = `auth`;
const userURL = `user`;

/// This API uses the UserController found in the .NET API
const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/`,
    credentials: "include",
  }),
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    // This endpoint is only authorized by user
    getAuthorizedUser: builder.query({
      query: () => ({
        url: `${userURL}/authorize`,
        method: "GET",
      }),
    }),
    getAllUsers: builder.query({
      query: () => ({
        url: `${userURL}/`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    getAllUsersByRole: builder.query({
      query: (roleName: string) => ({
        url: `${userURL}/role?roleName=${roleName}`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    getUserById: builder.query({
      query: (userId: string) => ({
        url: `${userURL}/${userId}`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    registerUser: builder.mutation({
      query: (registerInputs) => ({
        url: `${authURL}/register`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: registerInputs,
      }),
      invalidatesTags: ["Users"],
    }),
    deactivateUser: builder.mutation({
      query: (userId) => ({
        url: `${userURL}/deactivate`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        params: {
          userId: userId,
        },
      }),
      invalidatesTags: ["Users"],
    }),
    reactivateUser: builder.mutation({
      query: (userId) => ({
        url: `${userURL}/reactivate`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        params: {
          userId: userId,
        },
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${userURL}`,
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
        },
        params: {
          userId: userId,
        },
      }),
      invalidatesTags: ["Users"],
    }),
    updateUser: builder.mutation({
      query: (updateUserParams) => ({
        url: `${userURL}/${updateUserParams.id}`,
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: updateUserParams,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetAuthorizedUserQuery,
  useLazyGetAuthorizedUserQuery,
  useGetAllUsersQuery,
  useGetAllUsersByRoleQuery,
  useRegisterUserMutation,
  useDeactivateUserMutation,
  useReactivateUserMutation,
  useDeleteUserMutation,
  useGetUserByIdQuery,
  useUpdateUserMutation,
} = userApi;
export default userApi;
