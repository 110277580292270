const ReturnIconSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 27 26" fill="none">
      <path
        d="M13.4376 0C20.859 0 26.8752 5.79236 26.8752 12.9376C26.8752 20.0828 20.859 25.8752 13.4376 25.8752C6.01621 25.8752 0 20.0828 0 12.9376C0 5.79236 6.01621 0 13.4376 0ZM13.4376 23.6252C19.5683 23.6252 24.5382 18.8402 24.5382 12.9376C24.5382 7.03497 19.5683 2.25001 13.4376 2.25001C7.3069 2.25001 2.33696 7.035 2.33696 12.9376C2.34426 18.8373 7.30982 23.6181 13.4369 23.6252H13.4376L13.4376 23.6252ZM16.7795 17.2801H7.86393V15.0301H16.7795C18.5961 15.0301 20.0688 13.6122 20.0688 11.8632C20.0688 10.1141 18.5961 8.69629 16.7795 8.69629H7.86393V6.44629H16.7795C19.8868 6.44629 22.4057 8.87153 22.4057 11.8632C22.4057 14.8549 19.8868 17.2801 16.7795 17.2801ZM11.4886 21.2469L6.21405 16.1551L11.4886 11.0634L13.1432 12.6519L9.51385 16.1551L13.1431 19.6585L11.4886 21.2469Z"
        fill="url(#paint0_linear_65_337)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_65_337"
          x1="26"
          y1="12"
          x2="1"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.203806" stopColor="#4A5764" />
          <stop offset="0.957096" stopColor="#008037" stopOpacity="0.6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ReturnIconSvg;
