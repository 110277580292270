export enum AMLDocumentType {
  PASSPORT = 1,
  DRIVER_LICENSE = 2,
  BIRTH_CERTIFICATE = 3,
  CITIZENSHIP_CARD = 4,
  RESIDENCE_PERMIT = 5,
  INDIAN_STATUS_CERTIFICATE = 6,
  NEXUS_CARD = 7,
  BC_SERVICES_CARD = 8,
  PROVINCIAL_HEALTH_CARD = 9,
  PROVINCIAL_PHOTO_ID_CARD = 10,
  CANADIAN_ARMED_FORCES_ID_CARD = 11,
  OTHER = 12,
}
