import React, { useState } from "react";
import "./styles/stepFormWizard.scss";
interface StepFormWizardProps {
  startIndex: number;
  steps: WizardStep[];
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  handleStepChange?: any;
  submitForm: any;
  nextText?: string;
  participant?: any; // This is new, sometimes we need other information about the participant
  viewMode?: boolean;
  // isCompleted: boolean;
  // CompletedComponent: React.ComponentType<any>;
}

interface WizardStep {
  label: string;
  subtitle: string;
  content: React.ComponentType<WizardStepProps>;
  validator?: any;
  //completed?: boolean;
}

interface WizardStepProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  participant?: any;
  viewMode?: boolean;
}

function StepFormWizard({
  startIndex,
  steps,
  formData,
  setFormData,
  handleStepChange,
  submitForm,
  nextText,
  participant,
  viewMode,
}: StepFormWizardProps) {
  const [currentStep, setCurrentStep] = useState(startIndex);
  if (steps.length == 0) {
    return <div>Steps Not Defined</div>;
  }

  const CurrentStepComponent = steps[currentStep].content;
  const currentStepValidator = steps[currentStep].validator;

  if (!formData) {
    return <div>Loading...</div>;
  }

  const handleNextStepChange = async () => {
    if (currentStepValidator) {
      const isValid = currentStepValidator(formData);
      if (!isValid) {
        return;
      }
    }

    if (handleStepChange) {
      await handleStepChange();
    }

    setCurrentStep(currentStep + 1);
    window.scrollTo(0, 0);
  };

  const handlePrevStepChange = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  const handleSubmitFinal = async () => {
    if (currentStepValidator) {
      const isValid = currentStepValidator(formData);
      if (!isValid) {
        return;
      }
    }

    await submitForm();
  };

  return (
    <div>
      {WizardSteps(steps, currentStep, setCurrentStep)}
      <div className="form-progress-container">
        <CurrentStepComponent
          formData={formData}
          setFormData={setFormData}
          participant={participant}
          viewMode={viewMode}
        />
        <div className="form-progress-btns-container">
          <div className="form-progress-btn-container">
            <button onClick={handlePrevStepChange} disabled={currentStep == 0}>
              Prev
            </button>
          </div>

          {currentStep != steps.length - 1 ? (
            <div className="form-progress-btn-container">
              <button onClick={handleNextStepChange}>{nextText ? nextText : "Next"}</button>
            </div>
          ) : (
            <div className="form-progress-btn-container">
              <button className="btn btn-success" onClick={handleSubmitFinal}>
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StepFormWizard;

function WizardSteps(
  steps: WizardStep[],
  currentStep: number,
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
) {
  return (
    <div className="form-progress-bar">
      {steps.map((step, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className={`form-progress-bar-step ${index === currentStep ? "active" : ""}`}
              onClick={() => setCurrentStep(index)}
            >
              <div className={`form-progress-bar-step-number ${currentStep > index ? "complete" : ""}`}>
                <p>{currentStep > index ? <i className="bi bi-check"></i> : index + 1}</p>
              </div>
              <div className="form-progress-bar-step-label">{step.label}</div>
              <div className="form-progress-bar-step-subtitle">{step.subtitle}</div>
            </div>
            {index !== steps.length - 1 && (
              <div className="form-progress-bar-outerline">
                <div className="form-progress-bar-innerline" style={currentStep > index ? { width: "100%" } : {}}></div>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
