import { SIAInput } from "components/Shared";
import { IPSSectionComponentProps } from "../types";

const IPSLimitsOnInvestment = ({ formData, setFormData }: IPSSectionComponentProps) => {
  return (
    <div className="ips-section-container">
      <div className="ips-section-header-container">
        <p className="ips-section-header">Provide the Limits for Investment</p>
      </div>
      <div className="ips-section-body-container">
        <div className="input-section">
          <label style={{ fontSize: "1.25rem" }}>
            Maximum % Commerical Paper Investment of One Issuer
          </label>
          <SIAInput
            value={formData.maxCommericialPaperPercentage}
            onChange={(e: any) =>
              setFormData({ ...formData, maxCommericialPaperPercentage: Number(e.target.value) })
            }
            type="number"
          />
        </div>
        <div className="input-section">
          <label style={{ fontSize: "1.25rem" }}>Maximum % for Securities of One Issuer</label>
          <SIAInput
            value={formData.maxSecurityIssuerPercentage}
            onChange={(e: any) =>
              setFormData({ ...formData, maxSecurityIssuerPercentage: Number(e.target.value) })
            }
            type="number"
          />
        </div>
      </div>
    </div>
  );
};

export default IPSLimitsOnInvestment;
