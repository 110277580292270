import { useEffect, useState } from "react";
import withAuth from "../../../HOC/withAuth";
import { Advisor } from "../../../types/Advisor/Advisor";
import { RootState } from "../../../stores/store";
import { useSelector } from "react-redux";
import { HouseholdState } from "../../../stores/Redux/householdSlice";
import { ApiResponseModel, Household } from "types";
import CreateHouseholdForm from "./CreateHouseholdForm";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "components/Shared/Modal";
import "../styles/households.scss";
import "../styles/householdSection.scss";
import { checkEmptyValue, notify } from "utils";
import {
  useDeactivateHouseholdMutation,
  useDeleteHouseholdMutation,
  useGetHouseholdsByAdvisorQuery,
  useLazyGetHouseholdsByAdvisorQuery,
  useUpdateHouseholdMutation,
} from "apis/mainApi";
import { ReturnIconSvg } from "assets/svg";
import { SIAInput } from "components/Shared";
import { AuthUser } from "features/auth/types";
import { UserRoles } from "constants/enums";
/**
 * This page focuses on the link between households and advisors
 * Also provides information on the accounts found in the household
 *
 *
 * New Change, we will be able to add an advisor id the end of the URL here, use that to query the the database
 * @todo: rename this feature to `households`
 * @returns
 */
function HouseholdsPage() {
  // Logged in User Data
  const userData: AuthUser = useSelector((state: RootState) => state.userAuthStore);
  // Retrieving Parameter from URL using params hook
  const { advisorId } = useParams();
  // Search Text Hook State
  const [searchText, setSearchText] = useState("");
  // OLD -> using advisor state
  const advisor: Advisor = useSelector((state: RootState) => state.advisorStore);
  // Advisor Household coming from state
  const advisorHouseholdsStore: HouseholdState = useSelector(
    (state: RootState) => state.householdStore
  );
  const [advisorHouseholds, setAdvisorHouseholds] = useState<Household[]>([]);
  const [viewHouseholds, setViewHouseholds] = useState<Household[]>([]);

  // UseStates for Creating a Household
  const [showModalBackdrop, setShowModalBackdrop] = useState(false);
  const [showCreateHousehold, setShowCreateHousehold] = useState(false);

  // No Results State
  const [noResults, setNoResults] = useState(false);
  // Query for the adviors
  const [advisorHouseholdQuery, { isSuccess, data, isError, isLoading }] =
    useLazyGetHouseholdsByAdvisorQuery();
  // Navigation State
  const navigate = useNavigate();

  // Getting Advisors from the database
  useEffect(() => {
    if (!checkEmptyValue(advisorId) && checkEmptyValue(advisor.id)) {
      // Query the Database using params
      advisorHouseholdQuery(advisorId!);
    } else if (checkEmptyValue(advisorId) && !checkEmptyValue(advisor.id)) {
      // Query the Database use stores
      advisorHouseholdQuery(advisor.id!);
    }
  }, [advisorId, advisor.id]);

  // Checking the results from the Database
  useEffect(() => {
    if (!isLoading && isSuccess && data?.isSuccess) {
      const households: Household[] = data.result;
      // Set up the hooks for the advisors
      setAdvisorHouseholds(households);
      setViewHouseholds(households);

      // Setting bool flag for no results
      if (households.length == 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    } else if (isError) {
      notify("An error has occured when retrieving advisors", "error");
    }
  }, [data]);

  // Search Text Functionality
  useEffect(() => {
    if (searchText.length > 0) {
      const filteredHouseholds = advisorHouseholds.filter((household) => {
        return household.fullName.toLowerCase().includes(searchText.toLowerCase());
      });
      setViewHouseholds(filteredHouseholds);
    } else {
      setViewHouseholds(advisorHouseholds);
    }
  }, [searchText]);

  const handleCreateNewHouseholdModalOpen = () => {
    setShowModalBackdrop(true);
    setShowCreateHousehold(true);
  };

  return (
    <div className="main-page-list-container" id="households-page">
      {showModalBackdrop && <div className="backdrop"></div>}
      {advisorId && (
        <button style={{ background: "white", border: "none" }} onClick={() => navigate(-1)}>
          <ReturnIconSvg />
        </button>
      )}
      <div className="households-header">
        <div className="household-header-texts">
          <p className="households-header-text">Households</p>
          <p className="households-subheader-text">
            Choose any of the households to view further details
          </p>
          <button className="add-new-btn" onClick={handleCreateNewHouseholdModalOpen}>
            Add New
          </button>
        </div>
        <div className="household-header-search">
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Households..."
          ></input>
        </div>
      </div>
      <div className="hr"></div>
      <div className="household-list-container">
        {viewHouseholds.length > 0 &&
          viewHouseholds.map((household, index) => {
            return (
              <HouseholdRow
                household={household}
                key={index}
                isAdmin={userData?.roles?.includes(UserRoles.ADMIN)}
              />
            );
          })}
        {noResults && (
          <div className="household-row">
            <div className="household-row-information">
              <p className="household-row-id">No Households Found</p>
            </div>
          </div>
        )}
      </div>
      <Modal
        children={
          <CreateHouseholdForm
            advisorId={advisorId ? advisorId : advisor.id!}
            closeModal={() => {
              setShowCreateHousehold(false);
              setShowModalBackdrop(false);
            }}
          />
        }
        show={showCreateHousehold}
        modelTitle="Add New Household"
        setModalBackdrop={setShowModalBackdrop}
        setModelDisplay={setShowCreateHousehold}
      ></Modal>
    </div>
  );
}

interface HouseholdRowProps {
  household: Household;
  isAdmin?: boolean;
}

const HouseholdRow = ({ household, isAdmin }: HouseholdRowProps) => {
  // Household Copy State
  const [householdCopy, setHouseholdCopy] = useState<Household>({ ...household });
  // Update Household Mutation
  const [updateHouseholdMutation] = useUpdateHouseholdMutation();
  // Edit State
  const [isEditing, setIsEditing] = useState(false);
  // Navigation
  const navigate = useNavigate();
  // Delete Household Mutation
  const [deleteHouseholdMutation] = useDeleteHouseholdMutation();
  // Deactivate Household Mutation
  const [deactivateHouseholdMutation] = useDeactivateHouseholdMutation();

  // Handle Delete Click
  const handleDeleteClick = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this household? This will permanently delete all participants in this household."
      )
    ) {
      const { data, error }: ApiResponseModel = await deleteHouseholdMutation(household.id);
      if (data && data.isSuccess) {
        notify("Household has been deleted", "success");
      }
      if (error) {
        notify("An error has occured when deleting the household", "error");
        console.log(error);
      }
    }
  };

  // Handle Deactivate Click
  const handleDeactivateClick = async () => {
    if (
      window.confirm(
        "Are you sure you want to deactivate this household? Re-activating will require an admin to do so."
      )
    ) {
      const { data, error }: ApiResponseModel = await deactivateHouseholdMutation(household.id);
      if (data && data.isSuccess) {
        notify("Household has been deactivated", "success");
      }
      if (error) {
        notify("An error has occured when deactivating the household", "error");
        console.log(error);
      }
    }
  };

  /**
   * This function handles the edit click
   * This will either save the household or set the correct state to edit the household
   */
  const handleEditClick = async () => {
    if (isEditing) {
      // Save the household
      const { data, error }: ApiResponseModel = await updateHouseholdMutation(householdCopy);
      if (data && data.isSuccess) {
        notify("Household has been updated", "success");
        setIsEditing(false);
      }
      if (error) {
        notify("An error has occured when updating the household", "error");
        console.log(error);
      }
    } else {
      // Edit the household
      setIsEditing(true);
    }
  };

  return (
    <div className="household-row">
      <div className="household-row-information">
        {isEditing ? (
          <SIAInput
            value={householdCopy.fullName}
            type="text"
            onChange={(e) => {
              setHouseholdCopy({ ...householdCopy, fullName: e.target.value });
            }}
          />
        ) : (
          <p className="household-row-name">{household.fullName}</p>
        )}

        <p className="household-row-advisor-code">{household.advisor?.iaNumber}</p>
        <p className="household-row-status">SIA Only</p>
      </div>
      <div className="household-row-actions">
        <div className="household-view-actions-container">
          <button
            className="household-row-action-btn view-participants-btn"
            onClick={() => navigate(`/householdParticipants/${household.id}`)}
          >
            <p>View Participants</p>
            <div className="icon-holder">
              <i className="bi bi-arrow-right-circle-fill"></i>
            </div>
          </button>
          <button className="btn btn-info" onClick={handleEditClick}>
            {!isEditing ? "Edit" : "Save"}
          </button>
          {isEditing && (
            <button className="btn btn-warning" onClick={() => setIsEditing(false)}>
              Cancel
            </button>
          )}
          {!isEditing && (
            <button className="btn btn-warning" onClick={handleDeactivateClick}>
              Deactivate{" "}
            </button>
          )}
          {isAdmin && !isEditing && (
            <button className="btn btn-danger" onClick={handleDeleteClick}>
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withAuth(HouseholdsPage);
