import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreateInvestmentAccountDto, InvestmentAccountDto } from "types/InvestmentAccount";

const investmentAccountApiRoute = "investmentAccount";

const investmentAccountApi = createApi({
  reducerPath: "investmentAccountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/`,
    credentials: "include",
    cache: "no-cache",
  }),
  tagTypes: ["Accounts", "IncompleteAccounts"],
  endpoints: (builder) => ({
    // Create an Investment Account
    createOrUpdateAccount: builder.mutation({
      query: (createInvestmentAccountRequest: CreateInvestmentAccountDto) => ({
        url: `${investmentAccountApiRoute}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createInvestmentAccountRequest,
      }),
      invalidatesTags: ["Accounts", "IncompleteAccounts"],
    }),
    getInvestmentAccountHolder: builder.query({
      query: (participandId: string) => ({
        url: `${investmentAccountApiRoute}/accountHolder/${participandId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }),
      providesTags: ["Accounts"],
    }),
    getInvestmentAccountById: builder.query({
      query: (accountId: string) => ({
        url: `${investmentAccountApiRoute}/${accountId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }),
      providesTags: ["Accounts"],
    }),
    updateInvestmentAccount: builder.mutation({
      query: (updateAccountRequest: InvestmentAccountDto) => ({
        url: `${investmentAccountApiRoute}`,
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: updateAccountRequest,
      }),
      invalidatesTags: ["Accounts"],
    }),
    archiveInvestmentAccount: builder.mutation({
      query: (accountId: string) => ({
        url: `${investmentAccountApiRoute}/archive/${accountId}`,
        method: "POST",
      }),
      invalidatesTags: ["Accounts"],
    }),
    getIncompleteInvestmentAccount: builder.query({
      query: (participandId: string) => ({
        url: `${investmentAccountApiRoute}/incomplete?participantId=${participandId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
        }
      }),
      providesTags: ["IncompleteAccounts"]
    }),
  }),
});

export const {
  useCreateOrUpdateAccountMutation,
  useGetInvestmentAccountHolderQuery,
  useLazyGetInvestmentAccountHolderQuery,
  useGetInvestmentAccountByIdQuery,
  useLazyGetInvestmentAccountByIdQuery,
  useUpdateInvestmentAccountMutation,
  useArchiveInvestmentAccountMutation,
  useGetIncompleteInvestmentAccountQuery,
  useLazyGetIncompleteInvestmentAccountQuery
} = investmentAccountApi;
export default investmentAccountApi;
