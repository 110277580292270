import { useEffect, useState } from "react";
import { AccountSectionProps } from "../types";
import { ParticipantTableComponent } from "./ParticipantTable";
import { ParticipantRole } from "constants/enums";
import { SelectExistingParticipantWithRole } from "./SelectExistingParticipantWithRole";

/**
 * @name    AccountJointDetails
 * @summary Part of the Create Investment Account Process where a client can specify if the account is joint or not
 *          This was already specified in the general details, but this is a more detailed view where the user
 *          can ensure that their spouse is selected the joint name on the account.
 *          Since the spouse details are required in the general details of the client, this will really be
 *          just a view-only mode where a client can ensure that the spouse entered is correct.
 *
 *          CHANGE: A joint account holder must have their entire KYC workup done. This means that you can only
 *          select those clients for whom you have done the KYC workup for. Even if you have spousal information,
 *          if they don't have a KYC workup, then they don't count as a joint account holder. A joint account holder
 *          can also come from a seperate household that the advisor can manage. This means that the advisor can
 *          select a client from a different household as a joint account holder.
 *
 * @param   formData the new investment account form data
 * @param   setFormData the react hook form set form data function
 * @param   participant the participant state information
 * @returns React.Component
 */
function AccountJointDetails({
  formData,
  setFormData,
  participant,
  viewMode,
}: AccountSectionProps) {
  // State to keep track of adding holder or not
  const [isAddingAccountHolder, setIsAddingAccountHolder] = useState(false);

  useEffect(() => {
    if (participant) {
      // If the first account holder isn't the current participant, we need to re-arrange
      if (formData.accountHolders && formData.accountHolders[0] !== participant.id) {
        const tempAccount = { ...formData };
        const accountHolders = [...tempAccount.accountHolders!];
        const index = accountHolders.indexOf(participant.id);
        if (index > -1) {
          accountHolders.splice(index, 1);
          accountHolders.unshift(participant.id);
        }

        tempAccount.accountHolders = accountHolders;
        setFormData(tempAccount);
      }
    }
  }, [participant]);

  // Handler to delete an account holder
  const handleAccountHolderDelete = (index: number) => {
    const tempAccount = { ...formData };
    if (tempAccount.accountHolders) {
      tempAccount.accountHolders.splice(index, 1);
      setFormData(tempAccount);
    }
  };

  // Handler to add an account holder
  const handleAccountHolderAdd = (participantId: string) => {
    const tempAccount = { ...formData };
    if (tempAccount.accountHolders) {
      tempAccount.accountHolders.push(participantId);
      setFormData(tempAccount);
    } else {
      setFormData({ ...tempAccount, accountHolders: [participantId] });
    }
  };

  return (
    <div className="account-section-details-container">
      <div className="account-section-details-header">
        <h1>Joint Account Details</h1>
        <h3>Ensure All Account Holders Are Listed</h3>
      </div>
      <ParticipantTableComponent
        participantIds={formData.accountHolders!}
        handleDelete={handleAccountHolderDelete}
        disabled={viewMode}
      />
      <div className="add-account-holder-btn-container">
        {!isAddingAccountHolder && !viewMode && (
          <button className="btn btn-primary" onClick={() => setIsAddingAccountHolder(true)}>
            Add Account Holder
          </button>
        )}
      </div>
      <div className="account-section-body">
        {isAddingAccountHolder && (
          <SelectExistingParticipantWithRole
            roleName="Account Holder"
            role={ParticipantRole.ACCOUNT_HOLDER}
            householdId=""
            setIsChoosingExisiting={setIsAddingAccountHolder}
            addParticipant={handleAccountHolderAdd}
          />
        )}
      </div>
    </div>
  );
}

export default AccountJointDetails;
