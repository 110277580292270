import React, { useEffect, useState } from "react";
import {
  ParticipantCountrySelectProps,
  ParticipantDetailsCompProps,
  ParticipantDetailsSectionProps,
  ParticipantInputProps,
  ParticipantProp,
  ParticipantRegionSelectProps,
  ParticipantSelectProps,
  RadioButtonContainerProps,
} from "../types";
import $ from "jquery";
import "../styles/participantDetails.scss";
import { MaritalStatusOptions } from "constants/selectOptions";
import { ApiResponseModel, Participant } from "types";
import { convertDateToString, notify, numberWithCommas } from "utils";
import { useUpdateNewClientMutation } from "apis/mainApi";
import { SpinningLoader } from "components/Shared";
import { AddressTypes } from "assets/StaticData";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { EmploymentTypes } from "assets/StaticData/EditClientOptions";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { AuthUser } from "features/auth/types";
import { useSelector } from "react-redux";
import { RootState } from "stores/store";
import { ParticipantRole } from "constants/enums";

/*************** Shared Input Components *******************************************/
const ParticipantInput = ({
  label,
  value,
  type = "text",
  disabled = false,
  onChange,
  isDollar,
  hasTooltip,
  tooltipText,
}: ParticipantInputProps) => (
  <div className="participant-details">
    <div className="full-label-container">
      <label>{label}</label>
      {hasTooltip && (
        <a data-tooltip-id="details-tooltip" data-tooltip-content="Test">
          <i className="bi bi-info-circle-fill"></i>
        </a>
      )}
    </div>
    <div className="full-input-container">
      {isDollar && <span className="dollar-sign">$</span>}
      <div className="input-container">
        <input
          value={value ? value : ""}
          type={type}
          disabled={disabled}
          onChange={(e) => onChange?.(e.target.value)}
        />
        <div className="underline"></div>
      </div>
    </div>
  </div>
);

const ParticipantSelect = ({
  label,
  value,
  options,
  disabled = false,
  onChange,
}: ParticipantSelectProps) => (
  <div className="participant-details">
    <div className="full-label-container">
      <label>{label}</label>
    </div>
    <div className="input-container">
      <select
        value={value ? value : ""}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="underline"></div>
    </div>
  </div>
);

const RadioButtonContainer = ({
  label,
  value,
  disabled = false,
  onChange,
  hasTooltip,
  tooltipText,
}: RadioButtonContainerProps) => {
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === "true";
    onChange?.(newValue);
  };

  return (
    <div className="participant-details">
      <div className="full-label-container">
        <label>{label}</label>
        {hasTooltip && (
          <a data-tooltip-id="details-tooltip" data-tooltip-content={tooltipText}>
            <i className="bi bi-info-circle-fill"></i>
          </a>
        )}
      </div>
      <div className="radio-button-container">
        <div>
          <input
            type="radio"
            id={`${label}-true`}
            name={label}
            value="true"
            disabled={disabled}
            checked={value === true}
            onChange={handleRadioChange}
          />
          <label htmlFor={`${label}-true`}>Yes</label>
        </div>
        <div>
          <input
            type="radio"
            id={`${label}-false`}
            name={label}
            value="false"
            disabled={disabled}
            checked={value === false}
            onChange={handleRadioChange}
          />
          <label htmlFor={`${label}-false`}>No</label>
        </div>
      </div>
    </div>
  );
};

const ParticipantRegionSelect = ({
  label,
  value,
  disabled = false,
  onChange,
  country,
}: ParticipantRegionSelectProps) => {
  return (
    <div className="participant-details">
      <div className="full-label-container">
        <label>{label}</label>
      </div>
      <div className="full-input-container">
        <div className="input-container">
          <RegionDropdown
            country={country ? country : ""}
            value={value ? value.toString() : ""}
            onChange={(e) => onChange?.(e)}
            disabled={disabled}
          />
          <div className="underline"></div>
        </div>
      </div>
    </div>
  );
};

const ParticipantCountrySelect = ({
  label,
  value,
  disabled = false,
  onChange,
}: ParticipantCountrySelectProps) => {
  return (
    <div className="participant-details">
      <div className="full-label-container">
        <label>{label}</label>
      </div>
      <div className="full-input-container">
        <div className="input-container">
          <CountryDropdown
            priorityOptions={["CA", "US"]}
            value={value ? value.toString() : ""}
            onChange={(e) => onChange?.(e)}
            disabled={disabled}
          />
          <div className="underline"></div>
        </div>
      </div>
    </div>
  );
};

/**
 * Component focused on handling the participant General Details
 * @param param0
 * @returns
 */
const ParticipantGeneralDetailsComp = ({
  participant,
  editMode,
  setParticipant,
  setEditButtonDisabled,
}: ParticipantDetailsCompProps) => {
  const handleInputChange = (val: number | string | boolean, attrName: string) => {
    let updatedGeneralDetails = { ...participant.generalDetails };
    // Check if attr exists in general details
    if (attrName in updatedGeneralDetails) {
      updatedGeneralDetails[attrName] = val;
    }

    setParticipant({ ...participant, generalDetails: updatedGeneralDetails });
  };

  // Edit button may be disabled for some reason
  useEffect(() => {
    setEditButtonDisabled(false);
  }, []);

  return (
    <div className="participant-details-section">
      <p className="participant-details-section-header">General Details</p>
      <div className="participant-details-section-header-underline"></div>
      <RadioButtonContainer
        label="Is Individual"
        value={participant.generalDetails.isIndividual}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "isIndividual")}
      />
      <ParticipantInput
        label="Title"
        value={participant.generalDetails.title}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "title")}
      />
      <ParticipantSelect
        label="Marital Status"
        value={participant.generalDetails.maritalStatus}
        options={MaritalStatusOptions}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "maritalStatus")}
      />
      <ParticipantInput
        label="First Name"
        value={participant.generalDetails.firstName}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "firstName")}
      />
      <ParticipantInput
        label="Middle Initial"
        value={participant.generalDetails.middleInitial}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "middleInitial")}
      />
      <ParticipantInput
        label="Last Name"
        value={participant.generalDetails.lastName}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "lastName")}
      />
      <ParticipantInput
        label="Social Insurance Number"
        value={participant.generalDetails.sin}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "sin")}
      />
      <ParticipantInput
        label="Date of Birth"
        value={convertDateToString(participant.generalDetails.dateOfBirth)}
        type="date"
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "dateOfBirth")}
      />
      <ParticipantInput
        label="Number of Dependents"
        value={participant.generalDetails.numDependents}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleInputChange(e, "numDependents")}
      />
      <ParticipantInput
        label="Home Phone #"
        value={participant.generalDetails.homePhone}
        placeholder={editMode ? "555-555-5555" : ""}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "homePhone")}
      />
      <ParticipantInput
        label="Business Phone #"
        value={participant.generalDetails.businessPhone}
        placeholder={editMode ? "555-555-5555" : ""}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "businessPhone")}
      />
      <ParticipantInput
        label="Business Phone Ext"
        value={participant.generalDetails.businessPhoneExt}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleInputChange(e, "businessPhoneExt")}
      />
      <ParticipantInput
        label="Cell Phone #"
        value={participant.generalDetails.cellPhone}
        placeholder={editMode ? "555-555-5555" : ""}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "cellPhone")}
      />
      <ParticipantInput
        label="Email"
        value={participant.generalDetails.email}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "email")}
      />
      <ParticipantCountrySelect
        label="Citizenship"
        value={participant.generalDetails.citizenship}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "citizenship")}
      />
      <ParticipantCountrySelect
        label="Other Citizenship"
        value={participant.generalDetails.otherCitizenship}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "otherCitizenship")}
      />
      <ParticipantInput
        label="Language"
        value={participant.generalDetails.language}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "language")}
      />
    </div>
  );
};

/**
 * Component focused on handling the participant Address Details
 * @param param0
 * @returns
 */
const ParticipantAddressDetailsComp = ({
  participant,
  editMode,
  setParticipant,
  setEditButtonDisabled,
}: ParticipantDetailsCompProps) => {
  const handlePermanentAddressChange = (val: any, attrName: string) => {
    let updatedPermanentAddressDetails = { ...participant.permanentAddressDetails };
    // Check if attr exists in general details
    if (attrName in updatedPermanentAddressDetails) {
      updatedPermanentAddressDetails[attrName] = val;
    }

    setParticipant({ ...participant, permanentAddressDetails: updatedPermanentAddressDetails });
  };

  const handleMailingAddressChange = (val: any, attrName: string) => {
    let updatedMailingAddress = { ...participant.mailingAddressDetails };
    updatedMailingAddress[attrName] = val;

    setParticipant({ ...participant, mailingAddressDetails: updatedMailingAddress });
  };

  const handleThirdPartyAddressChange = (val: any, attrName: string) => {
    let updatedThirdPartyAddressDetails = { ...participant.thirdPartyAddressDetails };
    updatedThirdPartyAddressDetails[attrName] = val;

    setParticipant({ ...participant, thirdPartyAddressDetails: updatedThirdPartyAddressDetails });
  };

  useEffect(() => {
    setEditButtonDisabled(false);
  }, []);

  return (
    <div className="participant-details-section">
      <p className="participant-details-section-header">Permanent Address Details</p>
      <div className="participant-details-section-header-underline"></div>
      <ParticipantSelect
        label="Address Type"
        value={participant.permanentAddressDetails.addressType}
        options={AddressTypes}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "addressType")}
      />
      <ParticipantInput
        label="Apt / Unit / Suite #"
        value={participant.permanentAddressDetails.aptUnitSuiteNumber}
        type="number"
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "aptUnitSuiteNumber")}
      />
      <ParticipantInput
        label="Street #"
        value={participant.permanentAddressDetails.streetNumber}
        type="number"
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "streetNumber")}
      />
      <ParticipantInput
        label="Street Name"
        value={participant.permanentAddressDetails.streetName}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "streetName")}
      />
      <ParticipantInput
        label="City"
        value={participant.permanentAddressDetails.city}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "city")}
      />
      <ParticipantRegionSelect
        label="State/Province"
        value={participant.permanentAddressDetails.region}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "region")}
        country={participant.permanentAddressDetails.country}
      />
      <ParticipantCountrySelect
        label="Country"
        value={participant.permanentAddressDetails.country}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "country")}
      />
      <RadioButtonContainer
        label="Is Same as Mailing Address"
        value={participant.permanentAddressDetails.isSameAsMailingAddress}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "isSameAsMailingAddress")}
      />
      <RadioButtonContainer
        label="Has 3rd Party Address"
        value={participant.permanentAddressDetails.hasThirdPartyAddress}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "hasThirdPartyAddress")}
      />
      {!participant.permanentAddressDetails.isSameAsMailingAddress && (
        <>
          <p className="participant-details-section-header">Mailing Address Details</p>
          <div className="participant-details-section-header-underline"></div>
          <ParticipantSelect
            label="Address Type"
            value={
              participant.mailingAddressDetails ? participant.mailingAddressDetails.addressType : ""
            }
            options={AddressTypes}
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "addressType")}
          />
          <ParticipantInput
            label="Apt / Unit / Suite #"
            value={
              participant.mailingAddressDetails
                ? participant.mailingAddressDetails.aptUnitSuiteNumber
                : ""
            }
            type="number"
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "aptUnitSuiteNumber")}
          />
          <ParticipantInput
            label="Street #"
            value={
              participant.mailingAddressDetails
                ? participant.mailingAddressDetails.streetNumber
                : ""
            }
            type="number"
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "streetNumber")}
          />
          <ParticipantInput
            label="Street Name"
            value={
              participant.mailingAddressDetails ? participant.mailingAddressDetails.streetName : ""
            }
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "streetName")}
          />
          <ParticipantInput
            label="City"
            value={participant.mailingAddressDetails ? participant.mailingAddressDetails.city : ""}
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "city")}
          />
          <ParticipantRegionSelect
            label="State/Province"
            value={
              participant.mailingAddressDetails ? participant.mailingAddressDetails.region : ""
            }
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "region")}
            country={
              participant.mailingAddressDetails ? participant.mailingAddressDetails.country : ""
            }
          />
          <ParticipantCountrySelect
            label="Country"
            value={
              participant.mailingAddressDetails ? participant.mailingAddressDetails.country : ""
            }
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "country")}
          />
        </>
      )}
      {participant.permanentAddressDetails.hasThirdPartyAddress && (
        <>
          <p className="participant-details-section-header">3rd Party Address Details</p>
          <div className="participant-details-section-header-underline"></div>
          <ParticipantSelect
            label="Address Type"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.addressType
                : ""
            }
            options={AddressTypes}
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "addressType")}
          />
          <ParticipantInput
            label="Apt / Unit / Suite #"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.aptUnitSuiteNumber
                : ""
            }
            type="number"
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "aptUnitSuiteNumber")}
          />
          <ParticipantInput
            label="Street #"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.streetNumber
                : ""
            }
            type="number"
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "streetNumber")}
          />
          <ParticipantInput
            label="Street Name"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.streetName
                : ""
            }
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "streetName")}
          />
          <ParticipantInput
            label="City"
            value={
              participant.thirdPartyAddressDetails ? participant.thirdPartyAddressDetails.city : ""
            }
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "city")}
          />
          <ParticipantRegionSelect
            label="State/Province"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.region
                : ""
            }
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "region")}
            country={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.country
                : ""
            }
          />
          <ParticipantCountrySelect
            label="Country"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.country
                : ""
            }
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "country")}
          />
        </>
      )}
    </div>
  );
};

/**
 * Compopnent focused on handling the participant Employment Details
 * @param param0
 * @returns
 */
const ParticipantEmploymentDetailsComp = ({
  participant,
  editMode,
  setParticipant,
  setEditButtonDisabled,
}: ParticipantDetailsCompProps) => {
  const handleEmploymentInputChange = (val: number | string | boolean, attrName: string) => {
    let updatedEmploymentDetails = { ...participant.employmentDetails };
    // Check if attr exists in general details
    if (attrName in updatedEmploymentDetails) {
      updatedEmploymentDetails[attrName] = val;
    }

    setParticipant({ ...participant, employmentDetails: updatedEmploymentDetails });
  };

  useEffect(() => {
    setEditButtonDisabled(false);
  }, []);

  return (
    <div className="participant-details-section">
      <p className="participant-details-section-header">Employment Details</p>
      <div className="participant-details-section-header-underline"></div>
      <ParticipantSelect
        label="Employment Status"
        value={participant.employmentDetails.status}
        options={EmploymentTypes}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "status")}
      />
      <ParticipantInput
        label="Occupation"
        value={participant.employmentDetails.occupation}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "occupation")}
      />
      <ParticipantInput
        label="Employer Name"
        value={participant.employmentDetails.name}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "name")}
      />
      <ParticipantInput
        label="Type of Business"
        value={participant.employmentDetails.businessType}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "businessType")}
      />
      <ParticipantInput
        label="Length of Employment (Years)"
        value={participant.employmentDetails.lengthInYears}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleEmploymentInputChange(e, "lengthInYears")}
      />
      <ParticipantInput
        label="Address of Employment"
        value={participant.employmentDetails.address}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "address")}
      />
      <ParticipantInput
        label="City"
        value={participant.employmentDetails.city}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "address")}
      />
      <ParticipantRegionSelect
        label="State/Province"
        value={participant.employmentDetails.region}
        country={participant.employmentDetails.country}
        onChange={(e) => handleEmploymentInputChange(e, "region")}
        disabled={!editMode}
      />
      <ParticipantCountrySelect
        label="Country"
        value={participant.employmentDetails.country}
        onChange={(e) => handleEmploymentInputChange(e, "country")}
        disabled={!editMode}
      />
      <ParticipantInput
        label="Postal Code"
        value={participant.employmentDetails.postalCode}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "postalCode")}
      />
    </div>
  );
};

/**
 * Component focused on handling the participant Spouse Details,
 * if the Spouse is a client themselves then they cannot edit their details
 * here, instead there will be a link to their profile.
 */
const ParticipantSpouseDetailsComp = ({
  participant,
  editMode,
  setEditMode,
  setParticipant,
  setEditButtonDisabled,
}: ParticipantDetailsCompProps) => {
  useEffect(() => {
    if (participant.spouseDetails?.isClient) {
      setEditButtonDisabled(true);
      setEditMode(false);
    } else {
      setEditButtonDisabled(false);
    }
  }, []);

  const handleSpouseDetailChange = (val: number | string | boolean, attrName: string) => {
    let updatedSpouseDetails = { ...participant.spouseDetails };
    // Check if attr exists in general details
    if (attrName in updatedSpouseDetails) {
      updatedSpouseDetails[attrName] = val;
    }

    setParticipant({ ...participant, spouseDetails: updatedSpouseDetails });
  };

  const navigate = useNavigate();

  return (
    <div className="participant-details-section">
      <p className="participant-details-section-header">Spouse Details</p>
      <div className="participant-details-section-header-underline"></div>
      {participant.spouseDetails ? (
        <>
          <ParticipantInput
            label="First Name"
            value={participant.spouseDetails ? participant.spouseDetails.firstName : ""}
            disabled={!editMode}
            onChange={(e) => handleSpouseDetailChange(e, "firstName")}
          />
          <ParticipantInput
            label="Middle Initial"
            value={participant.spouseDetails ? participant.spouseDetails.middleInitial : ""}
            disabled={!editMode}
            onChange={(e) => handleSpouseDetailChange(e, "middleInitial")}
          />
          <ParticipantInput
            label="Last Name"
            value={participant.spouseDetails ? participant.spouseDetails.lastName : ""}
            disabled={!editMode}
            onChange={(e) => handleSpouseDetailChange(e, "lastName")}
          />
          <ParticipantInput
            label="Employer Name"
            value={participant.spouseDetails ? participant.spouseDetails.employerName : ""}
            disabled={!editMode}
            onChange={(e) => handleSpouseDetailChange(e, "employerName")}
          />
          <ParticipantInput
            label="Occupation"
            value={participant.spouseDetails ? participant.spouseDetails.occupation : ""}
            disabled={!editMode}
            onChange={(e) => handleSpouseDetailChange(e, "occupation")}
          />
          <ParticipantInput
            label="Type of Business"
            value={participant.spouseDetails ? participant.spouseDetails.typeOfBusiness : ""}
            disabled={!editMode}
            onChange={(e) => handleSpouseDetailChange(e, "typeOfBusiness")}
          />
          <ParticipantInput
            label="SIN"
            value={participant.spouseDetails ? participant.spouseDetails.sin : ""}
            disabled={!editMode}
            onChange={(e) => handleSpouseDetailChange(e, "sin")}
          />
          <ParticipantInput
            label="SIN"
            value={participant.spouseDetails ? participant.spouseDetails.sin : ""}
            disabled={!editMode}
            onChange={(e) => handleSpouseDetailChange(e, "sin")}
          />
          {/* <ParticipantInput
          label="Gender"
          value={participant.spouseDetails ? participant.spouseDetails.gender : ""}
          disabled={!editMode}
          onChange={(e) => handleSpouseDetailChange(e, "gender")}
        /> */}
          {participant.spouseDetails?.isClient && participant.spouseDetails.participantId && (
            <div>
              <button
                onClick={() => {
                  navigate(`/participant/${participant.spouseDetails.participantId}`);
                  navigate(0); // allows for a refresh
                }}
              >
                View Participant Profile
              </button>
            </div>
          )}
        </>
      ) : (
        <p>Spouse Details Not Found</p>
      )}
    </div>
  );
};

/**
 * Component focused on handling the participant Net Worth Details
 * Recently added the external investments section on 2023-10-10
 * @param param0
 * @returns
 */
const ParticipantNetWorthDetails = ({
  participant,
  editMode,
  setParticipant,
}: ParticipantDetailsCompProps) => {
  const handleNetWorthChange = (val: any, attrName: string) => {
    let netWorthDetails = { ...participant.netWorthDetails };
    // Check if attr exists in general details
    if (attrName in netWorthDetails) {
      netWorthDetails[attrName] = val;
    }

    setParticipant({ ...participant, netWorthDetails: netWorthDetails });
  };

  return (
    <div className="participant-details-section">
      <p className="participant-details-section-header">Net Worth Details</p>
      <div className="participant-details-section-header-underline"></div>
      <ParticipantInput
        label="Estimated Net Liquid Assets"
        value={participant.netWorthDetails.liquidAssets}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleNetWorthChange(e, "liquidAssets")}
        isDollar={true}
      />
      <ParticipantInput
        label="Estimated Net Fixed Assets"
        value={participant.netWorthDetails.fixedAssets}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleNetWorthChange(e, "fixedAssets")}
        isDollar={true}
      />
      <ParticipantInput
        label="Annual Income From Sources"
        value={participant.netWorthDetails.annualIncome}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleNetWorthChange(e, "annualIncome")}
        isDollar={true}
      />
      <p className="participant-details-section-header">External Investments</p>
      <div className="participant-details-section-header-underline"></div>
      <ParticipantInput
        label="Equities"
        value={participant.netWorthDetails.equitiesValue}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleNetWorthChange(e, "equitiesValue")}
        isDollar={true}
      />
      <ParticipantInput
        label="Fixed Income"
        value={participant.netWorthDetails.fixedIncomeValue}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleNetWorthChange(e, "fixedIncomeValue")}
        isDollar={true}
      />
      <ParticipantInput
        label="Cash and Equivalents"
        value={participant.netWorthDetails.cashEquivalentValue}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleNetWorthChange(e, "cashEquivalentValue")}
        isDollar={true}
      />
      <ParticipantInput
        label="Commoditities"
        value={participant.netWorthDetails.commoditiesValue}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleNetWorthChange(e, "commoditiesValue")}
        isDollar={true}
      />
      <ParticipantInput
        label="Real Estate"
        value={participant.netWorthDetails.realEstateValue}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleNetWorthChange(e, "realEstateValue")}
        isDollar={true}
      />
    </div>
  );
};

/**
 * Component focused on handling the participant Regulatory information
 * Insider, Shareholder, HIO, PEP, etc.
 * @param param0
 * @returns
 */
const ParticipantRegulatoryDetails = ({
  participant,
  editMode,
  setParticipant,
}: ParticipantDetailsCompProps) => {
  // Handles the change of any boolean attribute in the regulatory details
  const handleRegulatoryDetailChange = (val: number | string | boolean, attrName: string) => {
    let regulatoryDetails = { ...participant.regulatoryDetails };

    regulatoryDetails[attrName] = val;
    setParticipant({ ...participant, regulatoryDetails: regulatoryDetails });
  };

  // Handles the add of any insider detail attribute that is a dynamic array
  const handleGenericInsiderDetailAdd = (attrName: string) => {
    let tempData = { ...participant.regulatoryDetails };
    if (!tempData[attrName]) {
      tempData[attrName] = [];
    }

    // Using spread operator to add an empty insider detail
    tempData[attrName] = [...tempData[attrName], { ...{ symbol: "", companyName: "" } }];
    setParticipant({ ...participant, regulatoryDetails: tempData });
  };

  // Handle the change of any insider detail attribute that is a dynamic array
  const handleGenericInsiderDetailChange = (
    val: number | string | boolean,
    insiderAttrName: string,
    attrName: string,
    index: number
  ) => {
    let regulatoryDetails = { ...participant.regulatoryDetails };

    const updatedObject = {
      ...regulatoryDetails[insiderAttrName][index],
      [attrName]: val,
    };

    regulatoryDetails[insiderAttrName] = [
      ...regulatoryDetails[insiderAttrName].slice(0, index),
      updatedObject,
      ...regulatoryDetails[insiderAttrName].slice(index + 1),
    ];

    setParticipant({ ...participant, regulatoryDetails: regulatoryDetails });
  };

  // Handles the removal of any insider detail attribute that is a dynamic array
  const handleGenericRemoveInsiderDetails = (insiderAttrName: string, index: number) => {
    if (window.confirm("Are you sure you want to delete this asset?")) {
      let tempData = { ...participant.regulatoryDetails };
      if (!tempData[insiderAttrName]) {
        return;
      }

      tempData[insiderAttrName] = [
        ...tempData[insiderAttrName].slice(0, index),
        ...tempData[insiderAttrName].slice(index + 1),
      ];

      setParticipant({ ...participant, regulatoryDetails: tempData });
    }
  };

  // General handler for any insider detail attribute
  const handleGenericInsiderDetail = (
    val: string | number | boolean,
    insiderAttrName: string,
    attrName: string
  ) => {
    let tempData = { ...participant.regulatoryDetails };
    if (!tempData[insiderAttrName]) {
      tempData[insiderAttrName] = {};
    }

    console.log(tempData);
    tempData[insiderAttrName][attrName] = val;

    setParticipant({ ...participant, regulatoryDetails: tempData });
  };

  return (
    <div className="participant-details-section">
      <p className="participant-details-section-header">Regulatory Details</p>
      <div className="participant-details-section-header-underline"></div>
      <p className="participant-regulatory-detail-section-header">Insider Details</p>
      <RadioButtonContainer
        label="Insider?"
        value={participant.regulatoryDetails?.isInsider}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "isInsider");
        }}
        hasTooltip={true}
        tooltipText="An insider in trading is someone with access to confidential information about a publicly traded company, and trading on this non-public information is illegal."
      />
      {participant.regulatoryDetails?.isInsider && (
        <InsiderDetails
          participant={participant}
          setParticipant={setParticipant}
          editMode={editMode}
          insiderAttrName="insiderDetails"
        />
      )}
      <p className="participant-regulatory-detail-section-header">10% Shareholder Details</p>
      <RadioButtonContainer
        label="10% Stakeholder?"
        value={participant.regulatoryDetails?.is10PercentShareholder}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "is10PercentShareholder");
        }}
      />
      {participant.regulatoryDetails?.is10PercentShareholder && (
        <InsiderDetails
          participant={participant}
          editMode={editMode}
          setParticipant={setParticipant}
          insiderAttrName="shareholder10Details"
        />
      )}
      <p className="participant-regulatory-detail-section-header">20% Shareholder Details</p>
      <RadioButtonContainer
        label="20% Stakeholder?"
        value={participant.regulatoryDetails?.is20PercentShareholder}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "is20PercentShareholder");
        }}
      />
      {participant.regulatoryDetails?.is20PercentShareholder && (
        <InsiderDetails
          participant={participant}
          editMode={editMode}
          setParticipant={setParticipant}
          insiderAttrName="shareholder20Details"
        />
      )}
      <p className="participant-regulatory-detail-section-header">IIROC Details</p>
      <RadioButtonContainer
        label="IIROC Member?"
        value={participant.regulatoryDetails?.isIIROCMember}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "isIIROCMember");
        }}
        hasTooltip={true}
        tooltipText="IIROC oversees the registration of firms and individuals who provide advice and conduct securities trading in Canada"
      />
      {participant.regulatoryDetails?.isIIROCMember && (
        <>
          <ParticipantInput
            label="Name Of Firm"
            value={participant.regulatoryDetails?.iirocDetails?.nameOfFirm}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "iirocDetails", "nameOfFirm")}
          />
        </>
      )}
      <p className="participant-regulatory-detail-section-header">
        Foreign Poltically Exposed Person Details
      </p>
      <RadioButtonContainer
        label="Foreign PEP?"
        value={participant.regulatoryDetails?.isForeignPEP}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "isForeignPEP");
        }}
      />
      {participant.regulatoryDetails?.isForeignPEP && (
        <>
          <ParticipantCountrySelect
            label="Country"
            value={participant.regulatoryDetails?.foreignPEPDetails?.positionHeld}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "foreignPEPDetails", "positionHeld")}
          />
          <ParticipantInput
            label="Position Held"
            value={participant.regulatoryDetails?.foreignPEPDetails?.country}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "foreignPEPDetails", "country")}
          />
        </>
      )}
      <div
        className="divider"
        style={{ height: "2px", width: "100%", backgroundColor: "#ccc" }}
      ></div>
      <RadioButtonContainer
        label="Associate of Foreign PEP?"
        value={participant.regulatoryDetails?.isAssociateForeignPEP}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "isAssociateForeignPEP");
        }}
      />
      {participant.regulatoryDetails?.isAssociateForeignPEP && (
        <>
          <ParticipantInput
            label="Associate Name"
            value={participant.regulatoryDetails?.foreignAssociatePEPDetails?.associateName}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "associateName")
            }
          />
          <ParticipantCountrySelect
            label="Country"
            value={participant.regulatoryDetails?.foreignAssociatePEPDetails?.country}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "country")}
          />
          <ParticipantInput
            label="Organization"
            value={participant.regulatoryDetails?.foreignAssociatePEPDetails?.organizationName}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "organizationName")
            }
          />
          <ParticipantInput
            label="Job Title"
            value={participant.regulatoryDetails?.foreignAssociatePEPDetails?.jobTitle}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "jobTitle")
            }
          />
          <ParticipantInput
            label="Relationship"
            value={participant.regulatoryDetails?.foreignAssociatePEPDetails?.relationship}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "relationship")
            }
          />
          <ParticipantInput
            label="Position Held"
            value={participant.regulatoryDetails?.foreignAssociatePEPDetails?.positionHeld}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "foreignAssociatePEPDetails", "positionHeld")
            }
          />
        </>
      )}
      <p className="participant-regulatory-detail-section-header">
        Domestic Poltically Exposed Person Details
      </p>
      <RadioButtonContainer
        label="Domestic PEP?"
        value={participant.regulatoryDetails?.isDomesticPEP}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "isDomesticPEP");
        }}
      />
      {participant.regulatoryDetails?.isDomesticPEP && (
        <>
          <ParticipantInput
            label="Position Held"
            value={participant.regulatoryDetails?.domesticPEPDetails?.positionHeld}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "domesticPEPDetails", "positionHeld")}
          />
        </>
      )}
      <div
        className="divider"
        style={{ height: "2px", width: "100%", backgroundColor: "#ccc" }}
      ></div>
      <RadioButtonContainer
        label="Associate of Domestic PEP?"
        value={participant.regulatoryDetails?.isAssociateDomesticPEP}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "isAssociateDomesticPEP");
        }}
      />
      {participant.regulatoryDetails?.isAssociateDomesticPEP && (
        <>
          <ParticipantInput
            label="Associate Name"
            value={participant.regulatoryDetails?.domesticAssociatePEPDetails?.associateName}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "domesticAssociatePEPDetails", "associateName")
            }
          />
          <ParticipantInput
            label="Job Title"
            value={participant.regulatoryDetails?.domesticAssociatePEPDetails?.jobTitle}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "domesticAssociatePEPDetails", "jobTitle")
            }
          />
          <ParticipantInput
            label="Relationship"
            value={participant.regulatoryDetails?.domesticAssociatePEPDetails?.relationship}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "domesticAssociatePEPDetails", "relationship")
            }
          />
          <ParticipantInput
            label="Position Held"
            value={participant.regulatoryDetails?.domesticAssociatePEPDetails?.positionHeld}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "domesticAssociatePEPDetails", "positionHeld")
            }
          />
        </>
      )}
      <p className="participant-regulatory-detail-section-header">
        Head of International Organization (HIO) Details
      </p>
      <RadioButtonContainer
        label="HIO?"
        value={participant.regulatoryDetails?.isHIO}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "isHIO");
        }}
      />
      {participant.regulatoryDetails.isHIO && (
        <>
          <ParticipantInput
            label="Job Title"
            value={participant.regulatoryDetails?.hioDetails?.jobTitle}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "hioDetails", "jobTitle")}
          />
          <ParticipantCountrySelect
            label="Country"
            value={participant.regulatoryDetails?.hioDetails?.country}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "hioDetails", "country")}
          />
          <ParticipantInput
            label="Name of Organization"
            value={participant.regulatoryDetails?.hioDetails?.nameOfOrganization}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "hioDetails", "nameOfOrganization")}
          />
        </>
      )}
      <div
        className="divider"
        style={{ height: "2px", width: "100%", backgroundColor: "#ccc" }}
      ></div>
      <RadioButtonContainer
        label="Associate of HIO?"
        value={participant.regulatoryDetails?.isAssociateHIO}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "isAssociateHIO");
        }}
      />
      {participant.regulatoryDetails?.isAssociateHIO && (
        <>
          <ParticipantInput
            label="Name Of Associate"
            value={participant.regulatoryDetails?.associateHIODetails?.nameOfAssociate}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "associateHIODetails", "nameOfAssociate")
            }
          />
          <ParticipantInput
            label="Job Title"
            value={participant.regulatoryDetails?.associateHIODetails?.jobTitle}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "associateHIODetails", "jobTitle")}
          />
          <ParticipantCountrySelect
            label="Country"
            value={participant.regulatoryDetails?.associateHIODetails?.country}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "associateHIODetails", "country")}
          />
          <ParticipantInput
            label="Name of Organization"
            value={participant.regulatoryDetails?.associateHIODetails?.nameOfOrganization}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "associateHIODetails", "nameOfOrganization")
            }
          />
          <ParticipantInput
            label="Relationship"
            value={participant.regulatoryDetails?.associateHIODetails?.relationship}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "associateHIODetails", "relationship")}
          />
        </>
      )}
      <p className="participant-regulatory-detail-section-header">Securities Firm Details</p>
      <RadioButtonContainer
        label="Associate of Securities Firm Member?"
        value={participant.regulatoryDetails?.isSecuritiesFirm}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "isSecuritiesFirm");
        }}
      />
      {participant.regulatoryDetails.isSecuritiesFirm && (
        <>
          <ParticipantInput
            label="Name Of Firm"
            value={participant.regulatoryDetails?.securitiesFirmInformation?.name}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "securitiesFirmInformation", "name")}
          />
          <ParticipantInput
            label="Relationship"
            value={participant.regulatoryDetails?.securitiesFirmInformation?.relationship}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "securitiesFirmInformation", "relationship")
            }
          />
          <ParticipantInput
            label="Company Name"
            value={participant.regulatoryDetails?.securitiesFirmInformation?.companyName}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "securitiesFirmInformation", "companyName")
            }
          />
          <ParticipantInput
            label="Company Position"
            value={participant.regulatoryDetails?.securitiesFirmInformation?.companyPosition}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "securitiesFirmInformation", "companyPosition")
            }
          />
        </>
      )}
      <p className="participant-regulatory-detail-section-header">Broker Dealer Details</p>
      <RadioButtonContainer
        label="Member of Broker Dealer?"
        value={participant.regulatoryDetails?.isBrokerDealer}
        disabled={!editMode}
        onChange={(e) => {
          handleRegulatoryDetailChange(e, "isBrokerDealer");
        }}
      />
      {participant.regulatoryDetails.isBrokerDealer && (
        <>
          <ParticipantInput
            label="Associate Name"
            value={participant.regulatoryDetails?.brokerDealerInformation?.name}
            disabled={!editMode}
            onChange={(e) => handleGenericInsiderDetail(e, "brokerDealerInformation", "name")}
          />
          <ParticipantInput
            label="Relationship"
            value={participant.regulatoryDetails?.brokerDealerInformation?.relationship}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "brokerDealerInformation", "relationship")
            }
          />
          <ParticipantInput
            label="Company Name"
            value={participant.regulatoryDetails?.brokerDealerInformation?.companyName}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "brokerDealerInformation", "companyName")
            }
          />
          <ParticipantInput
            label="Company Position"
            value={participant.regulatoryDetails?.brokerDealerInformation?.companyPosition}
            disabled={!editMode}
            onChange={(e) =>
              handleGenericInsiderDetail(e, "brokerDealerInformation", "companyPosition")
            }
          />
        </>
      )}
    </div>
  );
};

interface InsiderDetailsProps {
  participant: Participant;
  setParticipant: React.Dispatch<React.SetStateAction<Participant>>;
  editMode: boolean;
  insiderAttrName: string;
}

export const InsiderDetails = ({
  participant,
  setParticipant,
  editMode,
  insiderAttrName,
}: InsiderDetailsProps) => {
  const handleInsiderAddNew = () => {
    let tempData = { ...participant.regulatoryDetails };
    if (!tempData[insiderAttrName]) {
      tempData[insiderAttrName] = [];
    }

    // Using spread operator to add an empty insider detail
    tempData[insiderAttrName] = [
      ...tempData[insiderAttrName],
      { ...{ symbol: "", companyName: "" } },
    ];
    setParticipant({ ...participant, regulatoryDetails: tempData });
  };

  const handleGenericInsiderDetailChange = (
    val: number | string | boolean,
    attrName: string,
    index: number
  ) => {
    let regulatoryDetails = { ...participant.regulatoryDetails };

    const updatedObject = {
      ...regulatoryDetails[insiderAttrName][index],
      [attrName]: val,
    };

    regulatoryDetails[insiderAttrName] = [
      ...regulatoryDetails[insiderAttrName].slice(0, index),
      updatedObject,
      ...regulatoryDetails[insiderAttrName].slice(index + 1),
    ];

    setParticipant({ ...participant, regulatoryDetails: regulatoryDetails });
  };

  // Handles the removal of any insider detail attribute that is a dynamic array
  const handleGenericRemoveInsiderDetails = (index: number) => {
    if (window.confirm("Are you sure you want to delete this asset?")) {
      let tempData = { ...participant.regulatoryDetails };
      if (!tempData[insiderAttrName]) {
        return;
      }

      tempData[insiderAttrName] = [
        ...tempData[insiderAttrName].slice(0, index),
        ...tempData[insiderAttrName].slice(index + 1),
      ];

      setParticipant({ ...participant, regulatoryDetails: tempData });
    }
  };

  return (
    <div
      className="insider-details-container"
      style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}
    >
      {editMode && (
        <div className="add-insider-detainer-btn-cont">
          <button onClick={handleInsiderAddNew}>Add Insider Detail</button>
        </div>
      )}
      <div className="insider-table-container">
        {participant.regulatoryDetails?.[insiderAttrName]?.length > 0 && (
          <table>
            <tr>
              <th>Symbol</th>
              <th>Company Name</th>
              {editMode && <th></th>}
            </tr>
            {participant.regulatoryDetails?.[insiderAttrName]?.map(
              (insiderDetail: { symbol: string; companyName: string }, index: number) => {
                return (
                  <tr>
                    <td>
                      {editMode ? (
                        <input
                          value={insiderDetail.symbol}
                          onChange={(e) =>
                            handleGenericInsiderDetailChange(e.target.value, "symbol", index)
                          }
                          placeholder="e.g. AAPL"
                        />
                      ) : (
                        insiderDetail.symbol
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          value={insiderDetail.companyName}
                          onChange={(e) =>
                            handleGenericInsiderDetailChange(e.target.value, "companyName", index)
                          }
                          placeholder="e.g. Apple"
                        />
                      ) : (
                        insiderDetail.companyName
                      )}
                    </td>
                    {editMode && (
                      <td>
                        <button
                          onClick={() => {
                            handleGenericRemoveInsiderDetails(index);
                          }}
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </td>
                    )}
                  </tr>
                );
              }
            )}
          </table>
        )}
      </div>
    </div>
  );
};

const ParticipantDetailsComponents: React.ComponentType<ParticipantDetailsCompProps>[] = [
  ParticipantGeneralDetailsComp,
  ParticipantAddressDetailsComp,
  ParticipantEmploymentDetailsComp,
  ParticipantSpouseDetailsComp,
  ParticipantNetWorthDetails,
  ParticipantRegulatoryDetails,
];

function ParticipantDetails({ participant }: ParticipantDetailsSectionProps) {
  // We need to maintain a copy of participant in state so that we can update it
  // and then pass it back to the parent component
  const [participantCopy, setParticipantCopy] = useState<Participant>({ ...participant });
  // Keep Track of Edit Mode
  const [editMode, setEditMode] = useState(false);
  // Track State of which component is being rendered
  const [currentComponent, setCurrentComponent] = useState(0);
  // Grab the current component
  const CurrentComponent = ParticipantDetailsComponents[currentComponent];
  // Update Participant Mutation
  const [updateParticipant] = useUpdateNewClientMutation();
  // Save Loading State
  const [saveLoading, setSaveLoading] = useState(false);
  // Edit button disabled state, this only applies when spouse details are being edited
  // When the spouse is a client, we need to disable the edit button, otherwise, edit away
  const [editBtnDisabled, setEditBtnDisabled] = useState(false);

  // Cancel Edit Functionality
  const handleCancelEdit = () => {
    // Reset the participant copy to the original participant
    setParticipantCopy({ ...participant });
    // Disable edit mode
    setEditMode(false);
  };

  // Save Functionality
  const handleSave = async () => {
    setSaveLoading(true);
    // Attempt to edit the participant
    const result: ApiResponseModel = await updateParticipant(participantCopy);
    // Check if the edit was successful
    if (result.data && result.data.isSuccess) {
      notify("Participant Updated Successfully", "success");
      // Disable edit mode
      setEditMode(false);
    } else if (result.error) {
      notify(result.error.message, "error");
    }

    setSaveLoading(false);
  };

  return (
    <div className="participant-details-container">
      <Tooltip id="details-tooltip" />
      <ParticipantDetailsNavBar
        setCurrentComponent={setCurrentComponent}
        participant={participant}
      />
      <div className="vertical-line"></div>
      <CurrentComponent
        participant={participantCopy}
        editMode={editMode}
        setEditMode={setEditMode}
        setParticipant={setParticipantCopy}
        setEditButtonDisabled={setEditBtnDisabled}
      />
      <div className="actions-container">
        <div className="actions-container-btns">
          {!editMode && (
            <a
              data-tooltip-id="details-tooltip"
              data-tooltip-content={
                editBtnDisabled
                  ? "Spouse Details Cannot Be Edited When Spouse Is A Participant"
                  : ""
              }
            >
              <button
                onClick={() => setEditMode(true)}
                className="btn btn-warning"
                disabled={editBtnDisabled}
              >
                Enable
                <br /> Edit Mode
              </button>
            </a>
          )}
          {editMode && (
            <>
              <button className="btn btn-success" onClick={handleSave}>
                Save {saveLoading && <SpinningLoader />}
              </button>
              <button className="btn btn-danger" onClick={handleCancelEdit}>
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

interface ParticipantDetailsNavBarProps {
  setCurrentComponent: React.Dispatch<React.SetStateAction<number>>;
  participant?: Participant;
}

const ParticipantDetailsNavBar = ({
  setCurrentComponent,
  participant,
}: ParticipantDetailsNavBarProps) => {
  const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    $(".participant-details-navbar-item").removeClass("active");
    $(e.currentTarget).parent().addClass("active");
    setCurrentComponent(index);
  };

  return (
    <div className="participant-details-nav-bar">
      <div className="participant-details-navbar-item active">
        <button onClick={(e) => handleTabClick(e, 0)}>General</button>
      </div>
      {participant &&
        participant.roles.includes(ParticipantRole.ACCOUNT_HOLDER.toLocaleLowerCase()) &&
        participant?.accountHolderWorkflow?.kycCompleted && (
          <>
            <div className="participant-details-navbar-item">
              <button onClick={(e) => handleTabClick(e, 1)}>Address</button>
            </div>
            <div className="participant-details-navbar-item">
              <button onClick={(e) => handleTabClick(e, 2)}>Employment</button>
            </div>
            <div className="participant-details-navbar-item">
              <button onClick={(e) => handleTabClick(e, 3)}>Spouse</button>
            </div>
            <div className="participant-details-navbar-item">
              <button onClick={(e) => handleTabClick(e, 4)}>Net Worth</button>
            </div>
            <div className="participant-details-navbar-item">
              <button onClick={(e) => handleTabClick(e, 5)}>Regulatory</button>
            </div>
          </>
        )}
    </div>
  );
};

export default ParticipantDetails;
