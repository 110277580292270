import { useSendMagicLinkMutation } from "apis/mainApi";
import { SpinningLoader } from "components/Shared";
import { ParticipantDetailsSectionProps } from "features/participant/types";
import { useState } from "react";
import { ApiResponseModel } from "types";
import { notify } from "utils";

/**
 * This component is responsible for displaying the status of the RTQ Sent to Participant Workflow Item
 * As this is the first task, there is no previous task to check if it is completed or not
 */
const RTQSentToParticipantItem = ({ participant }: ParticipantDetailsSectionProps) => {
  // Sending Link for Completion of RTQ
  const [sendMagicLink] = useSendMagicLinkMutation();
  // Keep Track of State of Sending Link
  const [isSending, setIsSending] = useState(false);
  // Handle Sending the Magic Link
  const handleSendMagicLink = async () => {
    const params = {
      participantId: participant.id,
    };
    try {
      setIsSending(true);
      const result: ApiResponseModel = await sendMagicLink(params);
      if (result.data && result.data.isSuccess) {
        notify("Risk Tolerance Link sent successfully", "success");
      } else {
        notify("Error sending Risk Tolerance Link", "error");
        console.log(result.error);
      }
      setIsSending(false);
    } catch (error) {
      notify("Error sending Risk Tolerance Link", "error");
      console.log(error);
    }
  };

  return (
    <>
      {!participant?.accountHolderWorkflow?.rtqSentToParticipant ? (
        <div className="workflow-item-status-container">
          <a
            data-tooltip-id="workflow-tooltip"
            data-tooltip-content="Click to Email Questionaire to Client"
          >
            <button className="status-btn not-complete-status-btn" onClick={handleSendMagicLink}>
              <i className="bi bi-envelope-at-fill"></i>Questionaire Not Sent To Client{" "}
              {isSending && <SpinningLoader />}
            </button>
          </a>
        </div>
      ) : (
        <div className="workflow-item-status-container">
          <a
            data-tooltip-id="workflow-tooltip"
            data-tooltip-content="Click to Re-Send Questionaire to Client"
          >
            <button className="status-btn complete-status-btn" onClick={handleSendMagicLink}>
              <i className="bi bi-envelope-at-fill"></i> Questionaire Sent To Client{" "}
              {isSending && <SpinningLoader />}
            </button>
          </a>
        </div>
      )}
    </>
  );
};

export default RTQSentToParticipantItem;
