import { SIACountrySelect, SIASelect } from "components/Shared";
import { AccountSelectProps } from "../types";

/**
 * @name           AccountSelect
 * @summary        This component is responsible for displaying the select dropdowns for the Create Account Page.
 * @param          label the label for the select dropdown
 * @param          value the value of the select dropdown
 * @param          options the options for the select dropdown
 * @param          onChange the onChange function for the select dropdown
 * @param          isCountry if the select dropdown is a country select dropdown
 * @returns        React.Component
 */
export function AccountSelect({
  label,
  value,
  options,
  onChange,
  isCountry,
  disabled,
}: AccountSelectProps) {
  return (
    <div className="account-section-detail">
      <div className="label-container">
        <label>{label}</label>
      </div>
      {isCountry ? (
        <SIACountrySelect value={value} onChange={(e) => onChange?.(e)} />
      ) : (
        <SIASelect
          options={options}
          value={value}
          onChange={(e) => onChange?.(e)}
          disabled={disabled}
        />
      )}
    </div>
  );
}

export default AccountSelect;
