import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { notify } from "utils";

/**
 * Validator to ensure that the account has two joint holders on the account
 * @param {CreateInvestmentAccountDto} data
 */
const ValidateJointAccountDetails = (data: CreateInvestmentAccountDto) => {
  if (data.accountHolders && data?.accountHolders.length < 2) {
    notify("Please add at least two account holders", "error");
    return false;
  }

  return true;
};

export default ValidateJointAccountDetails;
