import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { checkEmptyValue, notify } from "utils";

const ValidatePACDetails = (accountData: CreateInvestmentAccountDto) => {
  const pacDetails = accountData.pacDetails;
  if (pacDetails === undefined || pacDetails === null || pacDetails.length === 0) {
    notify("Please enter a PAC Detail", "error");
    return false;
  }

  for (let i = 0; i < pacDetails.length; i++) {
    const pacDetail = pacDetails[i];
    if (checkEmptyValue(pacDetail.type)) {
      notify("Please enter a type for all PAC Details", "error");
      return false;
    }
    if (checkEmptyValue(pacDetail.amount)) {
      notify("Please enter an amount for all PAC Details", "error");
      return false;
    }
    if (checkEmptyValue(pacDetail.frequency)) {
      notify("Please enter a frequency for all PAC Details", "error");
      return false;
    }
    if (checkEmptyValue(pacDetail.startDate)) {
      notify("Please enter a start date for all PAC Details", "error");
      return false;
    }
    if (checkEmptyValue(pacDetail.accountOption)) {
      notify("Please enter an account option for all PAC Details", "error");
      return false;
    }
    if (checkEmptyValue(pacDetail.bankAccountId)) {
      notify("Please select a bank account for all PAC Details", "error");
      return false;
    }
  }

  return true;
};

export default ValidatePACDetails;
