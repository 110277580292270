import { SIAInput } from "components/Shared";
import { IPSSectionComponentProps } from "../types";

/**
 * Allows user to specify specific investment objectives for the client
 * @param param0
 * @returns
 */
const IPSClientInvestmentObjectives = ({ formData, setFormData }: IPSSectionComponentProps) => {
  return (
    <div className="ips-section-container">
      <div className="ips-section-header-container">
        <p className="ips-section-header">Investment Objectives</p>
      </div>
      <div className="ips-section-body-container">
        <div className="ips-objective-section">
          <p className="ips-section-body-container-header">Cash and Equivalent Objectives</p>
          <div className="ips-objective-inputs-section">
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Lower Limit %</label>
              <SIAInput
                value={formData.cashAndEquivalentsObjectives.lowerLimit}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cashAndEquivalentsObjectives: {
                      ...formData.cashAndEquivalentsObjectives,
                      lowerLimit: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Target %</label>
              <SIAInput
                value={formData.cashAndEquivalentsObjectives.target}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cashAndEquivalentsObjectives: {
                      ...formData.cashAndEquivalentsObjectives,
                      target: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Upper Limit %</label>
              <SIAInput
                value={formData.cashAndEquivalentsObjectives.upperLimit}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    cashAndEquivalentsObjectives: {
                      ...formData.cashAndEquivalentsObjectives,
                      upperLimit: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
          </div>
        </div>
        <div className="ips-objective-section">
          <p className="ips-section-body-container-header">Fixed Income Objectives</p>
          <div className="ips-objective-inputs-section">
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Lower Limit %</label>
              <SIAInput
                value={formData.fixedIncomeObjectives.lowerLimit}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    fixedIncomeObjectives: {
                      ...formData.fixedIncomeObjectives,
                      lowerLimit: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Target %</label>
              <SIAInput
                value={formData.fixedIncomeObjectives.target}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    fixedIncomeObjectives: {
                      ...formData.fixedIncomeObjectives,
                      target: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Upper Limit %</label>
              <SIAInput
                value={formData.fixedIncomeObjectives.upperLimit}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    fixedIncomeObjectives: {
                      ...formData.fixedIncomeObjectives,
                      upperLimit: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
          </div>
        </div>
        <div className="ips-objective-section">
          <p className="ips-section-body-container-header">Equity Objectives</p>
          <div className="ips-objective-inputs-section">
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Lower Limit %</label>
              <SIAInput
                value={formData.equityObjectives.lowerLimit}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    equityObjectives: {
                      ...formData.equityObjectives,
                      lowerLimit: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Target %</label>
              <SIAInput
                value={formData.equityObjectives.target}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    equityObjectives: {
                      ...formData.equityObjectives,
                      target: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Upper Limit %</label>
              <SIAInput
                value={formData.equityObjectives.upperLimit}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    equityObjectives: {
                      ...formData.equityObjectives,
                      upperLimit: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
          </div>
        </div>
        <div className="ips-objective-section">
          <p className="ips-section-body-container-header">Alternative Objectives</p>
          <div className="ips-objective-inputs-section">
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Lower Limit %</label>
              <SIAInput
                value={formData.alternativeInvestmentsObjectives.lowerLimit}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    alternativeInvestmentsObjectives: {
                      ...formData.alternativeInvestmentsObjectives,
                      lowerLimit: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Target %</label>
              <SIAInput
                value={formData.alternativeInvestmentsObjectives.target}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    alternativeInvestmentsObjectives: {
                      ...formData.alternativeInvestmentsObjectives,
                      target: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
            <div className="ips-objective-input-section">
              <label style={{ marginRight: "0.8rem" }}>Upper Limit %</label>
              <SIAInput
                value={formData.alternativeInvestmentsObjectives.upperLimit}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    alternativeInvestmentsObjectives: {
                      ...formData.alternativeInvestmentsObjectives,
                      upperLimit: Number(e.target.value),
                    },
                  })
                }
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPSClientInvestmentObjectives;
