import { useCheckOSSRiskToleranceMutation } from "apis/mainApi";
import { ParticipantDetailsSectionProps } from "features/participant/types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WorkflowStatus from "../../WorkflowStatus";

/**
 * Component to display the status of the RTQ Signed by Participant Workflow Item
 * @param param0
 * @returns
 */
const RTQSignedParticipantItem = ({ participant }: ParticipantDetailsSectionProps) => {
  const navigate = useNavigate();
  const completedPreviousTask = participant?.accountHolderWorkflow?.rtqSentForSignature;
  const completedTask = participant?.accountHolderWorkflow?.rtqSignedByParticipant;
  const completedText = "Questionaire Signed By Client";
  const notCompletedText = "Questionaire Not Signed By Client";
  const completeTooltipText = "";
  const notCompleteTooltipText = "";

  // Here we need to do a check for the RTQ Signed By Participant
  const [checkSignatureStatus] = useCheckOSSRiskToleranceMutation();

  useEffect(() => {
    if (participant.id) {
      checkSignatureStatus(participant.id);
    }
  }, []);

  const emptyActionHandler = () => {};
  // If the Previous Task is completed, then we display content with actions
  return (
    <WorkflowStatus
      completedPreviousTask={completedPreviousTask}
      completedTask={completedTask}
      completeTooltipText={completeTooltipText}
      notCompleteTooltipText={notCompleteTooltipText}
      completedText={completedText}
      notCompletedText={notCompletedText}
      completeActionHander={emptyActionHandler}
      notCompleteActionHandler={emptyActionHandler}
      notCompleteIconClass="bi-pen-fill"
      completeIconClass="bi-pen"
      noCursor={true}
    />
  );
};

export default RTQSignedParticipantItem;
