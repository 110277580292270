import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { notify } from "utils";

/**
 * This endpoint uses the Document Controller found in the .NET API
 */
const documentApi = createApi({
  reducerPath: "documentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/document`,
    credentials: "include",
  }),
  tagTypes: ["DocumentRetrieval"],
  endpoints: (builder) => ({
    // Gets all clients from the database
    uploadParticipantAMLDocument: builder.mutation({
      query: (formData) => ({
        url: `aml`,
        method: "POST",
        body: formData,
        formData: true,
      }),
    }),
    getAMLDocumentsByParticipant: builder.query({
      query: (participantId) => ({
        url: `aml/${participantId}`,
        method: "GET",
      }),
    }),
    downloadAMLDocument: builder.query({
      query: (documentId) => ({
        url: `aml/download/${documentId}`,
        method: "GET",
        responseHandler: async (response) => {
          if (!response.ok){
            notify("An Error Occured With Downloading the Document", 'error');
            return;
          }
          var hiddenElement = document.createElement("a");
          var url = window.URL || window.webkitURL;
          var blob = await response.blob();
          var blobPDF = url.createObjectURL(blob);
          hiddenElement.href = blobPDF;
          hiddenElement.target = "_blank";
          hiddenElement.download = `${documentId.slice(0, 6)}_AMLDocument.jpeg`;
          hiddenElement.click();
        },
        cache: "no-cache",
      }),
    }),
    getIncompleteAMLDocuments: builder.query({
      query: (participantId) => ({
        url: `aml/incomplete?participantId=${participantId}`,
        method: "GET",
      }),
    })
  }),
});

export const {
  useUploadParticipantAMLDocumentMutation,
  useLazyGetAMLDocumentsByParticipantQuery,
  useLazyDownloadAMLDocumentQuery,
  useLazyGetIncompleteAMLDocumentsQuery,
} = documentApi;
export default documentApi;
