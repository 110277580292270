import { RiskToleranceFormData, RiskToleranceSection } from "../../../types";
import SIATable, { ColumnConfig } from "../../../components/Shared/SIATable";
import "../styles/riskSection.scss";

interface RiskWizardSectionProps {
  formData: RiskToleranceFormData;
  section: RiskToleranceSection;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>, sectionId: number) => void;
  value?: number;
}

/**
 * This component focuses on rendering each section of the Risk Tolerance Form.
 * Each Section will contain a header, a question, and a select picker to choose an answer.
 * There are cases where a section may contain a table to depict another type of visualization.
 * @param param0
 * @returns
 */
const RiskWizardSection = ({ formData, section, onChange }: RiskWizardSectionProps) => {
  let columns: ColumnConfig[] = []; // used for a table
  let data: any[] = []; // used for a table

  // Set up a table if it's present
  if (section.isTable) {
    const tableData = section.tableData;
    if (tableData) {
      data = tableData;
      const columnKeys = Object.keys(tableData[0]);
      for (var key of columnKeys) {
        if (key != "id") {
          columns.push({ dataField: key, text: key });
        }
      }
    }
  }

  // Find the form value if it has been answered
  let sectionValue = 0;
  const sectionAnswer = formData.riskScoreSections.filter((x) => x.sectionId == section.id);
  if (sectionAnswer.length == 1) {
    sectionValue = sectionAnswer[0].answerScore;
  }

  return (
    <div className="p-5 max-w-2xl text-center w-full">
      <p className="text-lg font-bold mb-4">
        {section.id}. {section.header}
      </p>
      <p className="text-base mb-6">{section.question}</p>
      {section.isTable && (
        <div className="mb-6">
          <SIATable columns={columns} data={data} keyField="id" />
        </div>
      )}
      <div className="">
        <select
          onChange={(e) => onChange(e, section.id)}
          value={sectionValue}
          id={`risk-section-${section.id}`}
          className={`p-3 text-base rounded ${
            sectionValue === 0 ? "border-red-500" : "border-gray-300"
          } truncate w-4/5`}
        >
          <option value={0}>Select Option</option>
          {section.possibleAnswers.map((answer, index) => (
            <option key={index} value={answer.score}>
              {answer.answer}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default RiskWizardSection;
