import ProgressBar from "@ramonak/react-progress-bar";
import $ from "jquery";
import { sleep } from "../../../utils";
import { RiskToleranceFormData, RiskToleranceWizardStep } from "../../../types";
import "../styles/riskWizard.scss";

import { useLazyGetRiskToleranceResponseByClientQuery } from "apis/riskToleranceApi";

import { useEffect, useState } from "react";
import { GuidValues } from "../../../constants/enums";

interface WizardProps {
  steps: RiskToleranceWizardStep[];
  formData: RiskToleranceFormData;
  setFormData: React.Dispatch<React.SetStateAction<RiskToleranceFormData>>;
  handleStepChange: (data: Partial<RiskToleranceFormData>) => Promise<void>;
  currentRiskScore: number;
  handleRiskFormSubmit: () => Promise<void>;
  isCompleted: boolean;
  CompletedComponent: React.ComponentType<any>;
  showRiskScore: boolean;
}

function RiskToleranceWizard({
  steps,
  formData,
  handleStepChange,
  currentRiskScore,
  handleRiskFormSubmit,
  isCompleted,
  CompletedComponent,
  showRiskScore,
}: WizardProps) {
  const [setRiskData, riskData] = useLazyGetRiskToleranceResponseByClientQuery();
  if (formData.foClientId && riskData.isUninitialized) {
    setRiskData(formData.foClientId, false);
  }

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (riskData.isSuccess && !riskData.isFetching) {
      const result = riskData.data.result;
      handleStepChange({
        responseId: result.id,
        riskScoreSections: result.riskScoreSections,
        specialNotes: result.specialNotes,
      });
    }

    if (riskData.error) {
      handleStepChange({
        responseId: GuidValues.EMPTY_GUID,
      });
    }
  }, [riskData]);

  const handleNextStep = async (): Promise<void> => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = async () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const currentWizardStep = steps[currentStep];
  const CurrentStepComponent = steps[currentStep].component;

  // Choose Start and End Index if Applicable
  let startIndex = 0;
  let endIndex = 0;

  if (currentWizardStep.startIndex != undefined && currentWizardStep.endIndex != undefined) {
    startIndex = currentWizardStep.startIndex;
    endIndex = currentWizardStep.endIndex;
  }

  const completed = Math.floor((currentStep / steps.length) * 100);

  return (
    <>
      {isCompleted ? (
        <CompletedComponent />
      ) : (
        <div className="mt-4 w-full min-h-[800px]">
          <ProgressBar completed={completed} className="progressBar" />
          {showRiskScore && (
            <div className="risk-score-container">
              <p>Risk Score: {currentRiskScore}</p>
            </div>
          )}
          <div className="main-risk-container">
            {currentStep !== 0 && (
              <button onClick={handlePreviousStep} className="step-button">
                <i className="bi bi-arrow-left"></i>
                <p>Prev Step</p>
              </button>
            )}
            <CurrentStepComponent
              propData={formData}
              onChange={handleStepChange}
              startIndex={startIndex}
              endIndex={endIndex}
            />
            {currentStep < steps.length - 1 && formData.foClientId && (
              <button onClick={handleNextStep} className="step-button">
                <i className="bi bi-arrow-right"></i>
                <p>Next Step</p>
              </button>
            )}
          </div>

          <div className="risk-wizard-progress-btn-container">
            {currentStep === steps.length - 1 && (
              <button onClick={handleRiskFormSubmit} className="btn btn-success">
                Submit
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default RiskToleranceWizard;
