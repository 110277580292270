import { ParticipantDetailsSectionProps } from "features/participant/types";
import { useEffect, useState } from "react";
import KYCFormCompleted from "./KYCFormCompleted";
import KYCDocumentsUploaded from "./KYCDocumentsUploaded";
import { useLazyDownloadUnsignedKYCDocumentQuery } from "apis/mainApi";
import { MiniLoader } from "components/Shared";
import { Spinner } from "reactstrap";

/**
 * This is the Know Your Client Workflow Item List
 * 1. Know Your Client Form Completed
 * 2. Know Your Client Documents Uploaded
 * @param param0
 * @returns
 */
const KYCWorkflowItem = ({ participant }: ParticipantDetailsSectionProps) => {
  // Status Text of where we are in the process
  const [statusText, setStatusText] = useState("");
  const allStepsCompleted = participant?.accountHolderWorkflow?.kycDocumentsUploaded;
  const previousStepCompleted = participant?.accountHolderWorkflow?.rtqReviewedByAdvisor;

  const [downloadKYC] = useLazyDownloadUnsignedKYCDocumentQuery();

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (!participant?.accountHolderWorkflow?.rtqReviewedByAdvisor) {
      setStatusText("SIA Risk Tolerance Not Completed");
    } else if (!participant?.accountHolderWorkflow?.kycCompleted) {
      setStatusText("Know Your Client Must Be Completed By Advisor");
    } else if (!participant?.accountHolderWorkflow?.kycDocumentsUploaded) {
      setStatusText("Know Your Client Documents Must Be Uploaded By Advisor");
    } else {
      setStatusText("All Actions Items Are Completed For This Step. No Further Action Required");
    }
  }, [participant]);

  const handleDownloadKYC = async () => {
    setIsDownloading(true);
    await downloadKYC(participant.id);
    setIsDownloading(false);
  };

  return (
    <div className="workflow-item">
      <div className="workflow-item-header">
        <p className="workflow-item-header-text">
          SIA Know Your Client
          {!previousStepCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="Previous Step Not Completed"
            >
              <i className="bi bi-x-circle no-action"></i>
            </a>
          )}
          {previousStepCompleted && !allStepsCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="All Action Items Are Completed for this Step"
            >
              <i className="bi bi-exclamation-diamond not-complete"></i>
            </a>
          )}
          {previousStepCompleted && allStepsCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="Action Items Left To Complete"
            >
              <i className="bi bi-check-circle-fill complete"></i>
            </a>
          )}
        </p>
        <div className="action-items-container">
          {participant?.accountHolderWorkflow?.kycCompleted &&
            participant?.accountHolderWorkflow?.tradingAccountCompleted && (
              <>
                <a
                  data-tooltip-id="workflow-tooltip"
                  data-tooltip-content="Download Unsigned Individual Account Opening Documents"
                  style={{ marginRight: "1rem" }}
                  onClick={handleDownloadKYC}
                >
                  <i style={{ fontSize: "1.5rem" }} className="bi bi-download"></i>
                </a>
                {isDownloading && <Spinner />}
              </>
            )}
        </div>
      </div>
      <div className="workflow-item-status-container">{statusText}</div>
      <div className="workflow-items-status-container">
        <KYCFormCompleted participant={participant} />
        <KYCDocumentsUploaded participant={participant} />
      </div>
    </div>
  );
};

export default KYCWorkflowItem;
