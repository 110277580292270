import { checkEmptyValue, notify, validatePhone } from "../../../utils";
import validateSIN from "../../../utils/validateSIN";
import { Participant } from "../../../types";

/**
 * Function that validates a client's general details
 * @param participant
 * @returns
 **/

const ValidateGeneralDetails = (participant: Participant) => {
  // Check Details of General Information
  if (participant.generalDetails.isIndividual == undefined || null) {
    notify("Please Select an Individual Status", "error");
    return false;
  }

  if (checkEmptyValue(participant.generalDetails.title)) {
    notify("Please Select a Title", "error");
    return false;
  }
  if (checkEmptyValue(participant.generalDetails.firstName)) {
    notify("Please Enter a First Name", "error");
    return false;
  }
  if (checkEmptyValue(participant.generalDetails.lastName)) {
    notify("Please Enter a Last Name", "error");
    return false;
  }
  // SIN is required and needs to be validated
  if (checkEmptyValue(participant.generalDetails.sin)) {
    notify("Please Enter a Social Insurance Number", "error");
    return false;
  } else if (validateSIN(participant.generalDetails.sin) == false) {
    notify("Please Enter a Valid Social Insurance Number", "error");
    return false;
  }
  // Check that the date of birth is not null
  if (
    checkEmptyValue(participant.generalDetails.dateOfBirth) ||
    participant.generalDetails.dateOfBirth.length == 0
  ) {
    notify("Please Enter a Date of Birth", "error");
    return false;
  }

  if (
    checkEmptyValue(participant.generalDetails.numDependents) &&
    participant.generalDetails.numDependents != 0
  ) {
    notify("Please Enter a Number of Dependents", "error");
    return false;
  }
  // Check Citizenship
  if (checkEmptyValue(participant.generalDetails.citizenship)) {
    notify("Please Select a Citizenship", "error");
    return false;
  }
  if (
    !checkEmptyValue(participant.generalDetails.businessPhone) &&
    !validatePhone(participant.generalDetails.businessPhone)
  ) {
    notify("Please Enter a Valid Business Phone Number", "error");
    return false;
  }

  return true;
};

export default ValidateGeneralDetails;
