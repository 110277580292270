import { ParticipantDetailsSectionProps } from "features/participant/types";
import { useEffect, useState } from "react";
import SIAPackageSentToParticipant from "./SIAPackageSentToParticipant";
import SIAPackageSignedByClient from "./SIAPackageSignedByClient";
import SIAPackageSignedByAdvisor from "./SIAPackageSignedByAdvisor";
import SIAPackageSignedByCCO from "./SIAPackageSignedByCCO";
import {
  useCheckStatusOfSIAPackageMutation,
  useLazyDownloadSignedSIAPackageQuery,
} from "apis/mainApi";
import { ApiResponseModel } from "types";
import { notify } from "utils";
import { Spinner } from "reactstrap";

/**
 * This is the Know Your Client Workflow Item List
 * 1. Know Your Client Form Completed
 * 2. Know Your Client Documents Uploaded
 * @param param0
 * @returns
 */
const SIAPackageWorkflowItem = ({ participant }: ParticipantDetailsSectionProps) => {
  // Check Status Mutation
  const [checkSIAPackageMutation] = useCheckStatusOfSIAPackageMutation();
  // Download Signed SIA Documents
  const [downloadSignedDocuments] = useLazyDownloadSignedSIAPackageQuery();
  // Status Text of where we are in the process
  const [statusText, setStatusText] = useState("");
  const allStepsCompleted =
    participant?.accountHolderWorkflow?.siaPackageSentToParticipantForCompletion &&
    participant?.accountHolderWorkflow?.siaPackageSignedByAdvisor &&
    participant?.accountHolderWorkflow?.siaPackageSignedByCCO &&
    participant?.accountHolderWorkflow?.siaPackageSignedByParticipant;

  const previousStepCompleted = participant?.accountHolderWorkflow?.ipsCompleted;
  useEffect(() => {
    if (!participant?.accountHolderWorkflow?.ipsCompleted) {
      setStatusText("Previous Step Not Completed");
    } else if (!participant?.accountHolderWorkflow?.siaPackageSentToParticipantForCompletion) {
      setStatusText("SIA Package Must Be Sent to Be Signed By All Participants");
    } else if (!allStepsCompleted) {
      setStatusText("All Required Parties Must Sign Document");
    } else {
      setStatusText("All Actions Items Are Completed For This Step. No Further Action Required");
    }
  }, [participant]);

  useEffect(() => {
    if (participant?.accountHolderWorkflow?.siaPackageSentToParticipantForCompletion) {
      checkSIAPackageMutation(participant.id);
    }
  }, [participant]);

  const [isDownloading, setIsDownloading] = useState(false);

  const downloadSignedDocumentsHandler = async () => {
    setIsDownloading(true);
    const result: ApiResponseModel = await downloadSignedDocuments(participant.id);
    if (result.error != undefined) {
      notify("An error has occured!", "error");
      console.log(result.error);
    } else if (!result.data?.isSuccess && result.data?.errorMessages != undefined) {
      notify("An error has occured!", "error");
      console.log(result.data?.errorMessages);
    }
    setIsDownloading(false);
  };

  return (
    <div className="workflow-item">
      <div className="workflow-item-header">
        <p className="workflow-item-header-text">
          SIA Package Signatures
          {!previousStepCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="Previous Step Not Completed"
            >
              <i className="bi bi-x-circle no-action"></i>
            </a>
          )}
          {previousStepCompleted && !allStepsCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="All Action Items Are Completed for this Step"
            >
              <i className="bi bi-exclamation-diamond not-complete"></i>
            </a>
          )}
          {previousStepCompleted && allStepsCompleted && (
            <a
              data-tooltip-id="workflow-tooltip"
              data-tooltip-content="Action Items Left To Complete"
            >
              <i className="bi bi-check-circle-fill complete"></i>
            </a>
          )}
        </p>
        <div className="action-items-container">
          {participant?.accountHolderWorkflow?.siaPackageSignedByParticipant &&
            participant?.accountHolderWorkflow?.siaPackageSignedByAdvisor &&
            participant?.accountHolderWorkflow?.siaPackageSignedByCCO && (
              <>
                <a
                  data-tooltip-id="workflow-tooltip"
                  data-tooltip-content="Download Signed SIA Documents"
                  style={{ marginRight: "1rem" }}
                  onClick={downloadSignedDocumentsHandler}
                >
                  <i style={{ fontSize: "1.5rem" }} className="bi bi-download"></i>
                </a>
                {isDownloading && <Spinner />}
              </>
            )}
        </div>
      </div>
      <div className="workflow-item-status-container">{statusText}</div>
      <div className="workflow-items-status-container">
        <SIAPackageSentToParticipant participant={participant} />
        <SIAPackageSignedByClient participant={participant} />
        <SIAPackageSignedByAdvisor participant={participant} />
        <SIAPackageSignedByCCO participant={participant} />
      </div>
    </div>
  );
};

export default SIAPackageWorkflowItem;
