import {
  useArchiveInvestmentAccountMutation,
  useLazyGetInvestmentAccountByIdQuery,
  useUpdateInvestmentAccountMutation,
} from "apis/investmentAccountApi";
import { useLazyGetClientByIdQuery } from "apis/mainApi";
import withAuth from "HOC/withAuth";
import { ReturnIconSvg } from "assets/svg";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { ApiResponseModel, Participant } from "types";
import { InvestmentAccountDto } from "types/InvestmentAccount";
import AccountGeneralDetails from "./AccountGeneralDetails";
import { StepFormWizard } from "components/Shared";
import {
  BeneficiaryTypeRequiredAccounts,
  JointTypeRequiredAccounts,
} from "assets/StaticData/InvestmentAccount";
import AccountJointDetails from "./AccountJointDetails";
import AccountBeneficiaries from "./AccountBeneficiaries";
import ValidateGeneralDetails from "../utils/ValidateGeneralDetails";
import {
  ValidateBeneficiaries,
  ValidateEFTs,
  ValidateIncomeFundDetails,
  ValidateJointAccountDetails,
  ValidatePACDetails,
  ValidateSpouseContributer,
} from "../utils";
import { SuccessorAnnuitantTypeRequiredAccounts } from "assets/StaticData/InvestmentAccount/AccountTypeRequiredData";
import AccountSuccessorAnnuitant from "./AccountSuccessorAnnuitant";
import { InvestmentAccountSubType, InvestmentAccountType } from "constants/enums";
import AccountRESPBeneficiaries from "./AccountRESPBeneficiaries";
import AccountSpouseContributer from "./AccountSpouseContributer";
import AccountEFTDetails from "./AccountEFTDetails";
import AccountTransferDetails from "./AccountTransferDetails";
import { notify } from "utils";
import AccountPAC from "./AccountPAC";
import AccountSWP from "./AccountSWP";
import ValidateSWPDetails from "../utils/ValidateSWPDetails";
import AccountITF from "./AccountITF";
import AccountIncomeFundDetails from "./AccountIncomeFundDetails";
import AccountReview from "./AccountReview";
import { EmptyInvestmentAccount } from "constants/emptyStates";

function ViewTradingAccountPage() {
  // Navigate back
  const navigate = useNavigate();
  // Get the Participant Id from the URL
  const { participantId, accountId } = useParams();
  const [participant, setParticipant] = useState<Participant>();
  const [account, setAccount] = useState<InvestmentAccountDto>();
  const [viewMode, setViewMode] = useState<boolean>(true);
  const [clientQuery, clientResult] = useLazyGetClientByIdQuery();
  const [accountQuery, accountResult] = useLazyGetInvestmentAccountByIdQuery();
  const [isloading, setIsLoading] = useState<boolean>(false);

  const [updateAccount] = useUpdateInvestmentAccountMutation();
  // Get the participant information
  useEffect(() => {
    if (participantId && accountId) {
      clientQuery(participantId);
      accountQuery(accountId);
    }
  }, [participantId, accountId]);

  // Retrieves information about the participant
  // and sets up the account holder entry in the form data
  useEffect(() => {
    if (
      clientResult.isSuccess &&
      clientResult.data.result &&
      accountResult.isSuccess &&
      accountResult.data.result
    ) {
      const result: Participant = { ...clientResult.data.result };
      setParticipant(result);

      const account: InvestmentAccountDto = { ...accountResult.data.result };
      console.log(account);
      setAccount(account);
    }
  }, [clientResult, accountResult]);

  const handleSubmit = async (navigateBack?: boolean) => {
    // Update the Investment Account Details
    setIsLoading(true);
    const updateResult: ApiResponseModel = await updateAccount(account!);
    if (updateResult.data?.isSuccess) {
      notify("Account Updated Successfully", "success");
      setIsLoading(false);
      if (navigateBack) {
        navigate(-1);
      }
    } else {
      notify("Account Update Failed", "error");
      setIsLoading(false);
      console.log(updateResult);
    }
  };

  return (
    <div className="main-create-trade-container">
      <Tooltip id="trade-account-tooltip" />
      <div className="page-header-rtn-container">
        <button
          style={{ background: "none", border: "none", outline: "none" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ReturnIconSvg />
        </button>
      </div>
      <div className="main-create-trade-container-header">
        <h1>View Investment Account</h1>
        {participant && (
          <h3>
            {participant.generalDetails.firstName} {participant.generalDetails.lastName}
          </h3>
        )}
      </div>
      <div className="main-create-trade-container-body">
        <div className="action-items-container">
          <p>Actions</p>
          <button className="btn btn-primary" onClick={() => setViewMode(!viewMode)}>
            {viewMode ? "Edit Mode" : "View Mode"}
          </button>
        </div>
        <StepFormWizard
          startIndex={0}
          steps={[
            {
              label: "General Details",
              subtitle: "",
              content: AccountGeneralDetails,
              validator: ValidateGeneralDetails,
            },
            ...(JointTypeRequiredAccounts.includes(Number(account?.type)) && account?.isJointAccount
              ? [
                  {
                    label: "Joint Account Details",
                    subtitle: "",
                    content: AccountJointDetails,
                    validator: ValidateJointAccountDetails,
                  },
                ]
              : []),
            ...(BeneficiaryTypeRequiredAccounts.includes(Number(account?.type)) &&
            account?.allowBeneficiarys
              ? [
                  {
                    label: "Beneficiary Details",
                    subtitle: "",
                    content: AccountBeneficiaries,
                    validator: ValidateBeneficiaries,
                  },
                ]
              : []),
            ...(SuccessorAnnuitantTypeRequiredAccounts.includes(Number(account?.type)) &&
            account?.allowSuccessorAnnuitants
              ? [
                  {
                    label: "Successor Annuitant",
                    subtitle: "",
                    content: AccountSuccessorAnnuitant,
                    validator: ValidateBeneficiaries,
                  },
                ]
              : []),
            ...(account?.type == InvestmentAccountType.RESP
              ? [
                  {
                    label: "RESP Beneficiaries",
                    subtitle: "",
                    content: AccountRESPBeneficiaries,
                    validator: ValidateBeneficiaries,
                  },
                ]
              : []),
            ...(Number(account?.type) == InvestmentAccountType.RSP &&
            Number(account?.subType) == InvestmentAccountSubType.SPOUSAL
              ? [
                  {
                    label: "Spousal Contributers",
                    subtitle: "",
                    content: AccountSpouseContributer,
                    validator: ValidateSpouseContributer,
                  },
                ]
              : []),
            ...(account?.allowEFT
              ? [
                  {
                    label: "EFT Details",
                    subtitle: "",
                    content: AccountEFTDetails,
                    validator: ValidateEFTs,
                  },
                ]
              : []),
            ...(account?.allowTransfers
              ? [
                  {
                    label: "Transfer Details",
                    subtitle: "",
                    content: AccountTransferDetails,
                    validator: () => {
                      if (!account?.transferDetails || account?.transferDetails.length < 1) {
                        notify("Please add at least one transfer detail", "error");
                        return false;
                      }

                      return true;
                    },
                  },
                ]
              : []),
            ...(account?.allowPAC
              ? [
                  {
                    label: "PAC Details",
                    subtitle: "",
                    content: AccountPAC,
                    validator: ValidatePACDetails,
                  },
                ]
              : []),
            ...(account?.allowSWP
              ? [
                  {
                    label: "SWP Details",
                    subtitle: "",
                    content: AccountSWP,
                    validator: ValidateSWPDetails,
                  },
                ]
              : []),
            ...(account?.type == InvestmentAccountType.CASH_IN_TRUST_FOR
              ? [
                  {
                    label: "ITF Details",
                    subtitle: "",
                    content: AccountITF,
                    validator: () => {
                      if (!account?.trustees || account?.trustees.length < 1) {
                        notify("Please add at least one trustee", "error");
                        return false;
                      }
                      if (!account?.itfBeneficiaries || account?.itfBeneficiaries.length < 1) {
                        notify("Please add at least one beneficiary", "error");
                        return false;
                      }

                      return true;
                    },
                  },
                ]
              : []),
            ...(account?.type == InvestmentAccountType.RIF
              ? [
                  {
                    label: "Income Fund Details",
                    subtitle: "",
                    content: AccountIncomeFundDetails,
                    validator: ValidateIncomeFundDetails,
                  },
                ]
              : []),
            {
              label: "Review",
              subtitle: "",
              content: AccountReview,
            },
          ]}
          formData={account}
          setFormData={setAccount}
          nextText="Continue"
          submitForm={() => handleSubmit(true)}
          handleStepChange={() => {}}
          participant={participant}
          viewMode={viewMode}
        />
      </div>
    </div>
  );
}

export default withAuth(ViewTradingAccountPage);
