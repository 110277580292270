import { AMLDocumentType } from "constants/enums/AMLDocumentType";
import { SelectItem } from "types/Shared";

/**
 * AML Document Type Options
 * @author Teddy K.
 */
export const AMLDocumentTypeOptions: SelectItem[] = [
  {
    label: "Passport",
    value: AMLDocumentType.PASSPORT,
  },
  {
    label: "Driver License",
    value: AMLDocumentType.DRIVER_LICENSE,
  },
  {
    label: "Birth Certificate",
    value: AMLDocumentType.BIRTH_CERTIFICATE,
  },
  {
    label: "Citizenship Card",
    value: AMLDocumentType.CITIZENSHIP_CARD,
  },
  {
    label: "Residence Permit",
    value: AMLDocumentType.RESIDENCE_PERMIT,
  },
  {
    label: "Indian Status Certificate",
    value: AMLDocumentType.INDIAN_STATUS_CERTIFICATE,
  },
  {
    label: "Nexus Card",
    value: AMLDocumentType.NEXUS_CARD,
  },
  {
    label: "BC Services Card",
    value: AMLDocumentType.BC_SERVICES_CARD,
  },
  {
    label: "Provincial Health Card",
    value: AMLDocumentType.PROVINCIAL_HEALTH_CARD,
  },
  {
    label: "Provincial Photo ID Card",
    value: AMLDocumentType.PROVINCIAL_PHOTO_ID_CARD,
  },
  {
    label: "Canadian Armed Forces ID Card",
    value: AMLDocumentType.CANADIAN_ARMED_FORCES_ID_CARD,
  },
  {
    label: "Other",
    value: AMLDocumentType.OTHER,
  },
];
