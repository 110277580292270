import { GuidValues } from "constants/enums";
import { BankAccount } from "types/Participant/Participant";

const emptyBankAccount: BankAccount = {
  id: GuidValues.EMPTY_GUID,
  institutionName: "",
  accountName: "",
  address: "",
  city: "",
  region: "",
  country: "",
  postalCode: "",
  isPrimaryAccount: false,
  transitNumber: "",
  institutionNumber: "",
  accountNumber: "",
  currency: 0,
  voidCheque: null,
  isComplete: false,
};

export default emptyBankAccount;
