import { ParticipantRole } from "constants/enums";
import "../styles/participantWorkflows.scss";
import { ParticipantDetailsSectionProps } from "../types";
import { Tooltip } from "react-tooltip";
import { AccountHolderWorkflow } from "./workflows";

/**
 * The Primary Purpose of this component is to display the list of workflows that a participant is currently enrolled in.
 * Not only to display the list of workflows, but also to allows the user to interact with the workflow in order to provide
 * stuff like feedback, or to complete a workflow.
 * @returns
 */
function ParticipantWorkflows({ participant }: ParticipantDetailsSectionProps) {
  return (
    <div className="participant-workflow-container">
      <Tooltip id="workflow-tooltip" />
      <p className="workflow-container-header">Participant Workflows</p>
      <div className="workflow-container-header-underline"></div>
      <div className="participant-workflows">
        {participant.roles.includes(ParticipantRole.ACCOUNT_HOLDER.toLowerCase()) && (
          <AccountHolderWorkflow participant={participant} />
        )}
      </div>
    </div>
  );
}

export default ParticipantWorkflows;
