import { useCreateInitialNewParticipantMutation } from "apis/mainApi";
import { SIAInput } from "components/Shared";
import { EmptyNewParticipant } from "constants/emptyStates";
import { useState } from "react";
import { ApiResponseModel } from "types";
import { NewParticipantWithDOBDto } from "types/Participant";
import { notify } from "utils";

/**
 * @name    CreateNewParticipantWithRole
 * @summary This component is responsible for creating a new participant with a specified role and household id.
 * @param   roleName the name of the role
 * @param   role the role of the participant
 * @param   householdId the household id of the participant
 * @param   setIsCreatingNewParticipant the state for creating a new participant
 * @param   handleBeneficiaryAdd the function to handle adding a beneficiary
 * @returns React.Component
 */
export function CreateNewParticipantWithRole({
  roleName,
  role,
  householdId,
  setIsCreatingNewParticipant,
  handleBeneficiaryAdd,
}: {
  roleName: string;
  role: string;
  householdId: string;
  setIsCreatingNewParticipant: React.Dispatch<React.SetStateAction<boolean>>;
  handleBeneficiaryAdd: (participantId: string) => void;
}) {
  const [newParticipant, setNewParticipant] = useState<NewParticipantWithDOBDto>({
    ...EmptyNewParticipant,
    roles: [role],
    householdId: householdId,
  });

  const [createNewParticipant] = useCreateInitialNewParticipantMutation();

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Create the new participant
    const apiResponse: ApiResponseModel = await createNewParticipant(newParticipant);
    if (apiResponse.data && apiResponse.data.isSuccess) {
      notify("Sucessfully created new participant", "success");
      setIsCreatingNewParticipant(false);
      const result = apiResponse.data.result;
      handleBeneficiaryAdd(result.id);
    } else {
      console.log(apiResponse);
      notify("Failed to create new participant", "error");
    }
  };

  return (
    <form className="new-participant-form" onSubmit={handleFormSubmit}>
      <div className="new-participant-form-header">Create New {roleName}</div>
      <div className="new-participant-label-input-container">
        <div className="label-container">
          <label>First Name*</label>
        </div>
        <SIAInput
          value={newParticipant.firstName}
          type="text"
          onChange={(e: any) => setNewParticipant({ ...newParticipant, firstName: e.target.value })}
        />
      </div>
      <div className="new-participant-label-input-container">
        <div className="label-container">
          <label>Last Name*</label>
        </div>
        <SIAInput
          value={newParticipant.lastName}
          type="text"
          onChange={(e: any) => setNewParticipant({ ...newParticipant, lastName: e.target.value })}
        />
      </div>
      <div className="new-participant-label-input-container">
        <div className="label-container">
          <label>Social Insurance Number*</label>
        </div>
        <SIAInput
          value={newParticipant.sin}
          type="text"
          onChange={(e: any) => setNewParticipant({ ...newParticipant, sin: e.target.value })}
        />
      </div>
      <div className="new-participant-label-input-container">
        <div className="label-container">
          <label>Date of Birth*</label>
        </div>
        <SIAInput
          value={newParticipant.dateOfBirth}
          type="date"
          onChange={(e: any) =>
            setNewParticipant({ ...newParticipant, dateOfBirth: e.target.value })
          }
        />
      </div>
      <div className="new-participant-label-input-container">
        <div className="label-container">
          <label>Email</label>
        </div>
        <SIAInput
          value={newParticipant.email}
          type="text"
          onChange={(e: any) => setNewParticipant({ ...newParticipant, email: e.target.value })}
          placeholder="user@example.com"
        />
      </div>
      <div className="new-participant-label-input-container">
        <div className="label-container">
          <label>Phone Number</label>
        </div>
        <SIAInput
          value={newParticipant.phoneNumber}
          type="text"
          onChange={(e: any) =>
            setNewParticipant({ ...newParticipant, phoneNumber: e.target.value })
          }
          placeholder="(123)-456-7890"
        />
      </div>
      <div className="action-btn-container">
        <button className="btn btn-danger" onClick={() => setIsCreatingNewParticipant(false)}>
          Cancel
        </button>
        <button className="btn btn-primary" type="submit">
          Create and Add as {roleName}
        </button>
      </div>
    </form>
  );
}
